import React, { FC, useEffect, useRef, useState } from "react";
import PdfIcon from '../../assets/icons/pdf_icon.png'
import DownloadIcon from '../../assets/icons/download_icon.png'
import './ExerciseReportList.scss';
import API from "../../services/rest/api";
import { useParams } from "react-router-dom";


type ExerciseReportListProps = {
    patient_id?: any
}

const ExerciseReportList: FC<ExerciseReportListProps> = () => {
    const params = useParams()
    const [reportList, setReportList] = useState<any>([]);

    useEffect(() => {
        const patient_id: any = params.id || params?.patientId
        localStorage.setItem('patient_id', patient_id)
        const token: any = params.token
        localStorage.setItem('token', token)
    }, [params])

    useEffect(() => {
        getPatientReports()
    }, [])

    //funtion to get patients report list
    const getPatientReports = async () => {
        try {
            const data = await API.GetPatientReport({ patient_id: params.id || params?.patientId, report_type: 'exercise' })
            if (data?.code === '1') {
                setReportList(data?.data)
            }
            else {
                setReportList([])
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="report-list-container">
            <div className="report-list-heading">Patient Exercise Reports List</div>
            {reportList.length ?
                <>
                    {
                        reportList?.map((report: any) => {
                            return (
                                <div className="report-container">
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <img src={PdfIcon} width={35} height={35} />
                                            <div className="report-date-title">
                                                <div className="report-title">
                                                    {report?.document_title} <span style={{ color: `${report?.status === 'Pending' ? 'orange' : (report?.status === 'Rejected' ? 'red' : 'green')}` }}>{`(${report?.status})`}</span>
                                                </div>
                                                <div className="report-date">
                                                    {report?.start_date} to {report?.end_date}
                                                </div>
                                            </div>
                                        </div>
                                        <a target="_blank" href={report?.document_url}>
                                            <img src={DownloadIcon} alt="download_icon" style={{ marginRight: '20px' }} />
                                        </a>
                                    </div>
                                    {report?.chief_remarks &&
                                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '5px', width: '96%' }}>
                                            <div style={{ color: '#760FB2', fontSize: '16px', fontWeight: '700' }}>Chief Remarks :-</div>
                                            <div>{report?.chief_remarks}</div>
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                </>
                :
                <div style={{ fontSize: '22px', fontWeight: '500', textAlign: 'center', marginTop: '100px', color: 'lightgray' }}>No Reports found!</div>
            }

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
                <button
                    className="add-que-button"
                    onClick={() => {
                        window.parent.postMessage("closeReportList", "*")
                    }
                    }
                >
                    Close
                </button>
            </div>

        </div>
    )
}

export default ExerciseReportList;