import { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Button, message, Modal, Popover, Table, Tag } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import AddPlanModal from './AddPlanModal';
import ViewPlanModal from "./ViewPlanModal";
import ViewAssessmentScore from './ViewAssessmentScore';
import ViewQNAModal from './ViewQNAModal';
import API from '../../services/rest/api';
import { getDecryptedData, getEncryptedBody } from '../../utils/common';

import './GetAllGetActyvPlans.scss';
import ViewPlanScoreModal from './ViewPlanScoreModal';

const GetAllGetActyvPlans: React.FC = () => {

    const params = useParams();
    const location = useLocation();

    const [getActyvId, setGetActyvId] = useState<string>('');
    const [getActyvHCId, setGetActyvHCId] = useState<string>('');
    const [patientId, setPatientId] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [planList, setPlanList] = useState<Array<any>>([]);
    const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
    const [viewModalOpen, setViewModalOpen] = useState<boolean>(false);
    const [quesModalOpen, setQuesModalOpen] = useState<boolean>(false);
    const [viewAssessmentModalOpen, setViewAssessmentModalOpen] = useState<boolean>(false);
    const [viewPlanScoreModalOpen, setViewPlanScoreModalOpen] = useState<boolean>(false);
    const [refreshList, setRefreshList] = useState<boolean>(false);
    const [selectedPlan, setSelectedPlan] = useState<any>(null);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name: any) => <a style={{ color: '#760fb2' }}>{name}</a>,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            // render: (text: any) => <a style={{ color: '#760fb2' }}>{text}</a>,
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
        },
        {
            title: 'Assessment Status',
            key: 'st',
            dataIndex: 'st',
            render: (_: any, { status }: any) => {
                let color;
                if (status === 'Completed') {
                    color = 'green';
                }
                if (status === 'N/A') {
                    color = '#a19b9a';
                }
                if (status === 'Pending') {
                    color = 'orange';
                }
                return (
                    <Tag color={color} key={status}>
                        {status.toUpperCase()}
                    </Tag>
                )
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (_: any, record: any) => {
                const PopoverComponent = ({ record, onPressViewPlan }: any) => {
                    const [open, setOpen] = useState(false);

                    const content = (
                        <div>
                            {/* <p style={{ cursor: 'pointer' }} onClick={() => { onPressViewPlan(record.id, record.type, record.sessionId); setOpen(false); }}>
                                {record.type === 'Assessment'
                                    ? 'View Assessment'
                                    : 'View Exercise Plan'}
                            </p>
                            {((record.status === 'Completed' && record.type === 'Assessment') || record.type === 'Exercise')
                                && <p style={{ cursor: 'pointer' }} onClick={() => { onPressViewPlan(record.id, record.type, record.sessionId); setOpen(false); }}>
                                    View Report
                                </p>
                            }
                            <p style={{ cursor: 'pointer' }} onClick={() => showConfirm(record.id)} >Delete Plan</p> */}
                            {record.type === 'Assessment'
                                ? <>
                                    {record.status === 'Completed'
                                        ? <p style={{ cursor: 'pointer' }} onClick={() => { onPressViewPlan(record.id, record.type, record.sessionId, 'AssessmentScore'); setOpen(false); }} >View Scores</p>
                                        : <p style={{ cursor: 'pointer' }} onClick={() => { onPressViewPlan(record.id, record.type, record.sessionId); setOpen(false); }} >View Assessment</p>}
                                </>
                                : <>
                                    <p style={{ cursor: 'pointer' }} onClick={() => { onPressViewPlan(record.id, record.type, record.sessionId, 'ViewPlan', record.endDate); setOpen(false); }} >View Plan</p>
                                    <p style={{ cursor: 'pointer' }} onClick={() => showConfirm(record.id)} >Delete Plan</p>
                                    <p style={{ cursor: 'pointer' }} onClick={() => { onPressViewPlan(record.id, record.type, record.sessionId, 'PlanScore'); setOpen(false); }}  >View Adherence</p>
                                </>}
                        </div>
                    );

                    return (
                        <Popover
                            content={content}
                            placement='bottomRight'
                            trigger='click'
                            onOpenChange={(o) => setOpen(o)}
                            open={open}
                        >
                            <Button type='primary' style={{ backgroundColor: '#760fb2' }}>Action</Button>
                        </Popover>
                    );
                };

                return (<PopoverComponent key={record.id} record={record} onPressViewPlan={onPressViewPlan} />);
            },
        },
    ];

    const showConfirm = (planId: string) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this plan?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action will delete the plan and its sessions.',
            async onOk() {
                try {
                    if (!planId) return;
                    const deletePlan = await API.DeleteGetActyvPlan(planId);
                    const decryptedResp: any = JSON.parse(getDecryptedData(deletePlan));
                    if (decryptedResp?.code !== 1) throw new Error(decryptedResp?.msg || 'Internal server error!');
                    message.success({ content: decryptedResp?.msg || 'Plan deleted successfully.' });
                    setRefreshList(true);
                } catch (error) {
                    console.log(error);
                    message.error({ content: 'Failed to delete plan!' });
                }
            },
            onCancel() { },
            okButtonProps: {
                style: { backgroundColor: '#760fb2', borderColor: '#760fb2' },
            }
        });
    };

    useEffect(() => {
        const token = params.token;
        const getActyvId = params.getActyvId;
        const getActyvHCId = params.getActyvHCId;
        const patientId = params.patientId;

        localStorage.setItem('token',
            (location.state?.token === ':token' || location.state?.token === undefined)
                ? token
                : location.state?.token
        );
        sessionStorage.setItem('token', (location.state?.token === ':token' || location.state?.token === undefined) ? token : location.state?.token);

        setGetActyvId(getActyvId as string);
        setGetActyvHCId(getActyvHCId as string);
        setPatientId(patientId as string);
    }, [params]);

    useEffect(() => {
        if (loading || !getActyvId) return;
        getPlanList(getActyvId);
    }, [getActyvId]);

    useEffect(() => {
        if (loading || !getActyvId || !refreshList) return;
        getPlanList(getActyvId);
    }, [refreshList])

    const modifyPlanResponseData = (response: any[]) => {
        const modifiedData = response.map((resp: any, index: number) => ({
            key: index,
            type: resp.Type || '',
            name: resp.Name || '',
            startDate: resp.startDate || '',
            endDate: resp.endDate || '',
            status: resp.status || '',
            id: resp.Id || '',
            sessionId: resp.sessionId || ''
        }));
        return modifiedData;
    };

    const getPlanList = async (getActyvId: string) => {
        try {
            setLoading(true);
            const encrypted = getEncryptedBody({ userId: getActyvId });
            const planData = await API.GetAllGetActyvPlanByUserId(encrypted);
            const decryptedResp: any = JSON.parse(getDecryptedData(planData));
            if (decryptedResp.code !== 1) throw new Error(decryptedResp.msg || 'Internal server error!');
            const modifiedPlanlist = modifyPlanResponseData(decryptedResp.response || []);
            setPlanList(modifiedPlanlist);
        } catch (error) {
            console.log(error);
            message.error({ content: 'Failed to fetch plan list!', });
        } finally {
            setLoading(false);
            setRefreshList(false);
        }
    };

    const onPressViewPlan = (id: string, type: string, sessionId: string, screen: string = 'ViewPlan', endDate: string) => {
        setSelectedPlan({ id, type, sessionId, endDate });
        if (screen === 'AssessmentScore') {
            setViewAssessmentModalOpen(true);
        } else if (screen === 'PlanScore') {
            setViewPlanScoreModalOpen(true);
        } else {
            setViewModalOpen(true);
        }
    };

    const onCloseViewModal = () => {
        setSelectedPlan(null);
        setViewPlanScoreModalOpen(false);
        setViewAssessmentModalOpen(false);
        setViewModalOpen(false);
    };

    return (
        <div className='container-wrapper'>
            <div className='button-wrapper'>
                <button
                    onClick={() => setQuesModalOpen(true)}
                    className='buttons'
                >
                    View Questionnaire
                </button>
                <button
                    onClick={() => setAddModalOpen(true)}
                    className='buttons'
                >
                    Add Exercise Plan
                </button>
            </div>
            <div className='table-wrapper'>
                <Table className='custom-table' dataSource={planList} columns={columns} loading={loading} />
            </div>

            {addModalOpen &&
                <Modal
                    className='custom-modal'
                    title='Add Exercise Plan'
                    centered
                    closeIcon={false}
                    open={addModalOpen}
                    // onOk={() => setAddModalOpen(false)}
                    // onCancel={() => setAddModalOpen(false)}
                    width={'85vw'}
                    footer={false}
                >
                    <AddPlanModal
                        setAddModalOpen={setAddModalOpen}
                        setRefreshList={setRefreshList}
                        getActyvId={getActyvId}
                        getActyvHCId={getActyvHCId}
                    />
                </Modal>}

            {viewModalOpen && <Modal
                className='view-plan-modal'
                title='Plan details'
                centered
                open={viewModalOpen}
                onOk={onCloseViewModal}
                onCancel={onCloseViewModal}
                width={'85vw'}
                footer={false}
            >
                <ViewPlanModal selectedPlan={selectedPlan} />
            </Modal>}

            {quesModalOpen && <Modal
                className='view-qna-modal'
                title='View patient saved question answer'
                centered
                open={quesModalOpen}
                onOk={() => setQuesModalOpen(false)}
                onCancel={() => setQuesModalOpen(false)}
                width={'85vw'}
                footer={false}
            >
                <ViewQNAModal patientId={patientId} />
            </Modal>}

            {viewAssessmentModalOpen && <Modal
                className='view-assessment-score-modal'
                title='View Assessment Score'
                centered
                open={viewAssessmentModalOpen}
                onOk={onCloseViewModal}
                onCancel={onCloseViewModal}
                width={'85vw'}
                footer={false}
            >
                <ViewAssessmentScore selectedPlan={selectedPlan} />
            </Modal>}

            {viewPlanScoreModalOpen && <Modal
                className='view-plan-score-modal'
                title='View Exercise Plan Score'
                centered
                open={viewPlanScoreModalOpen}
                onOk={onCloseViewModal}
                onCancel={onCloseViewModal}
                width={'85vw'}
                footer={false}
            >
                <ViewPlanScoreModal userId={getActyvId} selectedPlan={selectedPlan} />
            </Modal>}
        </div>
    );
};

export default GetAllGetActyvPlans;