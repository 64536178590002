import React, { useEffect, useState } from "react";
import ProfilePlaceholder from '../../assets/profile_placeholder.jpeg'
import './PatientDetails.scss'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BackIcon } from "../../assets/icons";
import HomeScreen from "../HomeScreen/HomeScreen";
import API from "../../services/rest/api";

const PatientDetails = () => {

    const [patientDetails, setPatientDetails] = useState<any>(null)
    const params = useParams()
    const navigate = useNavigate()
    const hcId = localStorage.getItem('health_coach_id')

    useEffect(() => {
        getPatientDetails()
    }, [params])

    const onGoBack = () => {
        navigate(-1)
    }

    const getPatientDetails = async () => {
        try {
            let payload = {
                patient_id: params?.patientId,
                health_coach_id: hcId 
            }
            const patientData = await API.GetPatientDetails(payload)
            if (patientData?.code === '1') {
                setPatientDetails(patientData?.data)
            }
            else {
                console.log(patientData?.message)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="patient-details-container">
            <div className="page-header">
                <div onClick={onGoBack} style={{ display: 'flex', alignItems: 'center' }}>
                    <BackIcon className="back-icon" />
                </div>
                <span className="header-text">
                    Patient Details
                </span>
            </div>

            <div className="patient-details-content">
                <div className="patient-image-container">
                    <img className="patient-img" src={patientDetails?.profile_pic ? patientDetails?.profile_pic :ProfilePlaceholder} alt='profile_placeholder' />
                    <div className="patient-size">
                        <span className="patient-height">Height: </span>
                        <span className="patient-height">Weight : </span>
                    </div>
                </div>

                <div className="patient-content">
                    <div className="patient-content-left">
                        <span className="patient-name">{patientDetails?.name}</span>
                        <div className="patient-left-bottom">
                            <span className="patient-age">{patientDetails?.patient_age}-{patientDetails?.gender}</span>
                            <span className="patient-age">{patientDetails?.contact_no}</span>
                        </div>
                    </div>

                    <div className="vertical-line"></div>

                    <div className="patient-content-left">
                        <span className="patient-name">{patientDetails?.medical_condition_name}</span>
                    </div>

                    <div className="vertical-line"></div>

                    <div className="patient-content-left">
                        <span className="patient-name">Dr.{patientDetails?.patient_link_doctor_details[0].name}</span>
                        <div className="patient-left-bottom">
                            <span className="patient-age">{patientDetails?.patient_link_doctor_details[0].contact_no}</span>
                        </div>
                    </div>

                </div>
            </div>

            {/* <HomeScreen /> */}
        </div>
    )
}

export default PatientDetails