import { FunctionComponent, useMemo, type CSSProperties, useState, useEffect } from "react";
import "./GroupComponent.css";

export type GroupComponentType = {
  fRAMEFRAMEPlaceholder?: string;
  propWidth?: CSSProperties["width"];
  pdfUrl: string;
  onApprove: () => void;
  isApproved: boolean;
  onReupload: () => void;
  isReupload: boolean;
  // isReupload: boolean;
};

const GroupComponent: FunctionComponent<GroupComponentType> = ({
  fRAMEFRAMEPlaceholder,
  propWidth,
  pdfUrl,
  onApprove,
  isApproved,
  onReupload,
  isReupload
  // isReupload
}) => {
  const [viewPdfUrl, setViewPdfUrl] = useState<string | null>(null);
  const fRAMEFRAMEProductsLStyle: CSSProperties = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  useEffect(() => {
    fetchPdfData();
  }, []);

  const fetchPdfData = async () => {
    try {
      // Replace 'your-pdf-url' with the actual URL of your PDF
      const response = await fetch(pdfUrl);
      const blob = await response.blob();

      // Create a blob URL for the PDF content
      const url = window.URL.createObjectURL(blob);
      setViewPdfUrl(url);
    } catch (error) {
      console.error('Error fetching PDF data:', error);
    }
  };

  const openPDFPreviewInNewTab = (pdfUrl: string) => {
    // Fetch the PDF file
    fetch(pdfUrl, {
      headers: {
        'Content-Disposition': 'inline',// Set the Content-Disposition header to 'inline'
        'allow-origin': "*"
      }
    })
      .then(response => response.blob())
      .then(pdfBlob => {
        // Create a URL for the PDF blob
        const pdfBlobUrl = URL.createObjectURL(pdfBlob);
        // Open the PDF preview in a new tab
        window.open(pdfBlobUrl, '_blank');
      })
      .catch(error => {
        console.error('Error fetching PDF:', error);
      });
  };

  const handleOpenPdf = async (pdfUrl: any) => {
    try {
      const response = await fetch(pdfUrl);
      const blob = await response.blob();

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };

  const handleApprove = () => {
    onApprove();
  };

  const handleReupload = () => {
    onReupload();
  }

  return (
    <div className="group-div">
      <div className="shape3" />
      <div className="f-r-a-m-e-group-frame-products">
        <label
          className="f-r-a-m-e-f-r-a-m-e-products-l"
          // placeholder={fRAMEFRAMEPlaceholder}
          style={fRAMEFRAMEProductsLStyle}
        >{fRAMEFRAMEPlaceholder}</label>
        <div className="f-r-a-m-e-f-r-a-m-e-button" />
      </div>
      {pdfUrl ?
        (
          <div
            className="pdf-viewer"
          // onClick={() => handleOpenPdf(pdfUrl)}
          >
            {/* <a href={pdfUrl} target="_blank" className="pdf-label"> */}
            <a href={pdfUrl} target="_blank" className="pdf-label" rel="noopener noreferrer" download="">
              <div className="pdf-icon" >
                <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
              </div>
              <div className="pdf-label">View Document</div>
            </a>
          </div>
        ) :
        (
          <div className="pdf-viewer">
            <div className="pdf-label">
              No Records
            </div>
          </div>
        )}

      {!isApproved && !isReupload &&
        <div className="button-wrapper">
          <button 
            className={"button0" + (!pdfUrl ? " disabled" : "")} 
            onClick={handleApprove} 
            disabled={!pdfUrl}
          >
            <div className="f-r-a-m-e-input-field-frame-la">
              <div className="button99">Approve</div>
            </div>
          </button>
          <button
            className={"button0" + (!pdfUrl ? " disabled" : " ")}
            onClick={handleReupload}
            disabled={!pdfUrl}
          >
            <div className="f-r-a-m-e-input-field-frame-la">
              <div className="button99">Request to upload again</div>
            </div>
          </button>
        </div>
      }
      {isApproved &&
        <button className="button-Green" style={{ display: isApproved ? 'block' : 'none', width: "30%" }}>
          <div className="f-r-a-m-e-input-field-frame-la">
            <div className="button99">Approved</div>
          </div>
        </button>
      }
      {isReupload &&
        <button
          className={"button0" + (isReupload ? " disabled" : " ")}
          style={{ display: isApproved ? 'none' : 'block', width: "36%" }}
          disabled={pdfUrl ? false : true}
        >
          <div className="f-r-a-m-e-input-field-frame-la">
            <div className="button99" >Requested to upload again</div>
          </div>
        </button>
      }
    </div>
  );
};

export default GroupComponent;


