import React, { useState } from 'react';
import { Button, message } from 'antd';

import Input from '../../../atoms/Input/Input';
import API from '../../../services/rest/api';

import './index.scss';

interface CopyDietTemplatePropsType {
    templateId: string | null;
    onCloseModal: () => void;
    setRefreshList: React.Dispatch<React.SetStateAction<boolean>>;
}

const CopyDietTemplate: React.FC<CopyDietTemplatePropsType> = (props) => {

    const { templateId, onCloseModal, setRefreshList } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [newTemplateName, setNewTemplateName] = useState<string>();

    const onSubmit = async () => {
        if (!newTemplateName) return;
        try {
            setLoading(true);
            const { status: fetchStatus, data: templateData, message: fetchMessage } = await API.GetDietTemplateByID(templateId);

            if (fetchStatus !== "success") {
                message.error(fetchMessage || 'Error while fetching template!');
                return;
            }

            const newTemplate = modifyData(templateData, newTemplateName);
            const { status: createStatus, message: createMessage } = await API.CreateDietTemplate(newTemplate);
            if (createStatus === "success") {
                message.success('Template Copied Successfully.');
                setRefreshList(true);
                onCloseModal();
            } else {
                message.error(createMessage || 'Error while copy template!');
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const modifyData = (data: any, newTemplateName: string) => {
        const meals = data.meals_data.map((meal: any) => ({
            meal_types_id: meal?.meal_types_id,
            meal_name: meal?.meal_name,
            order_no: meal?.order_no,
            start_time: meal?.start_time,
            end_time: meal?.end_time,
            hide_meal: meal?.hide_meal,
            options: meal.options.flatMap((option: any) =>
                option.food_items.map((foodItem: any) => ({
                    tips: option.tips,
                    option_no: option.option_no.toString(),
                    food_item_id: foodItem.food_item_id,
                    food_name: foodItem.food_name,
                    quantity: foodItem.quantity,
                    unit_option_id: foodItem.unit_option_id,
                    unit_option_name: foodItem.unit_option_name,
                    order_no: foodItem.order_no
                }))
            ),
        }));

        return {
            template_name: newTemplateName,
            actual_calories: data?.actual_calories,
            total_calories: data?.total_calories,
            proteins: data?.proteins,
            fat: data?.fat,
            carbs: data?.carbs,
            meals_data: meals
        };
    };

    return (
        <div>
            <div className='new_template_name'>
                <Input
                    label='Enter new template name '
                    placeholder='Template Name'
                    type='text'
                    name='templateName'
                    id='templateName'
                    required
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewTemplateName(e.target.value)}
                    value={newTemplateName}
                />
            </div>
            <div className='submit_button_div'>
                <Button
                    type='primary'
                    style={{ backgroundColor: '#760fb2' }}
                    onClick={onSubmit}
                    disabled={loading}
                    loading={loading}
                >
                    Submit
                </Button>
                <button
                    className='cancel_button'
                    onClick={onCloseModal}
                >
                    Cancel
                </button>
            </div>
        </div>
    )
}

export default CopyDietTemplate