import React from 'react';
import styles from './index.module.scss';
import Lottie from 'lottie-react';
import loaderAnimation from '../../assets/lotties/loader.json';

const Loader = ({ noBackground }) => {
  return (
    <section className={noBackground ? styles.loading : styles.loading_with_bg}>
      <Lottie
        className={`${styles.loaderAnimation} mt-3`}
        animationData={loaderAnimation}
        loop={true}
      />
    </section>
  );
};

export default Loader;
