import { FunctionComponent, useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "./CycleDetails.css";
import rectangleIcon from "../../assets/icons/rectangle-12.svg";
import arrowLeftIcon from "../../assets/icons/arrow--left.svg";
import directionRightIcon from "../../assets/icons/rightArrow.svg";
import GroupComponent from "../../molecules/CgGroupComponent/GroupComponent";
import ConfirmationModal from "../../molecules/CgConfirmationModal/ConfirmationModal";
import { getDecryptedData, getEncryptedBody, getEncryptedText } from "../../utils/common";
import API, { GetOverallPatientCount } from "../../services/rest/api";
import RequestToUpload from "../../molecules/CgRequestToUpload/RequestToUpload";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import editIcon from '../../assets/icons/edit.svg';

const CycleDetails: FunctionComponent = () => {

  interface VivitraDate {
    claimed_date?: string;
    ordered_date?: string;
    shipped_date?: string;
    delivered_date?: string;
  }
  const [userDetails, setUserDetails] = useState<any>(null)
  const [patientData, setPatientData] = useState<any>(null)
  const [coins, setCoins] = useState<any>();
  const [token, setToken] = useState<any>();
  const [isRewardsAssign, setIsRewardsAssign] = useState<boolean>(false);
  const [isPrescriptionApproved, setIsPrescriptionApproved] = useState<boolean>(false);
  const [isDischargeApproved, setIsDischargeApproved] = useState<boolean>(false);
  const [isPurchaseApproved, setIsPurchaseApproved] = useState<boolean>(false);
  const [isPrescriptionrReupload, setIsPrescriptionReupload] = useState<boolean>(false);
  const [isDischargeReupload, setIsDischargeReupload] = useState<boolean>(false);
  const [isPurchaseReupload, setIsPurchaseReupload] = useState<boolean>(false);
  const [isVivitraEnabled, setIsVivitraEnabled] = useState<any>(null);
  const [isConfirmed, setIsConfirmed] = useState<any>(null);
  const [showPurchaseApprove, setShowPurchaseApprove] = useState<boolean>(false);
  const [showDischargeApprove, setShowDischargeApprove] = useState<boolean>(false);
  const [showPurchaseReupload, setShowPurchaseReupload] = useState<boolean>(false);
  const [showDischargeReupload, setShowDischargeReupload] = useState<boolean>(false);
  const [showPrescriptionReupload, setshowPrescriptionReupload] = useState<boolean>(false);
  const [selectedPdfType, setSelectedPdfType] = useState<string>("");
  const [showPrescriptionApprove, setShowPrescriptionApprove] = useState<boolean>(false);
  const [showCreateCycleModal, setShowCreateCycleModal] = useState<boolean>(false);
  // const [showReqUploadModal, setShowReqUploadModal] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const [dischargeSummaryPdfUrl, setDischargeSummaryPdfUrl] = useState<string | null>(null);
  const [purchaseInvoicePdfUrl, setPurchaseInvoicePdfUrl] = useState<string | null>(null);
  const [prescriptionPdfUrl, setPrescriptionPdfUrl] = useState<string | null>(null);
  const [dischargeSummaryId, setDischargeSummaryId] = useState<string | null>(null);
  const [purchaseInvoiceId, setPurchaseInvoiceId] = useState<string | null>(null);
  const [prescriptionId, setPrescriptionId] = useState<string | null>(null);
  const [editedDetails, setEditedDetails] = useState<any>({});
  const [vivitraDetails, setVivitraDetails] = useState<any>({});
  const [visitData, setVisitData] = useState<any>({});
  const [productName, setProductName] = useState<string>('');
  const [productType, setProductType] = useState<string>('');
  const [vivitraStatus, setVivitraStatus] = useState<string>('');

  // const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  // const FetchPDFUrlAPI = `${process.env.REACT_APP_API_URL}/api/v8/healthcoach/get_patient_record_onco_without_visit_id`;
  const VisitRecordsAPI = `${process.env.REACT_APP_API_URL}/api/v8/healthcoach/get_records`;
  const PatientDetailsAPI = `${process.env.REACT_APP_API_URL}/api/v8/healthcoach/patient_details_by_id`;
  const UpdateVisitDetailsAPI = `${process.env.REACT_APP_API_URL}/api/v8/healthcoach/update_patient_visit`;
  const ScheduleVisitDetailsAPI = `${process.env.REACT_APP_API_URL}/api/v8/healthcoach/schedule_patient_visit`;
  const UpdateRecordStatusAPI = `${process.env.REACT_APP_API_URL}/api/v8/healthcoach/update_patient_record_data`;

  const params = useParams();
  const navigate = useNavigate();
  const location: any = useLocation();

  const handleBackButtonClick = (screen: string) => {
    // navigate(-1);
    navigate(`/${screen}/:token`);
  };

  const handleButtonClick = (screen: string) => {
    navigate(`/${screen}`);
  };

  useEffect(() => {
    const token: any = params.token;
    getProfileDetails();
    fetchVisitRecords();

    localStorage.setItem(
      "token",
      location.state?.token === ":token" || location.state?.token === undefined
        ? token
        : location.state?.token
    );
    sessionStorage.setItem('token', (location.state?.token === ":token" || location.state?.token === undefined) ? token : location.state?.token);
    setToken(localStorage.getItem("token"));
    // setUserDetails(localStorage.getItem("health-coach-id"))
  }, [params]);

  const fetchVisitRecords = async () => {
    try {
      const token = localStorage.getItem("token");
      const secretKey = "SfUyfAztruqg92sbm30rEIyHLNV7f5";
      const body = {
        "page": "1",
        "record_meta_info": {
          "visit_id": location?.state.visitID
        },
        "patient_id": location?.state.patientId
      }

      const enCryptedBody = getEncryptedBody(body)

      const response = await fetch(VisitRecordsAPI, {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
          token: `${token}`,
          health_secret: secretKey,
        },
        body: `${enCryptedBody}`,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch PDF URL");
      }
      const data = await response.json();
      const decryptedData = getDecryptedData(data)
      const visitRecordData = JSON.parse(decryptedData).data
      setVisitData(visitRecordData)

      visitRecordData?.forEach((item: any) => {
        setPdfUrlByTitle(item.title, item.document_url, item.patient_records_id, item?.record_meta_info?.report_status);
      });
    } catch (error) {
      console.error("Error fetching PDF URL:", error);
    }
  };

  //funtion to get profile details
  const getProfileDetails = async () => {
    try {
      const userInfo = await API.GetProfileDetails()

      setUserDetails(userInfo?.data)

    } catch (error) {
      console.log("error", error)
    }
  }

  // Function to set state based on title
  const setPdfUrlByTitle = (title: any, url: any, recordId: any, reportStatus: any) => {
    switch (title) {
      case "Discharge Summary":
        setDischargeSummaryPdfUrl(url);
        setDischargeSummaryId(recordId)
        if (reportStatus === "Approved") {
          setIsDischargeApproved(true)
        }
        else if (reportStatus === "Rejected") {
          setIsDischargeReupload(true)
        }
        break;
      case "Purchase Invoice":
        setPurchaseInvoicePdfUrl(url);
        setPurchaseInvoiceId(recordId)
        if (reportStatus === "Approved") {
          setIsPurchaseApproved(true)
        } else if (reportStatus === "Rejected") {
          setIsPurchaseReupload(true)
        }
        break;
      case "Prescription":
        setPrescriptionPdfUrl(url);
        setPrescriptionId(recordId)
        if (reportStatus === "Approved") {
          setIsPrescriptionApproved(true)
        } else if (reportStatus === "Rejected") {
          setIsPrescriptionReupload(true)
        }
        break;
      default:
        // Handle other cases if needed
        break;
    }
  };

  useEffect(() => {
    fetchPatientDetails();
    setVivitraDetails(location?.state?.visitMetaInfo)
  }, []);

  const fetchPatientDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const secretKey = "SfUyfAztruqg92sbm30rEIyHLNV7f5";
      const body = JSON.stringify({
        patient_id: location.state?.patientId,
      });
      const response = await fetch(
        PatientDetailsAPI,
        {
          method: "POST",
          headers: {
            "Content-Type": "text/plain",
            token: `${token}`,
            health_secret: secretKey,
          },
          body: body,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      const userData = data?.data;
      setPatientData(userData)
      setCoins(userData?.coins)
      setIsVivitraEnabled(userData?.patient_meta_info?.vivitra_eligible)
      // setDoctorData(userData?.patient_link_doctor_details[0])
    } catch (error) {
      console.error("Error fetching patient details:", error);
    }
  };

  const handleApprove = (pdfType: string) => {
    setSelectedPdfType(pdfType);
    window.parent.postMessage("scrollToTop", "*")
    if (pdfType === "purchase") {
      setShowPurchaseApprove(true)
    }
    else if (pdfType === "discharge") {
      setShowDischargeApprove(true)
    }
    else if (pdfType === "prescriptions") {
      setShowPrescriptionApprove(true)
    }
    // else {
    //   setIsPrescriptionApproved(true)
    // }
  };

  const handleReupload = (pdfType: string) => {
    setSelectedPdfType(pdfType);
    window.parent.postMessage("scrollToTop", "*")
    if (pdfType === "purchase") {
      setShowPurchaseReupload(true)
    }
    else if (pdfType === "discharge") {
      setShowDischargeReupload(true)
    }
    else if (pdfType === "prescriptions") {
      setshowPrescriptionReupload(true)
    }
  };

  const handleRewards = (action: boolean) => {
    setIsRewardsAssign(action);
  };

  const updateVisitDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const secretKey = "SfUyfAztruqg92sbm30rEIyHLNV7f5";
      // const userInfo = localStorage.getItem("userInfo");   
      const body = {
        "patient_visit_id": location.state?.visitID,
        "patient_phone": patientData.contact_no,
        "patient_name": patientData.name,
        // "follow_up_date": editedDetails?.next_cycle_date,
        "updated_by": userDetails.health_coach_id,
        "visit_meta_info": {
          "target_therapy_drug": editedDetails?.product_name,
          "therapy_type": editedDetails?.product_type,
          "rewards_assigned": isRewardsAssign ? 1 : 0,
        },
        "visit_status": "VA",
        // Conditionally add "assign_reward" and related parameters
        ...(isRewardsAssign
          ? {
            "assign_reward": 1,
            "assign_reward_params": {
              "amount": parseInt(`${coins}00`),
              "transactionType": "DOCUMENT_UPLOAD",
              "notes": {
                "type": "visit_status_approved"
              },
              "patientId": location.state?.patientId,
            }
          }
          : { "assign_reward": 0 }
        )
      }

      const enCryptedBody = getEncryptedBody(body)
      const decryptedBody = getDecryptedData(enCryptedBody)

      const response = await fetch(UpdateVisitDetailsAPI, {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
          token: `${token}`,
          health_secret: secretKey,
        },
        body: `${enCryptedBody}`,
      });

      if (!response.ok) {
        throw new Error("Failed to schedule a patient visit");
      }
      const data = await response.json();
      const decryptedData = getDecryptedData(data)

    } catch (error) {
      console.error("Error fetching PDF URL:", error);
    }
  };

  const vivitraDateString = location?.state?.visitMetaInfo?.vivitra_date ?? "";
  let vivitraDateObj: VivitraDate = {};

  try {
    if (vivitraDateString) {
      vivitraDateObj = JSON.parse(vivitraDateString);
    } else {
      console.error("vivitra_date string is empty");
    }
  } catch (error) {
    console.error("Error parsing vivitra_date:", error);
  }

  const updateVisitVivitraDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const secretKey = "SfUyfAztruqg92sbm30rEIyHLNV7f5";
      // const userInfo = localStorage.getItem("userInfo"); 
      const body = {
        "patient_visit_id": location.state?.visitID,
        "patient_phone": patientData.contact_no,
        "patient_name": patientData.name,
        // "follow_up_date": editedDetails?.next_cycle_date,
        "updated_by": userDetails.health_coach_id,
        "visit_meta_info": {
          "vivitra_address": vivitraDetails.vivitra_address,
          "vivitra_approved": 1,
          "vivitra_status": vivitraDetails.vivitra_status,
          "vivitra_date": JSON.stringify({
            "claimed_date": vivitraDateObj?.claimed_date,
            "ordered_date": (vivitraDateObj?.ordered_date) ? vivitraDateObj?.ordered_date : (vivitraDetails?.vivitra_status === "Ordered" ? vivitraDetails?.status_date : ""),
            "shipped_date": (vivitraDateObj?.shipped_date) ? vivitraDateObj?.shipped_date : (vivitraDetails?.vivitra_status === "Shipped" ? vivitraDetails?.status_date : ""),
            "delivered_date": (vivitraDateObj?.delivered_date) ? vivitraDateObj?.delivered_date : (vivitraDetails?.vivitra_status === "Delivered" ? vivitraDetails?.status_date : "")
          })
        }
      }

      const enCryptedBody = getEncryptedBody(body)
      // const decryptedBody = getDecryptedData(enCryptedBody)

      const response = await fetch(UpdateVisitDetailsAPI, {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
          token: `${token}`,
          health_secret: secretKey,
        },
        body: `${enCryptedBody}`,
      });

      if (!response.ok) {
        throw new Error("Failed to schedule a patient visit");
      }
      const data = await response.json();
      const decryptedData = getDecryptedData(data)
      console.log(decryptedData, "vivitra details updated successfully.")

    } catch (error) {
      console.error("Error fetching PDF URL:", error);
    }
  };

  const updateVivitraAddressDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const secretKey = "SfUyfAztruqg92sbm30rEIyHLNV7f5";
      // const userInfo = localStorage.getItem("userInfo"); 
      const body = {
        "patient_visit_id": location.state?.visitID,
        "patient_phone": patientData.contact_no,
        "patient_name": patientData.name,
        // "follow_up_date": editedDetails?.next_cycle_date,
        "updated_by": userDetails.health_coach_id,
        "visit_meta_info": {
          "vivitra_address": vivitraDetails.vivitra_address,
        }
      }

      const enCryptedBody = getEncryptedBody(body)
      // const decryptedBody = getDecryptedData(enCryptedBody)

      const response = await fetch(UpdateVisitDetailsAPI, {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
          token: `${token}`,
          health_secret: secretKey,
        },
        body: `${enCryptedBody}`,
      });

      if (!response.ok) {
        throw new Error("Failed to schedule a patient visit");
      }
      toast.success('Data saved successfully!', { position: 'top-right' });// toast message
      window.parent.postMessage("scrollToTop", "*")
      const data = await response.json();
      const decryptedData = getDecryptedData(data)
      console.log(decryptedData, "vivitra address updated successfully.")

    } catch (error) {
      console.error("Error fetching PDF URL:", error);
    }
  };

  const updateVivitraApproveDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const secretKey = "SfUyfAztruqg92sbm30rEIyHLNV7f5";
      // const userInfo = localStorage.getItem("userInfo");   
      const body = {
        "patient_visit_id": location.state?.visitID,
        "patient_phone": patientData.contact_no,
        "patient_name": patientData.name,
        // "follow_up_date": editedDetails?.next_cycle_date,
        "updated_by": userDetails.health_coach_id,
        "visit_meta_info": {
          "vivitra_approved": 1,
        }
      }

      const enCryptedBody = getEncryptedBody(body)
      // const decryptedBody = getDecryptedData(enCryptedBody)

      const response = await fetch(UpdateVisitDetailsAPI, {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
          token: `${token}`,
          health_secret: secretKey,
        },
        body: `${enCryptedBody}`,
      });

      if (!response.ok) {
        throw new Error("Failed to schedule a patient visit");
      }
      const data = await response.json();
      const decryptedData = getDecryptedData(data)
      console.log(decryptedData, "vivitra approve detail updated successfully.")

    } catch (error) {
      console.error("Error fetching PDF URL:", error);
    }
  };

  const scheduleVisitDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const secretKey = "SfUyfAztruqg92sbm30rEIyHLNV7f5";

      const body = {
        "patient_id": location.state?.patientId,
        "patient_phone": patientData.contact_no,
        "patient_name": patientData.name,
        "follow_up_date": editedDetails?.next_cycle_date,
        "updated_by": userDetails.health_coach_id,
        "visit_meta_info": {
          "visit_cycle": 1 + Number(location.state?.cycleNumber) // Incremented from the current visit
        }
      }

      const enCryptedBody = getEncryptedBody(body)
      // const decryptedBody = getDecryptedData(enCryptedBody)


      const response = await fetch(ScheduleVisitDetailsAPI, {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
          token: `${token}`,
          health_secret: secretKey,
        },
        body: `${enCryptedBody}`,
      });

      if (!response.ok) {
        throw new Error("Failed to schedule a patient visit");
      }
      const data = await response.json();
      const decryptedData = getDecryptedData(data)

      // setDischargeSummaryPdfUrl(data.pdfUrl);
      // setPurchaseInvoicePdfUrl(data.pdfUrl);
    } catch (error) {
      console.error("Error fetching PDF URL:", error);
    }
  };

  const handleVivitraDetail = async () => {
    toast.success('Data saved successfully!', { position: 'top-right' });
    await updateVisitVivitraDetails();
  }

  const updateRecordStatus = async (recordId: any) => {
    try {
      const token = localStorage.getItem("token");
      const secretKey = "SfUyfAztruqg92sbm30rEIyHLNV7f5";
      const body = {
        "patient_records_id": recordId,
        "patient_phone": patientData.contact_no,
        "patient_name": patientData.name,
        "record_meta_info": {
          "report_status": "Approved"
        }
      }

      const enCryptedBody = getEncryptedBody(body)
      // const decryptedBody = getDecryptedData(enCryptedBody)

      const response = await fetch(UpdateRecordStatusAPI, {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
          token: `${token}`,
          health_secret: secretKey,
        },
        body: `${enCryptedBody}`,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch PDF URL");
      }
      const data = await response.json();

      const decryptedData = getDecryptedData(data)
      const parsedRecordData = JSON.parse(decryptedData)

    } catch (error) {
      console.error("Error fetching PDF URL:", error);
    }
  };

  const handleConfirmation = async (action: boolean) => {

    switch (selectedPdfType) {
      case "purchase":
        setIsPurchaseApproved(action);
        await updateRecordStatus(purchaseInvoiceId)
        // setShowPurchaseApprove(false);
        break;
      case "discharge":
        setIsDischargeApproved(action);
        await updateRecordStatus(dischargeSummaryId)
        // setShowDischargeApprove(false);
        break;
      case "prescriptions":
        setIsPrescriptionApproved(action);
        await updateRecordStatus(prescriptionId)
        await updateVivitraApproveDetails()
        // setShowDischargeApprove(false);
        break;
      default:
        // setShowPrescriptionApprove(action);
        // setShowPrescriptionApprove(false);
        break;
    }
    handleCloseModal();
  };

  const handleReuploadConfirmation = async (action: boolean, text?: string) => {
    if (action) {
      switch (selectedPdfType) {
        case "purchase":
          setIsPurchaseReupload(action);
          await updateReuploadStatus(purchaseInvoiceId, text, "Discharge Summary")
          break;
        case "discharge":
          setIsDischargeReupload(action);
          await updateReuploadStatus(dischargeSummaryId, text, "Purchase Invoice")
          break;
        case "prescriptions":
          setIsPrescriptionReupload(action);
          await updateReuploadStatus(prescriptionId, text, "Prescriptions")
          break;
        default:
          // setShowPrescriptionApprove(action);
          // setShowPrescriptionApprove(false);
          break;
      }
    }
    handleCloseModal();
  };

  const updateReuploadStatus = async (recordId: any, text: any, type: string) => {
    try {
      const token = localStorage.getItem("token");
      const secretKey = "SfUyfAztruqg92sbm30rEIyHLNV7f5";
      const body = {
        "patient_records_id": recordId,
        "patient_phone": patientData.contact_no,
        "patient_name": patientData.name,
        "type_of_record": type,
        "record_meta_info": {
          "report_status": "Rejected",
          "rejected_reason": text
        }
      }

      const enCryptedBody = getEncryptedBody(body)

      // const decryptedBody = getDecryptedData(enCryptedBody)

      const response = await fetch(UpdateRecordStatusAPI, {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
          token: `${token}`,
          health_secret: secretKey,
        },
        body: `${enCryptedBody}`,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch PDF URL");
      }
      const data = await response.json();

      const decryptedData = getDecryptedData(data)
      // const parsedRecordData = JSON.parse(decryptedData)
    } catch (error) {
      console.error("Error fetching PDF URL:", error);
    }
  };

  const handleCloseModal = () => {
    setShowDischargeApprove(false);
    setShowPurchaseApprove(false);
    setShowPrescriptionApprove(false);
    setShowDischargeReupload(false);
    setShowPurchaseReupload(false);
    setshowPrescriptionReupload(false);
    setSelectedPdfType("");
  };

  const handleAddCycleButtonClick = () => {
    window.parent.postMessage("scrollToTop", "*")
    updateVisitDetails()
    scheduleVisitDetails()
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedDetails({ ...editedDetails, [e.target.name]: e.target.value });
  };

  const handleVivitraChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVivitraDetails({ ...vivitraDetails, [e.target.name]: e.target.value });
  };

  const handleProductNameChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setProductName(e.target.value);
    setEditedDetails({ ...editedDetails, [e.target.name]: e.target.value });
  };

  const handleProductTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setProductType(e.target.value);
    setEditedDetails({ ...editedDetails, [e.target.name]: e.target.value });
  };
  const handleVivitraStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setVivitraStatus(e.target.value);
    setVivitraDetails({ ...vivitraDetails, [e.target.name]: e.target.value });
  };

  const shouldEnableSaveButton = () => {
    // Check if PDF URL is present and approved
    if (prescriptionPdfUrl) {
      // Check if all three fields have values
      return vivitraDetails.vivitra_status && vivitraDetails.vivitra_address && vivitraDetails.status_date && isPrescriptionApproved;
    } else {
      return vivitraDetails.vivitra_status && vivitraDetails.vivitra_address && vivitraDetails.status_date;
    }
  };

  return (
    <>
      {showDischargeApprove && (
        <div
          className={`confirmation-modal ${showDischargeApprove ? "overlay visible" : ""
            }`}
        >
          <ConfirmationModal
            show={showDischargeApprove}
            onConfirmation={handleConfirmation}
            onClose={handleCloseModal}
          />
        </div>
      )}
      {showPurchaseApprove && (
        <div
          className={`confirmation-modal ${showPurchaseApprove ? "overlay visible" : ""
            }`}
        >
          <ConfirmationModal
            show={showPurchaseApprove}
            onConfirmation={handleConfirmation}
            onClose={handleCloseModal}
          />
        </div>
      )}
      {showPrescriptionApprove && (
        <div
          className={`confirmation-modal ${showPrescriptionApprove ? "overlay visible" : ""
            }`}
        >
          <ConfirmationModal
            show={showPrescriptionApprove}
            onConfirmation={handleConfirmation}
            onClose={handleCloseModal}
          />
        </div>
      )}
      {showDischargeReupload && (
        <div
          className={`confirmation-modal ${showDischargeReupload ? "overlay visible" : ""
            }`}
        >
          <RequestToUpload
            show={showDischargeReupload}
            onConfirmation={handleReuploadConfirmation}
            onClose={handleCloseModal}
          />
        </div>
      )}
      {showPurchaseReupload && (
        <div
          className={`confirmation-modal ${showPurchaseReupload ? "overlay visible" : ""
            }`}
        >
          <RequestToUpload
            show={showPurchaseReupload}
            onConfirmation={handleReuploadConfirmation}
            onClose={handleCloseModal}
          />
        </div>
      )}
      {showPrescriptionReupload && (
        <div
          className={`confirmation-modal ${showPrescriptionReupload ? "overlay visible" : ""
            }`}
        >
          <RequestToUpload
            show={showPrescriptionReupload}
            onConfirmation={handleReuploadConfirmation}
            onClose={handleCloseModal}
          />
        </div>
      )}
      <div className="desktop-3">
        <img className="desktop-3-child" alt="" src={rectangleIcon} />
        <header className="summary-header">
          <div>
            <Link
              to={`/${"patient-summary/:token"}`}
              style={{ textDecoration: "none" }}
              state={{ patientId: location.state?.patientId, token: `${token}` }}
            >
              <div className="rectangle-v-e-c-t-o-r">
                <div className="frame-f-r-a-m-e">
                  <button
                  /*   onClick={ () => handleBackButtonClick("all-patients")}>
                  onClick={handleBackButtonClick}> */
                  >
                    <img
                      className="arrow-left"
                      loading="lazy"
                      alt=""
                      src={arrowLeftIcon}
                    />
                  </button>
                </div>
                <h3 className="products-list1">Cycle Details</h3>
              </div>
            </Link>
          </div>
          <div className="sub-header">
            <div className="patient-info">
              <div className="patient-info-container">
                <label className="patientInfo-label">Patient Info</label>
                <div className="patient-info-details">
                  <div>Name: {patientData?.name}</div>
                  <div>Contact: {patientData?.contact_no}</div>
                </div>
              </div>
            </div>
            {location.state?.vivitraEligible &&
              <div className="vivitra">
                <div className="vivitra-sub-title">
                  <span>VIVITRA - {location?.state?.visitMetaInfo?.vivitra_status}</span>
                  {/* <Link to={{ pathname: `/patient-summary/:token` }} style={{ textDecoration: 'none' }} state={{ name: patientData?.name, contact: patientData?.contact_no, patientId: location.state?.patientId, token: token }}> */}
                  <Link
                    to={`/${"patient-summary/:token"}`}
                    style={{ textDecoration: "none" }}
                    state={{ patientId: location.state?.patientId, token: `${token}`, refresh: true }}
                  >
                    <button
                      className={location?.state?.visitStatus === "VA" || !shouldEnableSaveButton()  ? "vivitra-btn-disabled" : "vivitra-btn"}
                      onClick={handleVivitraDetail}
                      disabled={location?.state?.visitStatus === "VA" || !shouldEnableSaveButton()}
                    >
                      Save
                    </button>
                  </Link>
                </div>
                <img
                  className="save-address-icon"
                  loading="lazy"
                  alt=""
                  src={editIcon}
                  onClick={vivitraDetails?.vivitra_address ? updateVivitraAddressDetails : () => { }}
                />
                <div className="vivitra-container">
                  <div className="vivitra-address">
                    <div className="vivitra-label">
                      <label htmlFor="vivitra-address">Address:</label>
                    </div>
                    {/* <div className="address-field-container"> */}
                    <input
                      className="vivitra-placeHolder"
                      type="text"
                      placeholder="Enter delivery address "
                      name="vivitra_address"
                      id="vivitra_address"
                      value={vivitraDetails?.vivitra_address}
                      onChange={handleVivitraChange}
                    />
                  </div>
                  {/* </div> */}
                  <div className="vivitra-status">
                    <div className="vivitra-label">
                      <label>Status:</label>
                    </div>
                    <div className="vivitra-status-field">
                      <div className="input-field-container">
                        <select className="vivitra-select" id="vivitra_status" name="vivitra_status" value={vivitraStatus} onChange={handleVivitraStatusChange}>
                          <option value="">Select Status</option>
                          <option value="Ordered">Ordered</option>
                          <option value="Shipped">Shipped</option>
                          <option value="Delivered">Delivered</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="status-date">
                    <div className="vivitra-label">
                      <label htmlFor="status-date">Date:</label>
                    </div>
                    <input
                      className="vivitra-placeHolder"
                      type="date"
                      name="status_date"
                      id="status_date"
                      // value={editedDetails?.next_cycle_date}
                      onChange={handleVivitraChange}
                      min={new Date().toISOString().split('T')[0]}
                    />
                  </div>
                </div>
              </div>
            }
          </div>
        </header>
        <main className="group-f-r-a-m-e">
          <div className="cycle-details-container">
            <div className="f-r-a-m-e">
              <div className="products-list-input-field-grou">
                <div className="input-field7">
                  <div className="l-a-b-e-l">
                    <label className="label" htmlFor="currentCycleDate">Cycle Number:</label>
                  </div>
                  <input
                    className="placeholder-journey"
                    name="cycle_number"
                    // value={editedDetails?.cycle_number || ""}
                    value={location.state?.cycleNumber}
                    // placeholder="Enter Cycle Number"
                    // contentEditable={false}
                    readOnly
                    style={{ backgroundColor: "#d3d3d38f" }}
                    type="text"
                  // onChange={handleChange}
                  />
                </div>
                {location?.state?.visitStatus !== "VA" ?
                  (<div className="input-field8">
                    <div className="label-parent1">
                      <label className="label" htmlFor="nextCycleDate">Enter Upcoming Cycle Date:</label>
                    </div>
                    <input
                      className="placeholder-journey"
                      type="date"
                      name="next_cycle_date"
                      id="nextCycleDate"
                      value={editedDetails?.next_cycle_date || ""}
                      onChange={handleChange}
                      min={new Date().toISOString().split('T')[0]}
                    />
                  </div>)
                  :
                  (<div className="input-field8"></div>)
                }
                <div className="input-field9">
                  <div className="input-field-container">
                    <label className="label" htmlFor="productType">Enter Therapy Drug:</label>
                  </div>
                  <div className="input-field9">
                    <div className="input-field-container">
                      {location?.state?.visitMetaInfo?.target_therapy_drug ? (
                        <input
                          className="placeholder-journey"
                          // name="cycle_number"
                          // value={editedDetails?.cycle_number || ""}
                          value={location?.state?.visitMetaInfo?.target_therapy_drug}
                          // placeholder="Enter Cycle Number"
                          // contentEditable={false}
                          readOnly
                          style={{ backgroundColor: "#d3d3d38f" }}
                          type="text"
                        />
                      ) : (
                        <select
                          className="placeholder-select"
                          id="productType"
                          name="product_name"
                          value={editedDetails?.product_name || ""}
                          onChange={handleProductNameChange}
                        >
                          <option value="">Select Drug Name</option>
                          <option value="Sigrima">Sigrima</option>
                          <option value="Others">Others</option>
                        </select>
                      )
                      }
                    </div>
                  </div>
                </div>
                <div className="input-field9">
                  <div className="input-field-container">
                    <label className="label" htmlFor="productType">Enter Therapy Name:</label>
                  </div>
                  <div className="input-field9">
                    <div className="input-field-container">
                      {location?.state?.visitMetaInfo?.therapy_type ? (
                        <input
                          className="placeholder-journey"
                          // name="cycle_number"
                          // value={editedDetails?.cycle_number || ""}
                          value={location?.state?.visitMetaInfo?.therapy_type}
                          // placeholder="Enter Cycle Number"
                          // contentEditable={false}
                          readOnly
                          style={{ backgroundColor: "#d3d3d38f" }}
                          type="text"
                        />
                      ) : (
                        <select
                          className="placeholder-select"
                          id="productName"
                          name="product_type"
                          value={editedDetails?.product_type || ""}
                          onChange={handleProductTypeChange}
                        >
                          <option value="">Select Therapy Name</option>
                          <option value="Neoadjuvant (before surgery)">Neoadjuvant (before surgery)</option>
                          <option value="Adjuvant (after surgery)">Adjuvant (after surgery)</option>
                          <option value="Metastatic 1st line">Metastatic 1st line</option>
                          <option value="Metastatic 2nd line & beyond">Metastatic 2nd line & beyond</option>
                        </select>)
                      }
                    </div>
                  </div>
                </div>
                {productName === "Sigrima" &&
                  <div className="care-coins-container">
                    <div className="input-field-container">
                      <label className="care-coin-label" htmlFor="productType">Add {coins} care coins to patient wallet </label>
                    </div>
                    <div className=".btns-container">
                      <button className={isRewardsAssign ? "modal-btns-active" : "modal-btns"} onClick={() => handleRewards(true)}>Yes</button>
                      <button className={isRewardsAssign ? "modal-btns" : "modal-btns-active"} onClick={() => handleRewards(false)}>No</button>
                    </div>
                  </div>
                }

              </div>
            </div>
          </div>
          <div className="input-field-with-label-and-pla">
            {isVivitraEnabled && prescriptionPdfUrl && (
              <GroupComponent
                fRAMEFRAMEPlaceholder="Doctor Prescription"
                pdfUrl={prescriptionPdfUrl || ""}
                onApprove={() => handleApprove("prescriptions")}
                isApproved={isPrescriptionApproved}
                onReupload={() => handleReupload("prescriptions")}
                isReupload={isPrescriptionrReupload}
              />
            )}
            <GroupComponent
              fRAMEFRAMEPlaceholder="Discharge Summary"
              pdfUrl={dischargeSummaryPdfUrl || ""}
              onApprove={() => handleApprove("discharge")}
              isApproved={isDischargeApproved}
              onReupload={() => handleReupload("discharge")}
              isReupload={isDischargeReupload}
            />
            <GroupComponent
              fRAMEFRAMEPlaceholder="Purchase Invoice"
              pdfUrl={purchaseInvoicePdfUrl || ""}
              onApprove={() => handleApprove("purchase")}
              isApproved={isPurchaseApproved}
              onReupload={() => handleReupload("purchase")}
              isReupload={isPurchaseReupload}
            />
            <Link
              to={`/${"patient-summary/:token"}`}
              style={{ textDecoration: "none" }}
              state={{ patientId: location.state?.patientId, token: `${token}` }}
            >
              <div className="frame-f-r-a-m-e1" style={{ display: location?.state?.visitStatus !== "VA" ? "block" : "none" }}>
                <button
                  className={(!isPurchaseApproved || !isDischargeApproved || !editedDetails?.next_cycle_date || !editedDetails.product_name || !editedDetails?.product_type) ? "button-disabled" : "button"}
                  onClick={handleAddCycleButtonClick}
                  disabled={!isPurchaseApproved || !isDischargeApproved || !editedDetails?.next_cycle_date || !editedDetails.product_name || !editedDetails?.product_type}
                >
                  <div className="button-frame">
                    <div className="button1">Create Upcoming Cycle</div>
                  </div>
                </button>
              </div>
            </Link>
          </div>
        </main >
      </div >
    </>
  );
};

export default CycleDetails;
