import exerciseHelpers from "./exercise";

const helpers = {
  ...exerciseHelpers,
};

export default helpers;

export const generateQueryParams = (obj: any) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};