export const DIET_STATUS = {
  PUBLISHED: "published",
  REJECTED: "rejected",
  APPROVED: "approved",
  DRAFT: "draft",
  PENDING: "pending",
};

export const NUTRIENT_MESSAGE = {
  FAT_INFO: `<b>The "Fat goal" in grams is calculated as follows:</b>
  <span style="font-weight: 900;">&#x2022;</span> Convert the fat percentage to fat calories based on the total calories.
  <span style="font-weight: 900;">&#x2022;</span> Divide the fat calories by 9 to convert them into grams.

<b>"Actual Fat" represents:</b>
  <span style="font-weight: 900;">&#x2022;</span> The total of the average fats from each meal's options.`,

  PROTEIN_INFO: `<b>The "Protein goal" in grams is calculated as follows:</b>
  <span style="font-weight: 900;">&#x2022;</span> Convert the protein percentage to protein calories based on the total calories.
  <span style="font-weight: 900;">&#x2022;</span> Divide the protein calories by 4 to convert them into grams.

<b>"Actual Protein" represents:</b>
  <span style="font-weight: 900;">&#x2022;</span> The total of the average proteins from each meal's options.`,

  CARBS_INFO: `<b>The "Carbs goal" in grams is calculated as follows:</b>
  <span style="font-weight: 900;">&#x2022;</span> Convert the carbs percentage to carbs calories based on the total calories.
  <span style="font-weight: 900;">&#x2022;</span> Divide the carbs calories by 4 to convert them into grams.

<b>"Actual Carbs" represents:</b>
  <span style="font-weight: 900;">&#x2022;</span> The total of the average carbs from each meal's options.`
};