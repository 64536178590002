export const dataPointsToDisable: any[] = [
    { 'Upper Limb Strength': 'UL - no. of push ups in 30 sec' },
    { 'Lower Limb Strength': 'LL - no. of squats' },
    { 'Lower Limb Strength': 'LL - 30 sec chair test' },
    { 'Abdominal Strength': 'Abdominal strength - Plank hold duration' },
    { 'Cardiovascular endurance': 'Cardio Test - no of jumping jacks in a min' }
];

export const populateDataPointAnswers = (dataPoints: any, patientAssessments: any) => {
    try {
        let newDP = [...dataPoints];

        const maxAnswerLength = Math.max(...dataPoints.map((o: any) => o?.answer?.length || 0), 1);
        const dataPointsKeys = dataPointsToDisable.map(obj => Object.keys(obj)[0]);

        dataPoints.forEach((point: any, pointIdx: number) => {
            const pointQuestion = point?.question;

            for (let headerIndex = 0; headerIndex < maxAnswerLength; headerIndex++) {
                let value = point.answer[headerIndex];

                if (dataPointsKeys.includes(pointQuestion)) {
                    const assessmentQuestions = patientAssessments[headerIndex]?.answer_list?.assessment_questions || [];
                    const physioSpecific = assessmentQuestions.find((a: any) => a.section_type === 'physio_specific');
                    const strengthQuestions = physioSpecific?.questions_list?.find((a: any) => a.question === 'Strength')?.sub_questions || [];

                    dataPointsToDisable.forEach(dp => {
                        const questionKey = dp[pointQuestion];
                        const answer = strengthQuestions.find((q: any) => q.question === questionKey)?.answer;
                        if (answer) {
                            value = answer;
                        }
                    });
                }
                newDP[pointIdx].answer[headerIndex] = value;
            }
        });

        return newDP;
    } catch (error) {
        console.log(error);
        throw new Error('Error while populate data point answers!');
    }
};

const exerciseHelpers = {
    dataPointsToDisable,
};

export default exerciseHelpers;