import { useState, useEffect } from 'react';
import moment from 'moment';
import { message, Empty } from 'antd';

import { getDecryptedData, getEncryptedBody } from '../../utils/common';
import Loader from '../../atoms/Loader';
import API from '../../services/rest/api';

import './GetAllGetActyvPlans.scss';

type DataType = {
    _id: string;
    questionId: string;
    response: string[];
    userId: string;
    created_at: string;
    __v: number;
    question: string;
};

interface ViewQNAModalProps {
    patientId: string;
}

const ViewQNAModal: React.FC<ViewQNAModalProps> = (props) => {

    const { patientId } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [answerData, setAnswerData] = useState<Record<string, DataType[]>>();

    const groupByCreatedAt = (data: DataType[]) => {
        return data.reduce((acc, item) => {
            const dateKey = new Date(item.created_at).toISOString().split('T')[0];
            if (!acc[dateKey]) {
                acc[dateKey] = [];
            }
            acc[dateKey].push(item);
            return acc;
        }, {} as Record<string, DataType[]>);
    };

    const fetchData = async () => {
        try {
            setLoading(true);
            const payload = { patientId: patientId };
            const encrypted = getEncryptedBody(payload);
            const response = await API.GetUserSavedQNA(encrypted);
            const decryptedResp: any = JSON.parse(getDecryptedData(response));
            if (decryptedResp.code !== 1) throw new Error(decryptedResp.msg || 'Internal server error!');
            const modifiedData = groupByCreatedAt(decryptedResp.resp || []);
            setAnswerData(modifiedData);
        } catch (error) {
            console.log(error);
            message.error({ content: 'Failed to fetch data!' });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (loading) return;
        fetchData();
    }, []);

    return (
        <div className='view-qna-wrapper'>
            <div className='wrapper-header'>
                <div className='question'>Question</div>
                <div className='answer'>Response</div>
                <div className='answer'>Datetime</div>
            </div>
            {(!loading && !answerData)
                && <div className='no-data'><Empty /></div>}
            {loading && <Loader noBackground />}

            {answerData
                && <div className='wrapper-body'>
                    {Object.keys(answerData).map((date) => (
                        <div className='group'>
                            <div className='group-heading'>
                                {moment(date).format('MMMM Do YYYY')}
                            </div>
                            {answerData[`${date}`].map((data, index) => {
                                return (
                                    <div className='question-list' key={data._id} id={data._id}>
                                        <div className='question-box'>
                                            {data.question || '-'}
                                        </div>
                                        <div className='answer-box'>
                                            {data.response[0] || '-'}
                                        </div>
                                        <div className='answer-box'>
                                            {data.created_at ? moment(data.created_at).format('MMMM Do YYYY, hh:mm: a') : '-'}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </div>}
        </div>
    );
};

export default ViewQNAModal;