import { useEffect, useState } from "react";
import {
    format,
    startOfWeek,
    addDays,
    isSameDay,
    lastDayOfWeek,
    getWeek,
} from "date-fns";
import { LeftIcon, RightArrow, RightIcon } from "../../assets/icons";
import moment from "moment";

const Calendar = ({ showDetailsHandle, date }) => {
    const initialDate = new Date(date); // Parse the provided date string

    const [currentMonth, setCurrentMonth] = useState(initialDate);
    const [selectedDate, setSelectedDate] = useState(initialDate);

    useEffect(() => {
        setCurrentMonth(new Date(date))
        setSelectedDate(new Date(date))
    },[date])

    const renderHeader = () => {
        const dateFormat = "MMM yyyy";
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop:'10px'}}>
                <div style={{display: 'flex', alignItems: 'center' }}>
                    <p style={{ color: '#222', fontWeight: '600', fontSize: '14px', width: '70px' }}>{format(currentMonth, dateFormat)}</p>
                    <RightArrow />
                </div>
                <div style={{alignItems:'center', display:'flex', gap:'8px'}}>
                    <LeftIcon />
                    <RightIcon />
                </div>
            </div>
        );
    };

    const renderDays = () => {
        const dateFormat = "EEE";
        const days = [];
        let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
        for (let i = 0; i < 7; i++) {
            days.push(
                <div style={{ width: '30px', textAlign: 'center', color:'#616161', fontSize:'12px', fontWeight:'400' }} key={i}>
                    {format(addDays(startDate, i), dateFormat)}
                </div>
            );
        }
        return <div style={{ display: 'flex', justifyContent: 'space-between' }}>{days}</div>;
    };

    const renderCells = () => {
        const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
        const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
        const dateFormat = "d";
        const rows = [];
        let days = [];
        let day = startDate;
        let formattedDate = "";
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, dateFormat);
                days.push(
                    <div
                        className={`col cell ${isSameDay(day, new Date())
                            ? "today"
                            : isSameDay(day, selectedDate)
                                ? "selected"
                                : ""
                            }`}
                        key={day.getTime()}
                    >   
                        <p style={(moment(selectedDate).format('D') == formattedDate) ? { width: '30px', textAlign: 'center', color:'white', fontSize:'14px', fontWeight:'400', background:'#760FB2', borderRadius:'8px', border:'none', padding:'4px 0' } : { width: '30px', textAlign: 'center', color:'#9E9E9E', fontSize:'14px', fontWeight:'400', padding:'4px 0' }}>{formattedDate}</p>
                    </div>
                );
                day = addDays(day, 1);
            }

            rows.push(
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} key={day.getTime()}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div style={{ display: 'flex' }}>{rows}</div>;
    };

    return (
        <div style={{ padding: '0 15px' }}>
            {renderHeader()}

            <div style={{ alignItems: 'center' }}>
                {renderDays()}
                {renderCells()}
            </div>
        </div>
    );
};

export default Calendar;