import { FunctionComponent, useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
// import GroupComponent2 from "../../components/EdPatientInfo";
import "./CreateJourney.css";
import arrowLeftIcon from '../../assets/icons/arrow--left.svg';
import API, { GetOverallPatientCount } from "../../services/rest/api";
import { getDecryptedData, getEncryptedBody } from "../../utils/common";

type Journey = {
  number: number;
  productName: string;
  productType: string;
};

interface CreateJourneyProps {
  journeys: Journey[];
  setJourneys: React.Dispatch<React.SetStateAction<Journey[]>>;
}

interface Props {
  onCloseModal: any;
  patientId: any;
  healCoachId: any;
  patient_name: any;
  patient_phone: any;
  coins:any;
}

const CreateJourney: FunctionComponent<Props> = ({ onCloseModal, patientId, healCoachId, patient_name, patient_phone, coins}) => {
  const [isRewardsAssign, setIsRewardsAssign] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<any>(null)
  const [editedDetails, setEditedDetails] = useState<any>({});
  // const [currentCycleDate, setCurrentCycleDate] = useState<string>('');
  // const [nextCycleDate, setNextCycleDate] = useState<string>('');
  const [productName, setProductName] = useState<string>('');
  const [productType, setProductType] = useState<string>('');
  const navigate = useNavigate();

  const ScheduleVisitDetailsAPI = `${process.env.REACT_APP_API_URL}/api/v8/healthcoach/schedule_patient_visit`; 

  useEffect(() => {
    getProfileDetails()
    window.scrollTo(0, 0)
  }, [])

  const scheduleVisitDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const secretKey = "SfUyfAztruqg92sbm30rEIyHLNV7f5";
      const body = {
        "patient_id": patientId,
        "patient_phone":patient_phone,
        "patient_name":patient_name,
        "follow_up_date": editedDetails?.next_cycle_date,
        "updated_by": healCoachId.health_coach_id,
        "visit_meta_info": {
          "target_therapy_drug": editedDetails?.product_name,
          "therapy_type": editedDetails?.product_type,
          "visit_cycle": "1",
          "rewards_assigned": isRewardsAssign ? 1 : 0,
        },
        "visit_dates": [
          editedDetails?.current_cycleDate,
        ],
        // Conditionally add "assign_reward" and related parameters
        ...(isRewardsAssign
          ? {
            "assign_reward": 1,
            "assign_reward_params": {
              "amount": parseInt(`${coins}00`),
              "transactionType": "DOCUMENT_UPLOAD",
              "notes": {
                "type": "visit_status_approved"
              },
              "patientId": patientId,
            }
          }
          : { "assign_reward": 0 }
        )
      }

      const enCryptedBody = getEncryptedBody(body)

      const response = await fetch( ScheduleVisitDetailsAPI , {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
          token: `${token}`,
          health_secret: secretKey,
        },
        body: `${enCryptedBody}`,
      });

      if (!response.ok) {
        throw new Error("Failed to schedule a patient visit");
      }
      const data = await response.json();

      const decryptedData = getDecryptedData(data)

    } catch (error) {
      console.error("Error fetching PDF URL:", error);
    }
  };

  //funtion to get profile details
  const getProfileDetails = async () => {
    try {
      const userInfo = await API.GetProfileDetails()

      localStorage.setItem('userInfo', JSON.stringify(userInfo?.data))
      setUserDetails(userInfo?.data)

    } catch (error) {
      console.log("error", error)
    }
  }

  const handleRewards = (action: boolean) => {
      setIsRewardsAssign(action);
  };

  const handleBackButtonClick = () => {
    onCloseModal();
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedDetails({ ...editedDetails, [e.target.name]: e.target.value });
  };

  const handleProductNameChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setProductName(e.target.value);
    setEditedDetails({ ...editedDetails, [e.target.name]: e.target.value });
  };

  const handleProductTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setProductType(e.target.value);
    setEditedDetails({ ...editedDetails, [e.target.name]: e.target.value });
  };

  const handleAddCycle = async () => {
    await scheduleVisitDetails();
    onCloseModal();
  };

  return (
    // <div className="desktop-4">
    <div className="frame-container" id="modal_add_cycle">
      <div className="nested-frame">
        <div className="arrow-instance">
          <img
            onClick={handleBackButtonClick}
            className="arrow-left1"
            loading="lazy"
            alt=""
            src={arrowLeftIcon}
          />
        </div>
        <h3 className="products-list2">Create Journey</h3>
      </div>

      <div className="f-r-a-m-ewrapper">
        <div className="f-r-a-m-e">
          <div className="products-list-input-field-grou">
            <div className="input-field7">
              <div className="l-a-b-e-l">
                <label className="label" htmlFor="currentCycleDate">Enter Cycle Number:</label>
              </div>
              <input
                className="placeholder-journey"
                name="cycle_number"
                value={editedDetails?.cycle_number || ""}
                placeholder="Enter Cycle Number"
                // type="text"
                onChange={handleChange}
              />
            </div>
            <div className="input-field7">
              <div className="l-a-b-e-l">
                <label className="label" htmlFor="currentCycleDate">Enter Cycle Date:</label>
              </div>
              <input
                className="placeholder-journey"
                type="date"
                name="current_cycleDate"
                id="currentCycleDate"
                value={editedDetails?.current_cycleDate || ""}
                onChange={handleChange}
                max={new Date().toISOString().split('T')[0]}
              />
            </div>
            <div className="input-field8">
              <div className="label-parent1">
                <label className="label" htmlFor="nextCycleDate">Enter Upcoming Cycle Date:</label>
              </div>
              <input
                className="placeholder-journey"
                type="date"
                name="next_cycle_date"
                id="nextCycleDate"
                value={editedDetails?.next_cycle_date}
                onChange={handleChange}
                min={new Date().toISOString().split('T')[0]}
              />
            </div>
            <div className="input-field8">
            </div>
            <div className="input-field9">
              <div className="input-field-container">
                <label className="label" htmlFor="productType">Enter Therapy Drug:</label>
              </div>
              <div className="input-field9">
                <div className="input-field-container">
                  <select className="placeholder-select" id="productType" name="product_name" value={editedDetails?.product_name || ""} onChange={handleProductNameChange}>
                    <option value="">Select Drug Name</option>
                    <option value="Sigrima">Sigrima</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="input-field9">
              <div className="input-field-container">
                <label className="label" htmlFor="productType">Enter Therapy Name:</label>
              </div>
              <div className="input-field9">
                <div className="input-field-container">
                  <select className="placeholder-select" id="productName" name="product_type" value={editedDetails?.product_type || ""} onChange={handleProductTypeChange}>
                    <option value="">Select Therapy Name</option>
                    <option value="Neoadjuvant (before surgery)">Neoadjuvant (before surgery)</option>
                    <option value="Adjuvant (after surgery)">Adjuvant (after surgery)</option>
                    <option value="Metastatic 1st line">Metastatic 1st line</option>
                    <option value="Metastatic 2nd line & beyond">Metastatic 2nd line & beyond</option>
                  </select>
                </div>
              </div>
            </div>
            {productName === "Sigrima" &&
              <div className="care-coins-container">
                <div className="input-field-container">
                  <label className="care-coin-label" htmlFor="productType">Add {coins} care coins to patient wallet </label>
                </div>
                <div className=".btns-container">
                  <button className={isRewardsAssign ? "modal-btns-active" : "modal-btns"} onClick={() => handleRewards(true)}>Yes</button>
                  <button className={isRewardsAssign ? "modal-btns" : "modal-btns-active"} onClick={() => handleRewards(false)}>No</button>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <div className="button-frame10">
        <Link to={{ pathname: `/all-patients/:token` }} style={{ textDecoration: 'none' }} state={{ token: localStorage.getItem("token"), journey: "verified-patients" }}>
          <button 
            // className="button9"
            className={(!editedDetails?.cycle_number || !editedDetails?.current_cycleDate || !editedDetails?.next_cycle_date || !editedDetails.product_name || !editedDetails?.product_type) ? " button-disabled" : " button9"}
            onClick={handleAddCycle} 
            disabled={!editedDetails?.cycle_number || !editedDetails?.current_cycleDate || !editedDetails?.next_cycle_date || !editedDetails.product_name || !editedDetails?.product_type}
          >
            <div className="button-add-cycle">Create Current Cycle</div>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default CreateJourney;
