import React,{ FunctionComponent } from "react";

// import "../CgAppointmentCell/AppointmentCell.css";
import { Link } from "react-router-dom";

export type AppointmentCellType = {
  index?: number;
  name?: string;
  contact?: string;
  visitType?: string;
  doctor?: string;
  primaryButton?: string;
  userData?: any;
  visitsNo?:any;
  onPrimaryButtonClick?: () => void;
};

const VerifiedPatientsCell: FunctionComponent<AppointmentCellType> = ({
  index,
  name,
  contact,
  visitType,
  primaryButton,
  userData,
  visitsNo,
  onPrimaryButtonClick,
}) => {
  const token = localStorage.getItem("token")

  return (
    <div className="appointment-cell">
      <div className="contact-frame">{index}</div>
      <div className="appointment-cell-inner">
        <div className="contact-frame-parent">
          <div className="name">{name}</div>
        </div>
      </div>
      <div className="appointment-cell-inner">
        <div className="contact">{contact}</div>
      </div>
      <div className="appointment-cell-inner">
        <div className="contact1">{visitType}</div>
      </div>
      <div className="appointment-cell-inner" style={{paddingLeft : "3.5rem"}}>
        <div className="visit-type">{visitsNo}</div>
      </div>
      <div className="appointment-cell-inner">
        <Link to={{ pathname: `/patient-summary/:token` }} style={{ textDecoration: 'none' }} state={{ name:name, contact:contact, patientId: userData.patient_id, token:token}}>
          <button className="secondary-default" >
            <div className="primary-button">{primaryButton}</div>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default VerifiedPatientsCell;
