import { useState, useEffect } from 'react';
import { message, Table, Card, List, Empty } from 'antd';

import { getDecryptedData, getEncryptedBody, secToMin } from '../../utils/common';
import Loader from '../../atoms/Loader';
import API from '../../services/rest/api';

import './GetAllGetActyvPlans.scss';

interface ViewPlanScoreProps {
    selectedPlan: any;
    userId: string;
}

const ViewPlanScoreModal: React.FC<ViewPlanScoreProps> = (props) => {

    const { Column, ColumnGroup } = Table;
    const [loading, setLoading] = useState<boolean>(false);
    const [planResult, setPlanResult] = useState<any[]>([]);

    const modifyData = (payload: any) => {
        if (!Array.isArray(payload)) return [];
        const response: any[] = [];

        for (let i = 0; i < payload.length; i++) {
            const plan = payload[i];
            const modifiedPlan: any = {};
            modifiedPlan.id = plan.planId;
            modifiedPlan.exerciseDetails = plan?.sessionDetails[0].exerciseData || [];
            modifiedPlan.metrics = [];
            modifiedPlan.header = [];
            modifiedPlan.header.push({ title: 'Date', content: plan.date });
            modifiedPlan.header.push({ title: 'Total Time Taken', content: secToMin(plan.totalTimeTaken) });
            modifiedPlan.header.push({ title: 'Total Calories Burnt', content: plan.totalCaloriesBurnt });
            modifiedPlan.header.push({ title: 'Skipped Exercises', content: plan.skippedExercises });

            if (plan?.metrics && Object.keys(plan.metrics).length) {
                const keys = Object.keys(plan.metrics);
                for (let i = 0; i < keys.length; i++) {
                    const title = keys[i];
                    const data = Object.keys(plan.metrics[`${title}`])
                        .map((key: any) => ({ title: key, content: plan.metrics[`${title}`][`${key}`] }));
                    modifiedPlan.metrics.push({ title, data });
                }
            }
            response.push(modifiedPlan);
        }

        return response;
    };

    const fetchData = async () => {
        try {
            setLoading(true);
            const encrypted = getEncryptedBody({ userId: props.userId, planId: props?.selectedPlan?.id || {} });
            const response = await API.GetExercisePlanResults(encrypted);
            const decryptedResp: any = JSON.parse(getDecryptedData(response));
            if (decryptedResp.code !== 1) throw new Error(decryptedResp.msg || 'Internal server error!');
            setPlanResult(modifyData(decryptedResp?.data || []));
        } catch (error) {
            console.log(error);
            message.error({ content: 'Failed to fetch data!' });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (loading || !props.selectedPlan) return;
        fetchData();
    }, [props.selectedPlan]);

    return (
        <div className='view-assessment-wrapper'>
            {loading
                ? <Loader noBackground />
                : <>
                    {!!planResult.length ?
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingRight: '10px' }}>{
                            planResult.map((plan) => (
                                <div style={{
                                    border: '1px solid lightgray',
                                    padding: '10px',
                                    borderRadius: '10px',
                                    // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                                }}>
                                    {plan?.header?.length &&
                                        <List
                                            grid={{ gutter: 16, column: 4 }}
                                            dataSource={plan.header}
                                            renderItem={(item: any, index) => (
                                                <List.Item>
                                                    {index === 0
                                                        ? <Card className="custom-card-h" style={{ boxShadow: '0 4px 8px rgba(118, 15, 178, 0.5)' }} title={item.title}>{item.content}</Card>
                                                        : <Card className="custom-card" title={item.title}>{item.content}</Card>}

                                                </List.Item>
                                            )}
                                        />}

                                    {!!(plan?.exerciseDetails || []).length &&
                                        <Table dataSource={plan.exerciseDetails} pagination={false} className='custom-table'>
                                            <Column className='column-custom-header' title='Exercise Name' dataIndex='exerciseName' key='exerciseName' />
                                            <Column title='Exercise Type' dataIndex='exerciseType' key='exerciseType' />
                                            <Column title='Exercise Performance Type' dataIndex='exercisePerformanceType' key='exercisePerformanceType' />
                                            <ColumnGroup title='Reps'>
                                                <Column title='Prescribed' dataIndex='repsPrescribed' key='repsPrescribed' render={(item) => item} />
                                                <Column title='Performed' dataIndex='totalRepsPerformed' key='totalRepsPerformed' render={(item) => item} />
                                            </ColumnGroup>
                                            <ColumnGroup title='Sets'>
                                                <Column title='Prescribed' dataIndex='setsPrescribed' key='setsPrescribed' render={(item) => item || '-'} />
                                                <Column title='Performed' dataIndex='totalSetsPerformed' key='totalSetsPerformed' render={(item) => item || '-'} />
                                            </ColumnGroup>
                                            <ColumnGroup title='Time'>
                                                <Column title='Prescribed' dataIndex='timePrescribed' key='timePrescribed' render={(item) => secToMin(item)} />
                                                <Column title='Time Taken' dataIndex='exerciseTotalTimeTaken' key='exerciseTotalTimeTaken' render={(item) => secToMin(item)} />
                                            </ColumnGroup>
                                        </Table>}

                                    {plan?.metrics?.length > 0 &&
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', marginTop: '10px' }}>{
                                            plan?.metrics?.map((metric: any) => (
                                                <div style={{ boxShadow: '0 2px 4px rgba(0,0,0, 0.1)', padding: '5px', borderRadius: '10px' }}>
                                                    <h3 style={{ color: '#760fb2' }}>Matric & Score - {metric.title}</h3>
                                                    <List
                                                        grid={{ gutter: 16, column: 5 }}
                                                        dataSource={metric.data}
                                                        renderItem={(item: any) => (
                                                            <List.Item>
                                                                {['totalTimePrescribed', 'totalTimePerformed'].includes(item.title)
                                                                    ? <Card className="custom-card" title={item.title}>{secToMin(item.content)}</Card>
                                                                    : <Card className="custom-card" title={item.title}>{item.content}</Card>}
                                                            </List.Item>
                                                        )}
                                                    />
                                                </div>
                                            ))
                                        }
                                        </div>}
                                </div>
                            ))
                        }
                        </div>
                        : <>
                            <Empty description='Exercise Result Not Found!' />
                        </>}
                </>}
        </div>
    );
};

export default ViewPlanScoreModal;