import { useState, useEffect } from 'react';
import { message, Table, Card, List } from 'antd';

import { getDecryptedData, secToMin } from '../../utils/common';
import Loader from '../../atoms/Loader';
import API from '../../services/rest/api';

import './GetAllGetActyvPlans.scss';

interface ViewAssessmentProps {
    selectedPlan: any;
}

const ViewAssessmentScore: React.FC<ViewAssessmentProps> = (props) => {

    const { Column, ColumnGroup } = Table;
    const [loading, setLoading] = useState<boolean>(false);
    const [assessmentResult, setAssessmentResult] = useState<any>();

    const modifyData = (payload: any) => {
        const response: any = {};
        response.id = payload.assessmentId;
        response.exerciseDetails = payload?.exerciseDetails || [];
        response.metrics = [];
        response.header = [];
        response.header.push({ title: 'Total Time Taken', content: payload.totalTimeTaken ? secToMin(payload.totalTimeTaken) : '' });
        response.header.push({ title: 'Total Calories Burnt', content: payload.totalCaloriesBurnt });
        response.header.push({ title: 'Skipped Exercises', content: payload.skippedExercises });

        if (payload?.metrics && Object.keys(payload.metrics).length) {
            const keys = Object.keys(payload.metrics);
            for (let i = 0; i < keys.length; i++) {
                const title = keys[i];
                const data = Object.keys(payload.metrics[`${title}`])
                    .map((key: any) => ({ title: key, content: payload.metrics[`${title}`][`${key}`] }));
                response.metrics.push({ title, data });
            }
        }

        return response;
    };

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await API.GetAssessmentResults(props?.selectedPlan?.id || '');
            const decryptedResp: any = JSON.parse(getDecryptedData(response));
            if (decryptedResp.code !== 1) throw new Error(decryptedResp.msg || 'Internal server error!');
            setAssessmentResult(modifyData(decryptedResp?.resp?.assessmentResultDetails || null));
        } catch (error) {
            console.log(error);
            message.error({ content: 'Failed to fetch data!' });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (loading || !props?.selectedPlan?.id) return;
        fetchData();
    }, [props?.selectedPlan]);

    return (
        <div className='view-assessment-wrapper'>
            {loading
                ? <Loader noBackground />
                : <>
                    {assessmentResult?.header?.length &&
                        <List
                            grid={{ gutter: 16, column: 3 }}
                            dataSource={assessmentResult.header}
                            renderItem={(item: any) => (
                                <List.Item>
                                    <Card className="custom-card" title={item.title}>{item.content}</Card>
                                </List.Item>
                            )}
                        />}

                    {!!(assessmentResult?.exerciseDetails || []).length &&
                        <Table dataSource={assessmentResult.exerciseDetails} pagination={false} className='custom-table'>
                            <Column className='column-custom-header' title='Exercise Name' dataIndex='exerciseName' key='exerciseName' />
                            <Column title='Exercise Type' dataIndex='exerciseType' key='exerciseType' />
                            <Column title='Exercise Performance Type' dataIndex='exercisePerformanceType' key='exercisePerformanceType' />
                            <ColumnGroup title='Reps'>
                                <Column title='Prescribed' dataIndex='repsPrescribed' key='repsPrescribed' />
                                <Column title='Performed' dataIndex='totalRepsPerformed' key='totalRepsPerformed' />
                            </ColumnGroup>
                            <ColumnGroup title='Sets'>
                                <Column title='Prescribed' dataIndex='setsPrescribed' key='setsPrescribed' />
                                <Column title='Performed' dataIndex='totalSetsPerformed' key='totalSetsPerformed' />
                            </ColumnGroup>
                            <ColumnGroup title='Time'>
                                <Column title='Prescribed' dataIndex='timePrescribed' key='timePrescribed' render={(item) => secToMin(item)} />
                                <Column title='Time Taken' dataIndex='exerciseTotalTimeTaken' key='exerciseTotalTimeTaken' render={(item) => secToMin(item)} />
                            </ColumnGroup>
                        </Table>}

                    {assessmentResult?.metrics?.length > 0 && <>{
                        assessmentResult?.metrics?.map((metric: any) => (<>
                            <h2 style={{ color: '#760fb2' }}>Matric & Score - {metric.title}</h2>
                            <List
                                grid={{ gutter: 16, column: 5 }}
                                dataSource={metric.data}
                                renderItem={(item: any) => (
                                    <List.Item>
                                        {['totalTimePrescribed', 'totalTimePerformed'].includes(item.title)
                                            ? <Card className="custom-card" title={item.title}>{secToMin(item.content)}</Card>
                                            : <Card className="custom-card" title={item.title}>{item.content}</Card>}
                                    </List.Item>
                                )}
                            />
                        </>))
                    } </>}
                </>}
        </div>
    );
};

export default ViewAssessmentScore;