import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";
import ReactSelect from "react-select";
import { Checkbox, DatePicker, Select, Input } from "antd";

import API from "../../services/rest/api";
import ExerciseAssessmentView from "../../molecules/ExerciseAssessmentView/ExerciseAssessmentView";
import { populateDataPointAnswers, dataPointsToDisable } from "../../helpers/exercise";

import './ExerciseAssessmentForm.scss';

const ExerciseAssessmentForm = () => {

    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const [assessmentTab, setAssessmentTab] = useState<any>(1)
    const [error, setError] = useState<string>('')
    const [diseaseOptions, setDiseaseOptions] = useState<any>([])
    const [selectedDisease, setSelectedDisease] = useState<any>(null);
    const [addQue, setAddQue] = useState<boolean>(false)
    const [newQuestion, setNewQuestion] = useState<string>('')
    const [newSubQuestion, setNewSubQuestion] = useState<any>('')
    const [questionAnswers, setQuestionAnswers] = useState<any>([])
    const [dataPoints, setDataPoints] = useState<any>([]);
    const [healthGoals, setHealthGoals] = useState<any>([]);
    const [patientAssessmentId, setPatientAssessmentId] = useState<string>('')
    const [patientData, setPatientData] = useState<any>(null)

    //assessment states
    const [patientAssessments, setPatientAssessments] = useState<any>([])
    const [assessmentOptions, setAssessmentOptions] = useState<any>([])
    const [selectedAssessment, setSelectedAssessment] = useState<any>(null)
    const [selected, setSelected] = useState([]);
    const [submitLoader, setSubmitLoader] = useState(false);

    // const userInfo = JSON.parse(localStorage.getItem('userInfo') || '') || {}
    // const token = localStorage.getItem('token')

    const initDataFromLocation = async (data: any) => {
        if (data) {
            setSelectedDisease(data?.disease_type);
            setPatientAssessmentId(data?.patient_assessment_id);
            setQuestionAnswers(data?.answer_list?.assessment_questions);
            setHealthGoals(data?.answer_list?.health_goals_questions);
            // setDataPoints(data?.answer_list?.data_points_questions);

            let newPatientAssessments: any;
            if (!patientAssessments.length) {
                newPatientAssessments = await getPatientAssessments(data?.disease_type, data?.patient_assessment_id);
            } else {
                newPatientAssessments = patientAssessments;
            }
            const dataPoints = data?.answer_list?.data_points_questions || [];
            // const newDP = populateDataPointAnswers(dataPoints, newPatientAssessments);
            setDataPoints(dataPoints);
        }
        else {
            setSelectedDisease('')
            setPatientAssessmentId('')
        }
    };

    useEffect(() => {
        initDataFromLocation(location?.state?.data);
    }, [location?.state?.data])

    useEffect(() => {
        const patient_id: any = params.id || params?.patientId
        localStorage.setItem('patient_id', patient_id)
        const token: any = params.token
        localStorage.setItem('token', token)
    }, [params])

    useEffect(() => {
        getAllDiseases()
        if (!location?.state?.data) {
            getPatientAssessments()
            getPatientData()
        }
    }, [])

    const initData = async () => {
        const patientAssessments = await getPatientAssessments();
        getQuestions(patientAssessments);
    };

    useEffect(() => {
        if (!location?.state?.data && selectedDisease) {
            initData();
        }
    }, [selectedDisease]);

    //function to get all diseases
    const getAllDiseases = async () => {
        try {
            const data = await API.GetDiseasesData()
            if (data?.code === '1') {
                setDiseaseOptions(data?.data)
            }
            else {
                setDiseaseOptions([])
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getLastCount = () => {
        let updatedAnswerList: any = [];
        questionAnswers.forEach((res: any) => {
            updatedAnswerList = [...updatedAnswerList, ...(res.questions_list || [])]
        })
        return (updatedAnswerList.pop()?.order_no || 0)
    }

    //function to add question
    const addNewQuestion = () => {
        let lastCount = getLastCount();
        let allquestions = [...questionAnswers]
        allquestions[allquestions.length - 1].questions_list.push({
            order_no: lastCount + 1,
            section_type: 'extra',
            section_label: 'Extra',
            question: newQuestion,
            answer: ''

        })
        setNewQuestion('')
        setAddQue(false)
    }

    //function to Get Question
    const getQuestions = async (patientAssessments: any[]) => {
        try {
            let questionsRes = await API.GetAssessmentQuestions({ disease_type: selectedDisease, patient_id: params.id || params?.patientId, assessment_type: 'exercise' });

            if (questionsRes?.code === "1") {
                if (questionsRes?.data?.assessment_questions[0]?.questions_list?.length > 0) {
                    questionsRes.data.assessment_questions[0].questions_list[0].answer = patientData?.name;
                    questionsRes.data.assessment_questions[0].questions_list[1].answer = patientData?.patient_age;
                    questionsRes.data.assessment_questions[0].questions_list[2].answer = patientData?.gender === 'M' ? 'Male' : 'Female';
                    questionsRes.data.assessment_questions[0].questions_list[4].answer = patientData?.address;
                    questionsRes.data.assessment_questions[0].questions_list[5].answer = patientData?.height;
                    questionsRes.data.assessment_questions[0].questions_list[6].answer = patientData?.weight;
                }
                setQuestionAnswers(questionsRes?.data?.assessment_questions);
                setHealthGoals(questionsRes?.data?.health_goals_questions);
                // setDataPoints(questionsRes?.data?.data_points_questions);
                // await getPatientData();

                const dataPoints = questionsRes?.data?.data_points_questions || [];
                // const newDP = populateDataPointAnswers(dataPoints, patientAssessments);
                setDataPoints(dataPoints);
            }
            else {
                setQuestionAnswers([]);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    //funtion to get patient data
    const getPatientData = async () => {
        try {
            let payload = {
                patient_id: params.id || params?.patientId
            }
            const data = await API.GetPatientData(payload)
            if (data?.code === '1') {
                setPatientData(data?.data)
                setSelectedDisease(data?.data?.medical_condition_name)
            }
        } catch (error) {
            console.log(error)
        }
    }

    //function to sumbit assessment
    const submitAssessment = async () => {
        setSubmitLoader(true);
        if (location?.state?.data) {
            try {
                const payload = {
                    patient_assessment_id: patientAssessmentId,
                    patient_id: params?.patientId,
                    answer_list: {
                        assessment_questions: questionAnswers,
                        data_points_questions: dataPoints,
                        health_goals_questions: healthGoals
                    }
                }
                const data = await API.UpdateAssessmentForm(payload)
                if (data?.code === '1') {
                    if (location?.state?.screen === "assessment_form_screen") {
                        let encodedtoken = encodeURIComponent(params?.token || '')
                        window.parent.postMessage("SaveAssessment", "*")
                        navigate(`/patients/${params?.hcId}/exercise-assessment/${params?.patientId}/${encodedtoken}`)
                        getPatientAssessments()
                    }
                    else {
                        let encodedtoken = encodeURIComponent(params?.token || '')
                        navigate(`/patients/${params?.hcId}/exercise-assessment-view/${params?.patientId}/${encodedtoken}`)
                    }
                    setError('')
                    // setAssessmentName('')
                }
                else {
                    setError(data?.message)
                }
            } catch (error) {
                console.log(error)
            }
        }
        else {
            try {
                // let newQuestionAnswers = questionAnswers.map((main: any) => {
                //     (main.questions_list || []).map((q: any) => {
                //         if ((q?.sub_questions || []).length) {
                //             q.answer = q.sub_questions

                //         }
                //         return q
                //     })
                //     return main
                // })
                const payload: any = {
                    assessment_date: moment(new Date()).format('YYYY-MM-DD'),
                    patient_id: params?.patientId,
                    disease_type: selectedDisease,
                    answer_list: {
                        assessment_questions: questionAnswers,
                        data_points_questions: dataPoints,
                        health_goals_questions: healthGoals
                    }
                }
                const data = await API.SubmitAssessmentForm(payload)
                if (data?.code === '1') {
                    window.parent.postMessage("SaveAssessment", "*")
                    window.location.reload();
                    setError('')
                    // setAssessmentName('')
                }
                else {
                    setError(data?.message)
                }

            } catch (error) {
                console.log(error)
            }
        }
        setSubmitLoader(false);
    }

    //funtion to change answer
    const changeAnswer = (e: any, order_no: any, sec_type: any) => {
        let tempQueArray = [...questionAnswers];

        const mainIndex = tempQueArray.findIndex(item => item?.section_type === sec_type)
        const index = tempQueArray[mainIndex]?.questions_list.findIndex((item: any) => item.order_no === order_no);

        if (index !== -1) {
            tempQueArray[mainIndex].questions_list[index].answer = e.target.value;
            setQuestionAnswers(tempQueArray)
        } else {
            console.log('Order number not found.');
        }
    };

    const onChangeTargetAnswer = (event: any, orderNo: number, subField: string) => {
        const tempQueArray = [...questionAnswers];
        const targetSection = tempQueArray.find(item => item?.section_type === 'target');

        if (targetSection) {
            const question = targetSection.questions_list.find((item: any) => item.order_no === orderNo);

            if (question) {
                const answer = question.answer ? JSON.parse(question.answer) : {};
                question.answer = JSON.stringify({ ...answer, [subField]: event.target.value });
                setQuestionAnswers(tempQueArray);
            } else {
                console.log('Order number not found.');
            }
        } else {
            console.log('Target section not found.');
        }
    };

    //funtion to Other change answer
    const otherChangeAnswer = (e: any, order_no: any, sec_type: any) => {
        let tempQueArray = [...questionAnswers];

        const mainIndex = tempQueArray.findIndex(item => item?.section_type === sec_type)
        const index = tempQueArray[mainIndex]?.questions_list.findIndex((item: any) => item.order_no === order_no);

        if (index !== -1) {
            tempQueArray[mainIndex].questions_list[index].other_answer = e.target.value;
            setQuestionAnswers(tempQueArray)
        } else {
            console.log('Order number not found.');
        }
    };

    //funtion to get patient assessments
    const getPatientAssessments = async (disease_type?: string, selected_assesment_id?: string) => {
        try {
            const assessmentData = await API.GetPatientAssessments({ patient_id: params.id || params?.patientId, disease_type: selectedDisease ?? disease_type });
            if (assessmentData?.code === '1') {
                const tempAssessmentData = assessmentData?.data?.map((data: any) => {
                    return { ...data, label: data?.assessment_name, value: data?.patient_assessment_id }
                });
                setAssessmentOptions(tempAssessmentData);
                if (selected_assesment_id) {
                    const selectedPatientAssessment = (assessmentData?.data ?? []).find((assessment: any) => assessment.patient_assessment_id === selected_assesment_id);
                    setSelectedAssessment(selectedPatientAssessment ?? assessmentData?.data[assessmentData?.data?.length - 1]);
                } else {
                    setSelectedAssessment(assessmentData?.data[assessmentData?.data?.length - 1]);
                }
                setPatientAssessments(assessmentData?.data);
                return assessmentData?.data || [];
            }
            else {
                setPatientAssessments([]);
                setAssessmentOptions([]);
                return [];
            }
        } catch (error) {
            console.log(error)
        }
    }

    const onMultiChange = (order_no: any, op: any, sec_type: any) => {
        let tempQueArray: any = [...questionAnswers];

        const mainIndex = tempQueArray.findIndex((item: any) => item?.section_type === sec_type)
        const index = tempQueArray[mainIndex].questions_list.findIndex((item: any) => item.order_no === order_no);

        if (index !== -1) {
            if (tempQueArray[mainIndex]?.questions_list[index]?.answer && tempQueArray[mainIndex]?.questions_list[index]?.answer !== "") {
                const answerArray = tempQueArray[mainIndex].questions_list[index].answer?.split('<->');
                if (answerArray.includes(op?.label)) {
                    const updatedAnswerArray = answerArray.filter((item: any) => item !== op?.label);
                    tempQueArray[mainIndex].questions_list[index].answer = updatedAnswerArray.join('<->');

                    if (op?.label === 'Other') {
                        tempQueArray[mainIndex].questions_list[index].other_answer = null
                    }
                    setQuestionAnswers(tempQueArray);
                } else {
                    tempQueArray[mainIndex].questions_list[index].answer = tempQueArray[mainIndex].questions_list[index].answer + "<->" + op?.label;
                    setQuestionAnswers(tempQueArray);
                }
            } else {
                tempQueArray[mainIndex].questions_list[index].answer = op?.label;
                setQuestionAnswers(tempQueArray);
            }
        } else {
            console.log('Order number not found.');
        }
    }

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '33px',
            boxShadow: state.isFocused ? null : null,
            borderRadius: '5px',
            width: '300px',
            cursor: 'pointer',
            border: '1px solid #7E22B7',
            color: '#7E22B7'
        }),

        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            padding: '3px 10px',
            fontSize: '12px',
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: (state: any) => ({
            display: 'none',
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
        }),
        menuPortal: (base: any) => ({
            ...base,
            fontSize: '8px'
        })
    };

    const customStyles2 = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '33px',
            boxShadow: state.isFocused ? null : null,
            borderRadius: '5px',
            width: '200px',
            cursor: 'pointer',
            border: '1px solid #7E22B7',
            color: '#7E22B7'
        }),

        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            padding: '3px 10px',
            fontSize: '12px',
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: (state: any) => ({
            display: 'none',
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
        }),
        menuPortal: (base: any) => ({
            ...base,
            fontSize: '8px'
        })
    };

    const disabledDate = (current: any) => {
        // Disable dates before today
        return current && current < dayjs().startOf('day');
    };

    return (
        <div className="exercise-assessment-form-container">
            {/* <div onClick={() => navigate(-1)} style={{ display: 'flex', alignItems: 'center' }}>
                <BackIcon className="back-icon" />
            </div> */}
            <p className="exercise-assessment-form-heading">Patient Exercise Assessment Form</p>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                <div className="assessment-type">
                    <span style={{ fontSize: '14px', fontWeight: 700, color: '#760fb2' }}>Select Disease</span>
                    <ReactSelect
                        classNamePrefix="select"
                        name="color"
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 6,
                            colors: {
                                ...theme.colors,
                                primary25: '#F0E7F7',
                                primary: '#760fb2',
                            },
                        })}
                        options={diseaseOptions}
                        onChange={(selectedOption: any) => {
                            setSelectedDisease(selectedOption?.value)
                        }}
                        value={diseaseOptions.find((val: any) => val.value == selectedDisease)}
                        styles={customStyles}
                        placeholder='Select Disease'
                        isDisabled={location?.state?.data ? true : false}
                    />
                </div>

                {!location?.state?.data &&
                    <div className="assessment-type">
                        <span style={{ fontSize: '14px', fontWeight: 700, color: '#760fb2' }}>Select Assessment</span>
                        <ReactSelect
                            classNamePrefix="select"
                            name="color"
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 6,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#F0E7F7',
                                    primary: '#760fb2',
                                },
                            })}
                            options={assessmentOptions}
                            onChange={(selectedOption: any) => {
                                setSelectedAssessment(selectedOption)
                            }}
                            value={assessmentOptions.find((val: any) => val.value == selectedAssessment?.patient_assessment_id)}
                            styles={customStyles}
                            placeholder='Select Assessment'
                            isDisabled={location?.state?.data ? true : false}
                        />
                    </div>
                }
            </div>

            {(!selectedAssessment?.patient_assessment_id || location?.state?.data)
                ? <>
                    <div className='exercise-assessment-tabs'>
                        <div className={assessmentTab === 1 ? "exercise-assessment-tab-active" : "exercise-assessment-tab"} onClick={() => setAssessmentTab(1)}>
                            Assessment
                        </div>
                        <div className={assessmentTab === 2 ? "exercise-assessment-tab-active" : "exercise-assessment-tab"} onClick={() => setAssessmentTab(2)}>
                            Data Points
                        </div>
                        <div className={assessmentTab === 3 ? "exercise-assessment-tab-active" : "exercise-assessment-tab"} onClick={() => setAssessmentTab(3)}>
                            Health Goals
                        </div>
                    </div>

                    {assessmentTab === 1 && <>
                        <div className="exercise-assessment-personal">
                            <p className="assessment-personal-heading">Personal History</p>
                            <div className="personal-que-ans">
                                {questionAnswers[0]?.questions_list.map((d: any, idx: any) =>
                                    <div className="personal-que-ans-inner">
                                        <div className="personal-que-ans-left"><span>{d?.order_no}. {d?.question}</span> <span>:</span></div>
                                        {d?.options?.length
                                            ? <>
                                                {d?.is_multiselect == 'Y'
                                                    ? <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        {d?.options.map((op: any) =>
                                                            <div style={{ display: 'flex', gap: '5px', marginLeft: '15px' }}>
                                                                <Checkbox
                                                                    onChange={() => onMultiChange(d?.order_no, op, questionAnswers[0]?.section_type)}
                                                                    checked={d?.answer?.split('<->').includes(op?.label)}
                                                                />
                                                                <div>{op?.label}</div>
                                                            </div>
                                                        )}
                                                        {d?.answer?.split('<->').includes('Other') &&
                                                            <textarea
                                                                name={d?.question}
                                                                onChange={(e) => otherChangeAnswer(e, d?.order_no, d?.section_type)}
                                                                value={d?.other_answer}
                                                            />
                                                        }
                                                    </div>
                                                    : <div style={{ display: 'flex', flexDirection: 'column', margin: '5px 12px' }}>
                                                        <Select
                                                            style={{ width: 250 }}
                                                            onChange={(value: any) => {
                                                                let tempQueArray = [...questionAnswers];
                                                                const mainIndex = tempQueArray.findIndex(item => item?.section_type === questionAnswers[0].section_type)
                                                                const index = tempQueArray[mainIndex]?.questions_list?.findIndex((item: any) => item.order_no === d?.order_no);

                                                                if (index !== -1) {
                                                                    tempQueArray[mainIndex].questions_list[index].answer = value;
                                                                    setQuestionAnswers(tempQueArray)
                                                                } else {
                                                                    console.log('Order number not found.');
                                                                }
                                                            }}
                                                            value={questionAnswers[0].questions_list[idx]?.answer}
                                                            options={d?.options.map((op: any) => {
                                                                return { ...op, value: op?.label }
                                                            })}
                                                        />
                                                    </div>
                                                }
                                            </>
                                            : <input name="patient_name" type="text" value={d?.answer} onChange={(e) => changeAnswer(e, d?.order_no, 'personal_history')} />
                                        }
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="exercise-assessment-other">
                            {questionAnswers.slice(1).map((d: any, mainIndex: any) => <>
                                <span className="assessment-other-heading">
                                    {d?.section_label}
                                </span>
                                {d?.questions_list.map((que: any, idx: any) => <>
                                    {d?.section_type === 'target'
                                        ? <>
                                            <div className="assessment-target-que-ans ">
                                                <span className="assessment-que">{que?.order_no}. {que?.question}</span>
                                                <div className="assessment-target">
                                                    <Input
                                                        type="number"
                                                        placeholder="Enter target"
                                                        onChange={(e) => onChangeTargetAnswer(e, que?.order_no, 'value')}
                                                        value={que?.answer ? JSON.parse(que.answer)?.value : ''}
                                                        style={{ color: 'black' }}
                                                    />
                                                </div>
                                                <div className="assessment-target-limit">
                                                    <Input
                                                        type="number"
                                                        className="limit"
                                                        placeholder="Days"
                                                        onChange={(e) => onChangeTargetAnswer(e, que?.order_no, 'target_days')}
                                                        value={que?.answer ? JSON.parse(que.answer)?.target_days : ''}
                                                        style={{ color: 'black' }}
                                                    />
                                                    <span style={{ fontSize: '14px' }}>(Days)</span>
                                                </div>
                                            </div>
                                        </>
                                        : <div className="assessment-que-ans">
                                            <span className="assessment-que">{que?.order_no}. {que?.question}</span>
                                            {que?.sub_questions && que.sub_questions?.length ?
                                                <div style={{ marginTop: '10px', marginLeft: '10px' }}>
                                                    {que?.sub_questions.map((subQue: any, subQueIdx: any) =>
                                                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px' }}>
                                                            <span className="assessment-que" style={{ fontSize: '13px' }}>{subQueIdx + 1}. {subQue?.question}</span>
                                                            <textarea
                                                                name={subQue?.question}
                                                                value={subQue?.answer}
                                                                onChange={(e) => {
                                                                    const temp = [...questionAnswers];
                                                                    const mainIndex = temp.findIndex(item => item?.section_type === d?.section_type);
                                                                    const questionList = temp[mainIndex]?.questions_list;
                                                                    const questionIndex = questionList.findIndex((item: any) => item.order_no === que?.order_no);
                                                                    const subQuestion = questionList[questionIndex].sub_questions[subQueIdx];
                                                                    subQuestion.answer = e.target.value;

                                                                    const questionToDisable = subQuestion?.question;
                                                                    const dataPointToDisable = dataPointsToDisable.find(obj =>
                                                                        (Object.values(obj)[0] as string).trim().includes(questionToDisable.trim())
                                                                    );

                                                                    if (dataPointToDisable) {
                                                                        const assessmentIndex = (patientAssessments || []).findIndex((a: any) => a.assessment_name === selectedAssessment.assessment_name);
                                                                        const dataPointToUpdateIndex = (dataPoints || []).findIndex((dp: any) => dp.question === Object.keys(dataPointToDisable)[0]);

                                                                        const newDataPoints = [...dataPoints];
                                                                        const answers = newDataPoints[dataPointToUpdateIndex]?.answer || [];
                                                                        const questionsToCheck = ['LL - no. of squats', 'LL - 30 sec chair test'];
                                                                        if (!e.target.value && questionsToCheck.includes(questionToDisable)) {
                                                                            const targetQuestion = questionToDisable === questionsToCheck[0] ? questionsToCheck[1] : questionsToCheck[0];
                                                                            const alternativeAnswer = (questionList[questionIndex].sub_questions || []).find((a: any) => a.question === targetQuestion);
                                                                            answers[assessmentIndex] = alternativeAnswer ? alternativeAnswer.answer || '' : '';
                                                                        } else {
                                                                            answers[assessmentIndex] = e.target.value;
                                                                        }
                                                                        newDataPoints[dataPointToUpdateIndex].answer = answers;
                                                                        setDataPoints(newDataPoints);
                                                                    }

                                                                    setQuestionAnswers(temp);
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                    {(['Strength', 'Cardiovascular Endurance', 'Flexibility'].includes(que?.question)) &&
                                                        <>
                                                            {que?.is_add_subQue
                                                                ? <div className="add-que-container">
                                                                    <textarea name="que" placeholder="add new question here..." onChange={(e) => setNewSubQuestion(e.target.value)} />
                                                                    <button
                                                                        className={newSubQuestion?.length ? "save-que-button" : "save-que-button-disable"}
                                                                        onClick={() => {
                                                                            const temp = [...questionAnswers]
                                                                            const mainIndex = temp.findIndex(item => item?.section_type === d?.section_type)
                                                                            //const index = temp[mainIndex]?.questions_list.findIndex((item: any) => item.order_no === que?.order_no);
                                                                            temp[mainIndex].questions_list[idx].sub_questions.push({ question: newSubQuestion })
                                                                            delete temp[mainIndex].questions_list[idx].is_add_subQue
                                                                            setQuestionAnswers(temp)
                                                                            setNewSubQuestion('')
                                                                        }}
                                                                        disabled={!newSubQuestion?.length}
                                                                    >
                                                                        Save
                                                                    </button>
                                                                </div>
                                                                : <button
                                                                    className="add-que-button"
                                                                    onClick={() => {
                                                                        const temp = [...questionAnswers]
                                                                        const mainIndex = temp.findIndex(item => item?.section_type === d?.section_type)
                                                                        temp[mainIndex].questions_list[idx].is_add_subQue = true
                                                                        setQuestionAnswers(temp)
                                                                    }}
                                                                >
                                                                    + Add Question
                                                                </button>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                                : <>
                                                    {que?.options?.length
                                                        ? <>
                                                            {que?.is_multiselect == 'Y' ?
                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    {que?.options.map((op: any) => {
                                                                        return (
                                                                            <div style={{ display: 'flex', gap: '5px', marginLeft: '15px' }}>
                                                                                <Checkbox
                                                                                    onChange={() => onMultiChange(que?.order_no, op, d?.section_type)}
                                                                                    checked={que?.answer?.split('<->').includes(op?.label)}
                                                                                />
                                                                                <div>{op?.label}</div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                    {que?.answer?.split('<->').includes('Other') &&
                                                                        <textarea
                                                                            name={que?.question}
                                                                            onChange={(e) => otherChangeAnswer(e, que?.order_no, d?.section_type)}
                                                                            value={que?.other_answer}
                                                                        />
                                                                    }
                                                                </div>
                                                                :
                                                                <div style={{ width: '200px', margin: '5px 15px' }}>
                                                                    <Select
                                                                        style={{ width: 250 }}
                                                                        onChange={(value: any) => {
                                                                            let tempQueArray = [...questionAnswers];
                                                                            const mainIndex = tempQueArray.findIndex(item => item?.section_type === d?.section_type)
                                                                            const index = tempQueArray[mainIndex]?.questions_list?.findIndex((item: any) => item.order_no === que?.order_no);
                                                                            tempQueArray[mainIndex].questions_list[index].answer = value;
                                                                            setQuestionAnswers(tempQueArray)
                                                                        }}
                                                                        value={que?.answer}
                                                                        options={que?.options.map((op: any) => {
                                                                            return { ...op, value: op?.label }
                                                                        })}
                                                                    />
                                                                </div>
                                                            }
                                                        </>
                                                        : <textarea name={que?.question} value={que?.answer} onChange={(e) => changeAnswer(e, que?.order_no, d?.section_type)} />}
                                                </>
                                            }
                                        </div>
                                    }
                                </>)}
                            </>)}
                        </div>

                        {addQue
                            ? <div className="add-que-container">
                                <textarea name="que" placeholder="add new question here..." onChange={(e) => setNewQuestion(e.target.value)} />
                                <button className={newQuestion?.length ? "save-que-button" : "save-que-button-disable"} onClick={() => addNewQuestion()} disabled={!newQuestion?.length}>
                                    Save
                                </button>
                            </div>
                            : <button className="add-que-button" onClick={() => setAddQue(true)}>
                                + Add Question
                            </button>}

                        {error && <span style={{ color: 'red', marginTop: '15px' }}>{error}</span>}
                    </>}

                    {assessmentTab === 2 && dataPoints && !!dataPoints.length &&
                        <div className="data-points-table-main">
                            <table className="data-points-table">
                                <thead>
                                    <tr className="data-points-table-header-row">
                                        <th className="data-points-table-header-data fixed-column">Parameters to review ( Proactive )</th>
                                        {
                                            new Array(Math.max(...(dataPoints || []).map((o: any) => (o?.answer || []).length), 1)).fill(0).map((p, i) => i).map((headerIndex) => {
                                                return (
                                                    <th className="data-points-table-header-data">
                                                        Week {headerIndex + 1}
                                                        {/* & {headerIndex * 2 + 2} */}
                                                    </th>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td className="data-points-table-body-data fixed-column">
                                            Select Date
                                        </td>
                                        {
                                            new Array(Math.max(...(dataPoints || []).map((o: any) => (o?.answer || []).length), 1)).fill(0).map((p, i) => i).map((headerIndex) => {
                                                return (
                                                    <td className="data-points-table-body-data">
                                                        <DatePicker
                                                            value={dataPoints &&
                                                                dataPoints.length &&
                                                                dataPoints[0]?.exercise_dates?.length &&
                                                                dataPoints[0].exercise_dates[headerIndex] &&
                                                                dataPoints[0].exercise_dates[headerIndex] !== ""
                                                                ? dayjs(dataPoints[0].exercise_dates[headerIndex], 'YYYY-MM-DD')
                                                                :
                                                                null
                                                            }
                                                            format={'YYYY-MM-DD'}
                                                            onChange={(moment, date) => {
                                                                let temp = [...dataPoints]
                                                                let updatedArray = temp.map((t: any) => {
                                                                    let newDate = [...t?.exercise_dates]
                                                                    newDate[headerIndex] = date
                                                                    return { ...t, exercise_dates: newDate }
                                                                })
                                                                setDataPoints(updatedArray)
                                                            }}
                                                            disabledDate={disabledDate}
                                                        />
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                    {/* {
                                        (dataPoints || [])?.map((point: any, pointIdx: any) => {
                                            return (
                                                <tr>
                                                    <td className="data-points-table-body-data fixed-column">
                                                        {point?.question}
                                                        {point?.measurement && <span style={{ fontSize: '12px', fontWeight: '700', marginLeft: '4px' }}>{`(${point?.measurement})`}</span>}
                                                    </td>
                                                    {
                                                        new Array(Math.max(...(dataPoints || []).map((o: any) => (o?.answer || []).length), 1)).fill(0).map((p, i) => i).map((headerIndex) => {
                                                            return (
                                                                <td className="data-points-table-body-data">
                                                                    <input
                                                                        name="patient_name"
                                                                        type="text"
                                                                        value={dataPoints[pointIdx].answer[headerIndex]}
                                                                        onChange={(e) => {
                                                                            let temp = [...dataPoints]
                                                                            temp[pointIdx].answer[headerIndex] = e.target.value;
                                                                            setDataPoints(temp)
                                                                        }}
                                                                        disabled={dataPointsToDisable.map(obj => Object.keys(obj)[0]).includes(point?.question)}
                                                                        style={dataPointsToDisable.map(obj => Object.keys(obj)[0]).includes(point?.question) ? { cursor: 'no-drop' } : {}}
                                                                    />
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    } */}

                                    {(dataPoints || []).map((point: any, pointIdx: any) => (
                                        <tr>
                                            <td className="data-points-table-body-data fixed-column">
                                                {point?.question}
                                                {point?.measurement && (
                                                    <span style={{ fontSize: '12px', fontWeight: '700', marginLeft: '4px' }}>
                                                        {`(${point?.measurement})`}
                                                    </span>
                                                )}
                                            </td>
                                            {new Array(Math.max(...(dataPoints || []).map((o: any) => (o?.answer || []).length), 1))
                                                .fill(0)
                                                .map((_, headerIndex) => (
                                                    <td className="data-points-table-body-data">
                                                        <input
                                                            name="patient_name"
                                                            type="text"
                                                            value={dataPoints[pointIdx].answer[headerIndex]}
                                                            onChange={(e) => {
                                                                let temp = [...dataPoints];
                                                                temp[pointIdx].answer[headerIndex] = e.target.value;
                                                                setDataPoints(temp);
                                                            }}
                                                        // disabled={dataPointsToDisable.map(obj => Object.keys(obj)[0]).includes(point?.question)}
                                                        // style={
                                                        //     dataPointsToDisable.map(obj => Object.keys(obj)[0]).includes(point?.question)
                                                        //         ? { cursor: 'no-drop' }
                                                        //         : {}
                                                        // }
                                                        />
                                                    </td>
                                                ))}
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        </div>}

                    {(assessmentTab === 3 && healthGoals && !!healthGoals.length) &&
                        <>
                            <div className="health-goals-table-main">
                                <table className="health-goals-table">
                                    <thead>
                                        <tr className="health-goals-table-header-row">
                                            <th className="health-goals-table-header-data fixed-column">Parameters to review ( Proactive )</th>
                                            {
                                                new Array(Math.max(...(healthGoals || []).map((o: any) => (o?.answer || []).length), 1)).fill(0).map((p, i) => i).map((headerIndex) => {
                                                    return (
                                                        <th className="health-goals-table-header-data">
                                                            Week {headerIndex + 1}
                                                            {/* & {headerIndex * 2 + 2} */}
                                                        </th>
                                                    )
                                                })
                                            }
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td className="health-goals-table-body-data fixed-column">
                                                Select Date
                                            </td>
                                            {
                                                new Array(Math.max(...(healthGoals || []).map((o: any) => (o?.answer || []).length), 1)).fill(0).map((p, i) => i).map((headerIndex) => {
                                                    return (
                                                        <td className="health-goals-table-body-data">
                                                            <DatePicker
                                                                value={healthGoals &&
                                                                    healthGoals.length &&
                                                                    healthGoals[0]?.exercise_dates?.length &&
                                                                    healthGoals[0].exercise_dates[headerIndex] &&
                                                                    healthGoals[0].exercise_dates[headerIndex] !== ""
                                                                    ? dayjs(healthGoals[0].exercise_dates[headerIndex], 'YYYY-MM-DD')
                                                                    :
                                                                    null
                                                                }
                                                                format={'YYYY-MM-DD'}
                                                                onChange={(moment, date) => {
                                                                    let temp = [...healthGoals]
                                                                    let updatedArray = temp.map((t: any) => {
                                                                        let newDate = [...t?.exercise_dates]
                                                                        newDate[headerIndex] = date
                                                                        return { ...t, exercise_dates: newDate }
                                                                    })
                                                                    setHealthGoals(updatedArray)
                                                                }}
                                                                disabledDate={disabledDate}
                                                            />
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                        {
                                            (healthGoals || [])?.map((point: any, pointIdx: any) => {
                                                return (
                                                    <tr>
                                                        <td className="health-goals-table-body-data fixed-column">
                                                            {point?.question}
                                                            {point?.measurement && <span style={{ fontSize: '12px', fontWeight: '700', marginLeft: '4px' }}>{`(${point?.measurement})`}</span>}
                                                        </td>
                                                        {
                                                            new Array(Math.max(...(healthGoals || []).map((o: any) => (o?.answer || []).length), 1)).fill(0).map((p, i) => i).map((headerIndex) => {
                                                                return (
                                                                    <td className="health-goals-table-body-data">
                                                                        <input
                                                                            name="patient_name"
                                                                            type="text"
                                                                            value={healthGoals[pointIdx].answer[headerIndex]}
                                                                            onChange={(e) => {
                                                                                let temp = [...healthGoals]
                                                                                temp[pointIdx].answer[headerIndex] = e.target.value;
                                                                                setHealthGoals(temp)
                                                                            }}
                                                                        />
                                                                    </td>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </>
                    }

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
                        <button
                            className="add-que-button"
                            onClick={() => {
                                // if (location?.state?.data) {
                                //     let encodedtoken = encodeURIComponent(token || '')
                                //     navigate(`/homescreen/${params?.patientId}/${encodedtoken}`)
                                // }
                                // else {
                                window.parent.postMessage("cancelAssessment", "*")
                                navigate(-1)
                                // }
                            }}
                        >
                            Cancel
                        </button>
                        {assessmentTab === 3 ?
                            <button className={submitLoader ? "save-que-button-disable" : "save-que-button"} onClick={submitAssessment} disabled={submitLoader}>
                                Submit
                            </button>
                            :
                            <button className="save-que-button"
                                onClick={() => {
                                    setAssessmentTab(assessmentTab + 1)
                                    window.scrollTo(0, 0)
                                }}
                            >
                                Next
                            </button>
                        }
                    </div>
                </>
                : <ExerciseAssessmentView selectedAssessment={selectedAssessment} screen='assessment_form_screen' />
            }
        </div>
    )
}

export default ExerciseAssessmentForm;
