import { FC, useEffect, useState } from "react"
import './PendingDietApprovalList.scss';
import API from "../../services/rest/api";
import { CorrectSign, WrongSign } from "../../assets/icons";
import { Modal, Pagination } from "antd";
import EyeIcon from '../../assets/icons/eyeIcon.png'
import AddDietContainer from "../../organisms/AddDietPlan/AddDietPlan";
import DietAssessmentView from "../DietAssessmentView/DietAssessmentView";
import ReactSelect from "react-select";
import DietPlanPdf from "../DietPlanPdf/DietPlanPdf";
import Loader from "../../atoms/Loader";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";
import { DIET_STATUS } from "../../constants/diet";

type PendingDietApprovalProps = {

}

const PendingDietApprovalList: FC<PendingDietApprovalProps> = () => {

    const [pendingDietPlanList, setPendingDietPlanList] = useState<any>([])
    const [pendingDietPlanLoader, setPendingDietPlanLoader] = useState<boolean>(false)
    const [pendingDietPlanPagination, setPendingDietPlanPagination] = useState({ page: 1, limit: 10, total: 0 })
    const [openApprovalModal, setOpenApprovalModal] = useState<any>({ show: false, type: null, selectedDiet: null })
    const [showDietModal, setShowDietModal] = useState<any>(false)
    const [selectedDietPlan, setSelectedDietPlan] = useState<any>(null)

    const [selectedAssessment, setSelectedAssessment] = useState<any>(null)
    const [assessmentOptions, setAssessmentOptions] = useState<any>([])
    const [patientAssessments, setPatientAssessments] = useState<any>([])

    const [patientDetails, setPatientDetails] = useState<any>(null)
    const [diseaseOptions, setDiseaseOptions] = useState<any>([])
    const [selectedDisease, setSelectedDisease] = useState<any>(null);

    const [pdfModal, setPdfModal] = useState<any>(false)
    const [disableUpdateButton, setDisableUpdateButton] = useState<any>(false)
    const [userDetails, setUserDetails] = useState<any>(null)
    const token = localStorage.getItem("token")
    const params = useParams();
    const location: any = useLocation();

    useEffect(() => {
        const token: any = params.token
        getProfileDetails();
        localStorage.setItem('token', (location.state?.token === ":token" || location.state?.token === undefined) ? token : location.state?.token);
    }, [])

    const getProfileDetails = async () => {
        try {
        const userInfo = await API.GetProfileDetails()

        setUserDetails(userInfo?.data)

        } catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        getPendingDietPlanList(null)
    }, [pendingDietPlanPagination?.page])

    useEffect(() => {
        getAllDiseases()
    }, [patientDetails])

    useEffect(() => {
        if (!showDietModal) {
            getPendingDietPlanList(1)
            // setPendingDietPlanPagination({ ...pendingDietPlanPagination, page: 1 })
        }
    }, [showDietModal])

    useEffect(() => {
        if (selectedDisease) {
            getPatientAssessments()
        }
    }, [selectedDisease])

    //function to get pending dietplan list
    const getPendingDietPlanList = async (page: any) => {
        setPendingDietPlanLoader(true)
        try {
            const payload = {
                page: page ?? pendingDietPlanPagination?.page,
                limit: pendingDietPlanPagination?.limit
            }
            const pendingDietData = await API.GetPendingDietPlansUpdated(payload)
            if (Number(pendingDietData.code) === 1) {
                setPendingDietPlanList(pendingDietData?.data)
                setPendingDietPlanPagination({
                    ...pendingDietPlanPagination,
                    total: pendingDietData?.total_data || 0,
                    page: page ?? pendingDietPlanPagination?.page
                });
                setPendingDietPlanLoader(false)
            } else {
                // setPendingDietPlanList([])
                setPendingDietPlanLoader(false)
            }
        } catch (error) {
            console.error("Error while getting data", error)
            setPendingDietPlanLoader(false)
        }
    }

    //funtion to handle approve-reject by chief-hc
    const chiefBtnHandle = async (type: any) => {
        try {
            const newPayload = {
                diet_plans_id: openApprovalModal?.selectedDiet?.diet_plans_id,
                // chief_remarks: "",
                status: type === 'approve' ? DIET_STATUS.APPROVED : DIET_STATUS.REJECTED,
            }

            const response = await API.UpdateDietPlanStatusByChief(newPayload);

            if(Number(response?.code) === 1) {
                setOpenApprovalModal({ show: false, type: null, selectedDiet: null });
                getPendingDietPlanList(1);
                toast.success(response?.message || "Successfully updated!")
                // setPendingDietPlanPagination({ ...pendingDietPlanPagination, page: 1 })
            } else {
                toast.error(response?.message)
            }
        } catch (error: any) {
            console.error("Error while updating the Diet plan", error);
            toast.error(error?.message || "Something went wrong!");
        }
    }

    //function to get all diseases
    const getAllDiseases = async () => {
        try {
            const data = await API.GetDiseasesData()
            if (data?.code === '1') {
                setDiseaseOptions(data?.data)
            }
            else {
                setDiseaseOptions([])
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getPatientAssessments = async () => {
        try {
            let assessmentData = await API.GetPatientAssessments({ patient_id: selectedDietPlan?.patient_id, read_only: true, disease_type: selectedDisease, assessment_type: 'diet' })
            if (assessmentData?.code === '1') {
                setPatientAssessments(assessmentData?.data)
                // if (assessmentData?.data.length > 1) {
                let tempAssessmentData = assessmentData?.data?.map((data: any) => {
                    return { ...data, label: data?.assessment_name, value: data?.patient_assessment_id }
                })
                setAssessmentOptions(tempAssessmentData)
                setSelectedAssessment((assessmentData?.data[assessmentData?.data.length - 1]))
                // }
                // else {
                //     setSelectedAssessment((assessmentData?.data[assessmentData?.data.length - 1]))
                // }
            }
            else {
                setPatientAssessments([])
                setAssessmentOptions([])
                setSelectedAssessment(null)

            }
        } catch (error) {
            console.log(error)
        }
    }

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '33px',
            boxShadow: state.isFocused ? null : null,
            borderRadius: '5px',
            width: '170px',
            cursor: 'pointer',
            border: '1px solid #7E22B7',
            color: '#7E22B7'
        }),

        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            padding: '3px 10px',
            fontSize: '12px',
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: (state: any) => ({
            display: 'none',
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
        }),
        menuPortal: (base: any) => ({
            ...base,
            fontSize: '8px'
        })
    };

    const paginationChange = (page: any, pageSize: any) => {
        console.log(page, pageSize)
        setPendingDietPlanPagination({ ...pendingDietPlanPagination, page: page })
    }

    const getDietPlanById = async (patientId: any, dietPlanId: any) => {
        try {
            const response = await API.GetDietPlan({
                patient_id: patientId,
                diet_plans_id: dietPlanId
            });

            console.log(response);

            if(Number(response?.code) === 1 && response?.data?.length > 0) {
                return response?.data[0];
            } else {
                toast.error("Diet plan does't exists!");
            }
        } catch(error: any) {
            if(error?.json) {
                const response = await error?.json()
                console.error("Error while fetching Diet Plan Details", response);
                toast.error(response?.message)
            } else {
                console.error("Error while fetching Diet Plan Details", error);
            }
        }
    }

    return (
        <>
            {pendingDietPlanLoader && <div className="loaderAbsolute">
                <Loader noBackground={true} />
            </div>}
            <div className="pending-plan-approval-list">
                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr style={{ background: '#F0E7F7' }}>
                            <th style={{ fontWeight: '500', padding: '10px 0', fontSize: '12px' }}>No.#</th>
                            <th style={{ fontWeight: '500', padding: '10px 0', fontSize: '12px' }}>Health Coach Name</th>
                            <th style={{ fontWeight: '500', padding: '10px 0', fontSize: '12px' }}>Patient Name</th>
                            <th style={{ fontWeight: '500', padding: '10px 0', fontSize: '12px' }}>Plan Name</th>
                            <th style={{ fontWeight: '500', padding: '10px 0', fontSize: '12px' }}>Creation Date</th>
                            <th style={{ fontWeight: '500', padding: '10px 0', fontSize: '12px' }}>Start Date</th>
                            <th style={{ fontWeight: '500', padding: '10px 0', fontSize: '12px' }}>Expiry Date</th>
                            <th style={{ fontWeight: '500', padding: '10px 0', fontSize: '12px' }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pendingDietPlanList?.map((data: any, idx: any) => (
                            <tr style={{ borderBottom: '1px solid #F1F1FC' }}>
                                <td style={{ textAlign: 'center', padding: '20px 0', fontSize: '12px' }}>{(pendingDietPlanPagination?.page - 1) * pendingDietPlanPagination?.limit + idx + 1}</td>
                                <td style={{ textAlign: 'center', padding: '20px 0', fontSize: '12px' }}>{data?.health_coach_details?.first_name} {' '} {data?.health_coach_details?.last_name}</td>
                                <td style={{ textAlign: 'center', padding: '20px 0', fontSize: '12px' }}>{data?.patient_details?.name}</td>
                                <td style={{ textAlign: 'center', padding: '20px 0', fontSize: '12px' }}>{data?.plan_name}</td>
                                <td style={{ textAlign: 'center', padding: '20px 0', fontSize: '12px' }}>{data?.created_at}</td>
                                <td style={{ textAlign: 'center', padding: '20px 0', fontSize: '12px' }}>{data?.start_date}</td>
                                <td style={{ textAlign: 'center', padding: '20px 0', fontSize: '12px' }}>{data?.end_date}</td>
                                <td style={{ textAlign: 'center', padding: '20px 0', fontSize: '12px' }}>
                                    {data?.status === 'pending' ? (
                                        <div style={{ display: 'flex', gap: '20px', alignItems: 'center', justifyContent: 'center' }}>
                                            <img
                                                src={EyeIcon}
                                                style={{ cursor: 'pointer' }}
                                                onClick={async () => {
                                                    const dietPlanData = await getDietPlanById(data?.patient_id, data?.diet_plans_id)
                                                    if(dietPlanData) {
                                                        setSelectedDietPlan(dietPlanData)
                                                        setPatientDetails(data?.patient_details)
                                                        setSelectedDisease(data?.patient_details?.medical_condition_name)
                                                        setShowDietModal(true)
                                                    }
                                                }}
                                            />
                                            <CorrectSign style={{ cursor: 'pointer' }} onClick={() => setOpenApprovalModal({ show: true, type: 'approve', selectedDiet: data })} />
                                            <WrongSign style={{ cursor: 'pointer' }} onClick={() => setOpenApprovalModal({ show: true, type: 'reject', selectedDiet: data })} />
                                        </div>
                                    ) : (
                                        <div style={{ display: 'flex', gap: '20px', alignItems: 'center', justifyContent: 'center' }}>
                                            <img
                                                src={EyeIcon}
                                                style={{ cursor: 'pointer' }}
                                                onClick={async () => {
                                                    const dietPlanData = await getDietPlanById(data?.patient_id, data?.diet_plans_id)
                                                    if(dietPlanData) {
                                                        setSelectedDietPlan(dietPlanData)
                                                        setPdfModal(true)
                                                    }
                                                }}
                                            />
                                            <div style={{
                                                    color: `${(data?.status === 'published' || data?.status === DIET_STATUS.APPROVED) ? 'green' : 'red'}`,
                                                    fontWeight: '700'
                                                }}
                                            >
                                                {data?.status === 'published' || data?.status === DIET_STATUS.APPROVED ? 'Approved' : 'Rejected'}
                                            </div>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {!!pendingDietPlanList?.length &&
                    <div style={{ marginTop: '10px' }}>
                        <Pagination
                            defaultCurrent={1}
                            total={pendingDietPlanPagination?.total}
                            onChange={(page: any, pageSize: any) => paginationChange(page, pageSize)}
                            current={pendingDietPlanPagination?.page}
                            showSizeChanger={false}
                        />
                    </div>
                }

                <Modal
                    centered
                    open={openApprovalModal?.show}
                    footer={null}
                    onCancel={() => setOpenApprovalModal({ show: false, type: null, selectedDiet: null })}
                    width={'35%'}
                    style={{ height: '500px', marginTop: '20px' }}
                    zIndex={10000}
                >
                    <div style={{ margin: '10px auto', fontWeight: '600', fontSize: '16px', textAlign: 'center' }}>Do you Sure you want to {`${openApprovalModal?.type === 'approve' ? 'Approve' : 'Reject'}`} this plan?</div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px', marginBottom: '10px' }}>
                        <div className="save-items-button">
                            <button className="save-draft-button" onClick={() => chiefBtnHandle(openApprovalModal?.type)}>
                                Confirm
                            </button>
                            <button className="preview-assign-btn" onClick={() => setOpenApprovalModal({ show: false, type: null, selectedDiet: null })}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </Modal>

                <Modal
                    width={'100%'}
                    centered
                    open={showDietModal}
                    onCancel={() => {
                        setSelectedDietPlan(null)
                        setShowDietModal(false)
                        setSelectedDisease(null)
                    }}
                    footer={null}
                >
                    <div style={{ overflowY: 'auto', display: 'flex', width: '100%' }}>
                        <div style={{ width: '40%' }}>
                            <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                                <div style={{ width: '200px' }}>
                                    <span style={{ fontSize: '14px', fontWeight: 700, color: '#760fb2' }}>Select Disease</span>
                                    <ReactSelect
                                        classNamePrefix="select"
                                        name="color"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 6,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#F0E7F7',
                                                primary: '#760fb2',
                                            },
                                        })}
                                        options={diseaseOptions}
                                        onChange={(selectedOption: any) => {
                                            setSelectedDisease(selectedOption?.value)
                                        }}
                                        value={diseaseOptions.find((val: any) => val.value == selectedDisease)}
                                        styles={customStyles}
                                        placeholder='Select Disease'
                                    />
                                </div>
                                {/* {
                            patientAssessments?.length > 1 && */}
                                <div style={{ width: '300px' }}>
                                    <span style={{ fontSize: '14px', fontWeight: 700, color: '#760fb2' }}>Select Assessment</span>
                                    <ReactSelect
                                        classNamePrefix="select"
                                        name="color"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 6,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#F0E7F7',
                                                primary: '#760fb2',
                                            },
                                        })}
                                        options={assessmentOptions}
                                        onChange={(selectedOption: any) => {
                                            setSelectedAssessment(selectedOption)
                                        }}
                                        value={assessmentOptions.find((val: any) => val.value == selectedAssessment?.patient_assessment_id)}
                                        styles={customStyles}
                                        placeholder='Select Assessment'
                                    />
                                </div>
                                {/* } */}
                            </div>
                            <DietAssessmentView selectedAssessment={selectedAssessment} chief_hc={true} />
                        </div>
                        <div style={{ width: '60%' }}>
                            <AddDietContainer chief_hc={true} latestDietForApprove={selectedDietPlan} setShowDietModal={setShowDietModal} setDisableUpdateButton={setDisableUpdateButton} disableUpdateButton={disableUpdateButton} getPatientList={null}/>
                        </div>
                    </div>
                </Modal>

                <Modal
                    width={'100%'}
                    centered
                    open={pdfModal}
                    onCancel={() => {
                        setPdfModal(false)
                        setSelectedDietPlan(null)
                    }}
                    footer={null}
                >
                    <DietPlanPdf dietData={selectedDietPlan} userDetails={userDetails} />
                </Modal>
            </div>
        </>
    )
}

export default PendingDietApprovalList;