import React, { useEffect, useState } from "react";
import Input from "../../atoms/Input/Input";
import './addDiet-plan.scss'
import { TimePicker, DatePicker, Switch, Select, Spin, Modal, Progress, message, Input as AntdInput, Popover } from 'antd';
import { RiDeleteBin6Line, RiPencilFill } from 'react-icons/ri'
import { InfoCircleOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from "react-router-dom";
import API from "../../services/rest/api";
import { mealTypes } from "../../constants";
import dayjs from "dayjs";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import { BreakfastIcon, GraphIcon, LeftBigIcon, PlusIcon, ThreeDots } from "../../assets/icons";
import { BiRadioCircle } from "react-icons/bi";
import Calendar from "../../atoms/Calendar/Calendar";
import { DIET_STATUS, NUTRIENT_MESSAGE } from "../../constants/diet";
import { toast } from "react-toastify";
import Textarea from "../../atoms/TextArea/Textarea";
import Loader from "../../atoms/Loader";
import NutrientWarningModal from "../DiatPlanTemplate/AddDietPlanTemplate/NutrientWarningModal";

type AddDietPlanProps = {
    getPatientList?: any,
    setAddDietShow?: any,
    setScreen?: any,
    chief_hc?: any,
    latestDietForApprove?: any,
    setShowDietModal?: any,
    selectedAssessment?: any,
    userDetails?: any,
    setToggle?: any,
    setEnableSwitch?: any,
    disableUpdateButton?: any,
    setDisableUpdateButton?: any
    templateId?: string | null,
    editModal?: boolean,
}

type DietItemType = {
    diet_plan_id?: string,
    health_coach_id?: string,
    patient_id?: string,
    diet_name?: string,
    start_date?: string,
    end_date?: string,
    proteins?: string,
    calories?: string,
    actual_calories?: string,
    carbs?: string,
    fat?: string,
    fiber?: string,
    status?: string,
    is_active?: 'Y' | 'N',
    is_deleted?: 'Y' | 'N',
    updated_by?: string,
    created_at?: string,
    updated_at?: string,
    chief_remarks?: string
    meals:
    {
        diet_plan_meal_rel_id?: any;
        meal_total_carbs?: string,
        meal_total_calories?: string,
        meal_total_proteins?: string,
        meal_total_fats?: string,
        meal_total_fibers?: string,
        meal_total_sodium?: string,
        meal_total_potassium?: string,
        meal_total_fatty_acids?: string,
        meal_total_sugar?: string,

        diet_meal_type_rel_id?: string,
        diet_plan_id?: string,
        meal_types_id?: string,
        meal_name?: string,
        is_additional_meal?: boolean,
        is_edit_name?: boolean,
        start_time?: string,
        end_time?: string,
        order_no?: number,
        is_hidden?: string,
        patient_permission?: string,
        is_active?: string,
        is_deleted?: string,
        updated_by?: string,
        created_at?: string,
        updated_at?: string,
        options:
        {
            option_no?: number | undefined;
            diet_meal_options_id?: string,
            diet_meal_type_rel_id?: string,
            options_name?: string,
            tips?: string,
            total_calories?: string,
            total_carbs?: string,
            total_proteins?: string,
            total_fats?: string,
            total_fibers?: string,
            total_sodium?: string,
            total_potassium?: string,
            total_sugar?: string,
            total_saturated_fatty_acids?: string,
            total_monounsaturated_fatty_acids?: string,
            total_polyunsaturated_fatty_acids?: string,
            total_fatty_acids?: string,
            order_no?: number,
            is_active?: 'Y' | 'N',
            is_deleted?: 'Y' | 'N',
            updated_by?: string,
            created_at?: string,
            updated_at?: string,
            food_items:
            {
                diet_plan_food_item_id?: string,
                diet_meal_options_id?: string,
                food_item_id?: string,
                food_item_name?: string,
                quantity?: string,
                measure_id?: string,
                measure_name?: string,
                protein?: string,
                carbs?: string,
                fats?: string,
                fibers?: string,
                calories?: string,
                sodium?: string,
                potassium?: string,
                sugar?: string,
                saturated_fatty_acids?: string,
                monounsaturated_fatty_acids?: string,
                polyunsaturated_fatty_acids?: string,
                fatty_acids?: string,
                is_active?: 'Y' | 'N',
                is_deleted?: 'Y' | 'N',
                updated_by?: string,
                created_at?: string,
                updated_at?: string,
                measurements?: any,
                consumption?: {
                    consumed_qty?: number,
                    diet_plan_id?: string,
                    diet_plan_food_item_id?: string,
                    diet_plan_food_consumption_id?: string | null
                },
                is_consumed?: boolean,
                consumed_calories?: number
            }[],
            consumed_calories?: number
        }[]
    }[]
}


const { RangePicker } = DatePicker;
const SUM_ERROR_MESSAGE = 'The total sum of the percentages for protein, carbs, and fat must be exactly 100.';
const { FAT_INFO, CARBS_INFO, PROTEIN_INFO } = NUTRIENT_MESSAGE;

const AddDietPlan: React.FC<AddDietPlanProps> = ({
    getPatientList,
    setAddDietShow,
    chief_hc,
    latestDietForApprove,
    setShowDietModal,
    selectedAssessment,
    userDetails,
    setEnableSwitch,
    setToggle,
    disableUpdateButton,
    setDisableUpdateButton,
    editModal,
    templateId
}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const uniqueId = uuidv4();
    const patientId = localStorage.getItem('patient_id')

    const [selectedMealType, setSelectedMealType] = useState<any>();
    const [selectedOption, setSelectedOption] = useState<any>()
    const [errorMessage, setErrorMessage] = useState<any>(null)
    const [saveLoading, setSaveLoading] = useState<boolean>(false)
    const [draftLoading, setDraftLoading] = useState<boolean>(false)
    const [foodItemOptions, setFoodItemOptions] = useState<any>([])
    const [dietPlanData, setDietPlanData] = useState<DietItemType>({} as DietItemType)
    const [draggedItem, setDraggedItem] = useState<any>(null);
    const [visibleInputField, setVisibleInputField] = useState<any>(false)
    const [newMeal, setNewMeal] = useState<any>(null)
    const [editMealName, setEditMealName] = useState<any>(null)
    const [tipError, setTipError] = useState<any>(null)

    const [modalOpen, setModalOpen] = useState<any>(false)

    const [warningModal, setWarningModal] = useState<any>(false);
    const [saveType, setSaveType] = useState<any>(null)
    const [actualCalories, setActualCalories] = useState<any>('0');
    const [actualCarbs, setActualCarbs] = useState<string>('0');
    const [actualProtein, setActualProtein] = useState<string>('0');
    const [actualFat, setActualFat] = useState<string>('0');

    const [approveConfirmationModal, setApproveConfirmationModal] = useState<any>({ show: false, type: null });
    const [successMessage, setSuccessMessage] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (successMessage) {
            setTimeout(() => {
                setSuccessMessage(null)
            }, 3000)
        }
    }, [successMessage])

    useEffect(() => {
        if (!selectedMealType && dietPlanData?.meals && dietPlanData?.meals.length) {
            setSelectedMealType(dietPlanData?.meals[0].meal_types_id)
        }

        if (!selectedOption && (dietPlanData?.meals && dietPlanData?.meals.length && dietPlanData?.meals[0]?.options && dietPlanData?.meals[0]?.options.length)) {
            setSelectedOption(dietPlanData?.meals[0].options[0].order_no)
        }
    }, [dietPlanData])

    useEffect(() => {
        if (editModal && templateId) {
            fetchTemplateDataById(templateId);
        } else {
            if (!dietPlanData.start_date || !dietPlanData.end_date) {
                let temp = dietPlanData

                temp.start_date = moment(new Date()).format('YYYY-MM-DD')
                temp.end_date = moment(new Date()).add(1, 'day').format('YYYY-MM-DD')
                setDietPlanData(temp)
            }

            let mealsArray: any = []
            mealTypes.map((meal, mealIdx) => {
                mealsArray.push({
                    meal_types_id: meal?.meal_types_id,
                    meal_name: meal?.meal_type,
                    order_no: meal?.order_no,
                    start_time: meal?.default_time,
                    end_time: moment(meal?.default_time, 'HH:mm:ss').add(1, 'hours').format('HH:mm:ss'),
                    patient_permission: 'R',
                    options: [{
                        options_name: `Option 1`,
                        order_no: 1,
                        food_items: [{ is_deleted: 'N' }]
                    },
                    {
                        options_name: `Option 2`,
                        order_no: 2,
                        food_items: [{ is_deleted: 'N' }]
                    }],
                    is_hidden: 'N'
                })
            })
            setDietPlanData({ ...dietPlanData, meals: mealsArray })
        }
    }, [editModal, templateId])

    useEffect(() => {
        if (location?.state?.data) {
            const dietPlanData = getDietPlanWithOldKeys(location?.state?.data)

            if (location?.state?.button === "copy") {
                setDietPlanData(dietPlanData)
            } else {
                setDietPlanData(dietPlanData)
            }
        }
    }, [location?.state?.data])

    useEffect(() => {
        if (chief_hc) {
            const dietPlanData = getDietPlanWithOldKeys(latestDietForApprove)
            console.log("dietPlanData", dietPlanData)
            // setDietPlanData({} as DietItemType)
            setDietPlanData({ ...dietPlanData })
        }
    }, [latestDietForApprove])

    useEffect(() => {
        let temp = dietPlanData;
        let totalProtein = 0;
        let totalFats = 0;
        let totalCarbs = 0;
        temp?.meals?.forEach((meal, mealIdx) => {
            meal?.options?.forEach((op, opIdx) => {
                op.total_carbs = (op?.food_items?.filter((item) => item?.is_deleted == 'N')?.reduce((a, c) => a + (parseFloat(c.carbs || '0') * parseFloat(c.quantity || '0')), 0)).toString()
                op.total_calories = (op?.food_items?.filter((item) => item?.is_deleted == 'N')?.reduce((a, c) => a + (parseFloat(c.calories || '0') * parseFloat(c.quantity || '0')), 0)).toString()
                op.total_proteins = (op?.food_items?.filter((item) => item?.is_deleted == 'N')?.reduce((a, c) => a + (parseFloat(c.protein || '0') * parseFloat(c.quantity || '0')), 0)).toString()
                op.total_fats = (op?.food_items?.filter((item) => item?.is_deleted == 'N')?.reduce((a, c) => a + (parseFloat(c.fats || '0') * parseFloat(c.quantity || '0')), 0)).toString()
                op.total_fibers = (op?.food_items?.filter((item) => item?.is_deleted == 'N')?.reduce((a, c) => a + (parseFloat(c.fibers || '0') * parseFloat(c.quantity || '0')), 0)).toString()
                op.total_sodium = (op?.food_items?.filter((item) => item?.is_deleted == 'N')?.reduce((a, c) => a + (parseFloat(c.sodium || '0') * parseFloat(c.quantity || '0')), 0)).toString()
                op.total_potassium = (op?.food_items?.filter((item) => item?.is_deleted == 'N')?.reduce((a, c) => a + (parseFloat(c.potassium || '0') * parseFloat(c.quantity || '0')), 0)).toString()
                op.total_fatty_acids = (op?.food_items?.filter((item) => item?.is_deleted == 'N')?.reduce((a, c) => a + (parseFloat(c.fatty_acids || '0') * parseFloat(c.quantity || '0')), 0)).toString()
                op.total_sugar = (op?.food_items?.filter((item) => item?.is_deleted == 'N')?.reduce((a, c) => a + (parseFloat(c.sugar || '0') * parseFloat(c.quantity || '0')), 0)).toString()

            })

            // meal.meal_total_carbs = meal.options.reduce((a, c) => a + parseFloat(c.total_carbs ?? '0'), 0).toString()
            // meal.meal_total_calories = meal.options.reduce((a, c) => a + parseFloat(c.total_calories ?? '0'), 0).toString()
            let filtered_options = meal.options.filter((option: any) => parseFloat(option.total_calories || '0') !== 0)
            meal.meal_total_calories = (
                filtered_options.reduce(
                    (sum: number, option: any) =>
                        sum + parseFloat(option.total_calories || '0'),
                    0,
                ) / (filtered_options.length || 1)
            ).toString()
            meal.meal_total_carbs = (
                filtered_options.reduce(
                    (sum: number, option: any) =>
                        sum + parseFloat(option.total_carbs || '0'),
                    0,
                ) / (filtered_options.length || 1)
            ).toString()
            meal.meal_total_proteins = (
                filtered_options.reduce(
                    (sum: number, option: any) =>
                        sum + parseFloat(option.total_proteins || '0'),
                    0,
                ) / (filtered_options.length || 1)
            ).toString()
            meal.meal_total_fats = (
                filtered_options.reduce(
                    (sum: number, option: any) =>
                        sum + parseFloat(option.total_fats || '0'),
                    0,
                ) / (filtered_options.length || 1)
            ).toString()

            meal.meal_total_fibers = meal.options.reduce((a, c) => a + parseFloat(c.total_fibers ?? '0'), 0).toString()
            meal.meal_total_sodium = meal.options.reduce((a, c) => a + parseFloat(c.total_sodium ?? '0'), 0).toString()
            meal.meal_total_potassium = meal.options.reduce((a, c) => a + parseFloat(c.total_potassium ?? '0'), 0).toString()
            meal.meal_total_fatty_acids = meal.options.reduce((a, c) => a + parseFloat(c.total_fatty_acids ?? '0'), 0).toString()
            meal.meal_total_sugar = meal.options.reduce((a, c) => a + parseFloat(c.total_sugar ?? '0'), 0).toString()

            totalProtein += parseFloat(meal.meal_total_proteins || '0');
            totalFats += parseFloat(meal.meal_total_fats || '0');
            totalCarbs += parseFloat(meal.meal_total_carbs || '0')
        })

        temp.actual_calories = temp?.meals?.reduce((a, c) => a + parseFloat(c?.meal_total_calories ?? '0'), 0).toString()
        setActualCalories(Number.isInteger(+temp.actual_calories)
            ? temp.actual_calories
            : Number(Number(temp.actual_calories).toFixed(3)));
        setActualProtein(totalProtein.toFixed(3));
        setActualFat(totalFats.toFixed(3));
        setActualCarbs(totalCarbs.toFixed(3));

        if (errorMessage) setErrorMessage('');
        // setDietPlanData({...temp})
    }, [dietPlanData])

    const fetchTemplateDataById = async (templateId: string) => {
        try {
            setLoading(true);
            const templateData: any = await API.GetDietTemplateByID(templateId);
            if (templateData.code !== 1) throw new Error(templateData.msg || 'Internal server error!');
            const modifiedData = modifyResponse(templateData.data || {});
            setDietPlanData(modifiedData);
        } catch (error) {
            console.log('Error fetching template date:', error);
            message.error({ content: 'Failed to fetch template data!' });
        } finally {
            setLoading(false);
        }
    };

    const modifyResponse = (data: any) => {
        try {
            const { meals_data, _id, chief_health_coach_id, created_at, updated_at, __v, ...otherData } = data;
            const response: any = { ...otherData, id: _id };

            const newMealData = meals_data.map(({ options, _id, ...otherMealData }: any) => {
                const newOptions = (options && options.length > 0) ? options.map((option: any) => ({
                    order_no: option.option_no,
                    options_name: `Option ${option.option_no}`,
                    total_calories: option.meal_total_calories,
                    tips: option.tips,
                    food_items: option.food_items.map(({ option_no, food_item_name, unit_option_id, unit_option_name, _id, ...otherData }: any) => ({
                        is_deleted: "N",
                        ...otherData,
                        food_item_name: otherData.food_name,
                        measure_name: unit_option_name,
                        measure_id: unit_option_id,
                        protein: otherData.protein / Number(otherData.quantity),
                        carbs: otherData.carbs / Number(otherData.quantity),
                        fiber: otherData.fiber / Number(otherData.quantity),
                        calories: otherData.calories / Number(otherData.quantity),
                        fats: otherData.fats / Number(otherData.quantity),
                        sodium: otherData.sodium / Number(otherData.quantity),
                        potassium: otherData.potassium / Number(otherData.quantity),
                        sugar: otherData.sugar / Number(otherData.quantity),
                        fatty_acid: otherData.fatty_acid / Number(otherData.quantity),
                    })),
                })) : [
                    { options_name: 'Option 1', order_no: 1, food_items: [{ is_deleted: 'N' }] },
                    { options_name: 'Option 2', order_no: 2, food_items: [{ is_deleted: 'N' }] }
                ];

                return {
                    ...otherMealData,
                    options: newOptions,
                    patient_permission: "R",
                    is_hidden: otherMealData.hide_meal,
                };
            });

            response.meals = newMealData;
            response.calories = data.total_calories
            response.diet_name = data.template_name;

            setActualCalories(response?.actual_calories?.toString());
            response.start_date = moment(new Date()).format('YYYY-MM-DD');
            response.end_date = moment(new Date()).add(1, 'day').format('YYYY-MM-DD');
            return response;
        } catch (error) {
            console.log('Error while modifying template data:', error);
            message.error({ content: 'Error while modifying template data!' });
        }
    };

    const validateTemplateData = (data: Record<string, any>) => {
        const keyMapToValidate: any = {
            diet_name: 'Name of the Diet Plan',
            calories: 'Total Calories Goal',
            actual_calories: 'Actual Calories',
            proteins: 'Protien Percentage',
            fat: 'Fat Percentage',
            carbs: 'Carbs Percentage',
        };
        const inValidKeys = [];
        for (const key of Object.keys(keyMapToValidate)) {
            if (!data[key] || data[key] === null || data[key] === '' || data[key] == "0" || (key !== 'diet_name' && isNaN(data[key]))) {
                inValidKeys.push(keyMapToValidate[key]);
            }
        }
        return inValidKeys;
    };

    const isValidNutrientPercentage = () =>
        parseFloat(dietPlanData?.fat || '0') +
        parseFloat(dietPlanData?.proteins || '0') +
        parseFloat(dietPlanData?.carbs || '0') === 100;

    const handleValidateSave = () => {
        const inValidKeys = validateTemplateData(dietPlanData);
        if (inValidKeys.length) {
            if (inValidKeys.length == 1)
                setErrorMessage(`${inValidKeys.join(', ')} fields is required!`);
            else
                setErrorMessage(`The following field are required: ${inValidKeys.join(', ')}`);
            return false;
        }
        if (!isValidNutrientPercentage()) {
            setErrorMessage(SUM_ERROR_MESSAGE);
            return false;
        };

        return true;
    }

    let timeout: ReturnType<typeof setTimeout> | null;

    const getFoodItemsOptions = async (value: any) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }

        const fetchItem = async () => {
            try {
                setFoodItemOptions([])
                let payload = {
                    page: 1,
                    food_name: value
                }
                const data = await API.GetFoodItemsOptions(payload)
                if (Number(data?.code) === 1) {
                    let newData = data?.data?.map((d: any) => {
                        return {
                            ...d,
                            label: d?.FOOD_NAME,
                            value: d?.FOOD_ID
                        }
                    })
                    setFoodItemOptions(newData)
                }
            } catch (error) {
                setFoodItemOptions([])
                console.log(error)
            }
        }

        if (value) {
            timeout = setTimeout(fetchItem, 300);
        } else {
            setFoodItemOptions([])
        }

    }

    const getFoodItemNutritions = async (foodItem: any) => {
        const payload = {
            "food_id": foodItem?.FOOD_ID,
            "unit_id": foodItem?.UNIT_ID
        }

        const response = await API.GetFoodItemNutritions(payload);

        if (Number(response?.code) === 1) {
            return response?.data;
        } else {
            console.error("Unabel to fetch Food item nutritions!");
        }
        return null;
    }

    const handleChangeDietPlanData = (e: any) => {
        const { value, name } = e?.target;
        if (name == 'diet_name' || name == 'calories') {
            setDietPlanData({ ...dietPlanData, [name]: value })
        }
        else {
            if (parseFloat(value) <= 100 || !value) {
                setDietPlanData({ ...dietPlanData, [name]: value })
            }
            else {
                return
            }
        }
    }

    const handleOptionChange = (e: any, mealIdx: number, opIdx: number) => {
        const { value, name } = e?.target;
        let temp = dietPlanData

        if (name) {
            temp.meals[mealIdx].options[opIdx] = {
                ...temp.meals[mealIdx].options[opIdx],
                [name]: value
            }
            setDietPlanData({ ...temp })
        }
    }

    const handleFoodItemChange = (e: any, mealIdx: number, opIdx: number, itemIdx: number) => {
        const { value, name } = e?.target;
        let temp = dietPlanData

        if (name) {
            temp.meals[mealIdx].options[opIdx].food_items[itemIdx] = {
                ...temp.meals[mealIdx].options[opIdx].food_items[itemIdx],
                [name]: value
            }
            setDietPlanData({ ...temp })
        }
    }

    const createDietPlan = async (type: any) => {
        if (!tipError) {
            if ((location?.state?.data && location?.state?.button === 'edit') || chief_hc) {
                try {
                    if ((type ?? saveType) == DIET_STATUS.DRAFT) {
                        setDraftLoading(true)
                    } else {
                        setSaveLoading(true)
                    }

                    let updatedMeals = dietPlanData.meals.map((meal) => {
                        meal.options = (meal?.options && meal?.options.length) ? (meal.options.filter((op) => op.total_calories !== '0' && op?.total_calories || ((op?.food_items || []).filter((res) => res.food_item_name && res.food_item_name !== '').length > 0))) : [];
                        return meal;
                    });
                    let newDietPlan = {
                        ...dietPlanData,
                        meals: updatedMeals,
                        //status: DIET_STATUS.PENDING
                    }

                    let payload = {
                        ...newDietPlan,
                        status: type ?? saveType,
                        //patient_id: patientId,
                        calories: dietPlanData?.calories?.toString()
                    }

                    const newPayload = getDietPlanWithNewKeys(payload, payload?.diet_plan_id);

                    if (dietPlanData?.chief_remarks) {
                        newPayload["chief_remarks"] = dietPlanData?.chief_remarks;
                    }

                    let data = await API.CreateDietPlanNew(newPayload)

                    if (data.code == 0) {
                        setErrorMessage(data.message)
                        setSaveLoading(false)
                        setDraftLoading(false)
                        setWarningModal(false)
                        setModalOpen(false)
                    } else if (data.code == '1') {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        // For chief Approval
                        if (approveConfirmationModal?.show) {
                            setApproveConfirmationModal({ show: false, type: null })
                            setShowDietModal(false)
                            toast.success(data.message)
                            if (getPatientList) {
                                getPatientList()
                            }
                        }
                        if (!chief_hc) {
                            setErrorMessage(null)
                            navigate(-1)
                            setSaveLoading(false)
                            setDraftLoading(false)
                            setWarningModal(false)
                            setAddDietShow(true)
                            setEnableSwitch(true)
                            setToggle(true)
                            setActualCalories('0')
                        } else {
                            setErrorMessage(null)
                            setWarningModal(false)
                            setSuccessMessage(data?.message)
                            setSaveLoading(false)
                            // setShowDietModal(false)
                            setDisableUpdateButton(true)
                            if (getPatientList) {
                                getPatientList()
                            }
                        }
                    }
                } catch (error: any) {
                    if (error?.json) {
                        const response = await error?.json()
                        console.error("Error while creating/updating Diet plan", response)
                        toast.error(response.message)
                    }

                    setSaveLoading(false)
                    setDraftLoading(false)
                    setWarningModal(false)
                    setModalOpen(false)
                }
            } else {
                try {
                    if ((type ?? saveType) == DIET_STATUS.DRAFT) {
                        setDraftLoading(true)
                    } else {
                        setSaveLoading(true)
                    }

                    let updatedMeals = dietPlanData.meals.map((meal) => {
                        meal.options = (meal?.options && meal?.options.length) ? (meal.options.filter((op) => op.total_calories !== '0' && op?.total_calories || ((op?.food_items || []).filter((res) => res.food_item_name && res.food_item_name !== '').length > 0))) : [];
                        return meal;
                    });
                    let newDietPlan = {
                        ...dietPlanData,
                        meals: updatedMeals
                    }

                    let payload = {
                        ...newDietPlan,
                        status: ((((type ?? saveType) == DIET_STATUS.PENDING) && userDetails?.chief_hc === 'Y') || (((type ?? saveType) == DIET_STATUS.PENDING) && location?.state?.userDetails?.chief_hc === 'Y')) ? 'Published' : (type ?? saveType),
                        patient_id: patientId,
                        calories: dietPlanData?.calories?.toString(),
                        patient_assessment_id: selectedAssessment?.patient_assessment_id
                    }

                    const newPayload = getDietPlanWithNewKeys(payload);

                    let data = await API.CreateDietPlanNew(newPayload)

                    if (data.code == 0) {
                        setErrorMessage(data.message)
                        setSaveLoading(false)
                        setDraftLoading(false)
                        setWarningModal(false)
                        setModalOpen(false)
                    } else if (data.code == "1") {
                        if (location?.state?.button == "copy") {
                            console.log('copied diet')
                            navigate(-1)
                        }
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        toast.success(data.message)
                        setErrorMessage(null)
                        setSaveLoading(false)
                        setDraftLoading(false)
                        setWarningModal(false)
                        setAddDietShow(true)
                        setEnableSwitch(true)
                        setToggle(true)
                        setActualCalories('0')

                    }
                } catch (error: any) {
                    if (error?.json) {
                        const response = await error?.json()
                        console.error("Error while creating/updating Diet plan", response)
                        toast.error(response.message)
                    }

                    setSaveLoading(false)
                    setDraftLoading(false)
                    setWarningModal(false)
                    setModalOpen(false)
                }
            }
        }
    }

    const checkCaloriesValidation = async (type: string) => {
        setSaveType(type);
        let invalidNutrientValues = compareNutrientValues();
        if (invalidNutrientValues.length > 0) {
            setWarningModal(true);
            return;
        } else {
            createDietPlan(type);
            return;
        }
    };

    const onWarningModalSubmit = async () => {
        createDietPlan(saveType);
    };

    const compareNutrientValues = () => {
        let finalDataObject: any[] = [];

        if (parseFloat(actualCalories || '0') > parseFloat(dietPlanData.calories || '0')) finalDataObject.push({ key: "actual_calories", goal: parseFloat(dietPlanData.calories || '0'), actual: parseFloat(actualCalories || '0') })
        if (parseFloat(actualProtein || '0') > calculateActualNutrientInGram('protein')) finalDataObject.push({ key: "protein", goal: calculateActualNutrientInGram('protein'), actual: parseFloat(actualProtein || '0') })
        if (parseFloat(actualFat || '0') > calculateActualNutrientInGram('fat')) finalDataObject.push({ key: "fat", goal: calculateActualNutrientInGram('fat'), actual: parseFloat(actualFat || '0') })
        if (parseFloat(actualCarbs || '0') > calculateActualNutrientInGram('carbs')) finalDataObject.push({ key: "carbs", goal: calculateActualNutrientInGram('carbs'), actual: parseFloat(actualCarbs || '0') })

        return finalDataObject;
    };

    //Drag and Drop

    const handleDragStart = (e: any, item: any) => {
        setDraggedItem(item);
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/plain', JSON.stringify(item));
    };

    const handleDragOver = (e: any) => {
        e.preventDefault();
    };

    const handleDrop = (e: any, type: any) => {
        e.preventDefault();
        const droppedItem = JSON.parse(e.dataTransfer.getData('text'));
        const draggedIndex = dietPlanData.meals.findIndex(item => item === draggedItem);
        const droppedIndex = dietPlanData.meals.findIndex(item => item === type);

        if (draggedIndex > -1 && droppedIndex > -1) {
            const updatedMeals = [...dietPlanData.meals];
            updatedMeals.splice(draggedIndex, 1);
            updatedMeals.splice(droppedIndex, 0, droppedItem);

            const updatedMealsWithOrder = updatedMeals.map((meal, index) => ({
                ...meal,
                order_no: index + 1, // Assuming order_no starts from 1
            }));

            setDietPlanData({ ...dietPlanData, meals: updatedMealsWithOrder });
        }
    };

    const onAddMeal = (e: any) => {
        if (e.key == 'Enter') {
            let temp = dietPlanData?.meals || []

            let payload: any = {
                diet_plan_id: dietPlanData?.diet_plan_id || '',
                meal_types_id: uniqueId,
                meal_name: newMeal,
                is_additional_meal: true,
                order_no: temp.length + 1,
                start_time: "00:00:00",
                end_time: "00:00:00",
                patient_permission: 'R',
                options: [{
                    options_name: `Option 1`,
                    order_no: 1,
                    food_items: [{ is_deleted: 'N' }]
                },
                {
                    options_name: `Option 2`,
                    order_no: 2,
                    food_items: [{ is_deleted: 'N' }]
                }],
                is_hidden: 'N'
            }
            temp.push(payload)
            setNewMeal(null)
            setSelectedMealType(payload.meal_types_id)
            setVisibleInputField(false)
            setDietPlanData({ ...dietPlanData, meals: temp })
        }
    }

    const showDetailsHandle = (dayStr: any) => {
        console.log(dayStr)
    };

    const getDietPlanWithOldKeys = (data: any) => {
        return {
            // ...location?.state?.data,
            // diet_name: data?.plan_name,
            // start_date: moment(new Date()).format('YYYY-MM-DD'),
            // end_date: moment(new Date()).add(1, 'day').format('YYYY-MM-DD'),
            diet_name: data?.plan_name,
            diet_plan_id: data?.diet_plans_id,
            patient_id: data?.patient_id,
            start_date: data?.start_date,
            end_date: data?.end_date,
            calories: data?.total_calories,
            actual_calories: data?.actual_calories,
            proteins: data?.protein,
            fat: data?.fat,
            carbs: data?.carbs,
            chief_remarks: data?.chief_remarks,
            status: data?.status,
            meals: data?.diet_plan_meal_rel?.map((mealData: any) => ({
                diet_plan_meal_rel_id: mealData?.diet_plan_meal_rel_id,
                meal_types_id: mealData?.meal_types_id,
                meal_name: mealData?.meal_type,
                is_hidden: mealData?.hide_meal,
                start_time: mealData?.start_time,
                end_time: mealData?.end_time,
                order_no: mealData?.order_no,
                options: mealData?.meal_options?.map((mealOption: any, mealOptionIndex: number) => ({
                    order_no: mealOptionIndex,
                    options_name: `Option ${mealOptionIndex + 1}`,
                    // as tips has to be outside the food items.
                    tips: mealOption?.option_data?.length > 0 ? mealOption?.option_data[0]?.tips : "",
                    food_items: mealOption?.option_data?.map((optionData: any) => ({
                        calories: optionData?.calories,
                        carbs: optionData?.carbs,
                        fats: optionData?.fats,
                        fatty_acids: optionData?.fatty_acid,
                        fibers: optionData?.fiber,
                        food_item_id: optionData?.food_item_id,
                        food_item_name: optionData?.food_name,
                        meal_taken: optionData?.meal_taken,
                        order_no: optionData?.order_no,
                        potassium: optionData?.potassium,
                        protein: optionData?.protein,
                        quantity: optionData?.quantity,
                        sodium: optionData?.sodium,
                        sugar: optionData?.sugar,
                        // this has to be outside the food items
                        // tips: optionData?.tips,
                        measure_id: optionData?.unit_option_id,
                        measure_name: optionData?.unit_option_name,
                        is_deleted: optionData?.is_deleted || 'N'
                    }))
                }))
            })),
        };
    }

    const getDietPlanWithNewKeys = (data: any, diet_plan_id?: any) => {
        const dietPlan: any = {
            "plan_name": data?.diet_name,
            "patient_id": data?.patient_id,
            "start_date": data?.start_date,
            "end_date": data?.end_date,
            "total_calories": data?.calories,
            "actual_calories": data?.actual_calories,
            "protein": data?.proteins,
            "fat": data?.fat,
            "carbs": data?.carbs,
            "status": data?.status === "Published" ? "approved" : data?.status,
            "meals_data": data?.meals?.map((meal: any) => ({
                "diet_plan_meal_rel_id": meal?.diet_plan_meal_rel_id,
                "meal_types_id": meal?.meal_types_id,
                "meal_name": meal?.meal_name,
                "hide_meal": meal?.is_hidden,
                "start_time": meal?.start_time,
                "end_time": meal?.end_time,
                "order_no": meal?.order_no,
                "options_data": meal?.options?.flatMap((option: any) =>
                    option.food_items.map((food_item: any, food_item_index: number) => ({
                        "tips": option?.tips,
                        "option_no": option?.options_name?.split(' ')[1] || option?.option_no || option?.order_no,
                        "food_item_id": food_item.food_item_id,
                        "food_name": food_item.food_item_name,
                        "quantity": food_item.quantity,
                        "unit_option_id": food_item.measure_id,
                        "order_no": option?.order_no || food_item_index + 1,
                    }))
                )
            }))
        }

        if (diet_plan_id)
            dietPlan["diet_plans_id"] = diet_plan_id

        return dietPlan
    }

    const nutrientCustomInput = (label: string, value: string) => {
        return (
            <div style={{ marginTop: "10px" }}>
                <label className='custom_label'>{label}</label>
                <AntdInput
                    placeholder='Protein Goal(gm)'
                    disabled
                    value={value}
                    style={{ width: '80%', color: "#760fb2" }} />
            </div>
        )
    }

    const calculateActualNutrientInGram = (key: string) => {
        const totalCalories = parseFloat(dietPlanData?.calories || '0');
        return key === 'protein' ? Number(((parseFloat(dietPlanData?.proteins || '0') * totalCalories) / (4 * 100)).toFixed(2)) :
            key === 'carbs' ? Number(((parseFloat(dietPlanData?.carbs || '0') * totalCalories) / (4 * 100)).toFixed(2)) :
                key === 'fat' ? Number(((parseFloat(dietPlanData?.fat || '0') * totalCalories) / (9 * 100)).toFixed(2)) :
                    0
    };

    const onKeyDownForNumber = (e: React.KeyboardEvent<HTMLInputElement>) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

    const renderInfoPopover = (text: string) => {
        return (
            <div style={{ width: '250px', margin: '0px !important' }}>
                <div dangerouslySetInnerHTML={{ __html: text?.replace(/\n/g, '<br>') }}></div>
            </div>
        );
    };

    return (
        <>{
            loading
                ? <Loader noBackground />
                : <>
                    <div style={{ height: '100vh', padding: '10px 40px' }}>
                        <div className="assign-title">
                            {chief_hc ?
                                'Approve/Reject' :
                                (`${location?.state?.button == 'edit' ?
                                    'Change' :
                                    (location?.state?.button == 'copy' ?
                                        'Copy' :
                                        'Add')}`
                                )}
                            <span style={{ marginLeft: '5px' }}>Diet Plan</span>
                        </div>
                        <div className="diet-input-container">
                            <div className="diet-input">
                                <Input
                                    label="Name of the Diet Plan"
                                    placeholder="Name of the Diet Plan"
                                    type="text"
                                    name="diet_name"
                                    id="diet_name"
                                    value={dietPlanData.diet_name}
                                    onChange={(e: any) => handleChangeDietPlanData(e)}
                                    required
                                />
                                <Input
                                    label="Total Calories Goal"
                                    placeholder="Total Calories Goal"
                                    type="number"
                                    name="calories"
                                    id="calories"
                                    value={dietPlanData.calories == '0' ? null : dietPlanData.calories}
                                    onChange={(e: any) => handleChangeDietPlanData(e)}
                                    required
                                />
                                <div className='nutrient_input'>
                                    <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: "space-between" }}>
                                        <Input
                                            label='Protein Percentage(%)'
                                            placeholder='Protein Percentage'
                                            type='number'
                                            name='proteins'
                                            id='proteins'
                                            required
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { if (parseFloat(e.target.value || '0') <= 100) handleChangeDietPlanData(e) }}
                                            value={dietPlanData?.proteins || null}
                                            onKeyDown={onKeyDownForNumber}
                                        />
                                        <Popover
                                            content={renderInfoPopover(PROTEIN_INFO)}
                                            trigger={'hover'}
                                            style={{ padding: '0px !important' }}
                                        >
                                            <InfoCircleOutlined style={{ cursor: 'pointer', opacity: "0.5" }} />
                                        </Popover>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        gap: "10px"
                                    }}>
                                        {nutrientCustomInput('Goal(gm)', calculateActualNutrientInGram('protein').toString())}
                                        {nutrientCustomInput('Actual(gm)', actualProtein.toString())}
                                    </div>
                                </div>
                            </div>

                            <div className="diet-input">
                                <div style={{ width: "85%" }}>
                                    <div className="date-range-label">Date Range</div>
                                    {/* <DatePicker placeholder="Date range" /> */}
                                    <RangePicker
                                        className={"createDateRangePicker"}
                                        format={'YYYY-MM-DD'}
                                        value={[dayjs((dietPlanData?.start_date?.length || 0) > 0 ? dietPlanData.start_date : moment(new Date()).add(1, 'day').format('YYYY-MM-DD'), 'YYYY-MM-DD'), dayjs((dietPlanData?.end_date?.length || 0) > 0 ? dietPlanData.end_date : moment(new Date()).add(2, 'day').format('YYYY-MM-DD'), 'YYYY-MM-DD')]}
                                        onChange={(moments, dates) => {
                                            if (dates[0] && dates[1]) {
                                                setDietPlanData({ ...dietPlanData, start_date: dates[0], end_date: dates[0] == dates[1] ? moment(dates[1]).add(1, 'day').format('YYYY-MM-DD') : dates[1] })
                                            }
                                        }}
                                        disabledDate={(current) => {
                                            let customDate = moment().format("YYYY-MM-DD");
                                            return current && current < moment(customDate, "YYYY-MM-DD");
                                        }}
                                    />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <Input
                                        label='Actual Calories'
                                        placeholder='Actual Calories'
                                        type='number'
                                        name='actual_calories'
                                        id='actual_calories'
                                        required
                                        disabled
                                        value={actualCalories}
                                    />
                                    <Popover
                                        content={renderInfoPopover("The 'Actual Calories' represent the sum of the average calories from each meal's options")}
                                        trigger={'hover'}
                                        style={{ padding: '0px !important' }}
                                    >
                                        <InfoCircleOutlined style={{ cursor: 'pointer', opacity: "0.5" }} />
                                    </Popover>
                                </div>
                                <div className='nutrient_input'>
                                    <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: "space-between" }}>
                                        <Input
                                            label='Fat Percentage(%)'
                                            placeholder='Fat Percentage'
                                            type='number'
                                            name='fat'
                                            id='fat'
                                            required
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { if (parseFloat(e.target.value || '0') <= 100) handleChangeDietPlanData(e) }}
                                            value={dietPlanData?.fat || null}
                                            onKeyDown={onKeyDownForNumber}
                                        />
                                        <Popover
                                            content={renderInfoPopover(FAT_INFO)}
                                            trigger={'hover'}
                                            style={{ padding: '0px !important' }}
                                        >
                                            <InfoCircleOutlined style={{ cursor: 'pointer', opacity: "0.5" }} />
                                        </Popover>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        gap: "10px"
                                    }}>
                                        {nutrientCustomInput('Goal(gm)', calculateActualNutrientInGram('fat').toString())}
                                        {nutrientCustomInput('Actual(gm)', actualFat.toString())}
                                    </div>
                                </div>

                            </div>

                            <div className="diet-input">
                                <div style={{ height: '68px' }}></div>
                                {/* <Input
                        label="Fiber Percentage(%)"
                        placeholder="Fiber percentage"
                        type="number"
                        name="fiber"
                        id="fiber"
                        value={dietPlanData.fiber == '0' ? null : dietPlanData.fiber}
                        onChange={(e: any) => handleChangeDietPlanData(e)}
                        required
                    /> */}
                                <div style={{ height: '68px' }}></div>
                                <div className='nutrient_input'>
                                    <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: "space-between" }}>
                                        <Input
                                            label='Carbs Percentage(%)'
                                            placeholder='Carbs Percentage'
                                            type='number'
                                            name='carbs'
                                            id='carbs'
                                            required
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { if (parseFloat(e.target.value || '0') <= 100) handleChangeDietPlanData(e) }}
                                            value={dietPlanData?.carbs || null}
                                            onKeyDown={onKeyDownForNumber}
                                        />
                                        <Popover
                                            content={renderInfoPopover(CARBS_INFO)}
                                            trigger={'hover'}
                                            style={{ padding: '0px !important' }}
                                        >
                                            <InfoCircleOutlined style={{ cursor: 'pointer', opacity: "0.5" }} />
                                        </Popover>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        gap: "10px"
                                    }}>
                                        {nutrientCustomInput('Goal(gm)', calculateActualNutrientInGram('carbs').toString())}
                                        {nutrientCustomInput('Actual(gm)', actualCarbs.toString())}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {errorMessage && !isValidNutrientPercentage() && <p style={{ color: 'red', fontSize: '16px', fontWeight: '500', padding: '0px 5px', marginLeft: '10px' }}>{SUM_ERROR_MESSAGE}</p>}

                        <div className="diet-schedule-container">
                            {(dietPlanData?.meals || []).map((type, typeIdx) => (
                                <>
                                    {!type.is_edit_name ? (
                                        <div
                                            className={selectedMealType !== type?.meal_types_id ? "diet-schedule" : "diet-schedule-activate"}
                                            onClick={() => {
                                                setSelectedMealType(type?.meal_types_id)
                                                if (type.options && type.options.length > 0) {
                                                    setSelectedOption(type.options[0].order_no)
                                                }
                                            }}
                                            key={type.meal_types_id}
                                            draggable
                                            onDragStart={(e) => handleDragStart(e, type)}
                                            onDragOver={handleDragOver}
                                            onDrop={(e) => handleDrop(e, type)}
                                        >
                                            {type?.meal_name}

                                            {type?.is_additional_meal &&
                                                <RiPencilFill
                                                    style={{ color: '#760fb2', cursor: 'pointer', height: '15px', width: '15px', alignSelf: 'center', marginLeft: '10px' }}
                                                    onClick={() => {
                                                        let tempDietPlan: any = dietPlanData;
                                                        tempDietPlan.meals[typeIdx].is_edit_name = true
                                                        setDietPlanData({ ...tempDietPlan })
                                                        setEditMealName(type?.meal_name)
                                                    }}
                                                />
                                            }
                                        </div>
                                    ) : (
                                        <div className={"diet-schedule"}>
                                            <Input
                                                label=""
                                                placeholder="Add Meal"
                                                type="text"
                                                name="meal"
                                                id="meal"
                                                value={editMealName}
                                                onChange={(e: any) => {
                                                    setEditMealName(e.target.value)
                                                }}
                                                onKeyDown={(e: any) => {
                                                    if (e.key == 'Enter') {
                                                        let tempDietPlan: any = dietPlanData;
                                                        tempDietPlan.meals[typeIdx].meal_name = editMealName
                                                        delete tempDietPlan.meals[typeIdx].is_edit_name
                                                        setDietPlanData({ ...tempDietPlan })

                                                    }
                                                }}
                                                className='add-meal-input'
                                            />
                                        </div>
                                    )}
                                </>
                            ))}

                            {!visibleInputField ? (
                                <div className={"diet-add"}
                                    style={dietPlanData?.meals?.length >= 7 ? { display: "none" } : undefined}
                                    onClick={() => setVisibleInputField(true)}>+</div>
                            ) : (
                                <div className={"diet-schedule"}>
                                    <Input
                                        label=""
                                        placeholder="Add Meal"
                                        type="text"
                                        name="meal"
                                        id="meal"
                                        value={newMeal}
                                        onChange={(e: any) => {
                                            setNewMeal(e.target.value)
                                        }}
                                        onKeyDown={onAddMeal}
                                        className='add-meal-input'
                                    />
                                </div>
                            )}
                        </div>

                        {
                            (dietPlanData.meals || []).map((meal, mealIdx) => {
                                return (
                                    <>
                                        {meal?.meal_types_id == selectedMealType &&
                                            <>
                                                <div className="breakfast-container">
                                                    <div className="meal-timing-container">
                                                        <div>Meal timing</div>
                                                        <div style={{ width: "110px" }}>
                                                            <TimePicker
                                                                placeholder=""
                                                                onChange={(time, timeString) => {
                                                                    let tempDietPlan: any = dietPlanData;
                                                                    tempDietPlan.meals[mealIdx].start_time = moment(timeString, 'hh:mm a').format('HH:mm:ss')
                                                                    setDietPlanData({ ...tempDietPlan })
                                                                }}
                                                                value={(dayjs(meal.start_time, 'HH:mm:ss'))}
                                                                style={{ width: '110px' }}
                                                                format={'hh:mm a'}
                                                                allowClear={false}
                                                                disabled={meal?.is_hidden == 'Y'}
                                                            />
                                                        </div>
                                                        <div>to</div>
                                                        <div style={{ width: "110px" }}>
                                                            <TimePicker
                                                                placeholder=""
                                                                onChange={(time, timeString) => {
                                                                    let tempDietPlan: any = dietPlanData;
                                                                    tempDietPlan.meals[mealIdx].end_time = moment(timeString, 'hh:mm a').format('HH:mm:ss')
                                                                    setDietPlanData({ ...tempDietPlan })
                                                                }}
                                                                value={dayjs(meal.end_time, 'HH:mm:ss')}
                                                                style={{ width: '110px' }}
                                                                format={'hh:mm a'}
                                                                allowClear={false}
                                                                disabled={meal?.is_hidden == 'Y'}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="hide-meal">
                                                        <div className="hide-meal-title">
                                                            <span style={{ marginRight: '10px', fontSize: '14px' }}>Hide this meal</span>
                                                            <Switch
                                                                onChange={(checked: any) => {
                                                                    let tempDietPlan: any = dietPlanData;
                                                                    tempDietPlan.meals[mealIdx].is_hidden = checked ? 'Y' : 'N'
                                                                    setDietPlanData({ ...tempDietPlan })
                                                                }}
                                                                style={{ marginLeft: '15px' }}
                                                                checked={meal.is_hidden == 'Y'}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <div>
                                            <span style={{ marginRight: '10px', fontSize: '14px' }}>User Permission</span>
                                            <Switch
                                                onChange={(checked: any) => {
                                                    let tempDietPlan: any = dietPlanData;
                                                    tempDietPlan.meals[mealIdx].patient_permission = checked ? 'W' : 'R'
                                                    setDietPlanData({ ...tempDietPlan })
                                                }}
                                            />
                                            <span style={{ color: '#7610B2', marginLeft: '10px' }}>{meal?.patient_permission == 'R' ? 'Read' : 'Write'}</span>
                                        </div> */}
                                                </div>

                                                <>
                                                    <div className="diet-schedule-options">
                                                        {
                                                            (meal.options || []).map((op, opIdx) => {
                                                                return (
                                                                    <div className={selectedOption !== op?.order_no ? "diet-schedule-option" : "diet-option-activate"} onClick={() => { setSelectedOption(op?.order_no) }}>{op?.options_name}</div>
                                                                )
                                                            })
                                                        }

                                                        {(meal?.is_hidden == 'N' && meal?.options?.length <= 6) &&
                                                            <div
                                                                className={'add-option-button'}
                                                                onClick={() => {
                                                                    let tempDietPlan: any = dietPlanData;
                                                                    tempDietPlan.meals[mealIdx].options.push({
                                                                        options_name: `Option ${tempDietPlan.meals[mealIdx].options.length + 1}`,
                                                                        order_no: tempDietPlan.meals[mealIdx].options.length + 1,
                                                                        food_items: [{ is_deleted: 'N' }]
                                                                    })
                                                                    setDietPlanData({ ...tempDietPlan })
                                                                }}>
                                                                + Add Option
                                                            </div>
                                                        }
                                                    </div>

                                                    {
                                                        (meal.options || []).map((op, opIdx) => {
                                                            return (
                                                                <>
                                                                    {op?.order_no == selectedOption &&
                                                                        <>
                                                                            <div className="option-container">
                                                                                <div style={{ marginBottom: "25px" }}>
                                                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }} >
                                                                                        <div className="total-diet-counts-title" style={{ width: "auto" }}> Tips</div>
                                                                                        <div style={{ width: "100%" }}>
                                                                                            <textarea
                                                                                                placeholder=""
                                                                                                name="tips"
                                                                                                id="tips"
                                                                                                value={op.tips}
                                                                                                onChange={(e: any) => {
                                                                                                    if (e.target.value.length > 2000) {
                                                                                                        setTipError('Tips length cannot exceed more than 200 characters')
                                                                                                    } else {
                                                                                                        setTipError(null)
                                                                                                    }
                                                                                                    handleOptionChange(e, mealIdx, opIdx)
                                                                                                }}
                                                                                                style={{ width: '100%', border: '1px solid #cecece', height: '100px', outline: 'none', color: '#760FB2' }}
                                                                                                disabled={meal?.is_hidden == 'Y'}
                                                                                            />
                                                                                            {tipError && <span style={{ color: 'red', fontSize: '14px' }}>{tipError}</span>}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="total-diet-flex">
                                                                                        <div className="total-diet-counts">
                                                                                            <div className="total-diet-counts-title">Total Calories</div>
                                                                                            <span>{(op?.food_items?.filter((item) => item?.is_deleted == 'N')?.reduce((a, c) => a + (parseFloat(c.calories || '0') * parseFloat(c.quantity || '0')), 0))}</span>
                                                                                        </div>

                                                                                        <div className="total-diet-counts">
                                                                                            <div className="total-diet-counts-title">Total Carbs</div>
                                                                                            <span>{(op?.food_items?.filter((item) => item?.is_deleted == 'N')?.reduce((a, c) => a + (parseFloat(c.carbs || '0') * parseFloat(c.quantity || '0')), 0)).toFixed(2)}</span>
                                                                                        </div>

                                                                                        <div className="total-diet-counts">
                                                                                            <div className="total-diet-counts-title">Total Protien  </div>
                                                                                            <span>{(op?.food_items?.filter((item) => item?.is_deleted == 'N')?.reduce((a, c) => a + (parseFloat(c.protein || '0') * parseFloat(c.quantity || '0')), 0)).toFixed(2)}</span>
                                                                                        </div>

                                                                                        <div className="total-diet-counts">
                                                                                            <div className="total-diet-counts-title">Total Fats</div>
                                                                                            <span>{(op?.food_items?.filter((item) => item?.is_deleted == 'N')?.reduce((a, c) => a + (parseFloat(c.fats || '0') * parseFloat(c.quantity || '0')), 0)).toFixed(2)}</span>
                                                                                        </div>

                                                                                        <div className="total-diet-counts">
                                                                                            <div className="total-diet-counts-title">Total Fiber</div>
                                                                                            <span>{(op?.food_items?.filter((item) => item?.is_deleted == 'N')?.reduce((a, c) => a + (parseFloat(c.fibers || '0') * parseFloat(c.quantity || '0')), 0)).toFixed(2)}</span>
                                                                                        </div>

                                                                                        <div className="total-diet-counts">
                                                                                            <div className="total-diet-counts-title">Total Sodium </div>
                                                                                            <span>{(op?.food_items?.filter((item) => item?.is_deleted == 'N')?.reduce((a, c) => a + (parseFloat(c.sodium || '0') * parseFloat(c.quantity || '0')), 0)).toFixed(2)}</span>
                                                                                        </div>

                                                                                        <div className="total-diet-counts">
                                                                                            <div className="total-diet-counts-title">Total Potassium </div>
                                                                                            <span>{(op?.food_items?.filter((item) => item?.is_deleted == 'N')?.reduce((a, c) => a + (parseFloat(c.potassium || '0') * parseFloat(c.quantity || '0')), 0)).toFixed(2)}</span>
                                                                                        </div>

                                                                                        <div className="total-diet-counts">
                                                                                            <div className="total-diet-counts-title">Total Sugar </div>
                                                                                            <span>{(op?.food_items?.filter((item) => item?.is_deleted == 'N')?.reduce((a, c) => a + (parseFloat(c.sugar || '0') * parseFloat(c.quantity || '0')), 0)).toFixed(2)}</span>
                                                                                        </div>

                                                                                        <div className="total-diet-counts">
                                                                                            <div className="total-diet-counts-title">Total Fatty Acids</div>
                                                                                            <span>{(op?.food_items?.filter((item) => item?.is_deleted == 'N')?.reduce((a, c) => a + (parseFloat(c.fatty_acids || '0') * parseFloat(c.quantity || '0')), 0)).toFixed(2)}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                                {(op?.food_items || []).map((item, itemIdx) => {
                                                                                    return (
                                                                                        <>
                                                                                            {item?.is_deleted == 'N' &&
                                                                                                <div className="food-items-main-container">
                                                                                                    <div className="food-items-container">
                                                                                                        <div className="food-items">
                                                                                                            <span className="label">Food Items</span>
                                                                                                            <Select
                                                                                                                className="ant-select-selection"
                                                                                                                showSearch
                                                                                                                filterOption={false}
                                                                                                                onSearch={getFoodItemsOptions}
                                                                                                                options={foodItemOptions}
                                                                                                                onChange={async (e: any, option: any) => {
                                                                                                                    let temp = dietPlanData
                                                                                                                    if (temp.meals[mealIdx].options[opIdx].food_items.find((item => item?.food_item_id == option.value))) {
                                                                                                                        return
                                                                                                                    } else {
                                                                                                                        //temp.meals[mealIdx].options[opIdx].food_items[itemIdx].measure_name = option.unit_name
                                                                                                                        temp.meals[mealIdx].options[opIdx].food_items[itemIdx].food_item_id = option.value
                                                                                                                        temp.meals[mealIdx].options[opIdx].food_items[itemIdx].food_item_name = option.label
                                                                                                                        //temp.meals[mealIdx].options[opIdx].food_items[itemIdx].protein = ((option?.protein?.includes('mg') ? parseFloat(option?.protein?.split('_')) / 1000 : parseFloat(option?.protein.split('_'))) || 0).toString()
                                                                                                                        // temp.meals[mealIdx].options[opIdx].food_items[itemIdx].carbs = ((option?.carbs?.includes('mg') ? parseFloat(option?.carbs?.split('_')) / 1000 : parseFloat(option?.carbs.split('_'))) || 0).toString()
                                                                                                                        // temp.meals[mealIdx].options[opIdx].food_items[itemIdx].fats = ((option?.fat?.includes('mg') ? parseFloat(option?.fat?.split('_')) / 1000 : parseFloat(option?.fat.split('_'))) || 0).toString()
                                                                                                                        // temp.meals[mealIdx].options[opIdx].food_items[itemIdx].fibers = ((option?.fiber?.includes('mg') ? parseFloat(option?.fiber.split('_')) / 1000 : parseFloat(option?.fiber.split('_'))) || 0).toString()
                                                                                                                        // temp.meals[mealIdx].options[opIdx].food_items[itemIdx].sodium = ((option?.sodium?.includes('mg') ? parseFloat(option?.sodium.split('_')) / 1000 : parseFloat(option?.sodium?.split('_'))) || 0).toString()
                                                                                                                        temp.meals[mealIdx].options[opIdx].food_items[itemIdx].quantity = '1'
                                                                                                                        // temp.meals[mealIdx].options[opIdx].food_items[itemIdx].fatty_acids = (((((option?.total_monounsaturated_fatty_acids.includes('mg') ? parseFloat(option?.total_monounsaturated_fatty_acids.split('_')) / 1000 : parseFloat(option?.total_monounsaturated_fatty_acids.split('_'))) || 0))
                                                                                                                        //     + ((option.total_polyunsaturated_fatty_acids.includes('mg') ? parseFloat(option.total_polyunsaturated_fatty_acids.split('_')) / 1000 : parseFloat(option.total_polyunsaturated_fatty_acids.split('_'))) || 0)
                                                                                                                        //     + ((option.total_saturated_fatty_acids.includes('mg') ? parseFloat(option.total_saturated_fatty_acids.split('_')) / 1000 : parseFloat(option.total_saturated_fatty_acids.split('_'))) || 0) || 0)).toString()

                                                                                                                        // temp.meals[mealIdx].options[opIdx].food_items[itemIdx].sugar = ((option?.sugar?.includes('mg') ? parseFloat(option?.sugar?.split('_')) / 1000 : parseFloat(option?.sugar.split('_'))) || 0).toString()
                                                                                                                        // temp.meals[mealIdx].options[opIdx].food_items[itemIdx].calories = (option.Energy_kcal || 0).toString()
                                                                                                                        // temp.meals[mealIdx].options[opIdx].food_items[itemIdx].potassium = ((option?.potassium.includes('mg') ? parseFloat(option?.potassium.split('_')) / 1000 : parseFloat(option?.potassium.split('_'))) || 0).toString()
                                                                                                                        // temp.meals[mealIdx].options[opIdx].food_items[itemIdx].measurements = option?.measurements || []
                                                                                                                        // TODO: made api call
                                                                                                                        const foodItemNutritions = await getFoodItemNutritions(option);

                                                                                                                        if (foodItemNutritions) {
                                                                                                                            temp.meals[mealIdx].options[opIdx].food_items[itemIdx].measure_name = option?.UNIT_NAME
                                                                                                                            temp.meals[mealIdx].options[opIdx].food_items[itemIdx].measure_id = option?.UNIT_ID

                                                                                                                            temp.meals[mealIdx].options[opIdx].food_items[itemIdx].protein = foodItemNutritions?.protein;
                                                                                                                            temp.meals[mealIdx].options[opIdx].food_items[itemIdx].carbs = foodItemNutritions?.carbs;
                                                                                                                            temp.meals[mealIdx].options[opIdx].food_items[itemIdx].fats = foodItemNutritions?.fats;
                                                                                                                            temp.meals[mealIdx].options[opIdx].food_items[itemIdx].fibers = foodItemNutritions?.fiber;
                                                                                                                            temp.meals[mealIdx].options[opIdx].food_items[itemIdx].sodium = foodItemNutritions?.sodium;
                                                                                                                            // temp.meals[mealIdx].options[opIdx].food_items[itemIdx].quantity = '1'
                                                                                                                            temp.meals[mealIdx].options[opIdx].food_items[itemIdx].fatty_acids = foodItemNutritions?.fatty_acid;

                                                                                                                            temp.meals[mealIdx].options[opIdx].food_items[itemIdx].sugar = foodItemNutritions?.sugar;
                                                                                                                            temp.meals[mealIdx].options[opIdx].food_items[itemIdx].potassium = foodItemNutritions?.potassium;
                                                                                                                            temp.meals[mealIdx].options[opIdx].food_items[itemIdx].calories = foodItemNutritions?.calories; //  (option.Energy_kcal || 0).toString()

                                                                                                                            // temp.meals[mealIdx].options[opIdx].food_items[itemIdx].protein = ((foodItemNutritions?.protein?.includes('mg') ? parseFloat(foodItemNutritions?.protein?.split('_')) / 1000 : parseFloat(foodItemNutritions?.protein.split('_'))) || 0).toString()
                                                                                                                            // temp.meals[mealIdx].options[opIdx].food_items[itemIdx].carbs = ((foodItemNutritions?.carbs?.includes('mg') ? parseFloat(foodItemNutritions?.carbs?.split('_')) / 1000 : parseFloat(foodItemNutritions?.carbs.split('_'))) || 0).toString()
                                                                                                                            // temp.meals[mealIdx].options[opIdx].food_items[itemIdx].fats = ((foodItemNutritions?.fat?.includes('mg') ? parseFloat(foodItemNutritions?.fat?.split('_')) / 1000 : parseFloat(foodItemNutritions?.fat.split('_'))) || 0).toString()
                                                                                                                            // temp.meals[mealIdx].options[opIdx].food_items[itemIdx].fibers = ((foodItemNutritions?.fiber?.includes('mg') ? parseFloat(foodItemNutritions?.fiber.split('_')) / 1000 : parseFloat(foodItemNutritions?.fiber.split('_'))) || 0).toString()
                                                                                                                            // temp.meals[mealIdx].options[opIdx].food_items[itemIdx].sodium = ((foodItemNutritions?.sodium?.includes('mg') ? parseFloat(foodItemNutritions?.sodium.split('_')) / 1000 : parseFloat(foodItemNutritions?.sodium?.split('_'))) || 0).toString()
                                                                                                                            // // temp.meals[mealIdx].options[opIdx].food_items[itemIdx].quantity = '1'
                                                                                                                            // temp.meals[mealIdx].options[opIdx].food_items[itemIdx].fatty_acids = (((((foodItemNutritions?.total_monounsaturated_fatty_acids?.includes('mg') ? parseFloat(foodItemNutritions?.total_monounsaturated_fatty_acids.split('_')) / 1000 : parseFloat(foodItemNutritions?.total_monounsaturated_fatty_acids.split('_'))) || 0))
                                                                                                                            //     + ((option.total_polyunsaturated_fatty_acids?.includes('mg') ? parseFloat(option.total_polyunsaturated_fatty_acids.split('_')) / 1000 : parseFloat(option.total_polyunsaturated_fatty_acids.split('_'))) || 0)
                                                                                                                            //     + ((option.total_saturated_fatty_acids?.includes('mg') ? parseFloat(option.total_saturated_fatty_acids.split('_')) / 1000 : parseFloat(option.total_saturated_fatty_acids.split('_'))) || 0) || 0)).toString()

                                                                                                                            // temp.meals[mealIdx].options[opIdx].food_items[itemIdx].sugar = ((foodItemNutritions?.sugar?.includes('mg') ? parseFloat(foodItemNutritions?.sugar?.split('_')) / 1000 : parseFloat(foodItemNutritions?.sugar.split('_'))) || 0).toString()
                                                                                                                            // temp.meals[mealIdx].options[opIdx].food_items[itemIdx].calories = (option.Energy_kcal || 0).toString()
                                                                                                                            // temp.meals[mealIdx].options[opIdx].food_items[itemIdx].potassium = ((foodItemNutritions?.potassium?.includes('mg') ? parseFloat(foodItemNutritions?.potassium.split('_')) / 1000 : parseFloat(foodItemNutritions?.potassium.split('_'))) || 0).toString()

                                                                                                                        }
                                                                                                                        setDietPlanData({ ...temp })
                                                                                                                        setFoodItemOptions([])
                                                                                                                    }
                                                                                                                }}
                                                                                                                value={item?.food_item_name ?? null}
                                                                                                                placeholder='select food item'
                                                                                                                disabled={meal?.is_hidden == 'Y'}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className="food-items">
                                                                                                            <span className="label">Quantity</span>
                                                                                                            <Input
                                                                                                                label=""
                                                                                                                placeholder="quantity"
                                                                                                                type="number"
                                                                                                                name="quantity"
                                                                                                                id="quantity"
                                                                                                                value={item?.quantity == '0' ? null : item?.quantity}
                                                                                                                onChange={(e: any) => {
                                                                                                                    handleFoodItemChange(e, mealIdx, opIdx, itemIdx)
                                                                                                                }}
                                                                                                                disabled={meal?.is_hidden == 'Y'}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className="food-items">
                                                                                                            <span className="label">Measure<span style={{ color: 'red' }}>*</span></span>
                                                                                                            <Input
                                                                                                                label=""
                                                                                                                placeholder="measure"
                                                                                                                type="text"
                                                                                                                name="measure_name"
                                                                                                                id="measure_name"
                                                                                                                value={item?.measure_name}
                                                                                                                onChange={(e: any) => {
                                                                                                                    handleFoodItemChange(e, mealIdx, opIdx, itemIdx)
                                                                                                                }}
                                                                                                                disabled
                                                                                                            />
                                                                                                            {/* <Select
                                                                                                    className="ant-select-selection"
                                                                                                    placeholder='select measure'
                                                                                                    options={item?.measurements?.map((measure: any) => {
                                                                                                        return { ...measure, label: `${!measure?.unit_option_name ? (measure?.unit_name) : `${measure?.unit_name}(${measure?.unit_option_name})`}`, value: `${!measure?.unit_option_name ? (measure?.unit_name) : `${measure?.unit_name}(${measure?.unit_option_name})`}` }
                                                                                                    })}
                                                                                                    onChange={(e: any, option: any) => {
                                                                                                        let temp = dietPlanData
                                                                                                        temp.meals[mealIdx].options[opIdx].food_items[itemIdx].measure_name = option.label
                                                                                                        temp.meals[mealIdx].options[opIdx].food_items[itemIdx].food_item_id = option.food_item_id
                                                                                                        temp.meals[mealIdx].options[opIdx].food_items[itemIdx].food_item_name = option.food_name
                                                                                                        temp.meals[mealIdx].options[opIdx].food_items[itemIdx].protein = ((option?.protein?.includes('mg') ? parseFloat(option?.protein?.split('_')) / 1000 : parseFloat(option?.protein.split('_'))) || 0).toString()
                                                                                                        temp.meals[mealIdx].options[opIdx].food_items[itemIdx].carbs = ((option?.carbs?.includes('mg') ? parseFloat(option?.carbs?.split('_')) / 1000 : parseFloat(option?.carbs.split('_'))) || 0).toString()
                                                                                                        temp.meals[mealIdx].options[opIdx].food_items[itemIdx].fats = ((option?.fat?.includes('mg') ? parseFloat(option?.fat?.split('_')) / 1000 : parseFloat(option?.fat.split('_'))) || 0).toString()
                                                                                                        temp.meals[mealIdx].options[opIdx].food_items[itemIdx].fibers = ((option?.fiber?.includes('mg') ? parseFloat(option?.fiber.split('_')) / 1000 : parseFloat(option?.fiber.split('_'))) || 0).toString()
                                                                                                        temp.meals[mealIdx].options[opIdx].food_items[itemIdx].sodium = ((option?.sodium?.includes('mg') ? parseFloat(option?.sodium.split('_')) / 1000 : parseFloat(option?.sodium?.split('_'))) || 0).toString()
                                                                                                        // temp.meals[mealIdx].options[opIdx].food_items[itemIdx].quantity = '1'
                                                                                                        temp.meals[mealIdx].options[opIdx].food_items[itemIdx].fatty_acids = (((((option?.total_monounsaturated_fatty_acids.includes('mg') ? parseFloat(option?.total_monounsaturated_fatty_acids.split('_')) / 1000 : parseFloat(option?.total_monounsaturated_fatty_acids.split('_'))) || 0))
                                                                                                            + ((option.total_polyunsaturated_fatty_acids.includes('mg') ? parseFloat(option.total_polyunsaturated_fatty_acids.split('_')) / 1000 : parseFloat(option.total_polyunsaturated_fatty_acids.split('_'))) || 0)
                                                                                                            + ((option.total_saturated_fatty_acids.includes('mg') ? parseFloat(option.total_saturated_fatty_acids.split('_')) / 1000 : parseFloat(option.total_saturated_fatty_acids.split('_'))) || 0) || 0)).toString()

                                                                                                        temp.meals[mealIdx].options[opIdx].food_items[itemIdx].sugar = ((option?.sugar?.includes('mg') ? parseFloat(option?.sugar?.split('_')) / 1000 : parseFloat(option?.sugar.split('_'))) || 0).toString()
                                                                                                        temp.meals[mealIdx].options[opIdx].food_items[itemIdx].calories = (option.Energy_kcal || 0).toString()
                                                                                                        temp.meals[mealIdx].options[opIdx].food_items[itemIdx].potassium = ((option?.potassium.includes('mg') ? parseFloat(option?.potassium.split('_')) / 1000 : parseFloat(option?.potassium.split('_'))) || 0).toString()
                                                                                                        setDietPlanData({ ...temp })
                                                                                                        setFoodItemOptions([])
                                                                                                    }}
                                                                                                    value={item?.measurements?.map((measure: any) => {
                                                                                                        return { ...measure, label: `${!measure?.unit_option_name ? (measure?.unit_name) : `${measure?.unit_name}(${measure?.unit_option_name})`}`, value: `${!measure?.unit_option_name ? (measure?.unit_name) : `${measure?.unit_name}(${measure?.unit_option_name})`}` }
                                                                                                    }).find((val: any) => val?.label === item?.measure_name)}
                                                                                                /> */}
                                                                                                        </div>
                                                                                                        <div className="food-items">
                                                                                                            <span className="label">Protien</span>
                                                                                                            <Input
                                                                                                                label=""
                                                                                                                placeholder="protien"
                                                                                                                type="number"
                                                                                                                name="protein"
                                                                                                                id="protein"
                                                                                                                value={item?.protein == '0' ? '0' : (parseFloat(item?.protein || '0') * parseFloat(item?.quantity || '0')).toFixed(2)}
                                                                                                                onChange={(e: any) => {
                                                                                                                    handleFoodItemChange(e, mealIdx, opIdx, itemIdx)
                                                                                                                }}
                                                                                                                disabled
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className="food-items">
                                                                                                            <span className="label">Carbs</span>
                                                                                                            <Input
                                                                                                                label=""
                                                                                                                placeholder="carbs"
                                                                                                                type="number"
                                                                                                                name="carbs"
                                                                                                                id="carbs"
                                                                                                                value={item?.carbs == '0' ? '0' : (parseFloat(item?.carbs || '0') * parseFloat(item?.quantity || '0')).toFixed(2)}
                                                                                                                onChange={(e: any) => {
                                                                                                                    handleFoodItemChange(e, mealIdx, opIdx, itemIdx)
                                                                                                                }}
                                                                                                                disabled
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className="food-items">
                                                                                                            <span className="label">Fats</span>
                                                                                                            <Input
                                                                                                                label=""
                                                                                                                placeholder="fats"
                                                                                                                type="number"
                                                                                                                name="fats"
                                                                                                                id="fats"
                                                                                                                value={item?.fats == '0' ? '0' : (parseFloat(item?.fats || '0') * parseFloat(item?.quantity || '0')).toFixed(2)}
                                                                                                                onChange={(e: any) => {
                                                                                                                    handleFoodItemChange(e, mealIdx, opIdx, itemIdx)
                                                                                                                }}
                                                                                                                disabled
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className="food-items">
                                                                                                            <span className="label">Fibers</span>
                                                                                                            <Input
                                                                                                                label=""
                                                                                                                placeholder="fibers"
                                                                                                                type="number"
                                                                                                                name="fibers"
                                                                                                                id="fibers"
                                                                                                                value={item?.fibers == '0' ? '0' : (parseFloat(item?.fibers || '0') * parseFloat(item?.quantity || '0')).toFixed(2)}
                                                                                                                onChange={(e: any) => {
                                                                                                                    handleFoodItemChange(e, mealIdx, opIdx, itemIdx)
                                                                                                                }}
                                                                                                                disabled
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className="food-items">
                                                                                                            <span className="label">Sodium</span>
                                                                                                            <Input
                                                                                                                label=""
                                                                                                                placeholder="sodium"
                                                                                                                type="number"
                                                                                                                name="sodium"
                                                                                                                id="sodium"
                                                                                                                value={item?.sodium == '0' ? '0' : (parseFloat(item?.sodium || '0') * parseFloat(item?.quantity || '0')).toFixed(2)}
                                                                                                                onChange={(e: any) => {
                                                                                                                    handleFoodItemChange(e, mealIdx, opIdx, itemIdx)
                                                                                                                }}
                                                                                                                disabled
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className="food-items">
                                                                                                            <span className="label">Fatty Acids</span>
                                                                                                            <Input
                                                                                                                label=""
                                                                                                                placeholder="fatty acids"
                                                                                                                type="number"
                                                                                                                name="fatty_acids"
                                                                                                                id="fatty_acids"
                                                                                                                value={item?.fatty_acids == '0' ? '0' : (parseFloat(item?.fatty_acids || '0') * parseFloat(item?.quantity || '0')).toFixed(2)}
                                                                                                                onChange={(e: any) => {
                                                                                                                    handleFoodItemChange(e, mealIdx, opIdx, itemIdx)
                                                                                                                }}
                                                                                                                disabled
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className="food-items">
                                                                                                            <span className="label">Calories</span>
                                                                                                            <Input
                                                                                                                label=""
                                                                                                                placeholder="calories"
                                                                                                                type="number"
                                                                                                                name="calories"
                                                                                                                id="calories"
                                                                                                                value={item?.calories == '0' ? '0' : (parseFloat(item?.calories || '0') * parseFloat(item?.quantity || '0')).toFixed(2)}
                                                                                                                onChange={(e: any) => {
                                                                                                                    handleFoodItemChange(e, mealIdx, opIdx, itemIdx)
                                                                                                                }}
                                                                                                                disabled
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className="food-items">
                                                                                                            <span className="label">Sugar</span>
                                                                                                            <Input
                                                                                                                label=""
                                                                                                                placeholder="sugar"
                                                                                                                type="number"
                                                                                                                name="sugar"
                                                                                                                id="sugar"
                                                                                                                value={item?.sugar == '0' ? '0' : (parseFloat(item?.sugar || '0') * parseFloat(item?.quantity || '0')).toFixed(2)}
                                                                                                                onChange={(e: any) => {
                                                                                                                    handleFoodItemChange(e, mealIdx, opIdx, itemIdx)
                                                                                                                }}
                                                                                                                disabled
                                                                                                            />
                                                                                                        </div>

                                                                                                        <div className="food-items">
                                                                                                            <span className="label">Potassium</span>
                                                                                                            <Input
                                                                                                                label=""
                                                                                                                placeholder="potassium"
                                                                                                                type="number"
                                                                                                                name="potassium"
                                                                                                                id="potassium"
                                                                                                                value={item?.potassium == '0' ? '0' : (parseFloat(item?.potassium || '0') * parseFloat(item?.quantity || '0')).toFixed(2)}
                                                                                                                onChange={(e: any) => {
                                                                                                                    handleFoodItemChange(e, mealIdx, opIdx, itemIdx)
                                                                                                                }}
                                                                                                                disabled
                                                                                                            />
                                                                                                        </div>

                                                                                                        <button className="delete-icons"
                                                                                                            onClick={async () => {
                                                                                                                let temp = dietPlanData;
                                                                                                                if (item?.diet_plan_food_item_id) {
                                                                                                                    // const payload = {
                                                                                                                    //     patient_id: temp?.patient_id,
                                                                                                                    //     diet_plan_food_item_id: item?.diet_plan_food_item_id
                                                                                                                    // }
                                                                                                                    // const data = await API.DeleteFoodItem(payload)
                                                                                                                    // if (data.code == '1') {
                                                                                                                    temp.meals[mealIdx].options[opIdx].food_items[itemIdx].is_active = 'N'
                                                                                                                    temp.meals[mealIdx].options[opIdx].food_items[itemIdx].is_deleted = 'Y'
                                                                                                                    setDietPlanData({ ...temp })
                                                                                                                    // }
                                                                                                                } else {
                                                                                                                    temp.meals[mealIdx].options[opIdx].food_items?.splice(itemIdx, 1)
                                                                                                                    setDietPlanData({ ...temp })
                                                                                                                }
                                                                                                            }} >
                                                                                                            {meal.is_hidden == 'N' && <><RiDeleteBin6Line /><span>Delete</span></>}
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                })}

                                                                                {(meal.is_hidden == 'N' && meal?.options[opIdx]?.food_items[meal?.options[opIdx]?.food_items?.length - 1]?.measure_name || op?.food_items.length === 0) &&
                                                                                    <div
                                                                                        className="addFood-item-button-1"
                                                                                        onClick={() => {
                                                                                            let temp = dietPlanData;
                                                                                            temp.meals[mealIdx].options[opIdx].food_items?.push({ is_deleted: 'N' })
                                                                                            setDietPlanData({ ...temp })
                                                                                        }}>
                                                                                        + Add Food Items
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    }

                                                </>
                                            </>
                                        }
                                    </>
                                )
                            })

                        }

                        {chief_hc &&
                            <Textarea
                                label="Notes"
                                name="chief_remarks"
                                rows={10}
                                onChange={(e) => {
                                    setDietPlanData({ ...dietPlanData, chief_remarks: e.target.value })
                                }}
                                value={dietPlanData?.chief_remarks || ''}
                            />
                        }


                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px', marginBottom: '10px' }}>
                            {errorMessage && <p style={{ color: 'red', fontSize: '16px', fontWeight: '500', padding: '0px 5px', marginLeft: '10px' }}>{errorMessage}</p>}
                            {successMessage && <p style={{ color: 'green', fontSize: '16px', fontWeight: '500', padding: '0px 5px', marginLeft: '10px' }}>{successMessage}</p>}

                            {!chief_hc ?
                                <>
                                    {((dietPlanData?.meals || [])[dietPlanData?.meals?.length - 1]?.meal_types_id == selectedMealType) ?
                                        <button
                                            className="save-next-button"
                                            onClick={() => {
                                                // checkCaloriesValidation(DIET_STATUS.PENDING)
                                                if (handleValidateSave()) {
                                                    setModalOpen(true);
                                                }
                                            }}
                                            disabled={saveLoading}
                                        >
                                            {!saveLoading ? 'Save' : <Spin style={{ color: 'white' }} />}
                                        </button>
                                        :
                                        <button
                                            className="save-next-button"
                                            onClick={() => {
                                                if (!tipError) {
                                                    if (selectedMealType) {
                                                        let mealIdx = dietPlanData?.meals?.findIndex((d) => d.meal_types_id == selectedMealType)
                                                        setSelectedMealType(dietPlanData?.meals[mealIdx + 1]?.meal_types_id)
                                                        if (dietPlanData?.meals[mealIdx + 1]?.options && dietPlanData?.meals[mealIdx + 1]?.options?.length) {
                                                            setSelectedOption(dietPlanData?.meals[mealIdx + 1]?.options[0]?.order_no)
                                                        }
                                                    } else {
                                                        setSelectedMealType(dietPlanData?.meals[0]?.meal_types_id)
                                                        if (dietPlanData?.meals[0]?.options && dietPlanData?.meals[0]?.options?.length) {
                                                            setSelectedOption(dietPlanData?.meals[0]?.options[0]?.order_no)
                                                        }
                                                    }
                                                }
                                            }}
                                        >
                                            Save & Next
                                        </button>
                                    }

                                    <div className="save-items-button">
                                        <button className="preview-assign-btn" onClick={() => {
                                            if (handleValidateSave()) {
                                                setModalOpen(true);
                                            }
                                        }
                                        }>
                                            Preview & Assign
                                        </button>
                                        {
                                            // (location?.state?.button !== 'edit' || !location?.state?.button) &&
                                            (dietPlanData?.status !== DIET_STATUS.PENDING || !dietPlanData?.status || location?.state?.button == 'copy') &&
                                            <button className="save-draft-button" onClick={() => {
                                                if (handleValidateSave()) {
                                                    checkCaloriesValidation(DIET_STATUS.DRAFT)
                                                }
                                            }} disabled={draftLoading}>
                                                {!draftLoading ? 'Save As Draft' : <Spin />}
                                            </button>
                                        }
                                        <button className="preview-assign-btn"
                                            onClick={() => {
                                                if (location?.state?.button === 'edit' || location?.state?.button === 'copy') {
                                                    navigate(-1)
                                                } else {
                                                    setAddDietShow(false)
                                                }
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </>
                                :
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
                                    <button
                                        className={disableUpdateButton ? "chief-approve-button-disable" : "chief-approve-button"}
                                        onClick={() => checkCaloriesValidation(DIET_STATUS.PENDING)}
                                        disabled={disableUpdateButton || saveLoading}
                                    >
                                        {!saveLoading ? 'Update' : <Spin />}
                                    </button>
                                    <button className="chief-approve-button" onClick={() => setApproveConfirmationModal({ show: true, type: 'approve' })}>
                                        Approve
                                    </button>
                                    <button className="chief-reject-button" onClick={() => setApproveConfirmationModal({ show: true, type: 'reject' })}>
                                        Reject
                                    </button>
                                    <button className="chief-cancel-btn" onClick={() => { setDisableUpdateButton(false); setShowDietModal(false) }}>
                                        Cancel
                                    </button>
                                </div>
                            }
                        </div>


                    </div>


                    <Modal
                        centered
                        open={modalOpen}
                        footer={null}
                        onCancel={() => setModalOpen(false)}
                        width={'98%'}
                        style={{ height: '900px', marginTop: '20px' }}
                    >
                        <div style={{ height: '800px', overflowY: 'scroll', padding: '10px 40px' }}>
                            <div className="diet-preview-upper">
                                <div className="diet-preview-input">
                                    <div className="diet-preview-field">
                                        <span>Name of the Diet Plan</span>
                                        <p className="diet-preview-value">
                                            {dietPlanData.diet_name}
                                        </p>
                                    </div>

                                    <div className="diet-preview-field">
                                        <span>Date Range</span>
                                        <p className="diet-preview-value">
                                            {dietPlanData.start_date} - {dietPlanData.end_date}
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <div className="diet-preview-field">
                                        <span>Total Calories for the Day</span>
                                        <p className="diet-preview-value">
                                            {dietPlanData.calories}
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div className="mobile-preview-container">
                                <div style={{ background: 'white', display: 'flex', flexDirection: 'column', padding: '15px 0', borderRadius: '10px' }}>
                                    <div style={{ padding: '0px 15px', gap: '8px', display: 'flex' }}>
                                        <LeftBigIcon />
                                        <span style={{ color: '#313131', fontWeight: '600', fontSize: '16px' }}>Diet</span>
                                    </div>

                                    {/* <div className="preview-tabs">
                            <span className="preview-tab-active">Diet Plan</span>
                            <span className="preview-tab">Exercise Plan</span>
                            <span className="preview-tab">Other Plan</span>
                        </div> */}

                                    <Calendar date={dietPlanData?.start_date} showDetailsHandle={showDetailsHandle} />

                                    <div className="drawer-icon"></div>

                                </div>

                                <div className="calories-preview-container">
                                    <div style={{ display: 'flex' }}>
                                        <Progress type="circle" percent={0} size={50} strokeColor="#1BBB33" trailColor="#C9F1C9B8" />
                                        <div className="calories-count-container">
                                            <div>
                                                <span style={{ fontWeight: 700, fontSize: '20px' }}>0</span> of <span>{dietPlanData.calories || 0}</span>
                                            </div>
                                            <span style={{ color: '#616161', fontWeight: '400', fontSize: '12px' }}>Calories consumed today!</span>
                                        </div>
                                    </div>
                                    <GraphIcon />
                                </div>

                                {
                                    (dietPlanData.meals || []).map((meal) => {
                                        return (
                                            <>
                                                {meal.is_hidden == 'N' &&
                                                    <div className="meal-preview-container">
                                                        <div className="meal-upper-container">
                                                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                                <BreakfastIcon />
                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    <span style={{ fontWeight: '700', fontSize: '16px' }}>
                                                                        {meal.meal_name}
                                                                    </span>
                                                                    <span style={{ fontWeight: '400', fontSize: '12px', color: '#616161' }}>
                                                                        Ideal time | <span style={{ fontWeight: '700' }}>0</span> of {meal.meal_total_calories}cal
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: 'flex', gap: '8px' }}>
                                                                <PlusIcon />
                                                            </div>
                                                        </div>


                                                        <div style={{ display: 'flex', gap: '8px', marginTop: '5px', marginLeft: '5px', flexWrap: 'wrap' }}>
                                                            {(meal.options || []).map((option, opIdx) => {
                                                                return (
                                                                    <span className={opIdx == 0 ? "preview-option-active" : "preview-option"}>{option?.options_name}</span>
                                                                )
                                                            })}
                                                        </div>

                                                        {
                                                            (meal?.options && meal?.options.length > 0 && meal.options[0]?.total_calories !== '0') &&
                                                            <>
                                                                {
                                                                    (meal.options[0].food_items || []).map((item) => {
                                                                        return (
                                                                            <>
                                                                                <div className="meal-center-container">
                                                                                    <div style={{ display: 'flex', gap: '10px' }}>
                                                                                        <BiRadioCircle size={20} style={{ paddingTop: '2px', color: 'lightgrey' }} />
                                                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                            <span style={{ fontWeight: '400', fontSize: '14px' }}>
                                                                                                {item?.food_item_name}
                                                                                            </span>
                                                                                            <span style={{ fontWeight: '400', fontSize: '12px', color: '#616161', alignSelf: 'left' }}>
                                                                                                {item?.quantity} | {(parseFloat(item?.quantity || '1') * (parseFloat(item?.sodium || '0') + parseFloat(item?.sugar || '0') + parseFloat(item?.fatty_acids || '0') + parseFloat(item?.potassium || '0'))).toFixed(2)} g
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div style={{ display: 'flex', gap: '8px' }}>
                                                                                        <span style={{ fontSize: '12px', color: '#616161' }}>{item?.calories} cal</span>
                                                                                        <ThreeDots />
                                                                                    </div>
                                                                                </div>

                                                                            </>
                                                                        )
                                                                    })
                                                                }

                                                                {
                                                                    meal.options[0].tips &&
                                                                    <div className="meal-tips">
                                                                        <span>{meal.options[0].tips}</span>
                                                                    </div>
                                                                }

                                                            </>
                                                        }

                                                    </div>
                                                }
                                            </>
                                        )
                                    })
                                }
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px', marginBottom: '10px' }}>
                                <div className="save-items-button">
                                    <button className="save-draft-button" onClick={() => checkCaloriesValidation(DIET_STATUS.PENDING)} disabled={saveLoading}>
                                        {!saveLoading ? 'Assign' : <Spin style={{ color: 'white' }} />}
                                    </button>
                                    <button className="preview-assign-btn" onClick={() => setModalOpen(false)}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>

                    {/* <Modal
                        centered
                        open={warningModal}
                        footer={null}
                        onCancel={() => setWarningModal(false)}
                        width={'35%'}
                        style={{ height: '500px', marginTop: '20px' }}
                        zIndex={10000}
                    >

                        <div style={{ margin: '10px auto', fontWeight: '600', fontSize: '20px', textAlign: 'center', color: 'red' }}>Actual calories greater than Total Calories Goal !</div>
                        <div style={{ margin: '10px auto', fontWeight: '600', fontSize: '16px', textAlign: 'center' }}>Do you still want to proceed?</div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px', marginBottom: '10px' }}>
                            <div className="save-items-button">
                                <button className="save-draft-button" onClick={() => createDietPlan(null)} disabled={saveLoading}>
                                    {!saveLoading ? 'Confirm' : <Spin style={{ color: 'white' }} />}
                                </button>
                                <button className="preview-assign-btn" onClick={() => setWarningModal(false)}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Modal> */}
                    {warningModal &&
                        <Modal
                            className='custom-warning-modal'
                            centered
                            open={warningModal}
                            onCancel={() => setWarningModal(false)}
                            footer={false}
                            closeIcon={false}

                        >
                            <NutrientWarningModal invalidNutrientValues={compareNutrientValues()} onSubmit={onWarningModalSubmit} onCancel={() => setWarningModal(false)} loading={loading} />

                        </Modal>
                    }

                    <Modal
                        centered
                        open={approveConfirmationModal?.show}
                        footer={null}
                        onCancel={() => setApproveConfirmationModal({ show: false, type: null })}
                        width={'35%'}
                        style={{ height: '500px', marginTop: '20px' }}
                        zIndex={10000}
                    >
                        <div style={{ margin: '10px auto', fontWeight: '600', fontSize: '16px', textAlign: 'center' }}>Do you Sure you want to {`${approveConfirmationModal?.type === 'approve' ? 'Approve' : 'Reject'}`} this plan?</div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px', marginBottom: '10px' }}>
                            <div className="save-items-button">
                                <button className="save-draft-button" onClick={() => createDietPlan(approveConfirmationModal?.type === 'approve' ? DIET_STATUS.APPROVED : DIET_STATUS.REJECTED)} disabled={saveLoading}>
                                    {!saveLoading ? 'Confirm' : <Spin style={{ color: 'white' }} />}
                                </button>
                                <button className="preview-assign-btn" onClick={() => setApproveConfirmationModal({ show: false, type: null })}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Modal>

                </>
        }</>
    )
}

export default AddDietPlan;