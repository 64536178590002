import { FunctionComponent, useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import rectangleIcon from "../../assets/icons/rectangle-12.svg";
import arrowLeftIcon from "../../assets/icons/arrow--left.svg";
import CaregiverInfo from "../../molecules/CgCaregiverInfo/CaregiverInfo";
import PatientInfo from "../../molecules/CgPatientInfo/PatientInfo";
import API, { GetOverallPatientCount } from "../../services/rest/api";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./ViewDetails.css";
import DoctorInfo from "../../molecules/CgDoctorInfo/DoctorInfo";

const ViewDetials: FunctionComponent = () => {
  const [userDetails, setUserDetails] = useState<any>(null)
  const [patientData, setPatientData] = useState<any>(null)
  const [isSaving, setIsSaving] = useState(false);
  const [doctorDetails, setDoctorDetails] = useState<any>({});
  const [patientDetails, setPatientDetails] = useState<any>({});
  const [careGiverDetails, setCareGiverDetails] = useState<any>({});
  const [token, setToken] = useState<any>();
  // const [isVivitraEnabled, setIsVivitraEnabled] = useState<any>(null);
  // const [showCreateCycleModal, setShowCreateCycleModal] = useState<boolean>(false);
  // const [showReqUploadModal, setShowReqUploadModal] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const params = useParams();
  const navigate = useNavigate();
  const location: any = useLocation();

  const PatientDetailsAPI = `${process.env.REACT_APP_API_URL}/api/v8/healthcoach/patient_details_by_id`;
  const SavePatientDetailsAPI = `${process.env.REACT_APP_API_URL}/api/v8/healthcoach/update_patient_information`;

  const handleBackButtonClick = (screen: string) => {
    // navigate(-1);
    navigate(`/${screen}/:token`);
  };

  const handleButtonClick = (screen: string) => {
    navigate(`/${screen}`);
  };

  useEffect(() => {
    const token: any = params.token;
    getProfileDetails();
    // setToken(token)
    localStorage.setItem(
      "token",
      location.state?.token === ":token" || location.state?.token === undefined
        ? token
        : location.state?.token
    );
    sessionStorage.setItem('token', (location.state?.token === ":token" || location.state?.token === undefined) ? token : location.state?.token);

    setToken(localStorage.getItem("token"));
    setUserDetails(localStorage.getItem("health-coach-id"))
  }, [params]);

  //funtion to get profile details
  const getProfileDetails = async () => {
    try {
      const userInfo = await API.GetProfileDetails()
      localStorage.setItem('userInfo', JSON.stringify(userInfo?.data))
      setUserDetails(userInfo?.data)
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    fetchPatientDetails();
  }, []);

  const fetchPatientDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const secretKey = "SfUyfAztruqg92sbm30rEIyHLNV7f5";
      const body = JSON.stringify({
        patient_id: location.state?.patientId,
      });
      const response = await fetch(
        PatientDetailsAPI,
        {
          method: "POST",
          headers: {
            "Content-Type": "text/plain",
            token: `${token}`,
            health_secret: secretKey,
          },
          body: body,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      const userData = data?.data;
      // setIsVivitraEnabled(userData?.patient_meta_info?.vivitra_eligible)
      setPatientData(userData)
      setPatientDetails({
        patient_name: userData.name,
        patient_contact_no: userData.contact_no,
        patient_gender: userData.gender,
        patient_dob: userData.dob,
        patient_height: userData.height,
        patient_height_unit: userData.height_unit,
        patient_weight: userData.weight,
        patient_weight_unit: userData.weight_unit,
        patient_email: userData.email,
        patient_address: userData.address,
      });
      setCareGiverDetails({
        cg_name: userData?.patient_meta_info?.cg_name,
        cg_contact_no: userData?.patient_meta_info?.cg_contact_no,
        cg_relation: userData?.patient_meta_info?.cg_relation,
      })
      setDoctorDetails({
        doc_name: userData?.patient_link_doctor_details[0]?.name,
        doc_contac_no: userData?.patient_link_doctor_details[0]?.contact_no,
        doc_city: userData?.patient_link_doctor_details[0]?.city,
        doc_clinic_name: userData?.patient_link_doctor_details[0]?.clinic_name,
      });   
    } catch (error) {
      console.error("Error fetching patient details:", error);
    }
  };

  const handleSave = async (updatedDetails: any, userType: string) => {
    try {
      // Update the state based on user type
      toast.success('Data saved successfully!', { position: 'top-right' });
      if (userType === 'Patient') {
        setPatientDetails(updatedDetails);
      } else if (userType === 'Caregiver') {
        setCareGiverDetails(updatedDetails);
      }
    } catch (error) {
      console.error("Error updating details:", error);
      // toast.error('Failed to save data. Please try again.', { position: 'top-right' });
    }
  };

  useEffect(() => {
    const savePatientDetails = async () => {
      try {
        setIsSaving(true);

        const body = JSON.stringify({
          patient_id: patientData?.patient_id,
          name: patientDetails.patient_name,
          contact_no: patientDetails.patient_contact_no,
          patient_name: patientDetails.patient_name,
          patient_phone: patientDetails.patient_contact_no,
          gender: patientDetails.patient_gender,
          dob: patientDetails.patient_dob,
          height: patientDetails.patient_height,
          height_unit: patientDetails.patient_height_unit,
          weight: patientDetails.patient_weight,
          weight_unit: patientDetails.patient_weight_unit,
          email: patientDetails.patient_email,
          address: patientDetails.patient_address,
          patient_meta_info: {
            user_type: patientData?.patient_meta_info?.user_type,
            support_program_type: patientData?.patient_meta_info?.support_program_type,
            ...careGiverDetails
          },
        });
        const token = location.state?.token ?? sessionStorage.getItem("token");
        const secretKey = 'SfUyfAztruqg92sbm30rEIyHLNV7f5';
        const response = await fetch(SavePatientDetailsAPI, {
          method: 'POST',
          headers: {
            'Content-Type': 'text/plain',
            'token': `${token}`,
            'health_secret': secretKey,
          },
          body: body,
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        console.log(data, "updated-successfully")
      } catch (error) {
        console.error("Error updating patient details:", error);
      }
    };

    if (Object.keys(patientDetails).length > 0) {
      savePatientDetails();
    }
  }, [patientDetails, careGiverDetails])

  const pdfUrl = 'https://example.com/path/to/your.pdf'

  return (
    <>
      <div className="desktop-3">
        <img className="desktop-3-child" alt="" src={rectangleIcon} />
        <header className="container-f-r-a-m-e">
        <Link to={{ pathname: `/patient-summary/:token` }} style={{ textDecoration: 'none' }} state={{ name:patientData?.patient_name, contact:patientData?.patient_contact_no, patientId: location.state?.patientId, token:token}}>
            <div className="rectangle-v-e-c-t-o-r">
              <div className="frame-f-r-a-m-e">
                <button
                /*   onClick={ () => handleBackButtonClick("all-patients")}>
                onClick={handleBackButtonClick}> */
                >
                  <img
                    className="arrow-left"
                    loading="lazy"
                    alt=""
                    src={arrowLeftIcon}
                  />
                </button>
              </div>
              <h3 className="products-list1">Patient Details</h3>
            </div>
          </Link>
        </header>
        <main className="group-f-r-a-m-e">
          <div className="left-container">
            <CaregiverInfo careGiverDetails={careGiverDetails} onSave={handleSave} />
            <PatientInfo patientDetails={patientDetails} onSave={handleSave} />
            <DoctorInfo doctorDetails={doctorDetails} onSave={handleSave} />
          </div>
        </main>
      </div>
    </>
  );
};

export default ViewDetials;
