import { FC, useEffect, useRef, useState } from "react"
import './DietPlanPdf.scss';
import html2pdf from "html2pdf.js";
import API from "../../services/rest/api";
import MyTatvaLogo from '../../assets/icons/MyTatva_logo.svg'
import QuantityHelpImage from '../../assets/icons/quantity_help.png'
import { CalendarIcon, CheckIcon, DiseaseIcon, DoctorNotesIcon, HeightIcon, HotIcon, ProfileIcon, TipIcon, WeightIcon } from "../../assets/icons";
import moment from "moment";

type DietPlanPdfProps = {
    dietData?: any;
    userDetails?: any;
}

const DietPlanPdf: FC<DietPlanPdfProps> = ({ dietData, userDetails }) => {
    const targetRef = useRef<any>(null);
    const [patientDetails, setPatientDetails] = useState<any>(null)

    useEffect(() => {
        if (userDetails) {
            patientData()
        }
    }, [dietData, userDetails])

    //funtion to get patient data
    const patientData = async () => {
        try {
            let payload = {
                patient_id: dietData?.patient_id,
                health_coach_id: dietData?.health_coach_id || userDetails?.health_coach_id
            }
            const patientData = await API.GetPatientDetails(payload)
            setPatientDetails(patientData?.data)
        } catch (error) {
            console.error(error)
        }
    }

    const GeneratePDF = () => {
        if (targetRef && targetRef.current) {
            try {
                const element = targetRef.current;

                // const pdfOptions = {
                //     filename: 'page.pdf',
                //     image: { type: 'jpeg', quality: 0.98 },
                //     html2canvas: { scale: 2 },
                //     jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
                // };

                // html2pdf().from(element).set(pdfOptions).save();

                const pdfOptions = {
                    filename: 'page.pdf',
                    image: { type: 'jpeg', quality: 0.98 },
                    html2canvas: { scale: 2, logging: false },
                    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
                    margin: [2, 0, 0, 0]
                };

                const paddingStyles = `
                    <style>
                        .pdf-padding-top {
                            margin-top: 20px; /* Adjust the top padding */
                        }
                        .pdf-padding-bottom {
                            margin-bottom: 20px; /* Adjust the bottom padding */
                        }
                    </style>
                `;

                // Generate PDF with padding elements
                html2pdf().from(element).set({
                    ...pdfOptions,
                    hooks: {
                        beforeRender: (pdf: any) => {
                            pdf.internal.insertHtml(paddingStyles, 'afterbegin');
                        }
                    }
                }).save();

            } catch (error) {
                console.error('Error generating PDF:', error);
            }
        }
    };

    return (
        <>
            <div className="diet-plan-pdf-container" ref={targetRef}>
                <div className="diet-basic-details">
                    <div className="diet-basic-header">
                        <div className="diet-pdf-header-title">
                            Smart Health Diet
                        </div>
                        <div>
                            <img src={MyTatvaLogo} />
                        </div>
                    </div>

                    <div className="diet-patient-details">
                        <div className="diet-patient-details-left">
                            <ProfileIcon />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span style={{ color: '#171725', fontSize: '14px', fontWeight: '600' }}>{patientDetails?.name}</span>
                                <span style={{ fontSize: '10px', fontWeight: '400' }}>{patientDetails?.gender === 'M' ? 'Male' : 'Female'} • {patientDetails?.patient_age} yrs</span>
                            </div>
                        </div>
                        <div className="diet-patient-details-right">
                            <CalendarIcon />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span style={{ color: '#171725', fontSize: '9px', fontWeight: '400' }}>Date Range</span>
                                <span style={{ fontSize: '10px', fontWeight: '600', color: '#171725' }}>{moment(dietData?.start_date).format('DD MMM YYYY')} - {moment(dietData?.end_date).format('DD MMM YYYY')}</span>
                            </div>
                        </div>
                    </div>

                    <div className="diet-name-container">
                        <span>DIET NAME : {dietData?.plan_name}</span>
                    </div>

                    <div className="diet-other-data-container">
                        <div className="diet-other-data">
                            <HeightIcon />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span style={{ color: '#171725', fontSize: '9px', fontWeight: '400' }}>Height</span>
                                <span style={{ fontSize: '10px', fontWeight: '600' }}>{patientDetails?.height} {patientDetails?.height_unit || "cms"}</span>
                            </div>
                        </div>
                        <div className="diet-other-data">
                            <WeightIcon />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span style={{ color: '#171725', fontSize: '9px', fontWeight: '400' }}>Weight</span>
                                <span style={{ fontSize: '10px', fontWeight: '600' }}>{patientDetails?.weight} {patientDetails?.weight_unit || "kg"}</span>
                            </div>
                        </div>
                        <div className="diet-other-data">
                            <DiseaseIcon />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span style={{ color: '#171725', fontSize: '9px', fontWeight: '400' }}>Disease</span>
                                <span style={{ fontSize: '10px', fontWeight: '600' }}>{patientDetails?.medical_condition_name}</span>
                            </div>
                        </div>
                        <div className="diet-other-data">
                            <HotIcon />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span style={{ color: '#171725', fontSize: '9px', fontWeight: '400' }}>Total Calories Goal</span>
                                <span style={{ fontSize: '10px', fontWeight: '600' }}>{dietData?.total_calories}(kcal)</span>
                            </div>
                        </div>
                    </div>

                    <div style={{ border: '1px solid #E2E2EA', marginTop: '30px' }}></div>

                    <div style={{ marginTop: '25px', width: '98%', padding: '0px 10px' }}>
                        {(dietData.diet_plan_meal_rel && !!dietData.diet_plan_meal_rel.length) &&
                            dietData.diet_plan_meal_rel
                                .sort((a: any, b: any) => a?.start_time?.localeCompare(b?.start_time))
                                .map((meal: any) => (
                                    (meal?.hide_meal == 'N' || !meal?.hide_meal) && !isNaN(meal?.meal_options[0]?.option_no)) &&
                                    <div style={{ marginTop: '20px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', position: 'absolute' }}>
                                                <CheckIcon />
                                                <div style={{ fontSize: '10px', fontWeight: '600', color: 'white', zIndex: '100', marginTop: '-16px', marginLeft: '5px', position: 'relative' }}>
                                                    {meal?.meal_type}
                                                </div>
                                            </div>
                                            <div style={{ color: '#171725', fontSize: '9px', fontWeight: '400', marginLeft: '100px' }}>Meal Timing : {`${meal?.start_time} - ${meal?.end_time}`}</div>
                                        </div>
                                        {(meal.meal_options && !!meal.meal_options.length) &&
                                            meal.meal_options.map((op: any) => (
                                                <div className="diet-list">
                                                    <div style={{ width: '100%', justifyContent: 'center' }}>
                                                        <div style={{ background: '#454551', borderRadius: '10px 10px 0px 0px', width: '25%', margin: 'auto', textAlign: 'center', color: 'white', fontSize: '10px', fontWeight: '600', padding: '1px 0', textTransform: 'uppercase' }}>
                                                            {meal?.meal_type} {op?.option_no}
                                                        </div>
                                                    </div>
                                                    <table style={{ borderCollapse: 'collapse', width: '95%', marginLeft: 'auto', marginRight: 'auto' }}>
                                                        <thead>
                                                            <tr style={{ borderTop: '1px solid black', background: '#F1F1F5' }}>
                                                                <th style={{ fontWeight: '500', padding: '10px 0', fontSize: '12px', textAlign: 'left', width: '500px' }}>Food Item</th>
                                                                <th style={{ fontWeight: '500', padding: '10px 0', fontSize: '12px', textAlign: 'left', width: '300px' }}>Quantity</th>
                                                                <th style={{ fontWeight: '500', padding: '10px 0', fontSize: '12px', textAlign: 'left' }}>Measurement</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {op.option_data?.map((data: any, idx: any) => (
                                                                <tr style={{ borderBottom: '1px solid #E2E2EA' }}>
                                                                    <td style={{ textAlign: 'left', padding: '10px 0', fontSize: '12px' }}>{data?.food_name}</td>
                                                                    <td style={{ textAlign: 'left', padding: '10px 0', fontSize: '12px' }}>{data?.quantity}</td>
                                                                    <td style={{ textAlign: 'left', padding: '10px 0', fontSize: '12px' }}>{data?.unit_option_name}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '10px', minHeight: '20px', maxHeight: '250px', paddingLeft: '20px', pageBreakInside: "avoid" }}>
                                                        <TipIcon />
                                                        <div style={{ fontSize: '9px', fontWeight: '600', alignItems: 'center' }}>
                                                            Tips : {op?.option_data[0]?.tips || ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                )}
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', fontSize: '10px', marginTop: '25px', width: '95%', marginLeft: 'auto', marginRight: 'auto', textAlign: 'justify' }}>

                        <div style={{ border: '1px solid #A461D8', borderRadius: '8px', padding: '10px', pageBreakInside: "avoid" }}>
                            <div style={{ fontWeight: '800', color: '#760FB2', pageBreakInside: "avoid" }}>Nutrition Tips:</div>
                            <ul style={{ pageBreakInside: "avoid" }}>
                                <li>
                                    Utilize unrefined oils, such as cold-pressed extra virgin coconut, rice bran, sesame, groundnut, and mustard oil, limiting intake to 3-4 teaspoons daily, along with 1 teaspoon of desi ghee.
                                </li>
                                <li>
                                    Opt for rock salt or Himalayan pink salt in cooking, minimizing overall salt intake. Reduce fatty and fried foods, as well as spicy and salty items like french fries, chips, papads, and pickles.
                                </li>
                                <li>
                                    For effective weight loss, restrict daily sugar intake.
                                </li>
                                <li>
                                    Limit consumption of bakery products, avoiding biscuits, toast, pav, white bread, and khari due to their trans fat, saturated fat, and baking soda content, which may be harmful to the liver and heart.
                                </li>
                                <li>
                                    Embrace turmeric, garlic, onions, ginger, sesame seeds, black pepper, cinnamon, and cooked tomatoes in meal preparations for their health benefits.
                                </li>
                                <li>
                                    Aim for 10-12 glasses of water per day.
                                </li>
                                <li>
                                    Consume salads or soups before your main course to promote a sense of partial fullness.
                                </li>
                            </ul>
                        </div>

                        <div style={{ border: '1px solid #A461D8', borderRadius: '8px', padding: '10px', marginTop: '10px', pageBreakInside: "avoid" }}>
                            <div style={{ fontWeight: '800', color: '#760FB2', pageBreakInside: "avoid" }}> Mindful Eating Tips:</div>
                            <ul>
                                <li> Maintain a gap of minimum 3 to 3.5 hours between any meals</li>
                                <li> Maintain a gap of 3 hours between last meal of the day and bedtime to prevent acidity and heartburn.</li>
                                <li> Have early dinner, preferably before 8:30 pm.</li>
                                <li>Practice mindful eating by chewing food thoroughly, eating slowly for at least 20 minutes, place your spoon/fork on table after every bite, take deep breaths, enjoy what you are eating. Stop when full.</li>
                            </ul>
                        </div>

                        <div style={{ border: '1px solid #A461D8', borderRadius: '8px', padding: '10px', marginTop: '10px', pageBreakInside: "avoid" }}>
                            <div style={{ fontWeight: '800', color: '#760FB2', pageBreakInside: "avoid" }}>Other lifestyle:</div>
                            <ul>
                                <li>Prioritize 7-8 hours of sleep, incorporating 5-10 minutes of deep breathing before bedtime. Oxygen intake helps suppress cortisol, promoting better sleep. Sleep is crucial for healing, growth, and detoxification.</li>
                                <li>Make time for your hobbies. It helps to improve your overall mood & mental health.</li>
                                <li>Keep yourself active by indulging in some sort of physical activity like yoga, walking, swimming, aerobics etc</li>
                            </ul>
                        </div>
                        <div style={{ border: '1px solid #A461D8', borderRadius: '8px', padding: '10px', marginTop: '10px', pageBreakInside: "avoid" }}>
                            <div style={{ fontWeight: '800', color: '#760FB2' }}>Kitchen Measurements:</div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <img src={QuantityHelpImage} style={{ width: "100%", height: "auto", maxWidth: "800px", pageBreakInside: "avoid" }} />
                            </div>
                        </div>

                        <div style={{ border: '1px solid #A2A2A8', marginTop: '20px' }}></div>

                        <div style={{ marginTop: '10px', pageBreakInside: "avoid" }}>
                            <div style={{ fontWeight: '800', color: '#760FB2', pageBreakInside: "avoid" }}>Disclaimer:</div>
                            The diet plan offered is tailored solely for the individual patient based on the information provided and their unique clinical condition. It is strictly confidential and not intended for sharing or application to anyone else. These are general tips, which we can share at the bottom of every diet plan.
                        </div>

                        <div style={{ fontWeight: '800', color: '#760FB2', marginTop: '20px', pageBreakInside: "avoid" }}>Important Note:</div>
                        <span style={{ pageBreakInside: "avoid" }}>Please adhere to the specified portions and meal combinations outlined in the diet plan. If you have any inquiries regarding the diet plan, please feel free to reach out to your health coach. If you are not comfortable with the provided diet plan and need some variations or adjustments, kindly contact your diet coach for assistance.</span>
                        <span style={{ marginTop: '10px', pageBreakInside: "avoid" }}>Inform your coach in advance about any planned occasions where you might be eating outside, such as weddings or birthdays. This allows the coach to assist you in making healthier food choices while still enjoying the event.</span>

                        <div style={{ border: '1px solid #A2A2A8', marginTop: '20px' }}></div>
                    </div>
                </div>
            </div>
            <div className="diet-pdf-btn" onClick={GeneratePDF}>
                Generate PDF
            </div>
        </>
    )
}

export default DietPlanPdf;