import React, { FunctionComponent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import "../CgAppointmentCell/AppointmentCell.css";
import editIcon from '../../assets/icons/edit.svg';
import { Link } from "react-router-dom";
import { getDecryptedData, getEncryptedBody } from "../../utils/common";
import API, { GetOverallPatientCount } from "../../services/rest/api";


export type AppointmentCellType = {
  cycleNumber: number;
  visitDate: any;
  theorapyType?: string;
  theorapyDrug?: string;
  vivitra?: string;
  documents?: string;
  rewards?: string;
  action?: any;
  token?: any;
  patientId?: any;
  visitID?: any;
  visitStatus?: any;
  rewardsStatus?: any;
  visitMetaInfo?: any;
  rescheduledDate?:any;
  phone?:any,
  name?:any,
  vivitraEligible?:any,
  onPrimaryButtonClick?: () => void;
};

const PatientsCycles: FunctionComponent<AppointmentCellType> = ({
  cycleNumber,
  visitDate,
  theorapyType,
  theorapyDrug,
  vivitra,
  rewards,
  action,
  patientId,
  visitID,
  visitStatus,
  rewardsStatus,
  visitMetaInfo,
  rescheduledDate,
  phone,
  name,
  vivitraEligible,
  onPrimaryButtonClick,
}) => {
  // const [dischargeSummaryPdfUrl, setDischargeSummaryPdfUrl] = useState<string | null>(null);
  // const [purchaseInvoicePdfUrl, setPurchaseInvoicePdfUrl] = useState<string | null>(null);
  // const [editedDetails, setEditedDetails] = useState<any>({});
  // const [visitData, setVisitData] = useState<any>({});
  const [userDetails, setUserDetails] = useState<any>(null)
  const [editedDate, setEditedDate] = useState<any>(null);
  const [visitdateValue, setVisitDateValue] = useState('');
  const token = localStorage.getItem("token")
  const params = useParams();
  const location: any = useLocation();

  const UpdateVisitDetailsAPI = `${process.env.REACT_APP_API_URL}/api/v8/healthcoach/update_patient_visit`; 

  useEffect(() => {
    const token: any = params.token
    getProfileDetails();
    localStorage.setItem('token', (location.state?.token === ":token" || location.state?.token === undefined) ? token : location.state?.token);
  }, [])

  useEffect(() => {
    setVisitDateValue(rescheduledDate || visitDate);
  }, [visitDate, rescheduledDate]);

  const formattedDate = visitdateValue ? visitdateValue.slice(0, 10) : '';
  //funtion to get profile details
  const getProfileDetails = async () => {
    try {
      const userInfo = await API.GetProfileDetails()

      setUserDetails(userInfo?.data)

    } catch (error) {
      console.log("error", error)
    }
  }

  const updateVisitDetails = async (editedDate:any) => {
    try {
      const token = localStorage.getItem("token");
      const secretKey = "SfUyfAztruqg92sbm30rEIyHLNV7f5";
      // const userInfo = localStorage.getItem("userInfo");   
      const body = {
        "patient_phone":phone,
        "patient_name":name,
        "patient_visit_id": visitID,
        "reschedule_date": editedDate,
        "updated_by":userDetails?.health_coach_id,
      }

      const enCryptedBody = getEncryptedBody(body)
      // const decryptedBody = getDecryptedData(enCryptedBody)

      const response = await fetch( UpdateVisitDetailsAPI , {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
          token: `${token}`,
          health_secret: secretKey,
        },
        body: `${enCryptedBody}`,
      });

      if (!response.ok) {
        throw new Error("Failed to schedule a patient visit");
      }
      const data = await response.json();
      const decryptedData = getDecryptedData(data)

    } catch (error) {
      console.error("Error while rescheduling the visit:", error);
    }
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVisitDateValue(e.target.value)
    // setEditedDate(e.target.value);
    updateVisitDetails(e.target.value)
  };

  return (
    <div className="cycle-table-frame">
      <div className="cycle-cell-inner">
        <div className="name">{cycleNumber}</div>
      </div>
      <div className="cycle-status-cell-inner">
        <div className="status-title">{visitStatus === "VA" ? "Completed" : "Upcoming"}</div>
        {/* <div className="name">{editedDate ? editedDate : visitDate}</div> */}
        {/* <div className="status-button" onClick={() => setShowDatePicker(true)}>Edit Date</div>
      {showDatePicker && (
        <div className="date-picker-container">
          <DatePicker selected={new Date(visitDate)} onChange={handleDateChange} />
        </div>
      )} */}
        {visitStatus !== "VA" ? (
          <>
          <input
            className="edit-date-container"
            name="cycle_date"
            value={formattedDate}
            type="date"
            onChange={handleDateChange}
          // min={new Date().toISOString().split('T')[0]}
          />
          {/* <img src={editIcon} alt="Edit" className="edit-icon" /> */}
          </>
        ) : (
          <input
            className="edit-date-container"
            name="cycle_date"
            value={formattedDate}
            // placeholder="Enter Name"
            type="text"
            readOnly
            // onChange={handleDateChange}
          />
        )
        }
      </div>
      <div className="cycle-cell-inner">
        <div className="contact">{theorapyType}</div>
      </div>
      <div className="cycle-cell-inner">
        <div className="contact1">{theorapyDrug}</div>
      </div>
      {/* <div className="cycle-cell-inner">
        <div className="visit-type">--</div>
      </div> */}
      <div className="cycle-cell-inner">
        <div className="visit-type">{rewardsStatus ? "Assigned" : "Pending"}</div>
      </div>
      <div className="cycle-cell-inner">
        <Link
          to={{ pathname: `/cycle-details/:token` }}
          style={{ textDecoration: 'none' }}
          state={{
            cycleNumber: cycleNumber,
            patientId: patientId,
            visitID: visitID,
            token: token,
            visitStatus: visitStatus,
            visitMetaInfo: visitMetaInfo,
            vivitraEligible: vivitraEligible
          }}
        >
          <button className="secondary-default">
            <div className="primary-button">{"View Details"}</div>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default PatientsCycles;
