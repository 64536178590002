import React, { FC, useState } from "react";
import AddDietPlan from "../../molecules/addDiet-plan/addDiet-plan";
import { mealTypes } from "../../constants";

type AddDietContainerProps = {
    getPatientList?: any,
    setAddDietShow?: any,
    setScreen?:any,
    chief_hc?:any,
    latestDietForApprove?: any,
    setShowDietModal?: any,
    selectedAssessment?:any,
    userDetails?:any,
    setEnableSwitch?:any,
    setToggle?:any,
    disableUpdateButton?:any,
    setDisableUpdateButton?:any
    editModal?: boolean,
    templateId?: string | null
}

const AddDietContainer: FC<AddDietContainerProps> = ({getPatientList, setAddDietShow, setScreen, chief_hc, latestDietForApprove, setShowDietModal, selectedAssessment, userDetails, setEnableSwitch, setToggle, disableUpdateButton, setDisableUpdateButton, editModal, templateId}) => {
    return (
        <>
            <div>
                <AddDietPlan editModal={editModal} templateId={templateId} getPatientList={getPatientList} setAddDietShow={setAddDietShow} setScreen={setScreen} chief_hc={chief_hc} latestDietForApprove={latestDietForApprove} setShowDietModal={setShowDietModal} selectedAssessment={selectedAssessment} userDetails={userDetails} setEnableSwitch={setEnableSwitch} setToggle={setToggle} disableUpdateButton={disableUpdateButton} setDisableUpdateButton={setDisableUpdateButton}/>
            </div>
        </>
    )
}

export default AddDietContainer;