import { FunctionComponent, useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "./PatientSummary.css";
import rectangleIcon from "../../assets/icons/rectangle-12.svg";
import arrowLeftIcon from "../../assets/icons/arrow--left.svg";
import { getDecryptedData, getEncryptedBody, getEncryptedText } from "../../utils/common";
import PatientsCycles from "../../molecules/CgPatientCycles/PatientsCycles";
import API, { GetOverallPatientCount } from "../../services/rest/api";
import ConfirmationModal from "../../molecules/CgConfirmationModal/ConfirmationModal";

const PatientSummary: FunctionComponent = () => {
  const [userDetails, setUserDetails] = useState<any>(null)
  const [visitList, setVisitList] = useState<any>([]);
  const [token, setToken] = useState<any>();
  const [patientData, setPatientData] = useState<any>(null)
  const [showVivitraConfirmation, setShowVivitraConfirmation] = useState<boolean>(false);
  const [isVivitraEnabled, setIsVivitraEnabled] = useState<any>(null);
  const [pageRefresh, setPageRefresh] = useState<boolean>(false);

  const params = useParams();
  const navigate = useNavigate();
  const location: any = useLocation();

  const PatientVisitListAPI = `${process.env.REACT_APP_API_URL}/api/v8/healthcoach/get_patient_visit_details`;
  const PatientDetailsAPI = `${process.env.REACT_APP_API_URL}/api/v8/healthcoach/patient_details_by_id`;
  const SavePatientDetailsAPI = `${process.env.REACT_APP_API_URL}/api/v8/healthcoach/update_patient_information`;

  const handleBackButtonClick = (screen: string) => {
    navigate(`/${screen}/:token`);
  };

  useEffect(() => {
    const token: any = params.token

    localStorage.setItem('token', (location.state?.token === ":token" || location.state?.token === undefined) ? token : location.state?.token);
    sessionStorage.setItem('token', (location.state?.token === ":token" || location.state?.token === undefined) ? token : location.state?.token);
    setPageRefresh(location?.state?.refresh ? true : false)
    setToken(localStorage.getItem("token"));
  }, [])

  useEffect(() => {
    const fetchVisitLists = async () => {
      try {
        const token = localStorage.getItem("token");
        const secretKey = "SfUyfAztruqg92sbm30rEIyHLNV7f5";
        const body = {
          "patient_id": location.state?.patientId,
          "records_needed": 1,
        }
        const enCryptedBody = getEncryptedBody(body)

        // const decryptedBody = getDecryptedData(enCryptedBody)

        const response = await fetch(PatientVisitListAPI, {
          method: "POST",
          headers: {
            "Content-Type": "text/plain",
            token: `${token}`,
            health_secret: secretKey,
          },
          body: `${enCryptedBody}`,
        });

        if (!response.ok) {
          throw new Error("Failed to fetch PDF URL");
        }
        const data = await response.json();
        const decryptedData = getDecryptedData(data)
        const visitList = JSON.parse(decryptedData).data
        setVisitList(visitList)

      } catch (error) {
        console.error("Error fetching PDF URL:", error);
      }
    };

    fetchVisitLists();
  }, [pageRefresh]);

  useEffect(() => {
    getProfileDetails();
    fetchPatientDetails();
  }, []);

  //funtion to get profile details
  const getProfileDetails = async () => {
    try {
      const userInfo = await API.GetProfileDetails();
      // localStorage.setItem('userInfo', JSON.stringify(userInfo?.data));
      setUserDetails(userInfo?.data);

    } catch (error) {
      console.log("error", error);
    }
  }

  const fetchPatientDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const secretKey = "SfUyfAztruqg92sbm30rEIyHLNV7f5";
      const body = JSON.stringify({
        patient_id: location.state?.patientId,
      });
      const response = await fetch(
        PatientDetailsAPI,
        {
          method: "POST",
          headers: {
            "Content-Type": "text/plain",
            token: `${token}`,
            health_secret: secretKey,
          },
          body: body,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      const userData = data?.data;
      setIsVivitraEnabled(userData?.patient_meta_info?.vivitra_eligible)
      setPatientData(userData)
    } catch (error) {
      console.error("Error fetching patient details:", error);
    }
  };

  const handleCloseModal = () => {
    setShowVivitraConfirmation(false)
  };

  const handleCheckboxChange = () => {
    // If checkbox is already confirmed, that can not be undo it.
    if (isVivitraEnabled === true) {
      setShowVivitraConfirmation(false);
    } else {
      setShowVivitraConfirmation(true);
    }
  };

  const handleVivitraConfirmation = async (action: boolean) => {
    setIsVivitraEnabled(action);
    if (action) {
      saveVivitraDetails();
    }
    handleCloseModal();
  };

  const saveVivitraDetails = async () => {
    try {
      const body = JSON.stringify({
        patient_id: patientData?.patient_id,
        patient_phone:patientData?.contact_no,
        patient_name:patientData?.name,
        patient_meta_info: {
          "vivitra_eligible": 1
        },
      });
      const token = location.state?.token ?? sessionStorage.getItem("token");
      const secretKey = 'SfUyfAztruqg92sbm30rEIyHLNV7f5';
      const response = await fetch(SavePatientDetailsAPI, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
          'token': `${token}`,
          'health_secret': secretKey,
        },
        body: body,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      console.log(data, "vivitra-updated-successfully")
    } catch (error) {
      console.error("Error updating patient details:", error);
    }
  };

  return (
    <>
      {showVivitraConfirmation && !isVivitraEnabled && (
        <div className={`confirmation-modal ${showVivitraConfirmation ? "overlay visible" : ""}`}>
          <ConfirmationModal
            show={showVivitraConfirmation}
            onConfirmation={handleVivitraConfirmation}
            onClose={handleCloseModal}
          />
        </div>
      )}
      <div className="patient-summary">
        <img className="desktop-3-child" alt="" src={rectangleIcon} />
        <header className="summary-header">
          <div>
            <Link
              to={`/${"all-patients/:token"}`}
              style={{ textDecoration: "none" }}
              state={{ token: `${token}`, journey: "verified-patients" }}
            >
              <div className="rectangle-v-e-c-t-o-r">
                <div className="frame-f-r-a-m-e">
                  <button
                  /*   onClick={ () => handleBackButtonClick("all-patients")}>
                  onClick={handleBackButtonClick}> */
                  >
                    <img
                      className="arrow-left"
                      loading="lazy"
                      alt=""
                      src={arrowLeftIcon}
                    />
                  </button>
                </div>
                <h3 className="products-list1">Patient Summary</h3>
              </div>
            </Link>
          </div>
          <div className="sub-header">
            <div className="patient-info">
              <div className="patient-info-container">
                <label className="patientInfo-label">Patient Info</label>
                <div className="patient-info-details">
                  <div>Name: {patientData?.name}</div>
                  <div>Contact:  {patientData?.contact_no} </div>
                </div>
              </div>
              <div className="view-details-btn">
                <Link to={{ pathname: `/view-details/:token` }} style={{ textDecoration: 'none' }} state={{ patientId: location.state?.patientId, token: token }}>
                  <button className="view-details-btn-button">View Details</button>
                </Link>
              </div>
            </div>
            <div className="checkbox-frame">
              <div
                className={`checkbox ${isVivitraEnabled !=="0" ? "checked" : ""}`}
                onClick={handleCheckboxChange}
              >
                <input
                  type="checkbox"
                  checked={isVivitraEnabled}
                  onChange={() => { }}
                />
                <span className="checkmark"></span>
              </div>
              <div className="label-checkbox">
                Enable this patient for VIVITRA Program
              </div>
            </div>
          </div>
        </header>
        <main className="cycle-frame">
          <h3 className="cycle-title">Patient Cycles</h3>
          <div className="cycletableframe">
            <div className="cycle-cell-inner">
              <div className="payment-number3">Cycle Number</div>
            </div>
            <div className="cycle-cell-inner">
              <div className="payment-number3">Status</div>
            </div>
            <div className="cycle-cell-inner">
              <div className="payment-number2">Therapy Type</div>
            </div>
            <div className="cycle-cell-inner">
              <div className="payment-number3">Therapy Drug</div>
            </div>
            {/* <div className="cycle-cell-inner">
              <div className="payment-number4">Vivitra</div>
            </div> */}
            <div className="cycle-cell-inner">
              <div className="payment-number5">Rewards</div>
            </div>
            <div className="cycle-cell-inner">
              <div className="payment-number5">ACTION</div>
            </div>
          </div>
          <div className="cycles-table">
            {visitList?.map((visit: any) =>
              <PatientsCycles
                cycleNumber={visit.visit_cycle}
                visitDate={visit.visit_date.split(" ")[0]}
                theorapyType={visit?.visit_meta_info?.target_therapy_drug || "--"}
                theorapyDrug={visit?.visit_meta_info?.therapy_type || "--"}
                vivitra="--"
                // documents="Not Uploaded"
                rewards="Pending"
                action={visit.visit_cycle}
                token={token}
                patientId={location.state?.patientId}
                visitID={visit?.patient_visit_id}
                visitStatus={visit?.visit_status}
                rewardsStatus={visit?.visit_meta_info?.rewards_assigned}
                visitMetaInfo={visit?.visit_meta_info}
                rescheduledDate={visit?.rescheduled_date}
                phone={patientData?.contact_no}
                name={patientData?.name}
                vivitraEligible={isVivitraEnabled}
              // onPrimaryButtonClick={() => handlePrimaryButtonClick("complete-profile")}
              />
            )
            }
          </div>
          <div className="desktop-1-item" />
        </main>
      </div>
    </>
  );
};

export default PatientSummary;
