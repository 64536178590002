import { useState, useEffect } from 'react';
import { message } from 'antd';

import { getDecryptedData, secToMin } from '../../utils/common';
import API from '../../services/rest/api';

import './GetAllGetActyvPlans.scss';
import Loader from '../../atoms/Loader';

interface ViewPlanModalProps {
    selectedPlan: string | null;
}

const ViewPlanModal: React.FC<ViewPlanModalProps> = (props) => {

    const { selectedPlan }: any = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [planData, setPlanData] = useState<any>(null);

    const modifiedPlanDetails = (planDetails: any[]) => {
        return planDetails;
    }

    const modifiedSessionDetails = (sessionData: any) => {
        return sessionData;
    }

    const fetchPlanData = async (selectedPlan: any) => {
        try {
            setLoading(true);
            if (selectedPlan?.type === 'Assessment') {
                const planData = await API.GetUserPlanAssessment(selectedPlan.id);
                const decryptedPlanData: any = JSON.parse(getDecryptedData(planData));
                if (decryptedPlanData.code !== 1) throw new Error(decryptedPlanData.msg || 'Internal server error!');

                setPlanData({
                    sessionDetails: {},
                    planDetails: modifiedPlanDetails(decryptedPlanData?.resp?.assessment?.assessmentDetails || [])
                })
            } else if (selectedPlan?.type === 'Exercise' && selectedPlan?.id && selectedPlan?.sessionId) {
                const planData = await API.GetUserPlan(selectedPlan.id);
                const decryptedPlanData: any = JSON.parse(getDecryptedData(planData));
                if (decryptedPlanData.code !== 1) throw new Error(decryptedPlanData.msg || 'Internal server error!');


                const sessionData = await API.GetUserPlanSession(selectedPlan.sessionId);
                const decryptedSessionData: any = JSON.parse(getDecryptedData(sessionData));
                if (decryptedSessionData.code !== 1) throw new Error(decryptedSessionData.msg || 'Internal server error!');
                
                setPlanData({
                    sessionDetails: modifiedSessionDetails(decryptedSessionData?.resp?.session[0] || {}),
                    planDetails: modifiedPlanDetails(decryptedPlanData?.resp?.plans?.planDetails || [])
                });
            }
        } catch (error) {
            console.log(error);
            message.error({ content: 'Failed to fetch plan!' });
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (loading || !selectedPlan) return;
        fetchPlanData(selectedPlan);
    }, [selectedPlan]);

    return (
        <div className='view-plan-wrapper'>
            {loading
                ? <Loader noBackground />
                : <div className='body'>
                    {(selectedPlan?.type === 'Exercise' && planData?.sessionDetails)
                        && <div className='session-detail-wrapper'>
                            <div className='left-detail'>
                                <div className='row'>
                                    <div className='key'>Title</div>
                                    <div className='value'>{planData?.sessionDetails?.session_title || '-'}</div>
                                </div>
                                <div className='row'>
                                    <div className='key'>Note</div>
                                    <div className='value'>{planData?.sessionDetails?.session_notes || '-'}</div>
                                </div>
                                <div className='row'>
                                    <div className='key'>Session Start Date</div>
                                    <div className='value'>{planData?.sessionDetails?.session_date || '-'}</div>
                                </div>
                            </div>
                            <div className='right-detail'>
                                <div className='row'>
                                    <div className='key'>Session Start Time</div>
                                    <div className='value'>{planData?.sessionDetails?.session_start_time || '-'}</div>
                                </div>
                                <div className='row'>
                                    <div className='key'>Session End Time</div>
                                    <div className='value'>{planData?.sessionDetails?.session_end_time || '-'}</div>
                                </div>
                                <div className='row'>
                                    <div className='key'>Session End Date</div>
                                    <div className='value'>{selectedPlan?.endDate || '-'}</div>
                                </div>
                            </div>
                        </div>}

                    {planData?.planDetails
                        && <div className='plan-detail-wrapper'>
                            <div className='plan-detail-header'>
                                <div className='exercise-image'>Exercise Image</div>
                                <div className='exercise-name'>Exercise Name</div>
                                <div className='count'>Reps</div>
                                <div className='count'>Set</div>
                                <div className='count'>Time</div>
                            </div>
                            <div className='plan-detail-body'>
                                {planData.planDetails.map((plan: any) => (
                                    <div className='plan-detail-list'>
                                        <div className='image-box'>
                                            <img src={plan.exercise_photo} alt='exercise_photo' />
                                        </div>
                                        <div className='exercise-box'>
                                            {plan.exercise_name}
                                        </div>
                                        <div className='count-box'>
                                            {plan.reps_prescribed}
                                        </div>
                                        <div className='count-box'>
                                            {plan.sets_prescribed}
                                        </div>
                                        <div className='count-box'>
                                            {secToMin(plan.time_prescribed)}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>}
                </div>
            }
        </div>
    );
};

export default ViewPlanModal;