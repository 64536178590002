import React from "react";
import "./Textarea.scss";

interface Props {
  label: string;
  name: string;
  onChange: (event: any) => any;
  value: string;
  rows?: number;
}

const Textarea: React.FC<Props> = ({
  label = "",
  name = "",
  onChange,
  value,
  rows = 4,
}) => {
  return (
    <div className="textarea-container">
      <label className="label">{label}</label>
      <textarea
        name={name}
        className="textarea"
        onChange={onChange}
        value={value}
        rows={rows}
      />
    </div>
  );
};

export default Textarea;
