import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import { Button, DatePicker, Input, message, Select, TimePicker, Tooltip, Modal } from 'antd';
import { MinusOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { getDecryptedData, getEncryptedBody } from '../../utils/common';
import API from '../../services/rest/api';

import './GetAllGetActyvPlans.scss';

// const coachId = '191e40c2-6461-4927-b71c-35782a5018b4';
// const userId = '852cb01b-90f9-4cb4-ad07-07a316c93a42';
// const patientId = '00e99687-b452-11ee-9b73-8fadb27840d6';

const RecurrenceType = [
    // { value: 'daily', label: <span>daily</span> },
    // { value: 'weekly', label: <span>weekly</span> },
    // { value: 'monthly', label: <span>monthly</span> },
    // { value: 'yearly', label: <span>yearly</span> },
    { value: 'custom', label: <span>custom</span> },
    // { value: 'standalone', label: <span>standalone</span> },
];

const RecurrenceCustomDays = [
    { value: 'all', label: <span>Select all</span> },
    { value: 'sunday', label: <span>Sunday</span> },
    { value: 'monday', label: <span>Monday</span> },
    { value: 'tuesday', label: <span>Tuesday</span> },
    { value: 'wednesday', label: <span>Wednesday</span> },
    { value: 'thursday', label: <span>Thursday</span> },
    { value: 'friday', label: <span>Friday</span> },
    { value: 'saturday', label: <span>Saturday</span> },
    { value: 'remove_all', label: <span>Remove all</span> },

];
interface Exercise {
    exercise_id: string;
    exercise_name: string;
    exercise_photo: string;
    exercise_description: string | null;
    exercise_subcategory: string;
    exercise_uri: string;
    exercise_landmark: string;
    exercise_type: string;
    exercise_category: string;
    exercise_video: string;
    exercise_loop_commands: string | null;
    exercise_met: number;
    exercise_performance_type: string;
    exercise_visibility_check: string;
    exercise_for_type: string;
    created_at: string;
    updated_at: string;
};

interface PlanDetails {
    exerciseId: string;
    repsPrescribed: number | string;
    setsPrescribed: number | string;
    timePrescribed: number | string;
};

interface SessionDetails {
    title: string;
    notes: string;
    sessionDate: string;
    startTime: string;
    endTime: string;
    recurrenceType: 'daily' | 'weekly' | 'monthly' | 'yearly' | 'custom' | 'standalone';
    // recurrenceCount: number;
    recurrenceEndDate: string;
    onlyWorkingDays: boolean;
    recurrenceCustomDays: Array<'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday'>;
};

interface AddPlanModalProps {
    setAddModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setRefreshList: React.Dispatch<React.SetStateAction<boolean>>;
    getActyvId: string;
    getActyvHCId: string;
};

const newObj = {
    exerciseId: '',
    repsPrescribed: '',
    setsPrescribed: '',
    timePrescribed: '',
};

const emptySession = {
    title: null,
    notes: null,
    onlyWorkingDays: false,
    sessionDate: null,
    startTime: null,
    endTime: null,
    recurrenceEndDate: null,
    // recurrenceCount: null,
    recurrenceCustomDays: [],
    recurrenceType: 'custom',
};

const keyToValidate = ['title', 'notes', 'sessionDate', 'startTime', 'endTime', 'recurrenceCustomDays', 'recurrenceType', 'exerciseId', 'repsPrescribed', 'setsPrescribed', 'timePrescribed'];

const AddPlanModal: React.FC<AddPlanModalProps> = (props) => {

    const { setAddModalOpen, setRefreshList, getActyvId, getActyvHCId } = props;

    const [exerciseList, setExerciseList] = useState<Exercise[]>([]);
    const [needToDisableReps, setNeedToDisableReps] = useState<string[]>([]);
    const [exerciseListLoading, setExerciseListLoading] = useState<boolean>(false);
    const [planDetails, setPlanDetails] = useState<PlanDetails[]>([newObj]);
    const [sessionDetails, setSessionDetails] = useState< SessionDetails>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>();
    const [planError, setPlanError] = useState<any>();

    useEffect(() => {
        if (exerciseListLoading || exerciseList.length) return;
        getExerciseList();
    }, []);

    const modifyExerciseResponseData = (response: any[]) => {
        const modifiedData = response
            // .filter((resp: Exercise) => resp.exercise_performance_type === 'rep')
            .map((resp: Exercise, _index: number) => ({
                value: resp.exercise_id,
                label: (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                        <img style={{ height: '80px', width: '80px', objectFit: 'cover', borderRadius: '5px' }} src={resp.exercise_photo} alt='exercise_photo' />
                        {resp.exercise_name}
                    </div>
                ),
                ...resp
            }));
        return modifiedData;
    }

    const getExerciseList = async () => {
        try {
            setExerciseListLoading(true);
            const exerciseList = await API.GetActyvExerciseList();
            const decryptedResp: any = JSON.parse(getDecryptedData(exerciseList));
            if (decryptedResp.code !== 1) throw new Error(decryptedResp.msg || 'Internal server error!');
            const modifiedExerciseList = modifyExerciseResponseData(decryptedResp?.resp?.exercises || []);
            setNeedToDisableReps(
                modifiedExerciseList
                    .filter(el => ['stretch', 'breath'].includes(el.exercise_performance_type))
                    .map(el => el.exercise_id)
            );
            setExerciseList(modifiedExerciseList as any[]);
        } catch (error) {
            console.log(error);
            setExerciseList([]);
        } finally {
            setExerciseListLoading(false);
        }
    }

    const onPressAddIcon = () => {
        setPlanDetails((prev) => {
            return [...prev, newObj];
        });
    }

    const onPressMinusIcon = (index: number) => {
        setPlanDetails(prevPlanDetails => {
            if (prevPlanDetails.length <= 1) {
                return [newObj];
            }
            return prevPlanDetails.filter((_, i) => i !== index);
        });
    };

    const onChangePlanDetailField = (key: string, value: string, index: number) => {
        setPlanDetails(prevPlanDetails => {
            const updatedPlanDetails = [...prevPlanDetails];
            updatedPlanDetails[index] = {
                ...updatedPlanDetails[index],
                [key]: key === 'exerciseId' ? value : key === 'timePrescribed' ? parseFloat(value) : parseInt(value)
            };
            if (key === 'exerciseId' && needToDisableReps.includes(value)) {
                updatedPlanDetails[index].repsPrescribed = 0;
            }
            return updatedPlanDetails;
        });
        if (planError) setPlanError(null);
    };

    const onChangeSessionDetailField = (key: string, value: any) => {
        const isEmpty = value === '' || value === null || !value;

        setSessionDetails((prev: any) => {
            let updatedSessionDetails = { ...prev };

            if (key === 'sessionDate' && isEmpty) {
                updatedSessionDetails = {
                    ...updatedSessionDetails,
                    sessionDate: '',
                    startTime: '',
                    endTime: '',
                    recurrenceEndDate: ''
                };
            } else if (key === 'startTime' && isEmpty) {
                updatedSessionDetails = {
                    ...updatedSessionDetails,
                    startTime: '',
                    endTime: ''
                };
            } else if (key === 'recurrenceCustomDays' && value.includes('all')) {
                const allDay = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
                updatedSessionDetails = {
                    ...updatedSessionDetails,
                    [key]: allDay
                };
            } else if (key === 'recurrenceCustomDays' && value.includes('remove_all')) {
                updatedSessionDetails = {
                    ...updatedSessionDetails,
                    [key]: []
                };
            } else {
                updatedSessionDetails = {
                    ...updatedSessionDetails,
                    [key]: key !== 'recurrenceCount' ? value : parseInt(value)
                };
            }

            return updatedSessionDetails;
        });

        if (error) setError(null);
    };

    const isNotNullOrUndefined = (value: any, checkForZero: boolean): boolean => {
        if (Array.isArray(value)) {
            return value.length > 0;
        }
        return value !== null && value !== undefined && value !== '' && !(checkForZero && value === 0);
    }

    const isAnyPropertyNotNullOrUndefined = (details: any): boolean => {
        for (const key in details) {
            if (keyToValidate.includes(key) && details.hasOwnProperty(key) && !isNotNullOrUndefined(details[key], false)) {
                setError({ key: key, error: `${key} should not be null` });
                return true;
            }
        }
        return false;
    }

    const isAnyPlanDetailsPropertyNotNullOrUndefined = (planDetails: any[]): boolean => {
        for (let i = 0; i < planDetails.length; i++) {
            const plan = planDetails[i];
            for (const key in plan) {
                if (keyToValidate.includes(key) && plan.hasOwnProperty(key) && !isNotNullOrUndefined(plan[key], (!needToDisableReps.includes(plan.exerciseId)) || key !== 'repsPrescribed')) {
                    setPlanError({ key: key, error: `${key} should not be null`, index: i });
                    return true;
                }
            }
        }
        return false;
    }

    const onPressAddPlan = async () => {
        if (loading) return;

        try {
            const sessionProps = { ...emptySession, ...sessionDetails };
            if (isAnyPropertyNotNullOrUndefined(sessionProps) || isAnyPlanDetailsPropertyNotNullOrUndefined(planDetails)) {
                return;
            }

            Modal.confirm({
                title: 'Are you sure you want to create an exercise plan?',
                icon: <ExclamationCircleOutlined />,
                content: 'This action will generate an exercise plan along with its sessions.',
                async onOk() {
                    try {
                        setLoading(true);

                        const encrypted = getEncryptedBody({ userId: getActyvId, coachId: getActyvHCId, planDetails: planDetails.map((plan) => ({ ...plan, timePrescribed: Number(plan.timePrescribed) * 60 })) });
                        const exerciseList = await API.AddGetActyvPlan(encrypted);
                        const decryptedResp: any = JSON.parse(getDecryptedData(exerciseList));

                        if (decryptedResp.code !== 1) throw new Error(decryptedResp.msg || 'Internal server error!');
                        const planId = decryptedResp?.resp?.plan?.plan_id || '';
                        if (!planId) throw new Error(decryptedResp.msg || 'Plan creation failed!');

                        const encryptedSessionResponse = getEncryptedBody({ userId: getActyvId, coachId: getActyvHCId, planId: planId, ...sessionProps });
                        await API.AddGetActyvSession(encryptedSessionResponse);
                        setAddModalOpen(false);
                        setRefreshList(true);
                        message.success({ content: 'Plan created successfully!', });
                    } catch (error) {
                        console.log(error);
                        message.error({ content: 'Plan creation failed!', });
                    } finally {
                        setLoading(false);
                    }
                },
                onCancel() { },
                okButtonProps: {
                    style: { backgroundColor: '#760fb2', borderColor: '#760fb2' },
                }
            });
        } catch (error) {
            console.log(error);
            message.error({ content: 'Plan creation failed!', });
        } finally {
            setLoading(false);
        }
    };

    const disabledStartDate = (current: any) => {
        return current && current < moment().startOf('day');
    };

    const disabledEndDate = (current: any) => {
        if (!sessionDetails || !sessionDetails?.sessionDate) {
            return false;
        }
        return current && current < moment(sessionDetails.sessionDate);
    }

    const currentHour = moment().hour();
    const currentMinute = moment().minute();
    const isToday = sessionDetails?.sessionDate && moment(sessionDetails.sessionDate, 'YYYY-MM-DD').isSame(moment(), 'day');

    const disabledStartHours = () => {
        if (!isToday) return [];
        const hours = [];
        for (let i = 0; i < currentHour; i++) {
            hours.push(i);
        }
        return hours;
    };

    const disabledStartMinutes = (selectedHour: any) => {
        if (!isToday) return [];
        if (selectedHour === currentHour) {
            const minutes = [];
            for (let i = 0; i < currentMinute + 5; i++) {
                minutes.push(i);
            }
            return minutes;
        }
        return [];
    };

    const disabledEndHours = () => {
        if (!sessionDetails?.startTime) return [];
        const startHours = parseInt(sessionDetails.startTime.split(':')[0]);
        const hours = [];
        for (let i = 0; i < startHours; i++) {
            hours.push(i);
        }
        return hours;
    };

    const disabledEndMinutes = (selectedHour: any) => {
        if (!sessionDetails?.startTime) return [];
        const startHours = parseInt(sessionDetails.startTime.split(':')[0]);
        const startMinute = parseInt(sessionDetails.startTime.split(':')[1]);
        if (selectedHour === startHours) {
            const minutes = [];
            for (let i = 0; i < startMinute + 5; i++) {
                minutes.push(i);
            }
            return minutes;
        }
        return [];
    };

    const showCloseConfirm = () => {
        Modal.confirm({
            title: 'Are you sure you want to close the modal?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action will remove all filled plan and session details.',
            async onOk() {
                setAddModalOpen(false)
            },
            onCancel() { },
            okButtonProps: {
                style: { backgroundColor: '#760fb2', borderColor: '#760fb2' },
            }
        });
    };

    return (
        <div className='add-plan-wrapper'>
            <div className='plan-details-wrapper'>
                <div className='row'>
                    <div className='column'>
                        <span className='label'>Title <div className='start'>*</div></span>
                        <Input
                            className='antd-input-type'
                            placeholder='Title'
                            value={sessionDetails?.title}
                            onChange={(e) => onChangeSessionDetailField('title', e.target.value)} />
                        {error?.key === 'title' && <span className='error-text'>{error.error}</span>}
                    </div>
                    <div className='column'>
                        <span className='label'>Notes <div className='start'>*</div></span>
                        <Input
                            className='antd-input-type'
                            placeholder='Notes'
                            value={sessionDetails?.notes}
                            onChange={(e) => onChangeSessionDetailField('notes', e.target.value)} />
                        {error?.key === 'notes' && <span className='error-text'>{error.error}</span>}
                    </div>
                    <div className='column switch-column'>
                        {/* <span className='label'>Only WorkingDays</span>
                        <Switch
                            className='switch'
                            checked={true}
                            disabled={true}
                        // checked={sessionDetails?.onlyWorkingDays}
                        // onChange={(e) => onChangeSessionDetailField('onlyWorkingDays', e)}
                        /> */}
                    </div>
                </div>

                <div className='row'>
                    <div className='column'>
                        <span className='label'>Session Date <div className='start'>*</div></span>
                        <DatePicker
                            className='date-picker'
                            placeholder='Select session date'
                            value={sessionDetails?.sessionDate ? dayjs(sessionDetails.sessionDate, 'YYYY-MM-DD') : null}
                            defaultValue={sessionDetails?.sessionDate ? dayjs(sessionDetails.sessionDate, 'YYYY-MM-DD') : undefined}
                            format={'YYYY-MM-DD'}
                            onChange={(_date, dateString) => { onChangeSessionDetailField('sessionDate', dateString) }}
                            disabledDate={disabledStartDate}
                        />
                        {error?.key === 'sessionDate' && <span className='error-text'>{error.error}</span>}
                    </div>
                    <div className='column'>
                        <span className='label'>Start Time <div className='start'>*</div></span>
                        <TimePicker
                            className='time-picker'
                            format={'HH:mm'}
                            placeholder='Select start time'
                            value={sessionDetails?.startTime ? dayjs(sessionDetails?.startTime, 'HH:mm') : null}
                            defaultValue={sessionDetails?.startTime ? dayjs(sessionDetails?.startTime, 'HH:mm') : undefined}
                            onChange={(_time, timeString) => onChangeSessionDetailField('startTime', timeString)}
                            disabledHours={disabledStartHours}
                            disabledMinutes={disabledStartMinutes}
                            disabled={!!!sessionDetails?.sessionDate}
                        />
                        {error?.key === 'startTime' && <span className='error-text'>{error.error}</span>}
                    </div>
                    <div className='column'>
                        <span className='label'>End Time <div className='start'>*</div></span>
                        <TimePicker
                            className='time-picker'
                            format={'HH:mm'}
                            placeholder='Select end time'
                            value={sessionDetails?.endTime ? dayjs(sessionDetails?.endTime, 'HH:mm') : null}
                            defaultValue={sessionDetails?.endTime ? dayjs(sessionDetails?.endTime, 'HH:mm') : undefined}
                            onChange={(_time, timeString) => onChangeSessionDetailField('endTime', timeString)}
                            disabledHours={disabledEndHours}
                            disabledMinutes={disabledEndMinutes}
                            disabled={!!!sessionDetails?.startTime}
                        />
                        {error?.key === 'endTime' && <span className='error-text'>{error.error}</span>}
                    </div>
                </div>

                <div className='row'>
                    <div className='column'>
                        <span className='label'>Recurrence End Date <div className='start'>*</div></span>
                        <DatePicker
                            className='date-picker'
                            placeholder='Select recurrence end date'
                            value={sessionDetails?.recurrenceEndDate ? dayjs(sessionDetails.recurrenceEndDate, 'YYYY-MM-DD') : null}
                            defaultValue={sessionDetails?.recurrenceEndDate ? dayjs(sessionDetails.recurrenceEndDate, 'YYYY-MM-DD') : undefined}
                            format={'YYYY-MM-DD'}
                            onChange={(_date, dateString) => { onChangeSessionDetailField('recurrenceEndDate', dateString) }}
                            disabledDate={disabledEndDate}
                            disabled={!!!sessionDetails?.sessionDate}
                        />
                        {error?.key === 'recurrenceEndDate' && <span className='error-text'>{error.error}</span>}
                    </div>
                    <div className='column'>
                        <span className='label'>Recurrence Custom Days <div className='start'>*</div></span>
                        {/* <Input
                            className='antd-input-type'
                            placeholder='Recurrence count'
                            type='number'
                            value={sessionDetails?.recurrenceCount}
                            onChange={(e) => onChangeSessionDetailField('recurrenceCount', e.target.value)} /> */}
                        <div className='antd-select-type'>
                            <Select
                                className='antd-input-type'
                                placeholder='Recurrence Custom Days'
                                options={RecurrenceCustomDays}
                                value={sessionDetails?.recurrenceCustomDays}
                                defaultValue={sessionDetails?.recurrenceCustomDays}
                                onChange={(e) => onChangeSessionDetailField('recurrenceCustomDays', e)}
                                mode='multiple'
                                showSearch={false}
                            />
                        </div>
                        {error?.key === 'recurrenceCustomDays' && <span className='error-text'>{error.error}</span>}
                    </div>
                    <div className='column'>
                        <span className='label'>Recurrence Type <div className='start'>*</div></span>
                        <div className='antd-select-type'>
                            <Select
                                className='antd-input-type'
                                placeholder='Recurrence type'
                                options={RecurrenceType}
                                value={'custom'}
                                defaultValue={'custom'}
                                // value={sessionDetails?.recurrenceType}
                                // defaultValue={sessionDetails?.recurrenceType}
                                onChange={(e) => onChangeSessionDetailField('recurrenceType', e)}
                                disabled
                            />
                        </div>
                        {error?.key === 'recurrenceType' && <span className='error-text'>{error.error}</span>}
                    </div>
                </div>
            </div>

            <div className='exercise-details-wrapper'>
                <div className='header'>
                    <div className='exercise'>Exercise <div className='start'>*</div></div>
                    <div className='prescribed'>Reps <div className='start'>*</div></div>
                    <div className='prescribed'>Sets <div className='start'>*</div></div>
                    <div className='prescribed'>Time <div className='exp'>(min)</div><div className='start'>*</div></div>
                    <div className='prescribed'>Add/Remove</div>
                </div>
                <div className='body'>
                    {planDetails.map((plan: PlanDetails, index: number) => (
                        <div key={index} className='exercise-list'>
                            <div className='antd-exercise-input'>
                                <Select
                                    placeholder='Select exercise'
                                    options={exerciseList.filter(el => !planDetails.map(pd => pd.exerciseId).includes(el.exercise_id))}
                                    value={exerciseList.find(ep => ep.exercise_id === plan.exerciseId)?.exercise_name}
                                    defaultValue={exerciseList.find(ep => ep.exercise_id === plan.exerciseId)?.exercise_name}
                                    onChange={(e) => onChangePlanDetailField('exerciseId', e, index)}
                                    showSearch
                                    filterOption={(input: any, option: any) =>
                                        option.exercise_name.toLowerCase().includes(input.toLowerCase())
                                    }
                                />
                                {(planError?.index === index && planError?.key === 'exerciseId') && <span className='error-text'>{planError.error}</span>}
                            </div>
                            <div className='antd-prescribed-input'>
                                <Input
                                    className='antd-prescribed-input-type'
                                    placeholder='Reps'
                                    type='number'
                                    value={plan.repsPrescribed}
                                    onChange={(e) => onChangePlanDetailField('repsPrescribed', e.target.value, index)}
                                    disabled={needToDisableReps.includes(plan.exerciseId)}
                                />
                                {(planError?.index === index && planError?.key === 'repsPrescribed') && <span className='error-text'>{planError.error}</span>}
                            </div>
                            <div className='antd-prescribed-input'>
                                <Input
                                    className='antd-prescribed-input-type'
                                    placeholder='Sets'
                                    type='number'
                                    value={plan.setsPrescribed}
                                    onChange={(e) => onChangePlanDetailField('setsPrescribed', e.target.value, index)} />
                                {(planError?.index === index && planError?.key === 'setsPrescribed') && <span className='error-text'>{planError.error}</span>}
                            </div>
                            <div className='antd-prescribed-input'>
                                <Input
                                    className='antd-prescribed-input-type'
                                    placeholder='Time'
                                    type='number'
                                    value={plan.timePrescribed}
                                    onChange={(e) => onChangePlanDetailField('timePrescribed', e.target.value, index)} />
                                {(planError?.index === index && planError?.key === 'timePrescribed') && <span className='error-text'>{planError.error}</span>}
                            </div>
                            <div className='antd-prescribed-input' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                <Tooltip title='add'>
                                    <Button shape='circle' icon={<PlusOutlined />} onClick={onPressAddIcon} />
                                </Tooltip>
                                <Tooltip title='remove'>
                                    <Button shape='circle' icon={<MinusOutlined />} onClick={() => onPressMinusIcon(index)} />
                                </Tooltip>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className='buttons-wrapper'>
                <Button className='buttons' disabled={loading} onClick={showCloseConfirm}>
                    Cancel
                </Button>
                <Button className='buttons' loading={loading} onClick={onPressAddPlan} disabled={loading}>
                    {!loading && 'Add Plan'}
                </Button>
            </div>
        </div>
    );
};

export default AddPlanModal;