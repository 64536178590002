import React from "react";
import spinner from "../../assets/spinner.svg";
import styles from "./Spinner.module.scss"; // Import the SCSS module

interface Props {
  width?: string;
  height?: string;
}
const Spinner: React.FC<Props> = ({ width = "32px", height = "32px" }) => {
  return (
    <div className={styles["spinner-container"]}>
      <img
        src={spinner}
        alt="Loading..."
        className={styles.spinner}
        width={width}
        height={height}
      />
    </div>
  );
};

export default Spinner;
