import React, { useState, useEffect } from 'react';
import { Card, Empty, List, message, Switch, Tabs, TimePicker, Input as AntdInput, Popover } from 'antd';
import Input from '../../../atoms/Input/Input';
import { InfoCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import API from '../../../services/rest/api';
import Loader from '../../../atoms/Loader';

import './index.scss';
import { NUTRIENT_MESSAGE } from '../../../constants/diet';

interface ViewDietPlanTemplateProps {
    templateId: string | null;
}

const { FAT_INFO, CARBS_INFO, PROTEIN_INFO } = NUTRIENT_MESSAGE;

const ViewDietPlanTemplate: React.FC<ViewDietPlanTemplateProps> = (props) => {

    const { templateId } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [templateData, setTemplateData] = useState<any>(null);

    const fetchTemplateDataById = async (templateId: string) => {
        try {
            setLoading(true);
            const templateData: any = await API.GetDietTemplateByID(templateId);
            if (templateData.code !== 1) throw new Error(templateData.msg || 'Internal server error!');
            const modifiedData = modifyResponse(templateData.data || {});
            setTemplateData(modifiedData);
        } catch (error) {
            console.log('Error fetching template date:', error);
            message.error({ content: 'Failed to fetch template data!' });
        } finally {
            setLoading(false);
        }
    };

    const modifyResponse = (data: any) => {
        try {
            const response: any = {};
            response.id = data._id;
            response.header = setHeaderData(data);
            response.nutrients = setNutrientData(data);
            response.tabs = setTebData(data?.meals_data || []);
            return response;
        } catch (error) {
            console.log('Error while modify template data:', error);
            message.error({ content: 'Error while modify template data!' });
        }
    };

    const setHeaderData = (data: any) => {
        return [
            { title: 'Template Name', content: data?.template_name },
            { title: 'Total Calories Goal', content: data?.total_calories },
            { title: 'Actual Calories', content: data?.actual_calories },
        ];
    };

    const setNutrientData = (data: any) => {
        let actualFat = 0;
        let actualCarbs = 0;
        let actualProtein = 0;
        data?.meals_data?.forEach((meal: any) => {
            let validOptionsCount = 0;
            let mealTotalCarbs = 0;
            let mealTotalProtein = 0;
            let mealTotalFats = 0;

            meal.options.forEach((option: any) => {
                if (parseFloat(option.meal_total_calories) > 0) {
                    validOptionsCount++;
                }
                mealTotalCarbs += option.meal_total_carbs;
                mealTotalProtein += option.meal_total_proteins;
                mealTotalFats += option.meal_total_fats;
            })
            actualCarbs += (mealTotalCarbs / (validOptionsCount || 1));
            actualProtein += (mealTotalProtein / (validOptionsCount || 1));
            actualFat += (mealTotalFats / (validOptionsCount || 1));
        })
        return [
            { title: "Protein Percentage(%)", percentage: data.proteins, actual: actualProtein.toFixed(3), goal: calculateActualNutrientInGram(data, 'protein'), infoMessage: PROTEIN_INFO },
            { title: "Fat Percentage(%)", percentage: data.fat, actual: actualFat.toFixed(3), goal: calculateActualNutrientInGram(data, 'fat'), infoMessage: FAT_INFO },
            { title: "Carbs Percentage(%)", percentage: data.carbs, actual: actualCarbs.toFixed(3), goal: calculateActualNutrientInGram(data, 'carbs'), infoMessage: CARBS_INFO }
        ]

    }

    const calculateActualNutrientInGram = (data: any, key: string) => {
        const totalCalories = parseFloat(data?.total_calories || '0');
        return key === 'protein' ? Number(((parseFloat(data?.proteins || '0') * totalCalories) / (4 * 100)).toFixed(3)) :
            key === 'carbs' ? Number(((parseFloat(data?.carbs || '0') * totalCalories) / (4 * 100)).toFixed(3)) :
                key === 'fat' ? Number(((parseFloat(data?.fat || '0') * totalCalories) / (9 * 100)).toFixed(3)) :
                    0
    };


    const setTebData = (mealsData: any[]) => {
        return mealsData.filter(meal => meal.options.length);
    };

    const renderNutrient = (data: any) => {
        return (
            <div className='nutrient_inputs' >
                <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: "space-between" }}>
                    <Card className='custom-header-card' title={data.title}>{data.percentage}</Card>
                    <Popover
                        content={<div style={{ width: '250px', margin: '0px !important' }}>
                            <div dangerouslySetInnerHTML={{ __html: data.infoMessage?.replace(/\n/g, '<br>') }}></div>
                        </div>}
                        trigger={'hover'}
                        style={{ padding: '0px !important' }}
                    >
                        <InfoCircleOutlined style={{ cursor: 'pointer', opacity: "0.5" }} />
                    </Popover>
                </div>
                <div style={{
                    display: "flex",
                    width: "100%",
                    gap: "10px"
                }}>
                    <div style={{ marginTop: "10px", display: "flex", flexDirection: "column", width: "48%" }}>
                        <label className='custom_label'>Goal</label>
                        <AntdInput
                            disabled
                            value={data.goal}
                            style={{ width: '94%', color: "#760fb2" }} />
                    </div>
                    <div style={{ marginTop: "10px", display: "flex", flexDirection: "column", width: "48%" }}>
                        <label className='custom_label'>Actual</label>
                        <AntdInput
                            disabled
                            value={data.actual}
                            style={{ width: '94%', color: "#760fb2" }} />
                    </div>
                </div>
            </div>
        )
    }
    const renderOptions = (option: any) => {
        const keyMap: any = {
            meal_total_calories: 'Total Calories',
            meal_total_carbs: 'Total Carbs',
            meal_total_proteins: 'Total Protein',
            meal_total_fats: 'Total Fats',
            meal_total_fibers: 'Total Fiber',
            meal_total_sodium: 'Total Sodium',
            meal_total_potassium: 'Total Potassium',
            meal_total_sugar: 'Total Sugar',
            meal_total_fatty_acids: 'Total Fatty Acids',
        };
        const finalTotalData = Object.keys(keyMap).map(key => ({ title: keyMap[key], content: option[key]?.toFixed(2) || 0 }));

        return (
            <div className='option-containers'>
                <div className='tips-container'>
                    <div className='tips-title'>Tips</div>
                    <AntdInput.TextArea
                        disabled
                        style={{ textAlign: 'start', color: 'black', fontSize: '13px', resize: 'none', height: '70px', background: 'light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3))' }}
                        value={option?.tips}
                    />
                </div>
                <div className='total-container'>
                    <List
                        grid={{ gutter: 16, column: 3 }}
                        dataSource={finalTotalData}
                        renderItem={(item: any) => (
                            <List.Item>
                                <Card className='custom-total-card' title={item.title}>{item.content}</Card>
                            </List.Item>
                        )}
                    />
                </div>
                {(option.food_items || []).map((foodItem: any) => {
                    const foodKeyMap: any = {
                        food_name: 'Food Items',
                        quantity: 'Quantity',
                        unit_option_name: 'Measure',
                        protein: 'Protein',
                        carbs: 'Carbs',
                        fats: 'Fats',
                        fiber: 'Fibers',
                        sodium: 'Sodium',
                        fatty_acid: 'Fatty Acids',
                        calories: 'Calories',
                        sugar: 'Sugar',
                        potassium: 'Potassium',
                    };
                    const finalFoodItemData = Object.keys(foodKeyMap).map(key => ({ title: foodKeyMap[key], content: typeof (foodItem[key]) === 'number' ? foodItem[key]?.toFixed(2) || 0 : foodItem[key] }));

                    return <div className='food-items-container'>
                        <List
                            grid={{ gutter: 16, column: 3 }}
                            dataSource={finalFoodItemData}
                            renderItem={(item: any, index) => (
                                <List.Item>
                                    <Card
                                        className='custom-food-itme-card'
                                        style={index === 0
                                            ? { boxShadow: '0 4px 8px rgba(118, 15, 178, 0.5)', whiteSpace: 'nowrap', overflow: 'auto hidden' }
                                            : {}}
                                        title={item.title}>{item.content}
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </div>
                })}
            </div>
        );
    };

    const renderMealData = (mealData: any) => {
        return (
            <div className='meal-data-container'>
                <div className='meal-data-header'>
                    <div className='meal-header-block'>
                        <div className='meal-header-title'>Meal timing</div>
                        <TimePicker value={dayjs(mealData.start_time, 'hh:mm:ss')} disabled /> to
                        <TimePicker value={dayjs(mealData.end_time, 'hh:mm:ss')} disabled />
                    </div>
                    <div className='meal-header-block'>
                        <div className='meal-header-title'>Hide this meal</div>
                        <Switch checked={mealData.hide_meal === 'Y'} disabled />
                    </div>
                </div>
                <div className='option-header'>
                    <Tabs
                        className='option-tab'
                        type='card'
                        items={mealData.options.map((option: any, i: number) => {
                            return {
                                label: 'Option ' + option?.option_no,
                                key: option?.option_no || i,
                                children: renderOptions(option),
                            };
                        })}
                    />
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (!templateId) return;
        fetchTemplateDataById(templateId);
    }, [templateId]);

    return (
        <div>
            {loading
                ? <Loader noBackground />
                : <>{templateData
                    ? <>
                        <List
                            grid={{ gutter: 16, column: 3 }}
                            dataSource={templateData.header}
                            renderItem={(item: any) => (
                                <List.Item className='data-list'>
                                    <Card className='custom-header-card' title={item.title}>{item.content}</Card>
                                </List.Item>
                            )}
                        />
                        <List
                            grid={{ gutter: 16, column: 3 }}
                            dataSource={templateData.nutrients}
                            renderItem={(item: any) => (
                                <List.Item>
                                    {renderNutrient(item)}
                                </List.Item>
                            )}
                        />
                        <hr />
                        <Tabs
                            className='custom-tabs'
                            type='card'
                            items={templateData.tabs.map((mealData: any, i: number) => {
                                return {
                                    label: mealData?.meal_name,
                                    key: mealData.meal_types_id || i,
                                    children: renderMealData(mealData),
                                };
                            })}
                        />
                    </>
                    : <Empty description='Deit Plan Template Result Not Found!' />
                }</>
            }
        </div>
    );
};

export default ViewDietPlanTemplate;