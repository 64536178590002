import React, { useEffect, useState } from "react";
import { Modal, Tooltip } from "antd";
import moment from "moment";
import { AiOutlineCopy } from "react-icons/ai";
import { RiPencilFill } from "react-icons/ri";
import API from "../../services/rest/api";
import './DietPlanList.scss'
import { useNavigate } from "react-router-dom";
import DietAndAdherence from "../Diet&Adherence/Diet&Adherence";
import DietPreference from "../DietPreference/DietPreference";
import BarChart from "../../atoms/Adherence-Graph/Adherence-Graph";
import DietPlanPdf from "../DietPlanPdf/DietPlanPdf";
import DownArrow from '../../assets/icons/downArrow.png'
import UpArrow from '../../assets/icons/upArrow.png'
import { toast } from "react-toastify";
import DietList from "../../atoms/Diet/DietList/DietList";
import { DIET_STATUS } from "../../constants/diet";

type DietPlanListProps = {
    patient_id: any,
    selectedDate: any,
    selectedFilter: any,
    dietListShow?: any,
    downView?: any,
    userDetails?: any
}

const DietPlanList: React.FC<DietPlanListProps> = ({ patient_id, selectedDate, selectedFilter, dietListShow, downView, userDetails }) => {
    const navigate = useNavigate()
    const [dietData, setDietData] = useState<any>([]);
    const [dietDetailModal, setDietDetailModal] = useState<any>(false)
    const [selectedDietData, setSelectedDietData] = useState<any>(null)
    const [showDietPdfModal, setShowDietPdfModal] = useState<any>(false)

    const [selectedDietDate, setSelectedDietDate] = useState<any>(null)

    useEffect(() => {
        fetchData()
    }, [selectedDate, selectedFilter])

    const fetchData = async (date = "") => {
        let payload = {
            patient_id: patient_id,
            ...(date ? { date: date } : {}),
            ...(selectedFilter ? { diet_type: selectedFilter } : {})
        }
        try {
            const data = await API.GetDietPlan(payload);
            if (data.code == 1) {
                const dietData = data?.data;
                setDietData(dietData);
                if(dietDetailModal) {
                    const selectedDietDataTemp = dietData?.filter((dietData: any) => dietData?.diet_plans_id === selectedDietData?.diet_plans_id)
                    if(selectedDietDataTemp?.length > 0) setSelectedDietData(selectedDietDataTemp[0]);

                }
            } else if (data.code == 2) {
                setDietData([])
            }
        } catch (error) {
            // Handle errors
            console.error("Error while fetching data", error);
        }
    };

    //funtion to Edit Diet Plan
    const editDietPlan = (data: any) => {
        const dietPlan = {
            ...data,
            diet_plan_meal_rel: data?.diet_plan_meal_rel?.map((meal: any) => {
                return {
                    ...meal,
                    meal_options: meal.meal_options.map((option: any) => {
                        return {
                            ...option,
                            option_data: option.option_data.filter((item: any) =>
                                // item.hide_meal === "N" &&
                                (item.is_food_item_added_by_patient === "N" || !item.hasOwnProperty('is_food_item_added_by_patient'))
                            )
                        };
                    })
                };
            })
        };
        navigate('/add-change-diet', { state: { data: dietPlan, button: 'edit' } })
    }

    //function to Copy Diet
    const copyDietPlan = async (data: any) => {
        // diet_plan_id: data?.diet_plans_id
        const dietPlan = {
            ...data,
            diet_plan_meal_rel: data?.diet_plan_meal_rel?.map((meal: any) => {
                return {
                    ...meal,
                    meal_options: meal.meal_options.map((option: any) => {
                        return {
                            ...option,
                            option_data: option.option_data.filter((item: any) =>
                                // item.hide_meal === "N" &&
                                (item.is_food_item_added_by_patient === "N" || !item.hasOwnProperty('is_food_item_added_by_patient'))
                            )
                        };
                    })
                };
            })
        };
        navigate('/add-change-diet', { state: { data: dietPlan, button: 'copy', userDetails: userDetails } })
    }

    //function to handle on click view diet
    const onViewDietClick = async (data: any) => {
        setSelectedDietData(data)
        setSelectedDietDate(data?.start_date)
        fetchData(data?.start_date)
        setDietDetailModal(true)
    }

    const onDietDetailsModalClose = () => {
        setDietDetailModal(false);
        setSelectedDietDate(null);
        fetchData();
    }

    //function to handle date change inside diet
    const onChangeDate = async (date: any) => {
        setSelectedDietDate(date)
        fetchData(date)
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {dietData.map((data: any, idx: any) => (
                <div className="diet-list-data" key={`diet-list-item-${idx}`}>
                    <div className="diet-list-data-header">
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <div className="diet-list-data-heading">
                                <div style={{ display: 'flex', gap: '10px', textAlign: 'center', alignItems: 'center' }}>
                                    <div className="diet-list-data-title">{data?.diet_name || data?.plan_name}</div>
                                    {data?.status === DIET_STATUS.PENDING ?
                                        <span style={{ color: 'orange' }}>{'(Pending)'}</span> :
                                        data?.status === DIET_STATUS.APPROVED ?
                                            <span style={{ color: 'green' }}>{'(Approved)'}</span> :
                                            data?.status === DIET_STATUS.DRAFT ?
                                                <span style={{ color: 'blue' }}>{'(Draft)'}</span> :
                                                <span style={{ color: 'red' }}>{'(Rejected)'}</span>
                                    }
                                </div>
                                <div className="diet-list-data-date">
                                    {moment(data.start_date).format('LL')} - {moment(data.end_date).format('LL')}
                                </div>
                            </div>
                            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                {data?.status !== DIET_STATUS.PENDING &&
                                    <>
                                        <div
                                            className="view-diet-btn"
                                            onClick={() => {
                                                onViewDietClick(data)
                                            }}
                                        >
                                            View Diet
                                        </div>
                                    <div
                                        className="view-diet-btn"
                                        onClick={() => {
                                            const dietPlan = {
                                                ...data,
                                                diet_plan_meal_rel: data?.diet_plan_meal_rel?.map((meal: any) => {
                                                    return {
                                                        ...meal,
                                                        meal_options: meal.meal_options.map((option: any) => {
                                                            return {
                                                                ...option,
                                                                option_data: option.option_data.filter((item: any) =>
                                                                    // item.hide_meal === "N" &&
                                                                    (item.is_food_item_added_by_patient === "N" || !item.hasOwnProperty('is_food_item_added_by_patient'))
                                                                )
                                                            };
                                                        })
                                                    };
                                                })
                                            };
                                            setShowDietPdfModal(true);
                                            setSelectedDietData(dietPlan);
                                        }}
                                    >
                                        View Pdf
                                    </div>
                                    </>
                                }
                                <Tooltip placement="topLeft" title={'Click to Copy Diet'}>
                                    <AiOutlineCopy
                                        style={{ color: '#760fb2', cursor: 'pointer', height: '20px', width: '20px' }}
                                        onClick={() => copyDietPlan(data)}
                                    />
                                </Tooltip>
                                {(data?.status === DIET_STATUS.PENDING || data?.status === DIET_STATUS.DRAFT) &&
                                    <Tooltip placement="topLeft" title={'Click to Change Diet'}>
                                        <RiPencilFill
                                            style={{ color: '#760fb2', cursor: 'pointer', height: '20px', width: '20px' }}
                                            onClick={() => editDietPlan(data)}
                                        />
                                    </Tooltip>
                                }

                                {downView &&
                                    <>
                                        {!data?.show_details ?
                                            <img
                                                src={DownArrow}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    const tempDiet = [...dietData]
                                                    tempDiet[idx].show_details = true
                                                    setDietData(tempDiet)
                                                }}
                                            />
                                            :   <img
                                                    src={UpArrow}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        const tempDiet = [...dietData]
                                                        tempDiet[idx].show_details = false
                                                        setDietData(tempDiet)
                                                    }}
                                                />
                                        }
                                    </>
                                }

                            </div>
                        </div>
                        {data?.chief_remarks &&
                            <div style={{ display: 'flex', marginTop: '15px', width: '100%' }}>
                                <div style={{ color: '#8129B9', fontWeight: '700', fontSize: '15px' }}>Chief Remarks :-</div>
                                <div style={{ marginLeft: '15px', fontWeight: '600', fontSize: '15px' }}>{data?.chief_remarks}</div>
                            </div>
                        }
                    </div>

                    {data?.show_details === true &&
                        <div style={{ marginTop: '25px' }}>
                            {data?.diet_plan_meal_rel &&
                                !!data?.diet_plan_meal_rel.length &&
                                data?.diet_plan_meal_rel.map((meal: any) => (
                                    meal?.hide_meal == 'N' || !meal?.hide_meal) &&
                                    <div>
                                        <span className="meal-name">{meal?.meal_type}</span>
                                        {meal.meal_options &&
                                            !!meal.meal_options.length &&
                                            meal.meal_options.map((mealOption: any) => 
                                                <DietList data={mealOption}/>
                                        )}
                                    </div>
                            )}
                        </div>
                    }
                </div>
            ))}

            <Modal
                centered
                open={dietDetailModal}
                footer={null}
                onCancel={onDietDetailsModalClose}
                width={'90%'}
                style={{ height: '500px', marginTop: '20px' }}
                zIndex={10000}
            >
                <div className="diet-grid-component">
                    <DietAndAdherence
                        selectedDietData={selectedDietData}
                        selectedDietDate={selectedDietDate}
                        onChangeDate={onChangeDate}
                    />
                    <DietPreference />
                    <div>
                        <BarChart patient_id={patient_id} />
                    </div>
                </div>
            </Modal>

            <Modal
                centered
                open={showDietPdfModal}
                footer={null}
                onCancel={() => setShowDietPdfModal(false)}
                width={'90%'}
                style={{ height: '500px', marginTop: '20px' }}
                zIndex={10000}
            >
                <div>
                    <DietPlanPdf
                        dietData={selectedDietData}
                        userDetails={userDetails}
                    />
                </div>
            </Modal>
        </div>

    )
}

export default DietPlanList;