
import React,{useState} from 'react';

interface ModalProps {
    show: boolean;onConfirmation: (action: boolean, text?: string) => void
    onClose: () => void;
}

const RequestToUpload: React.FC<ModalProps> = ({ show, onConfirmation, onClose }) => {
    const [text, setText] = useState('');

    const handleConfirm = (action: boolean) => {
        if (action) {
            onConfirmation(true, text);
        } else {
            onConfirmation(false);
        }
    }
    
    return (
        <div>
            {show && (
                <div className="modal">
                    <div className="close-btn" onClick={onClose}><span>X</span></div>
                    <div className="modal-content">
                        <div>
                            <p>Write a reason to to reject this document?</p>
                        </div>
                        <textarea
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            placeholder="Enter your text here..."
                        />
                        <div className="button-container">
                            <button className="modal-btn" onClick={() => handleConfirm(true)}>Submit</button>
                            <button className="modal-btn" onClick={() => handleConfirm(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RequestToUpload;
