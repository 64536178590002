import React, { FC, useEffect, useState } from "react";
import './DietPreference.scss';
import API from "../../services/rest/api";
import Input from "../../atoms/Input/Input";
import { RiDeleteBin6Line } from "react-icons/ri";

type AllergiesProps = {

}

type PatientAllergyObj = {
    created_at: string
    description: string
    is_active: string
    is_deleted: string
    patient_allergies_id: string
    patient_id: string
    updated_at: string
    updated_by: string
}[]

type PatientPreferenceObj = {
    created_at: string
    description: string
    is_active: string
    is_deleted: string
    patient_allergies_id: string
    patient_id: string
    updated_at: string
    updated_by: string
}[]

const DietPreference: FC<AllergiesProps> = () => {

    const [patientAllergies, setPatientAllergies] = useState<PatientAllergyObj>([])
    const [patientPreference, setPatientPreference] = useState<PatientPreferenceObj>([])

    const [allergy, setAllergy] = useState<any>('')
    const [preference, setPreference] = useState<any>('')

    useEffect(() => {
        getPatientAllergies()
        getPatientPreference()
    }, [])

    const getPatientAllergies = async () => {
        try {
            let payload = {
                patient_id: localStorage.getItem('patient_id')
            }
            const data = await API.GetPatientAllergies(payload)
            if (data?.code == 1) {
                setPatientAllergies(data.data)
            }
            else {
                setPatientAllergies([])
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getPatientPreference = async () => {
        try {
            let payload = {
                patient_id: localStorage.getItem('patient_id')
            }
            const data = await API.GetPatientPreference(payload)
            if (data?.code == 1) {
                setPatientPreference(data.data)
            }
            else {
                setPatientPreference([])
            }
        } catch (error) {
            console.log(error)
        }
    }

    const addAllergyPreference = async (type: any) => {
        try {
            if (type == 'allergy') {
                let payload = {
                    patient_id: localStorage.getItem('patient_id'),
                    description: allergy.trim()
                }

                if (payload?.description) {
                    const data = await API.AddPatientAllergy(payload)
                    if (data?.code == 1) {
                        getPatientAllergies()
                        setAllergy('')
                    }
                }
            }
            else {
                let payload = {
                    patient_id: localStorage.getItem('patient_id'),
                    description: preference.trim()
                }

                if (payload?.description) {
                    const data = await API.AddPatientPreference(payload)
                    if (data?.code == 1) {
                        getPatientPreference()
                        setPreference('')
                    }
                }
            }

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="diet-allergy-container">
            <span className="diet-adherence-heading">
                Diet Preference, and Allergies
            </span>

            <div className="diet-adherence-list">
                <span className='diet-adherence-title'>Allergies</span>
                <div className="diet-allergy-list">
                    {
                        (patientAllergies || []).map((allergy) => {
                            return (
                                <ul className="allergy-list">
                                   <li>{allergy.description}</li>
                                </ul>
                            )
                        })
                    }

                    <div className="diet-allergy-input">
                        <input
                            placeholder="Type allergy"
                            type="text"
                            name="allergy"
                            id="allergy"
                            className="allergy-input"
                            onChange={(e) => {
                                setAllergy(e.target.value)
                            }}
                            value={allergy}
                            maxLength={50}
                        />
                        <div className={(allergy.trim() !== '') ? 'add-allergy-button' : 'add-allergy-button-disable'} onClick={() => addAllergyPreference('allergy')}>
                            + Add
                        </div>
                    </div>
                </div>
            </div>

            <div className="diet-adherence-list">
                <span className='diet-adherence-title'>Preference</span>
                <div className="diet-allergy-list">
                    {
                        (patientPreference || []).map((pref) => {
                            return (
                                <ul>
                                    {pref.description && <li>{pref.description}</li>}
                                </ul>
                            )
                        })
                    }

                    <div className="diet-allergy-input">
                        <input
                            placeholder="Type preference"
                            type="text"
                            name="preference"
                            id="preference"
                            className="allergy-input"
                            onChange={(e) => {
                                setPreference(e.target.value)
                            }}
                            value={preference}
                            
                        />
                        <div className={(preference.trim() !== '') ? 'add-allergy-button' : 'add-allergy-button-disable'}onClick={() => addAllergyPreference('preference')}>
                            + Add
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DietPreference