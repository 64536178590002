import React, { FC } from "react";
import './Input.scss';

type InputProps = {
    label?: string;
    placeholder: string;
    type: string;
    name: string;
    id: string;
    value?: any | string | number;
    // formik?: any;
    // field?: string;
    onChange?: any;
    // index?: number;
    required?: any,
    disabled?: boolean,
    onKeyDown?: any,
    className?: any
}

const Input: FC<InputProps> = props => {
    const { label, placeholder, type, id, name, value, onChange, required, disabled, onKeyDown, className } = props;
    return (
        <div className="search_bar">
            <div className="label">{label}{required && <span style={{ color: 'red', fontSize: '16px' }}>*</span>}</div>
            <input
                className={className ? className : "search-input"}
                placeholder={placeholder}
                name={name}
                type={type}
                id={id}
                // disabled={name === 'url' || name === "user_email" || name === "calendar" || name === 'guest'  ? true : false}
                value={value}
                onChange={onChange
                    // if (name == 'search-bar' || name === 'modifiers' || name === "s-name" || name === "s-url") {
                    //     onChange(e.target.value)
                    // } else if (name === 'admin-name' || name === 'admin-email') {
                    //     onChange(e.target.value, index)
                    // } else {
                    //     formik.setFieldValue(field, e.target.value, false)
                    //     formik.setFieldError(field, '', false)
                    // }
                }
                // onWheel={(e:any) => e.target.blur()}
                disabled={disabled}
                onKeyDown={onKeyDown}
                onWheel={(e: any) => e.target.blur()}
            />
        </div>
    )
}

export default Input;