export const mealTypes = [
    {
        meal_types_id: "8890099d-37e4-11ec-ae06-4ec4eb4dc0dd",
        value: "8890099d-37e4-11ec-ae06-4ec4eb4dc0dd",
        meal_type: "Breakfast",
        label: "Breakfast",
        keys: "breakfast",
        default_time: "08:00:00",        
        order_no: 1,
    },
    {
        meal_types_id: "8ccb64e7-37e4-11ec-ae06-4ec4eb4dc0dd",
        value: "8ccb64e7-37e4-11ec-ae06-4ec4eb4dc0dd",
        meal_type: "Morning Snack",
        label: "Morning Snack",
        keys: "morning_snacks",
        default_time: "11:00:00",        
        order_no: 2,
    },
    {
        meal_types_id: "91210cf6-37e4-11ec-ae06-4ec4eb4dc0dd",
        value: "91210cf6-37e4-11ec-ae06-4ec4eb4dc0dd",
        meal_type: "Lunch",
        label: "Lunch",
        keys: "lunch",
        default_time: "14:00:00",        
        order_no: 3,
    },
    {
        meal_types_id: "955ad3c4-37e4-11ec-ae06-4ec4eb4dc0dd",
        value: "955ad3c4-37e4-11ec-ae06-4ec4eb4dc0dd",
        meal_type: "Evening Snack",
        label: "Evening Snack",
        keys: "evening_snacks",
        default_time: "17:00:00",        
        order_no: 4,
    },
    {
        meal_types_id: "994d3bcb-37e4-11ec-ae06-4ec4eb4dc0dd",
        value: "994d3bcb-37e4-11ec-ae06-4ec4eb4dc0dd",
        meal_type: "Dinner",
        label: "Dinner",
        keys: "dinner",
        default_time: "20:00:00",        
        order_no: 5,
    }
]