import { FC, useEffect, useState } from "react"
import './PendingDietReportApprovalList.scss';
import API from "../../services/rest/api";
import { CorrectSign, WrongSign } from "../../assets/icons";
import { Modal } from "antd";
import EyeIcon from '../../assets/icons/eyeIcon.png'
import AddDietContainer from "../../organisms/AddDietPlan/AddDietPlan";
import DownloadIcon from '../../assets/icons/download_icon.png'

type PendingDietReportApprovalProps = {
    selectedPatientId?: any
}

const PendingDietReportApprovalList: FC<PendingDietReportApprovalProps> = ({ selectedPatientId }) => {

    const [pendingReportList, setPendingReportList] = useState<any>([])
    const [openApprovalModal, setOpenApprovalModal] = useState<any>({ show: false, type: null, selectedReport: null })
    const [showDietModal, setShowDietModal] = useState<any>(false)
    const [selectedDietPlan, setSelectedDietPlan] = useState<any>(null)
    const [chiefRemarks, setChiefRemarks] = useState<any>('')
    const patient_id = localStorage.getItem('patient_id')

    useEffect(() => {
        getPendingReportList()
    }, [])

    useEffect(() => {
        getPendingReportList()
    }, [selectedPatientId])

    //function to get pending report list
    const getPendingReportList = async () => {
        try {
            let payload: { report_type: string, patient_id?: string | null } = {
                report_type: 'diet'
            };

            if (selectedPatientId) {
                payload = {
                    ...payload,
                    patient_id: patient_id || null
                }
            }
            const pendingReportData = await API.GetPendingApprovalReports(payload)
            if (pendingReportData.code === '1') {
                setPendingReportList(pendingReportData?.data)
            }
            else {
                setPendingReportList([])
            }
        } catch (error) {
            console.log(error)
        }
    }

    //funtion to handle approve-reject reports by chief-hc
    const chiefBtnHandle = async (type: any) => {
        try {
            let payload = {
                patient_healthcoach_report_id: openApprovalModal?.selectedReport?.patient_healthcoach_report_id,
                status: type === 'approve' ? 'Approved' : 'Rejected',
                report_details: { ...openApprovalModal?.selectedReport },
                chief_remarks: chiefRemarks
            }
            const data = await API.UpdateReportStatus(payload)
            if (data?.code === '1') {
                setOpenApprovalModal({ show: false, type: null, selectedDiet: null })
                getPendingReportList()
                setChiefRemarks('')
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="pending-plan-approval-list">
            <div style={{ color: 'rgb(118,15,178)', fontSize: '20px', fontWeight: '700', marginBottom: '20px' }}>Pending Diet Reports For Approval</div>
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                    <tr style={{ background: '#F0E7F7' }}>
                        <th style={{ fontWeight: '500', padding: '10px 0', fontSize: '12px' }}>No.#</th>
                        <th style={{ fontWeight: '500', padding: '10px 0', fontSize: '12px' }}>Health Coach Name</th>
                        <th style={{ fontWeight: '500', padding: '10px 0', fontSize: '12px' }}>patient Name</th>
                        <th style={{ fontWeight: '500', padding: '10px 0', fontSize: '12px' }}>Report Name</th>
                        <th style={{ fontWeight: '500', padding: '10px 0', fontSize: '12px' }}>Creation Date</th>
                        <th style={{ fontWeight: '500', padding: '10px 0', fontSize: '12px' }}>Start Date</th>
                        <th style={{ fontWeight: '500', padding: '10px 0', fontSize: '12px' }}>Expiry Date</th>
                        <th style={{ fontWeight: '500', padding: '10px 0', fontSize: '12px' }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        pendingReportList?.map((data: any, idx: any) => {
                            return (
                                <tr style={{ borderBottom: '1px solid #F1F1FC' }}>
                                    <td style={{ textAlign: 'center', padding: '20px 0', fontSize: '12px' }}>{idx + 1}</td>
                                    <td style={{ textAlign: 'center', padding: '20px 0', fontSize: '12px' }}>{data?.health_coach_details?.first_name} {' '} {data?.health_coach_details?.last_name}</td>
                                    <td style={{ textAlign: 'center', padding: '20px 0', fontSize: '12px' }}>{data?.patient_details?.name}</td>
                                    <td style={{ textAlign: 'center', padding: '20px 0', fontSize: '12px' }}>{data?.document_title}</td>
                                    <td style={{ textAlign: 'center', padding: '20px 0', fontSize: '12px' }}>{data?.created_at}</td>
                                    <td style={{ textAlign: 'center', padding: '20px 0', fontSize: '12px' }}>{data?.start_date}</td>
                                    <td style={{ textAlign: 'center', padding: '20px 0', fontSize: '12px' }}>{data?.end_date}</td>
                                    <td style={{ textAlign: 'center', padding: '20px 0', fontSize: '12px' }}>
                                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'center' }}>
                                            <a target="_blank" href={data?.document_url} style={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={DownloadIcon} alt="download_icon" width={20} height={20} />
                                            </a>
                                            {data?.status === 'Pending' ?
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <CorrectSign style={{ cursor: 'pointer' }} onClick={() => setOpenApprovalModal({ show: true, type: 'approve', selectedReport: data })} />
                                                    <WrongSign style={{ cursor: 'pointer' }} onClick={() => setOpenApprovalModal({ show: true, type: 'reject', selectedReport: data })} />
                                                </div>
                                                :
                                                <div style={{ color: `${data?.status === 'Approved' ? 'green' : 'red'}`, fontWeight: '700' }}>{data?.status === 'Approved' ? 'Approved' : 'Rejected'}</div>
                                            }
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>

            <Modal
                centered
                open={openApprovalModal?.show}
                footer={null}
                onCancel={() => {
                    setOpenApprovalModal({ show: false, type: null, selectedDiet: null })
                    setChiefRemarks('')
                }}
                width={'35%'}
                style={{ height: '500px', marginTop: '20px' }}
                zIndex={10000}
            >
                <div style={{ margin: '10px auto', fontWeight: '600', fontSize: '16px', textAlign: 'center' }}>Do you Sure you want to {`${openApprovalModal?.type === 'approve' ? 'Approve' : 'Reject'}`} this report?</div>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px', marginBottom: '10px' }}>
                    <div style={{ display: 'flex' }}>
                        <span>Notes :</span>
                        <textarea name='chief_remarks' value={chiefRemarks} onChange={(e) => setChiefRemarks(e.target.value)} style={{ resize: 'none', border: '1px solid gray', borderRadius: '5px', width: '80%', height: '100px', outline: 'none', marginLeft: '5px' }} />
                    </div>
                    <div className="save-items-button">
                        <button className="save-draft-button" onClick={() => chiefBtnHandle(openApprovalModal?.type)}>
                            Confirm
                        </button>
                        <button className="preview-assign-btn" 
                        onClick={() => {
                            setOpenApprovalModal({ show: false, type: null, selectedDiet: null })
                            setChiefRemarks('')
                        }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>
        </div >
    )
}

export default PendingDietReportApprovalList;