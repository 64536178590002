import { FC, useEffect, useState } from "react";
import MyTatvaLogo from '../../assets/icons/MyTatva_logo.svg'
import './PatientExerciseReport.scss';
import API from "../../services/rest/api";
import { useParams } from "react-router-dom";
import { BloodPressureIcon, BmiIcons, BodyCompositionIcon, DoctorNotesIcon, HealthCoachIcon, HipIcons, LDLIcon, LungsIcon, OtherLifeIcon, ProfileIcon, PusleIcon, SGPTIcons, SPO2Icon, TemperatureIcon, VitalsIcon, WaistIcons, WaistToHipIcons, WeightIcons } from "../../assets/icons";
import { Checkbox, DatePicker, Modal } from "antd";
import moment from "moment";
import LungIcon from '../../assets/icons/lung.png'
import WalkIcon from '../../assets/icons/six_min_walk.png'
import LiverIcon from '../../assets/icons/liver.png'
import KidneyIcon from '../../assets/icons/kidney.png'
import CardioIcon from '../../assets/icons/cardiology.png'
import CaloriesBurnIcon from '../../assets/icons/calories_burned.png'
import AccessbilityIcon from '../../assets/icons/accessibility.png'
import dayjs from "dayjs";
import PatientExerciseReportView from "../PatientExerciseReportView/PatientExerciseReportView";
import ReactSelect from "react-select";
import PdfIcon from '../../assets/icons/pdf_icon.png';
import DownloadIcon from '../../assets/icons/download_icon.png';
import { renderChangedData } from "../../utils/report";
const { RangePicker } = DatePicker;

type PatientExerciseReportProps = {
    reportPeriodDates?: any,
}

const PatientExerciseReport: FC<PatientExerciseReportProps> = () => {
    const params = useParams()
    // const patient_id = params?.patientId
    // const health_coach_id = params?.hcId
    //const userInfo = JSON.parse(localStorage.getItem('userInfo') || '') || {}

    const [userDetails, setUserDetails] = useState<any>(null)
    const [reportPeriodDates, setReportPeriodDates] = useState<any>(null)
    const [patientDetails, setPatientDetails] = useState<any>(null)
    const [reportDetails, setReportDetails] = useState<any>(null)
    const [selectedOptions, setSelectedOptions] = useState<any>({
        patient_profile_notes: 'Congratulations on completing your health diagnosis for the month! This significant milestone demonstrates your commitment to maintaining optimal health and identifying areas for improvement. Well done!'
    });
    const [reportModal, setReportModal] = useState<any>(false)

    const [exerciseListOptions, setExerciseListOptions] = useState<any>([])
    const [selectedExercisePlan, setSelectedExercisePlan] = useState<any>(null)

    const [reportList, setReportList] = useState<any>([]);

    useEffect(() => {
        const patient_id: any = params.id || params?.patientId
        localStorage.setItem('patient_id', patient_id)
        const token: any = params.token
        localStorage.setItem('token', token)
    }, [params])

    useEffect(() => {
        getPatientReports()
        getProfileDetails()
        getReportDetails()
        patientData()
        getAllExercises()
    }, [])

    useEffect(() => {
        getReportDetails()
    }, [reportPeriodDates?.start_date, reportPeriodDates?.end_date])

    const getPatientReports = async () => {
        try {
            const data = await API.GetPatientReport({ patient_id: params.id || params?.patientId, report_type: 'exercise' })
            if (data?.code === '1') setReportList(data?.data ?? [])
            else setReportList([]);
        } catch (error) {
            console.log(error)
        }
    }

    //funtion to get profile details
    const getProfileDetails = async () => {
        try {
            const userInfo = await API.GetProfileDetails()
            if (userInfo?.code === '1') {
                localStorage.setItem('userInfo', JSON.stringify(userInfo?.data))
                setUserDetails(userInfo?.data)
            }
            else {
                setUserDetails(null)
            }
        } catch (error) {

        }
    }

    //funtion to get generate report data
    const getReportDetails = async () => {
        try {
            let payload = {
                patient_id: params?.patientId,
                start_date: reportPeriodDates?.start_date,
                end_date: reportPeriodDates?.end_date,
                report_type: 'exercise'
            }
            const reportData = await API.GetPatientReportData(payload)
            if (reportData?.code === '1') {
                setReportDetails(reportData?.data)
            }
            else {
                console.log(reportData?.message)
            }
        } catch (error) {
            console.log(error)
        }
    }

    //funtion to get patient data
    const patientData = async () => {
        try {
            let payload = {
                patient_id: params?.patientId,
                health_coach_id: params?.hcId
            }
            const patientData = await API.GetPatientDetails(payload)
            setPatientDetails(patientData?.data)
        } catch (error) {
            console.log(error)
        }
    }

    //function to Get All Exercises for patient
    const getAllExercises = async () => {
        try {
            const payload = {
                per_page: 1000,
                patient_id: params?.patientId,
                page: 1,
                approved: "Y"
            }
            const allExercises = await API.GetExerciseList(payload)

            if (allExercises?.code === "1") {
                const tempExercises = allExercises?.data?.result?.map((exercise: any) => {
                    return { ...exercise, label: exercise?.exercise_plan_name, value: exercise?.patient_exercise_plans_id }
                })
                setExerciseListOptions(tempExercises)
            }
            else {
                setExerciseListOptions([])
            }
        } catch (error) {
            console.log(error)
        }
    }

    const onChange = (mainType: any, type: any) => {

        // Check if mainType exists in the initialData and if type exists within it
        if (reportDetails && reportDetails[mainType] && reportDetails[mainType][type]) {
            const updatedSelectedOptions = { ...selectedOptions };

            // Toggle the selection status of the option
            if (!updatedSelectedOptions[mainType]) {
                updatedSelectedOptions[mainType] = {};
            }

            const isOptionSelected = updatedSelectedOptions[mainType][type];

            if (!isOptionSelected) {
                updatedSelectedOptions[mainType][type] = reportDetails[mainType][type];
            } else {
                delete updatedSelectedOptions[mainType][type];
                if (Object.keys(updatedSelectedOptions[mainType]).length === 0) {
                    delete updatedSelectedOptions[mainType];
                }
            }
            setSelectedOptions(updatedSelectedOptions);
        }

        if (mainType === 'data_points') {
            const updatedSelectedOptions = { ...selectedOptions };
            const dataPoints = updatedSelectedOptions[mainType] || [];
            const index = dataPoints.findIndex((dp: any) => dp.question === type.question);

            if (index !== -1) {
                dataPoints.splice(index, 1);
            } else {
                dataPoints.push(type);
            }
            dataPoints.sort((a: any, b: any) => a.order_no - b.order_no);
            updatedSelectedOptions[mainType] = dataPoints;
            setSelectedOptions(updatedSelectedOptions);
        }
    };

    const onChangeFirstObj = (mainType: any) => {
        const updatedSelectedOptions = { ...selectedOptions };

        if (!updatedSelectedOptions[mainType]) {
            updatedSelectedOptions[mainType] = reportDetails[mainType];
        } else {
            delete updatedSelectedOptions[mainType];
        }
        setSelectedOptions(updatedSelectedOptions);
    };

    const onChangeForArrayVitals = (data: any) => {
        const updatedSelectedOptions = { ...selectedOptions };

        if (!updatedSelectedOptions.vitals_summary) {
            updatedSelectedOptions.vitals_summary = {
                other_vitals: [data],
            };
            setSelectedOptions(updatedSelectedOptions);
        } else {
            const otherVitals = updatedSelectedOptions.vitals_summary.other_vitals || [];
            const index = otherVitals.findIndex((v: any) => v.reading_name === data?.reading_name);

            if (index === -1) {
                updatedSelectedOptions.vitals_summary.other_vitals = [...otherVitals, data];
                setSelectedOptions(updatedSelectedOptions);
            } else {
                otherVitals.splice(index, 1);
                if (otherVitals.length === 0) {
                    delete updatedSelectedOptions.vitals_summary.other_vitals;

                    if (Object.keys(updatedSelectedOptions.vitals_summary).length === 0) {
                        delete updatedSelectedOptions.vitals_summary;
                    }
                }
                setSelectedOptions(updatedSelectedOptions);
            }
        }
    };


    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '33px',
            boxShadow: state.isFocused ? null : null,
            borderRadius: '5px',
            width: '200px',
            cursor: 'pointer',
            border: '1px solid #7E22B7',
            color: '#7E22B7'
        }),

        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            padding: '3px 10px',
            fontSize: '12px',
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: (state: any) => ({
            display: 'none',
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
        }),
        menuPortal: (base: any) => ({
            ...base,
            fontSize: '8px'
        })
    };

    const renderInItData = (num: any) => {
        return (typeof num === 'number' && num !== 0) ? Number(num).toFixed(2) : <span style={{ fontSize: '8px', alignSelf: 'center' }}>Data Not Provided</span>;
    };

    const renderAchievedData = (num: any) => {
        return (typeof num === 'number' && num !== 0) ? Number(num).toFixed(2) : <span style={{ fontSize: '8px', alignSelf: 'center' }}>Data Not Provided</span>;
    };

    return (
        <>
            <div style={{ color: '#8129B9', fontSize: '18px', marginTop: '10px', fontWeight: '700', textAlign: 'center' }}>Generate Report</div>

            <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '20px' }}>
                <div style={{ width: '200px' }}>
                    <ReactSelect
                        classNamePrefix="select"
                        name="color"
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 6,
                            colors: {
                                ...theme.colors,
                                primary25: '#F0E7F7',
                                primary: '#760fb2',
                            },
                        })}
                        options={exerciseListOptions}
                        onChange={(selectedOption: any) => {
                            setSelectedExercisePlan(selectedOption)
                            setReportPeriodDates({ start_date: selectedOption?.start_date, end_date: selectedOption?.end_date })
                        }}
                        // value={dietPlanListOptions.find((val: any) => val.value == selectedFilter)}
                        styles={customStyles}
                        placeholder='Select Exercise Plan'
                        isClearable={true}
                    />
                </div>

                <div style={{ width: '400px' }}>
                    <RangePicker
                        onChange={(moment: any, dates: any) => {
                            setReportPeriodDates({ start_date: dates[0], end_date: dates[1] })
                        }}
                        value={[
                            reportPeriodDates?.start_date ? dayjs(reportPeriodDates?.start_date) : null,
                            reportPeriodDates?.end_date ? dayjs(reportPeriodDates?.end_date) : null,
                        ]}
                        disabledDate={(current) => {
                            let customDate = moment().format("YYYY-MM-DD");
                            if (selectedExercisePlan) {
                                return (
                                    (selectedExercisePlan.start_date && current < moment(selectedExercisePlan.start_date, "YYYY-MM-DD").startOf('day')) ||
                                    (selectedExercisePlan.end_date && current > moment(selectedExercisePlan.end_date, "YYYY-MM-DD").endOf('day'))
                                );
                            } else {
                                return current && current > moment(customDate, "YYYY-MM-DD").endOf('day');
                            }
                        }}
                    />

                    {/* <DatePicker
                        value={reportPeriodDates?.start_date ? dayjs(reportPeriodDates?.start_date) : null}
                        format={'YYYY-MM-DD'}
                        onChange={(moment, date) => {
                            setReportPeriodDates({ ...reportPeriodDates, start_date: date })
                        }}
                        disabledDate={(current) => {
                            let customDate = moment().format("YYYY-MM-DD");
                            if (selectedExercisePlan) {
                                return (
                                    (selectedExercisePlan.start_date && current < moment(selectedExercisePlan.start_date, "YYYY-MM-DD").startOf('day')) ||
                                    (selectedExercisePlan.end_date && current > moment(selectedExercisePlan.end_date, "YYYY-MM-DD").endOf('day'))
                                );
                            } else {
                                return current && current > moment(customDate, "YYYY-MM-DD").endOf('day');
                            }
                        }}
                        placeholder="start date"
                    />

                    <DatePicker
                        value={reportPeriodDates?.end_date ? dayjs(reportPeriodDates?.end_date) : null}
                        format={'YYYY-MM-DD'}
                        onChange={(moment, date) => {
                            setReportPeriodDates({ ...reportPeriodDates, end_date: date })
                        }}
                        disabledDate={(current) => {
                            let customDate = moment().format("YYYY-MM-DD");
                            const startMoment = moment(reportPeriodDates?.start_date);

                            if (selectedExercisePlan) {
                                return (
                                    (selectedExercisePlan.start_date && current < moment(selectedExercisePlan.start_date, "YYYY-MM-DD").startOf('day')) ||
                                    (selectedExercisePlan.end_date && current > moment(selectedExercisePlan.end_date, "YYYY-MM-DD").endOf('day')) ||
                                    (startMoment && current < startMoment.startOf('day'))
                                );
                            } else {
                                return current && current > moment(customDate, "YYYY-MM-DD").endOf('day');
                            }
                        }}
                        placeholder="end date"
                    /> */}
                </div>
            </div>

            {(reportPeriodDates?.start_date && reportPeriodDates?.end_date) ?
                <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '10px', fontFamily: 'Poppins' }}>
                    <div className="patient-report-container">
                        <div className="report-header">
                            <div className="report-title">
                                Smart Health Report
                            </div>
                            <div>
                                <img src={MyTatvaLogo} />
                            </div>
                        </div>

                        <div className="patient-details-report">
                            <div className="patient-details-first-row">
                                <div className="patient-profile-sec">
                                    <div className="patient-profile-pic">
                                        {/* <img src={patientDetails?.profile_pic} alt="Profile" /> */}
                                        <ProfileIcon />
                                    </div>
                                    <div className="patient-name-age-container">
                                        <span style={{ fontSize: '14px', fontWeight: '600' }}>{patientDetails?.name}</span>
                                        <span style={{ fontSize: '10px', fontWeight: '400' }}>{patientDetails?.gender === 'M' ? 'Male' : 'Female'} • {patientDetails?.patient_age} yrs</span>
                                    </div>
                                </div>

                                <div className="hc-profile-sec">
                                    <HealthCoachIcon />
                                    <div className="hc-profile-name">
                                        <span style={{ fontWeight: '400', fontSize: '9px' }}>Health Coach</span>
                                        <span style={{ fontWeight: '600', fontSize: '10px' }}>{userDetails?.first_name} {userDetails?.last_name}</span>
                                    </div>
                                    <div style={{ border: '1px solid #4B4AD5', marginLeft: '20px' }}></div>
                                    <div className="hc-profile-name" style={{ marginLeft: '10px' }}>
                                        <span style={{ fontWeight: '600', fontSize: '12px', color: '#4B4AD5', lineHeight: 'normal', fontFamily: 'Poppins' }}>{patientDetails?.plan_name}</span>
                                        <span style={{ fontWeight: '400', fontSize: '9px' }}>End date - {moment(patientDetails?.expiry_date, 'YYYY-MM-DD').format('DD MMM YYYY')}</span>
                                    </div>
                                </div>
                            </div>

                            <div style={{ border: '1px solid #E2E2EA', margin: '20px auto 0px auto', width: '97.5%' }}></div>

                            <div className="patient-details-second-row">
                                <div className="patient-details-second">
                                    <span style={{ fontSize: '9px', fontWeight: '400' }}>Height</span>
                                    <span style={{ fontSize: '10px', fontWeight: '600' }}>{patientDetails?.height || '-'} {patientDetails?.height_unit}</span>
                                </div>
                                <div className="patient-details-second">
                                    <span style={{ fontSize: '9px', fontWeight: '400' }}>Weight</span>
                                    <span style={{ fontSize: '10px', fontWeight: '600' }}>{patientDetails?.weight || '-'} {patientDetails?.weight_unit}</span>
                                </div>
                                <div className="patient-details-second">
                                    <span style={{ fontSize: '9px', fontWeight: '400' }}>Disease</span>
                                    <span style={{ fontSize: '10px', fontWeight: '600' }}>{patientDetails?.medical_condition_name || '-'}</span>
                                </div>
                                <div className="patient-details-second">
                                    <span style={{ fontSize: '9px', fontWeight: '400' }}>Report Period</span>
                                    <span style={{ fontSize: '10px', fontWeight: '600' }}>{reportPeriodDates?.start_date} - {reportPeriodDates?.end_date}</span>
                                </div>
                            </div>

                            <div className="hc-month-content">
                                <textarea onChange={(e) => {
                                    let temp = selectedOptions;
                                    temp.patient_profile_notes = e.target.value;
                                    setSelectedOptions(temp)
                                }} style={{ resize: 'none', width: '100%', border: 'none', borderRadius: '10px', fontSize: '9px', fontWeight: '500', padding: '8px' }}>
                                    {selectedOptions?.patient_profile_notes}
                                </textarea>
                            </div>
                        </div>

                        <div className="reports-all-tables">

                            {/*Body Composition*/}
                            <div className="table-container">
                                <div className="table-heading">
                                    <BodyCompositionIcon />
                                    <span style={{ fontWeight: '600', fontSize: '12px' }}>Body Composition</span>
                                </div>

                                <div className="table-header">
                                    <div style={{ width: '33%', fontSize: '9px', fontWeight: '600', display: 'flex', alignItems: 'center' }}>
                                        Parameters
                                    </div>
                                    <div style={{ width: '33%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ fontSize: '9px', fontWeight: '600' }}>Initials</span>
                                            <span style={{ fontSize: '8px', fontWeight: '500' }}>{moment(reportPeriodDates?.start_date).format('ll')}</span>
                                        </div>
                                    </div>
                                    {/* <div style={{ width: '20%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ fontSize: '9px', fontWeight: '600' }}>Goals</span>
                                            <span style={{ fontSize: '8px', fontWeight: '500' }}>{moment(reportPeriodDates?.end_date).format('ll')}</span>
                                        </div>
                                    </div> */}
                                    <div style={{ width: '33%', fontSize: '9px', fontWeight: '600', display: 'flex', alignItems: 'center' }}>
                                        Achieved
                                    </div>
                                </div>

                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '33%', alignItems: 'center', gap: '5px' }}>
                                        <Checkbox onChange={() => onChange('body_composition', 'weight')} />
                                        <WeightIcons />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Weight (Kg)</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500' }}>
                                        {renderInItData(reportDetails?.body_composition?.weight?.initial_value)}
                                        {/* {Number(reportDetails?.body_composition?.weight?.initial_value).toFixed(2) || '-'} */}
                                    </div>
                                    {/* <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {reportDetails?.body_composition?.weight?.goal_value || 0}
                                    </div> */}
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500', alignItems: 'center', gap: '8px' }}>
                                        {renderAchievedData(reportDetails?.body_composition?.weight?.achieved_value)}
                                        {/* <Progress type="circle" percent={reportDetails?.body_composition?.weight?.achieved_value} size={20} /> */}
                                    </div>
                                </div>

                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '33%', alignItems: 'center', gap: '5px' }}>
                                        <Checkbox onChange={() => onChange('body_composition', 'bmi')} />
                                        <BmiIcons />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>BMI (kg/m2 )</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.body_composition?.bmi?.initial_value).toFixed(2) || '-'} */}
                                        {renderInItData(reportDetails?.body_composition?.bmi?.initial_value)}
                                    </div>
                                    {/* <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {reportDetails?.body_composition?.bmi?.goal_value || 0}
                                    </div> */}
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500', alignItems: 'center', gap: '8px' }}>
                                        {/* <Progress type="circle" percent={reportDetails?.body_composition?.bmi?.achieved_value} size={20} /> */}
                                        {/* {Number(reportDetails?.body_composition?.bmi?.achieved_value).toFixed(2) || 0} */}
                                        {renderAchievedData(reportDetails?.body_composition?.bmi?.achieved_value)}
                                    </div>
                                </div>

                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '33%', alignItems: 'center', gap: '5px' }}>
                                        <Checkbox onChange={() => onChange('body_composition', 'waist')} />
                                        <WaistIcons />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Waist (cm)</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.body_composition?.waist?.initial_value).toFixed(2) || '-'} */}
                                        {renderInItData(reportDetails?.body_composition?.waist?.initial_value)}
                                    </div>
                                    {/* <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {reportDetails?.body_composition?.waist?.goal_value || 0}
                                    </div> */}
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500', alignItems: 'center', gap: '8px' }}>
                                        {/* <Progress type="circle" percent={reportDetails?.body_composition?.waist?.achieved_value} size={20} /> */}
                                        {/* {Number(reportDetails?.body_composition?.waist?.achieved_value).toFixed(2) || 0} */}
                                        {renderAchievedData(reportDetails?.body_composition?.waist?.achieved_value)}
                                    </div>
                                </div>

                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '33%', alignItems: 'center', gap: '5px' }}>
                                        <Checkbox onChange={() => onChange('body_composition', 'hip')} />
                                        <HipIcons />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Hip (cm)</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.body_composition?.hip?.initial_value).toFixed(2) || '-'} */}
                                        {renderInItData(reportDetails?.body_composition?.hip?.initial_value)}
                                    </div>
                                    {/* <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {reportDetails?.body_composition?.hip?.goal_value || 0}
                                    </div> */}
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500', alignItems: 'center', gap: '8px' }}>
                                        {/* <Progress type="circle" percent={reportDetails?.body_composition?.hip?.achieved_value} size={20} /> */}
                                        {/* {Number(reportDetails?.body_composition?.hip?.achieved_value).toFixed(2) || 0} */}
                                        {renderAchievedData(reportDetails?.body_composition?.hip?.achieved_value)}
                                    </div>
                                </div>

                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '33%', alignItems: 'center', gap: '5px' }}>
                                        <Checkbox onChange={() => onChange('body_composition', 'waist_hip_ratio')} />
                                        <WaistToHipIcons />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Waist to Hip Ratio</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.body_composition?.waist_hip_ratio?.initial_value).toFixed(2) || '-'} */}
                                        {renderInItData(reportDetails?.body_composition?.waist_hip_ratio?.initial_value)}
                                    </div>
                                    {/* <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {reportDetails?.body_composition?.waist_hip_ratio?.goal_value || 0}
                                    </div> */}
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500', alignItems: 'center', gap: '8px' }}>
                                        {/* <Progress type="circle" percent={reportDetails?.body_composition?.waist_hip_ratio?.achieved_value} size={20} /> */}
                                        {/* {Number(reportDetails?.body_composition?.waist_hip_ratio?.achieved_value).toFixed(2) || 0} */}
                                        {renderAchievedData(reportDetails?.body_composition?.waist_hip_ratio?.achieved_value)}
                                    </div>
                                </div>
                            </div>

                            {/*Vitals Summary*/}
                            <div className="table-container" style={{ marginTop: '30px' }}>
                                <div className="table-heading">
                                    <VitalsIcon />
                                    <span style={{ fontWeight: '600', fontSize: '12px' }}>Vitals Summary</span>
                                </div>

                                <div className="table-header">
                                    <div style={{ width: '30%', fontSize: '9px', fontWeight: '600', display: 'flex', alignItems: 'center' }}>
                                        Parameters
                                    </div>
                                    <div style={{ width: '20%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ fontSize: '9px', fontWeight: '600' }}>Initials</span>
                                            <span style={{ fontSize: '8px', fontWeight: '500' }}>{moment(reportPeriodDates?.start_date).format('ll')}</span>
                                        </div>
                                    </div>
                                    <div style={{ width: '20%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ fontSize: '9px', fontWeight: '600' }}>Final</span>
                                            <span style={{ fontSize: '8px', fontWeight: '500' }}>{moment(reportPeriodDates?.end_date).format('ll')}</span>
                                        </div>
                                    </div>
                                    <div style={{ width: '30%', fontSize: '9px', fontWeight: '600', display: 'flex', alignItems: 'center' }}>
                                        %Change
                                    </div>
                                </div>

                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '30%', alignItems: 'center', gap: '5px' }}>
                                        <Checkbox onChange={() => onChange('vitals_summary', 'temperature')} />
                                        <TemperatureIcon />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Temperature (C)</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.vitals_summary?.temperature?.initial_value).toFixed(2) || '-'} */}
                                        {renderInItData(reportDetails?.vitals_summary?.temperature?.initial_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.vitals_summary?.temperature?.final_value).toFixed(2) || 0} */}
                                        {renderAchievedData(reportDetails?.vitals_summary?.temperature?.final_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '30%', fontSize: '9px', fontWeight: '500' }}>
                                        {renderChangedData(reportDetails?.vitals_summary?.temperature)}
                                    </div>
                                </div>

                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '30%', alignItems: 'center', gap: '5px' }}>
                                        <Checkbox onChange={() => onChange('vitals_summary', 'pulse')} />
                                        <PusleIcon />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Pulse (bpm)</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.vitals_summary?.pulse?.initial_value).toFixed(2) || '-'} */}
                                        {renderInItData(reportDetails?.vitals_summary?.pulse?.initial_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.vitals_summary?.pulse?.final_value).toFixed(2) || 0} */}
                                        {renderAchievedData(reportDetails?.vitals_summary?.pulse?.initial_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '30%', fontSize: '9px', fontWeight: '500' }}>
                                        {renderChangedData(reportDetails?.vitals_summary?.pulse)}
                                    </div>
                                </div>

                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '30%', alignItems: 'center', gap: '5px' }}>
                                        <Checkbox onChange={() => onChange('vitals_summary', 'resp_rate')} />
                                        <LungsIcon />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Resp. Rate (/min)</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.vitals_summary?.resp_rate?.initial_value).toFixed(2) || '-'} */}
                                        {renderInItData(reportDetails?.vitals_summary?.resp_rate?.initial_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.vitals_summary?.resp_rate?.final_value).toFixed(2) || 0} */}
                                        {renderAchievedData(reportDetails?.vitals_summary?.resp_rate?.initial_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '30%', fontSize: '9px', fontWeight: '500' }}>
                                        {renderChangedData(reportDetails?.vitals_summary?.resp_rate)}
                                    </div>
                                </div>

                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '30%', alignItems: 'center', gap: '5px' }}>
                                        <Checkbox onChange={() => onChange('vitals_summary', 'blood_pressure')} />
                                        <BloodPressureIcon />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Blood Pressure (mmHg)</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {!reportDetails?.vitals_summary?.blood_pressure?.initial_reading_value_data?.systolic && !reportDetails?.vitals_summary?.blood_pressure?.initial_reading_value_data?.diastolic
                                            ? <span style={{ fontSize: '8px', alignSelf: 'center' }}>Data Not Provided</span>
                                            : <>{reportDetails?.vitals_summary?.blood_pressure?.initial_reading_value_data?.systolic || '-'} / {reportDetails?.vitals_summary?.blood_pressure?.initial_reading_value_data?.diastolic || '-'}</>
                                        }
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {!reportDetails?.vitals_summary?.blood_pressure?.final_reading_value_data?.systolic && !reportDetails?.vitals_summary?.blood_pressure?.final_reading_value_data?.diastolic
                                            ? <span style={{ fontSize: '8px', alignSelf: 'center' }}>-</span>
                                            : <>{reportDetails?.vitals_summary?.blood_pressure?.final_reading_value_data?.systolic || '-'} / {reportDetails?.vitals_summary?.blood_pressure?.final_reading_value_data?.diastolic || '-'}</>
                                        }
                                    </div>
                                    <div style={{ display: 'flex', width: '30%', fontSize: '9px', fontWeight: '500' }}>
                                        {renderChangedData(reportDetails?.vitals_summary?.blood_pressure)}
                                    </div>
                                </div>

                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '30%', alignItems: 'center', gap: '5px' }}>
                                        <Checkbox onChange={() => onChange('vitals_summary', 'spo2')} />
                                        <SPO2Icon />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>SPO2</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.vitals_summary?.spo2?.initial_value).toFixed(2) || '-'} */}
                                        {renderInItData(reportDetails?.vitals_summary?.spo2?.initial_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.vitals_summary?.spo2?.final_value).toFixed(2) || 0} */}
                                        {renderAchievedData(reportDetails?.vitals_summary?.spo2?.initial_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '30%', fontSize: '9px', fontWeight: '500' }}>
                                        {renderChangedData(reportDetails?.vitals_summary?.spo2)}
                                    </div>
                                </div>

                                {
                                    reportDetails?.vitals_summary?.other_vitals.map((vital: any) => {
                                        return (
                                            <div className="table-data">
                                                <div style={{ display: 'flex', width: '30%', alignItems: 'center', gap: '5px' }}>
                                                    <Checkbox onChange={() => onChangeForArrayVitals(vital)} />
                                                    {(vital?.keys == 'lung' || vital?.keys == 'pef' || vital?.keys == 'fvc' || vital?.keys == 'aqi' || vital?.keys == 'humidity' || vital?.keys == 'fev1_fvc_ratio') &&
                                                        <img
                                                            src={LungIcon}
                                                            alt='vital_icon'
                                                            style={{
                                                                height: '13px',
                                                                width: '13px',
                                                                //filter: 'saturate(100%) hue-rotate(180deg)', // Apply CSS filters for desired color effects
                                                            }}
                                                        />
                                                    }
                                                    {(vital?.keys == 'six_min_walk') &&
                                                        <img
                                                            src={WalkIcon}
                                                            alt='vital_icon'
                                                            style={{
                                                                height: '13px',
                                                                width: '13px',
                                                                //filter: 'saturate(100%) hue-rotate(180deg)', // Apply CSS filters for desired color effects
                                                            }}
                                                        />
                                                    }
                                                    {(vital?.keys == 'fibro_scan' || vital?.keys == 'fatty_liver_ugs_grade' || vital?.keys == 'fib4') &&
                                                        <img
                                                            src={LiverIcon}
                                                            alt='vital_icon'
                                                            style={{
                                                                height: '13px',
                                                                width: '13px',
                                                                //filter: 'saturate(100%) hue-rotate(180deg)', // Apply CSS filters for desired color effects
                                                            }}
                                                        />
                                                    }

                                                    {(vital?.keys == 'egfr' || vital?.keys == 'acr' || vital?.keys == 'serum_creatinine') &&
                                                        <img
                                                            src={KidneyIcon}
                                                            alt='vital_icon'
                                                            style={{
                                                                height: '13px',
                                                                width: '13px',
                                                                //filter: 'saturate(100%) hue-rotate(180deg)', // Apply CSS filters for desired color effects
                                                            }}
                                                        />
                                                    }

                                                    {(vital?.keys == 'triglycerides' || vital?.keys == 'blood_glucose' || vital?.keys == 'basel_metabolic_rate' || vital?.keys == 'random_blood_glucose' || vital?.keys == 'platelet') &&
                                                        <img
                                                            src={CardioIcon}
                                                            alt='vital_icon'
                                                            style={{
                                                                height: '13px',
                                                                width: '13px',
                                                                //filter: 'saturate(100%) hue-rotate(180deg)', // Apply CSS filters for desired color effects
                                                            }}
                                                        />
                                                    }
                                                    {(vital?.keys == 'calories_burned') &&
                                                        <img
                                                            src={CaloriesBurnIcon}
                                                            alt='vital_icon'
                                                            style={{
                                                                height: '13px',
                                                                width: '13px',
                                                                //filter: 'saturate(100%) hue-rotate(180deg)', // Apply CSS filters for desired color effects
                                                            }}
                                                        />
                                                    }
                                                    {(vital?.keys == 'sedentary_time' || vital?.keys == 'hydration' || vital?.keys == 'metabolic_age' || vital?.keys == 'muscle_mass' || vital?.keys == 'subcutaneous_fat' || vital?.keys == 'protein' || vital?.keys == 'visceral_fat' || vital?.keys == 'skeletal_muscle' || vital?.keys == 'bone_mass' || vital?.keys == 'body_fat') &&
                                                        <img
                                                            src={AccessbilityIcon}
                                                            alt='vital_icon'
                                                            style={{
                                                                height: '13px',
                                                                width: '13px',
                                                                //filter: 'saturate(100%) hue-rotate(180deg)', // Apply CSS filters for desired color effects
                                                            }}
                                                        />
                                                    }
                                                    <span style={{ fontSize: '9px', fontWeight: '500' }}>{vital?.reading_name}</span>
                                                </div>
                                                {vital?.keys == 'blood_glucose' ?
                                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                                        {(!vital?.initial_reading_value_data?.fast && !vital?.initial_reading_value_data?.pp)
                                                            ? <span style={{ fontSize: '8px', alignSelf: 'center' }}>Data Not Provided</span>
                                                            : <>{vital?.initial_reading_value_data?.fast || '-'} / {vital?.initial_reading_value_data?.pp || '-'}</>
                                                        }
                                                    </div>
                                                    :
                                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                                        {/* {Number(vital?.initial_value).toFixed(2) || '-'} */}
                                                        {renderInItData(vital?.initial_value)}
                                                    </div>
                                                }
                                                {vital?.keys == 'blood_glucose' ?
                                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                                        {(!vital?.final_reading_value_data?.fast && !vital?.final_reading_value_data?.pp)
                                                            ? <span style={{ fontSize: '8px', alignSelf: 'center' }}>Data Not Provided</span>
                                                            : <>{vital?.final_reading_value_data?.fast || '-'} / {vital?.final_reading_value_data?.pp || '-'}</>
                                                        }
                                                    </div>
                                                    :
                                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                                        {/* {Number(vital?.final_value).toFixed(2) || '-'} */}
                                                        {renderAchievedData(vital?.final_value)}
                                                    </div>

                                                }
                                                <div style={{ display: 'flex', width: '30%', fontSize: '9px', fontWeight: '500' }}>
                                                    {renderChangedData(vital)}
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>

                            {/* SGPT Table */}
                            <div className="second-table-container">
                                <div className="second-table-header">
                                    <div className="second-table-header-left">
                                        <SGPTIcons />
                                        <span style={{ fontSize: '12px', fontWeight: '600' }}>SGPT</span>
                                    </div>
                                    <div className="second-table-header-right">
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>{reportDetails?.sgpt?.default_reading}</span>
                                    </div>
                                </div>

                                <div className="second-table-data">
                                    <Checkbox onChange={() => onChangeFirstObj('sgpt')} />
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>Initial {`(${moment(reportPeriodDates?.start_date).format('ll')})`}</span>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                            {/* {Number(reportDetails?.sgpt?.initial_value).toFixed(2) || '-'} */}
                                            {renderInItData(reportDetails?.sgpt?.initial_value)}
                                        </span>
                                    </div>
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>Final {`(${moment(reportPeriodDates?.end_date).format('ll')})`}</span>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                            {/* {Number(reportDetails?.sgpt?.final_value).toFixed(2) || 0} */}
                                            {renderAchievedData(reportDetails?.sgpt?.final_value)}
                                        </span>
                                    </div>
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column', fontSize: '9px', fontWeight: '500' }}>
                                        {renderChangedData(reportDetails?.sgpt)}
                                    </div>
                                </div>
                            </div>

                            {/* SGOT Table*/}
                            <div className="second-table-container">
                                <div className="second-table-header">
                                    <div className="second-table-header-left">
                                        <SGPTIcons />
                                        <span style={{ fontSize: '12px', fontWeight: '600' }}>SGOT</span>
                                    </div>
                                    <div className="second-table-header-right">
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>{reportDetails?.sgot?.default_reading}</span>
                                    </div>
                                </div>

                                <div className="second-table-data">
                                    <Checkbox onChange={() => onChangeFirstObj('sgot')} />
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>Initial {`(${moment(reportPeriodDates?.start_date).format('ll')})`}</span>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                            {/* {Number(reportDetails?.sgot?.initial_value).toFixed(2) || '-'} */}
                                            {renderInItData(reportDetails?.sgot?.initial_value)}
                                        </span>
                                    </div>
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>Final {`(${moment(reportPeriodDates?.end_date).format('ll')})`}</span>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                            {/* {Number(reportDetails?.sgot?.final_value).toFixed(2) || 0} */}
                                            {renderAchievedData(reportDetails?.sgot?.final_value)}
                                        </span>
                                    </div>
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column', fontSize: '9px', fontWeight: '500' }}>
                                        {renderChangedData(reportDetails?.sgot)}
                                    </div>
                                </div>
                            </div>

                            {/* <div style={{ fontWeight: '400', fontSize: '8px', display: 'flex', marginTop: '20px', gap: '8px' }}>
                                <span style={{ fontWeight: '600' }}>Note :</span><span>SGPT and SGOT are liver enzymes that indicate liver health; elevated levels may suggest liver damage or disease.</span>
                            </div> */}
                            <div style={{ fontWeight: '400', fontSize: '8px', display: 'flex', marginTop: '20px', gap: '8px' }}>
                                <textarea
                                    onChange={(e) => {
                                        let temp = selectedOptions;
                                        temp.sgpt_notes = e.target.value;
                                        setSelectedOptions(temp)
                                    }}
                                    style={{ resize: 'none', width: '100%', border: '1px solid lightgray', borderRadius: '4px', minHeight: '50px', outline: 'none' }}
                                    placeholder="notes..."
                                />
                            </div>

                            <div style={{ border: '1px solid #A2A2A8', marginTop: '20px' }}></div>

                            {/* LDL Table*/}
                            <div className="second-table-container">
                                <div className="second-table-header">
                                    <div className="second-table-header-left">
                                        <LDLIcon />
                                        <span style={{ fontSize: '12px', fontWeight: '600' }}>LDL</span>
                                    </div>
                                    <div className="second-table-header-right">
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>{reportDetails?.ldl?.default_reading}</span>
                                    </div>
                                </div>

                                <div className="second-table-data">
                                    <Checkbox onChange={() => onChangeFirstObj('ldl')} />
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>Initial {`(${moment(reportPeriodDates?.start_date).format('ll')})`}</span>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                            {/* {Number(reportDetails?.ldl?.initial_value).toFixed(2) || '-'} */}
                                            {renderInItData(reportDetails?.ldl?.initial_value)}
                                        </span>
                                    </div>
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>Final {`(${moment(reportPeriodDates?.end_date).format('ll')})`}</span>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                            {/* {Number(reportDetails?.ldl?.final_value).toFixed(2) || 0} */}
                                            {renderAchievedData(reportDetails?.ldl?.final_value)}
                                        </span>
                                    </div>
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column', fontSize: '9px', fontWeight: '500' }}>
                                        {renderChangedData(reportDetails?.ldl)}
                                    </div>
                                </div>
                            </div>

                            {/* HDL Table*/}
                            <div className="second-table-container">
                                <div className="second-table-header">
                                    <div className="second-table-header-left">
                                        <LDLIcon />
                                        <span style={{ fontSize: '12px', fontWeight: '600' }}>HDL</span>
                                    </div>
                                    <div className="second-table-header-right">
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>{reportDetails?.hdl?.default_reading}</span>
                                    </div>
                                </div>

                                <div className="second-table-data">
                                    <Checkbox onChange={() => onChangeFirstObj('hdl')} />
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>Initial {`(${moment(reportPeriodDates?.start_date).format('ll')})`}</span>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                            {/* {Number(reportDetails?.hdl?.initial_value).toFixed(2) || '-'} */}
                                            {renderInItData(reportDetails?.hdl?.initial_value)}
                                        </span>
                                    </div>
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>Final {`(${moment(reportPeriodDates?.end_date).format('ll')})`}</span>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                            {/* {Number(reportDetails?.hdl?.final_value).toFixed(2) || 0} */}
                                            {renderAchievedData(reportDetails?.hdl?.final_value)}
                                        </span>
                                    </div>
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column', fontSize: '9px', fontWeight: '500' }}>
                                        {renderChangedData(reportDetails?.hdl)}
                                    </div>
                                </div>
                            </div>

                            {/*Total Cholesterol*/}
                            <div className="second-table-container">
                                <div className="second-table-header">
                                    <div className="second-table-header-left">
                                        <LDLIcon />
                                        <span style={{ fontSize: '12px', fontWeight: '600' }}>Total Cholesterol</span>
                                    </div>
                                    <div className="second-table-header-right">
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>{reportDetails?.total_cholesterol?.default_reading}</span>
                                    </div>
                                </div>

                                <div className="second-table-data">
                                    <Checkbox onChange={() => onChangeFirstObj('total_cholesterol')} />
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>Initial {`(${moment(reportPeriodDates?.start_date).format('ll')})`}</span>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                            {/* {Number(reportDetails?.total_cholesterol?.initial_value).toFixed(2) || '-'} */}
                                            {renderInItData(reportDetails?.total_cholesterol?.initial_value)}
                                        </span>
                                    </div>
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>Final {`(${moment(reportPeriodDates?.end_date).format('ll')})`}</span>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                            {/* {Number(reportDetails?.total_cholesterol?.final_value).toFixed(2) || 0} */}
                                            {renderAchievedData(reportDetails?.total_cholesterol?.final_value)}
                                        </span>
                                    </div>
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column', fontSize: '9px', fontWeight: '500' }}>
                                        {renderChangedData(reportDetails?.total_cholesterol)}
                                    </div>
                                </div>
                            </div>

                            {/* <div style={{ fontWeight: '400', fontSize: '8px', display: 'flex', marginTop: '20px', gap: '8px' }}>
                                <span style={{ fontWeight: '600' }}>Note:</span><span> HDL (high-density lipoprotein) is 'good' cholesterol, with normal levels ideally above 40 mg/dL for men and 50 mg/dL for women; LDL (low-density lipoprotein) is 'bad' cholesterol, with optimal levels below 100 mg/dL; and total cholesterol levels should ideally be below 200 mg/dL.</span>
                            </div> */}
                            <div style={{ fontWeight: '400', fontSize: '8px', display: 'flex', marginTop: '20px', gap: '8px' }}>
                                <textarea
                                    onChange={(e) => {
                                        let temp = selectedOptions;
                                        temp.ldl_notes = e.target.value;
                                        setSelectedOptions(temp)
                                    }}
                                    style={{ resize: 'none', width: '100%', border: '1px solid lightgray', borderRadius: '4px', minHeight: '50px', outline: 'none' }}
                                    placeholder="notes..."
                                />
                            </div>

                            <div style={{ border: '1px solid #A2A2A8', marginTop: '20px' }}></div>

                            {/*HbA1c Table*/}
                            <div className="second-table-container">
                                <div className="second-table-header">
                                    <div className="second-table-header-left">
                                        <LDLIcon />
                                        <span style={{ fontSize: '12px', fontWeight: '600' }}>HbA1c</span>
                                    </div>
                                    <div className="second-table-header-right">
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>Normal <b>below 5.7 %</b></span>
                                    </div>
                                </div>

                                <div className="second-table-data">
                                    <Checkbox onChange={() => onChangeFirstObj('hba1c')} />
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>Initial {`(${moment(reportPeriodDates?.start_date).format('ll')})`}</span>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                            {/* {Number(reportDetails?.hba1c?.initial_value).toFixed(2) || '-'} */}
                                            {renderInItData(reportDetails?.hba1c?.initial_value)}
                                        </span>
                                    </div>
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>Final {`(${moment(reportPeriodDates?.end_date).format('ll')})`}</span>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                            {/* {Number(reportDetails?.hba1c?.final_value).toFixed(2) || 0} */}
                                            {renderAchievedData(reportDetails?.hba1c?.final_value)}
                                        </span>
                                    </div>
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column', fontSize: '9px', fontWeight: '500' }}>
                                        {renderChangedData(reportDetails?.hba1c)}
                                    </div>
                                </div>
                            </div>

                            {/*Physical Health Parameters Summary*/}
                            {!!((reportDetails?.data_points || []).length) &&
                                <div className="table-container" style={{ marginTop: '30px' }}>
                                    <div className="table-heading">
                                        <BodyCompositionIcon />
                                        <span style={{ fontWeight: '600', fontSize: '12px' }}>Physical Health Parameters</span>
                                    </div>

                                    <div className="table-header">
                                        <div style={{ width: '20%', fontSize: '9px', fontWeight: '600', display: 'flex', alignItems: 'center' }}>
                                            Parameters
                                        </div>
                                        <div style={{ width: '20%', fontSize: '9px', fontWeight: '600', display: 'flex', alignItems: 'center' }}>
                                            Targets
                                        </div>
                                        <div style={{ width: '20%' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: '9px', fontWeight: '600' }}>Initials</span>
                                                <span style={{ fontSize: '8px', fontWeight: '500' }}>{moment(reportPeriodDates?.start_date).format('ll')}</span>
                                            </div>
                                        </div>
                                        <div style={{ width: '20%' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: '9px', fontWeight: '600' }}>Final</span>
                                                <span style={{ fontSize: '8px', fontWeight: '500' }}>{moment(reportPeriodDates?.end_date).format('ll')}</span>
                                            </div>
                                        </div>
                                        <div style={{ width: '20%', fontSize: '9px', fontWeight: '600', display: 'flex', alignItems: 'center' }}>
                                            %Change
                                        </div>
                                    </div>

                                    {reportDetails.data_points.map((dp: any) => (
                                        <div className="table-data">
                                            <div style={{ display: 'flex', width: '20%', alignItems: 'center', gap: '5px' }}>
                                                <Checkbox onChange={() => onChange('data_points', dp)} />
                                                <span style={{ fontSize: '9px', fontWeight: '500', marginLeft: '8px' }}>{dp.question ?? '-'}</span>
                                            </div>
                                            <div style={{ display: 'flex', width: '20%', marginLeft: '5px', fontSize: '9px', fontWeight: '500', alignItems: 'center', gap: '8px' }}>
                                                {dp?.target || '-'}
                                            </div>
                                            <div style={{ display: 'flex', width: '20%', marginLeft: '15px', fontSize: '9px', fontWeight: '500' }}>
                                                {dp?.initial_value
                                                    ? dp.initial_value === '-'
                                                        ? <span style={{ fontSize: '8px', alignSelf: 'center' }}>Data Not Provided</span>
                                                        : dp.initial_value
                                                    : <span style={{ fontSize: '8px', alignSelf: 'center' }}>Data Not Provided</span>}
                                            </div>
                                            <div style={{ display: 'flex', width: '20%', marginRight: '-10px', fontSize: '9px', fontWeight: '500', alignItems: 'center', gap: '8px' }}>
                                                {dp?.final_value
                                                    ? dp.final_value === '-'
                                                        ? <span style={{ fontSize: '8px', alignSelf: 'center' }}>Data Not Provided</span>
                                                        : dp.final_value
                                                    : <span style={{ fontSize: '8px', alignSelf: 'center' }}>Data Not Provided</span>}
                                            </div>
                                            <div style={{ display: 'flex', width: '20%', fontSize: '9px', marginRight: '-10px', fontWeight: '500', alignItems: 'center', gap: '8px' }}>
                                                {renderChangedData(dp)}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }

                            {/*Suggested Exercise Notes */}
                            <div style={{ marginTop: '10px' }}>
                                <textarea
                                    onChange={(e) => {
                                        let temp = selectedOptions;
                                        temp.suggested_exercise = e.target.value;
                                        setSelectedOptions(temp)
                                    }}
                                    placeholder="notes.."
                                    style={{ resize: 'none', width: '100%', border: '1px solid lightgray', borderRadius: '4px', minHeight: '50px', outline: 'none' }}
                                />
                            </div>

                            <div style={{ border: '1px solid #A2A2A8', marginTop: '30px' }}></div>

                            {/*Suggested lifestyle goals input*/}
                            <div className="table-container" style={{ marginTop: '30px' }}>
                                <div className="table-heading">
                                    <OtherLifeIcon />
                                    <span style={{ fontWeight: '600', fontSize: '12px' }}>Suggested lifestyle goals</span>
                                </div>
                                <div>
                                    <textarea
                                        onChange={(e) => {
                                            let temp = selectedOptions;
                                            temp.life_style_notes = e.target.value;
                                            setSelectedOptions(temp)
                                        }}
                                        style={{ resize: 'none', width: '100%', border: '1px solid lightgray', borderRadius: '4px', minHeight: '100px', outline: 'none' }}
                                    />
                                </div>
                            </div>

                            <div style={{ border: '1px solid #A2A2A8', marginTop: '30px' }}></div>

                            {/*Doctor Notes*/}
                            <div className="table-container" style={{ marginTop: '30px' }}>
                                <div className="table-heading">
                                    <DoctorNotesIcon />
                                    <span style={{ fontWeight: '600', fontSize: '12px' }}>Doctor Notes</span>
                                </div>
                                <div>
                                    <textarea
                                        onChange={(e) => {
                                            let temp = selectedOptions;
                                            temp.doctor_notes = e.target.value;
                                            setSelectedOptions(temp)
                                        }}
                                        style={{ resize: 'none', width: '100%', border: '1px solid lightgray', borderRadius: '4px', minHeight: '50px', outline: 'none' }}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="report-btn" onClick={() => setReportModal(true)}>
                        Generate Report
                    </div>

                    <Modal
                        centered
                        open={reportModal}
                        footer={null}
                        onCancel={() => setReportModal(false)}
                        width={'100%'}
                        style={{}}
                        zIndex={10000}
                    >
                        <PatientExerciseReportView selectedReportData={selectedOptions} userInfo={userDetails} patientDetails={patientDetails} reportPeiodDates={reportPeriodDates} selectedExercisePlan={selectedExercisePlan} setReportModal={setReportModal} />
                    </Modal>
                </div>
                : <>
                    <div style={{ color: '#8129B9', fontSize: '18px', marginTop: '20px', fontWeight: '700', textAlign: 'center', marginBottom: '20px' }}>Patient Exercise Reports List                    </div>
                    {reportList?.map((report: any) => (
                        <div className="report-container">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', gap: '10px' }}>
                                    <img src={PdfIcon} width={35} height={35} />
                                    <div className="report-date-title">
                                        <div className="report-title">
                                            {report?.document_title} <span style={{ color: `${report?.status === 'Pending' ? 'orange' : (report?.status === 'Rejected' ? 'red' : 'green')}` }}>{`(${report?.status})`}</span>
                                        </div>
                                        <div className="report-date">
                                            {report?.start_date} to {report?.end_date}
                                        </div>
                                    </div>
                                </div>
                                <a target="_blank" href={report?.document_url}>
                                    <img src={DownloadIcon} alt="download_icon" style={{ marginRight: '20px' }} />
                                </a>
                            </div>
                            {report?.chief_remarks &&
                                <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '5px', width: '96%' }}>
                                    <div style={{ color: '#760FB2', fontSize: '16px', fontWeight: '700' }}>Chief Remarks :-</div>
                                    <div>{report?.chief_remarks}</div>
                                </div>
                            }
                        </div>
                    ))}
                </>
            }
        </>
    )
}

export default PatientExerciseReport;