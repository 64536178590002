import React, { FC } from "react";
import './ChangeDiet-btn.scss'
import { useNavigate } from "react-router-dom";
import { DatePicker } from "antd";

type ChangeDietBtnProps = {
    setAddDietShow: any
}

const ChangeDietBtn: FC<ChangeDietBtnProps> = ({setAddDietShow}) => {
    const navigate = useNavigate()

//    const openFullScreen = () => {
//         window.parent.postMessage({ action: 'openFullScreen' }, '*');
//         navigate("/add-change-diet", {state: {button: 'add'}})
//     };

    return (
            <button className="change-diet-btn" onClick={() => setAddDietShow(true)}>Add Diet Plan</button>
    )
}

export default ChangeDietBtn