import React from 'react';
import { Button } from 'antd';
import WarningIconPNG from '../../../../assets/icons/Warning-Whit.png';

import './index.scss';

interface NutrientWarningModalPropsType {
    invalidNutrientValues: any[];
    onSubmit: () => void;
    onCancel: () => void;
    loading: boolean;
    type?: any;
}

const NutrientWarningModal: React.FC<NutrientWarningModalPropsType> = ({ invalidNutrientValues, onSubmit, onCancel, loading, type }) => {
    const renderMessage = () => {
        return invalidNutrientValues.map((obj: any, index: number) => (
            <li key={index} className='warning-text' style={{marginLeft: '20px'}}>
                {obj.key === 'actual_calories' && `Actual calories (${obj.actual}) exceed the total calorie goal (${obj.goal}).`}
                {obj.key === 'protein' && `Actual protein (${obj.actual}) exceeds the total protein goal (${obj.goal}).`}
                {obj.key === 'fat' && `Actual fat (${obj.actual}) exceeds the total fat goal (${obj.goal}).`}
                {obj.key === 'carbs' && `Actual carbs (${obj.actual}) exceeds the total carbs goal (${obj.goal}).`}
            </li>
        ));
    };

    return (
        <div>
            <div className='warning-header'>
                <img src={WarningIconPNG} style={{ height: '100px', width: '100px' }} />
            </div>
            <div className='warning-modal'>
                <div className='header-text'>Warning!</div>
                <ul className='warning-list'>
                    <div className='warning-text'>The actual values have exceeded the set goals:</div>
                    {renderMessage()}
                </ul>
            </div>
            <div>
                <div className='proceed-button-container'>
                    <button
                        className='cancel-button'
                        onClick={onCancel}
                    >
                        Cancel
                    </button>
                    <Button
                        type='primary'
                        className='proceed-button'
                        onClick={onSubmit}
                        disabled={loading}
                        loading={loading}
                    >
                        Proceed
                    </Button>
                </div>
            </div>

        </div>
    );
};

export default NutrientWarningModal;