import { FunctionComponent, useEffect, useState } from "react";
import "./CaregiverInfo.css";
import editIcon from '../../assets/icons/edit.svg';
import 'react-toastify/dist/ReactToastify.css';

interface InfoProps {
  careGiverDetails: any;
  onSave?: (updatedDetails: any, userType:any) => void;
}

const CaregiverInfo: FunctionComponent<InfoProps> = ({ careGiverDetails, onSave }) => {

  const [editedDetails, setEditedDetails] = useState<any>({});
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (careGiverDetails) {
      setEditedDetails(careGiverDetails)
    }
  }, [careGiverDetails])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setEditedDetails({ ...editedDetails, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    try {
      onSave?.(editedDetails, "Caregiver");
    } catch (error) {
    }
  }


  return (
    <div className="button-frame-parent">
      <div className="button-frame1">
        <div className="products-list-parent">
          <div className="products-list8">Caregiver Info</div>
          <div className="input-field-frame-label-frame" onClick={handleSave}>
            <div className="frame-products-list-text-insta">
              <img
                className="edit-icon3"
                loading="lazy"
                alt=""
                src={editIcon}
              />
            </div>
            <div className="text4">
              <div className="i-n-s">{isSaving ? 'Saving...' : 'Save'}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="l-i-n-e1" />
      <div className="f-r-a-m-e-wrapper">
        <div className="f-r-a-m-e">
          <div className="products-list-input-field-grou">
            <div className="input-field7">
              <div className="l-a-b-e-l">
                <div className="label-custom">Name*</div>
              </div>
              <input
                className="placeholder5"
                name="cg_name"
                value={editedDetails.cg_name || ""}
                placeholder="Enter Name"
                type="text"
                onChange={handleChange}
              />
            </div>
            <div className="input-field8">
              <div className="label-parent1">
                <div className="label-custom">Phone Number*</div>
              </div>
              <input
                className="placeholder5"
                name="cg_contact_no"
                value={editedDetails?.cg_contact_no || ""}
                placeholder="Enter Number"
                type="text"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="input-field9">
            <div className="input-field7">
              <div className="l-a-b-e-l">
                <div className="label-custom">Relationship with user*</div>
              </div>
              <input
                className="placeholder5"
                name="cg_relation"
                value={editedDetails?.cg_relation || ""}
                placeholder="Enter relation with patient"
                type="text"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaregiverInfo;
  