import { DecreasedIcon, IncreasedIcon } from "../assets/icons";

export const renderChangedData = (data: any) => {
    if (!data || data.change_type === 'unchanged' || isNaN(data.percentage_changed)) {
        return <div>-</div>;
    }

    const isIncreased = data.change_type === 'Increased';
    const Icon = isIncreased ? IncreasedIcon : DecreasedIcon;

    return (
        <div style={{ display: 'flex', gap: '5px' }}>
            <Icon /> {data.percentage_changed}% {data.change_type}
        </div>
    );
};