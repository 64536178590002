import { FunctionComponent, useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "./VerifyDocuments.css";
import rectangleIcon from "../../assets/icons/rectangle-12.svg";
import arrowLeftIcon from "../../assets/icons/arrow--left.svg";
import directionRightIcon from "../../assets/icons/rightArrow.svg";
import GroupComponent from "../../molecules/CgGroupComponent/GroupComponent";
import CaregiverInfo from "../../molecules/CgCaregiverInfo/CaregiverInfo";
import PatientInfo from "../../molecules/CgPatientInfo/PatientInfo";
import DoctorInfo from "../../molecules/CgDoctorInfo/DoctorInfo";
import ConfirmationModal from "../../molecules/CgConfirmationModal/ConfirmationModal";
import { getDecryptedData, getEncryptedBody, getEncryptedText } from "../../utils/common";
import { decode } from "punycode";
import CreateJourney from "../CgCreateJourney/CreateJourney";
import API, { GetOverallPatientCount } from "../../services/rest/api";
import { Modal } from "antd";
import RequestToUpload from "../../molecules/CgRequestToUpload/RequestToUpload";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const VerifyDocuments: FunctionComponent = () => {
  const [userDetails, setUserDetails] = useState<any>(null)
  const [patientData, setPatientData] = useState<any>(null)
  const [isSaving, setIsSaving] = useState(false);
  const [patientDetails, setPatientDetails] = useState<any>({});
  const [careGiverDetails, setCareGiverDetails] = useState<any>({});
  const [doctorDetails, setDoctorDetails] = useState<any>({});
  const [coins, setCoins] = useState<any>();
  const [token, setToken] = useState<any>();
  const [isPrescriptionApproved, setIsPrescriptionApproved] = useState<boolean>(false);
  const [isDischargeApproved, setIsDischargeApproved] = useState<boolean>(false);
  const [isPurchaseApproved, setIsPurchaseApproved] = useState<boolean>(false);
  const [isPrescriptionrReupload, setIsPrescriptionReupload] = useState<boolean>(false);
  const [isDischargeReupload, setIsDischargeReupload] = useState<boolean>(false);
  const [isPurchaseReupload, setIsPurchaseReupload] = useState<boolean>(false);
  const [isVivitraEnabled, setIsVivitraEnabled] = useState<any>(null);
  const [showPurchaseApprove, setShowPurchaseApprove] = useState<boolean>(false);
  const [showDischargeApprove, setShowDischargeApprove] = useState<boolean>(false);
  const [showVivitraConfirmation, setShowVivitraConfirmation] = useState<boolean>(false);
  const [showPurchaseReupload, setShowPurchaseReupload] = useState<boolean>(false);
  const [showDischargeReupload, setShowDischargeReupload] = useState<boolean>(false);
  const [showPrescriptionReupload, setshowPrescriptionReupload] = useState<boolean>(false);
  const [selectedPdfType, setSelectedPdfType] = useState<string>("");
  const [showPrescriptionApprove, setShowPrescriptionApprove] = useState<boolean>(false);
  const [showCreateCycleModal, setShowCreateCycleModal] = useState<boolean>(false);
  // const [showReqUploadModal, setShowReqUploadModal] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const [dischargeSummaryPdfUrl, setDischargeSummaryPdfUrl] = useState<string | null>(null);
  const [purchaseInvoicePdfUrl, setPurchaseInvoicePdfUrl] = useState<string | null>(null);
  const [prescriptionPdfUrl, setPrescriptionPdfUrl] = useState<string | null>(null);
  const [dischargeSummaryId, setDischargeSummaryId] = useState<string | null>(null);
  const [prescriptionId, setPrescriptionId] = useState<string | null>(null);
  const [purchaseInvoiceId, setPurchaseInvoiceId] = useState<string | null>(null);
  // const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  const params = useParams();
  const navigate = useNavigate();
  const location: any = useLocation();

  const FetchPDFUrlAPI = `${process.env.REACT_APP_API_URL}/api/v8/healthcoach/get_patient_record_onco_without_visit_id`;
  // const FetchPDFUrlAPI = `${process.env.REACT_APP_API_URL}/api/v8/healthcoach/get_records`;
  const UpdateRecordStatusAPI = `${process.env.REACT_APP_API_URL}/api/v8/healthcoach/update_patient_record_data`;
  const PatientDetailsAPI = `${process.env.REACT_APP_API_URL}/api/v8/healthcoach/patient_details_by_id`;
  const SavePatientDetailsAPI = `${process.env.REACT_APP_API_URL}/api/v8/healthcoach/update_patient_information`;

  const handleBackButtonClick = (screen: string) => {
    // navigate(-1);
    navigate(`/${screen}/:token`);
  };

  const handleButtonClick = (screen: string) => {
    navigate(`/${screen}`);
  };

  useEffect(() => {
    const token: any = params.token;
    getProfileDetails();
    // setToken(token)
    localStorage.setItem(
      "token",
      location.state?.token === ":token" || location.state?.token === undefined
        ? token
        : location.state?.token
    );
    sessionStorage.setItem('token', (location.state?.token === ":token" || location.state?.token === undefined) ? token : location.state?.token);

    setToken(localStorage.getItem("token"));
    setUserDetails(localStorage.getItem("health-coach-id"))
  }, [params]);

  //funtion to get profile details
  const getProfileDetails = async () => {
    try {
      const userInfo = await API.GetProfileDetails()
      localStorage.setItem('userInfo', JSON.stringify(userInfo?.data))
      setUserDetails(userInfo?.data)
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    const fetchPDFUrl = async () => {
      try {
        const token = localStorage.getItem("token");
        const secretKey = "SfUyfAztruqg92sbm30rEIyHLNV7f5";
        const body = { 
          "patient_id": location.state?.patientId,
        }

        const enCryptedBody = getEncryptedBody(body)

        // const decryptedBody = getDecryptedData(enCryptedBody)

        const response = await fetch(FetchPDFUrlAPI, {
          method: "POST",
          headers: {
            "Content-Type": "text/plain",
            token: `${token}`,
            health_secret: secretKey,
          },
          body: `${enCryptedBody}`,
        });

        if (!response.ok) {
          throw new Error("Failed to fetch PDF URL");
        }
        const data = await response.json();

        const decryptedData = getDecryptedData(data)
        const parsedRecordData = JSON.parse(decryptedData)

        // Loop through record array to set the states
        parsedRecordData?.data.forEach((item: any) => {
          setPdfUrlByTitle(item.title, item.imageURL, item.patient_records_id, item?.record_meta_info?.report_status);
        });
      } catch (error) {
        console.error("Error fetching PDF URL:", error);
      }
    };

    fetchPDFUrl();
  }, []);

  // Function to set state based on title
  const setPdfUrlByTitle = (title: any, url: any, recordId: any, reportStatus: any) => {
    switch (title) {
      case "Discharge Summary":
        setDischargeSummaryPdfUrl(url);
        setDischargeSummaryId(recordId)
        if (reportStatus === "Approved") {
          setIsDischargeApproved(true)
        } else if (reportStatus === "Rejected") {
          setIsDischargeReupload(true)
        }
        break;
      case "Purchase Invoice":
        setPurchaseInvoicePdfUrl(url);
        setPurchaseInvoiceId(recordId)
        if (reportStatus === "Approved") {
          setIsPurchaseApproved(true)
        } else if (reportStatus === "Rejected") {
          setIsPurchaseReupload(true)
        }
        break;
      case "Prescription":
        setPrescriptionPdfUrl(url);
        setPrescriptionId(recordId)
        if (reportStatus === "Approved") {
          setIsPrescriptionApproved(true)
        } else if (reportStatus === "Rejected") {
          setIsPrescriptionReupload(true)
        }
        break;
      default:
        // this is to Handle other cases if needed
        break;
    }
  };

  const updateRecordStatus = async (recordId: any) => {
    try {
      const token = localStorage.getItem("token");
      const secretKey = "SfUyfAztruqg92sbm30rEIyHLNV7f5";
      const body = {
        "patient_phone":patientDetails.patient_name,
        "patient_name":patientDetails.patient_contact_no,
        "patient_records_id": recordId,
        "record_meta_info": {
          "report_status": "Approved"
        }
      }

      const enCryptedBody = getEncryptedBody(body)

      // const decryptedBody = getDecryptedData(enCryptedBody)

      const response = await fetch(UpdateRecordStatusAPI, {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
          token: `${token}`,
          health_secret: secretKey,
        },
        body: `${enCryptedBody}`,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch PDF URL");
      }
      const data = await response.json();

      const decryptedData = getDecryptedData(data)
      // const parsedRecordData = JSON.parse(decryptedData)
    } catch (error) {
      console.error("Error fetching PDF URL:", error);
    }
  };

  const updateReuploadStatus = async (recordId: any, text: any) => {
    try {
      const token = localStorage.getItem("token");
      const secretKey = "SfUyfAztruqg92sbm30rEIyHLNV7f5";
      const body = {
        "patient_records_id": recordId,
        "patient_phone":patientDetails.patient_name,
        "patient_name":patientDetails.patient_contact_no,
        "record_meta_info": {
          "report_status": "Rejected",
          "rejected_reason": text
        }
      }

      const enCryptedBody = getEncryptedBody(body)

      // const decryptedBody = getDecryptedData(enCryptedBody)

      const response = await fetch(UpdateRecordStatusAPI, {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
          token: `${token}`,
          health_secret: secretKey,
        },
        body: `${enCryptedBody}`,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch PDF URL");
      }
      const data = await response.json();

      const decryptedData = getDecryptedData(data)
      // const parsedRecordData = JSON.parse(decryptedData)

    } catch (error) {
      console.error("Error fetching PDF URL:", error);
    }
  };

  useEffect(() => {
    fetchPatientDetails();
  }, []);

  const fetchPatientDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const secretKey = "SfUyfAztruqg92sbm30rEIyHLNV7f5";
      const body = JSON.stringify({
        patient_id: location.state?.patientId,
      });
      const response = await fetch(
        PatientDetailsAPI,
        {
          method: "POST",
          headers: {
            "Content-Type": "text/plain",
            token: `${token}`,
            health_secret: secretKey,
          },
          body: body,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      const userData = data?.data;
      setIsVivitraEnabled(userData?.patient_meta_info?.vivitra_eligible)
      setPatientData(userData)
      setCoins(userData?.coins)
      setPatientDetails({
        patient_name: userData.name,
        patient_contact_no: userData.contact_no,
        patient_gender: userData.gender,
        patient_dob: userData.dob,
        patient_height: userData.height,
        patient_height_unit: userData.height_unit,
        patient_weight: userData.weight,
        patient_weight_unit: userData.weight_unit,
        patient_email: userData.email,
        patient_address: userData.address,
      });
      setCareGiverDetails({
        cg_name: userData?.patient_meta_info?.cg_name,
        cg_contact_no: userData?.patient_meta_info?.cg_contact_no,
        cg_relation: userData?.patient_meta_info?.cg_relation,
      })
      setDoctorDetails({
        doc_name: userData?.patient_link_doctor_details[0]?.name,
        doc_contac_no: userData?.patient_link_doctor_details[0]?.contact_no,
        doc_city: userData?.patient_link_doctor_details[0]?.city,
        doc_clinic_name: userData?.patient_link_doctor_details[0]?.clinic_name,
      });      

    } catch (error) {
      console.error("Error fetching patient details:", error);
    }
  };

  const handleSave = async (updatedDetails: any, userType: string) => {
    try {
      // Update the state based on user type
      window.parent.postMessage("scrollToTop", "*")
      toast.success('Data saved successfully!', { position: 'top-right' });
      if (userType === 'Patient') {
        setPatientDetails(updatedDetails);
      } else if (userType === 'Caregiver') {
        setCareGiverDetails(updatedDetails);
      } else if (userType === 'Doctor') {
        setDoctorDetails(updatedDetails);
      }
    } catch (error) {
      console.error("Error updating details:", error);
      toast.error('Failed to save data. Please try again.', { position: 'top-right' });
    }
  };

  useEffect(() => {
    const savePatientDetails = async () => {
      try {
        setIsSaving(true);

        const body = JSON.stringify({
          patient_id: patientData?.patient_id,
          name: patientDetails.patient_name,
          contact_no: patientDetails.patient_contact_no,
          patient_name: patientDetails.patient_name,
          patient_phone: patientDetails.patient_contact_no,
          gender: patientDetails.patient_gender,
          dob: patientDetails.patient_dob,
          height: patientDetails.patient_height,
          height_unit: patientDetails.patient_height_unit,
          weight: patientDetails.patient_weight,
          weight_unit: patientDetails.patient_weight_unit,
          email: patientDetails.patient_email,
          address: patientDetails.patient_address,
          patient_meta_info: {
            user_type: patientData?.patient_meta_info?.user_type,
            support_program_type: patientData?.patient_meta_info?.support_program_type,
            ...careGiverDetails
          },
        });
        const token = location.state?.token ?? sessionStorage.getItem("token");
        const secretKey = 'SfUyfAztruqg92sbm30rEIyHLNV7f5';
        const response = await fetch(SavePatientDetailsAPI, {
          method: 'POST',
          headers: {
            'Content-Type': 'text/plain',
            'token': `${token}`,
            'health_secret': secretKey,
          },
          body: body,
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        console.log(data, "updated-successfully")
      } catch (error) {
        console.error("Error updating patient details:", error);
      }
    };

    if (Object.keys(patientDetails).length > 0) {
      savePatientDetails();
    }
  }, [patientDetails, careGiverDetails])

  const handleApprove = (pdfType: string) => {
    setSelectedPdfType(pdfType);
    window.parent.postMessage("scrollToTop", "*")
    if (pdfType === "purchase") {
      setShowPurchaseApprove(true)
    }
    else if (pdfType === "discharge") {
      setShowDischargeApprove(true)
    }
    else if (pdfType === "prescriptions") {
      setShowPrescriptionApprove(true)
    }
  };

  const handleReupload = (pdfType: string) => {
    setSelectedPdfType(pdfType);
    window.parent.postMessage("scrollToTop", "*")
    if (pdfType === "purchase") {
      setShowPurchaseReupload(true)
    }
    else if (pdfType === "discharge") {
      setShowDischargeReupload(true)
    }
    else if (pdfType === "prescriptions") {
      setshowPrescriptionReupload(true)
    }
  };

  const pdfUrl = 'https://example.com/path/to/your.pdf'

  const handleConfirmation = async (action: boolean) => {
    switch (selectedPdfType) {
      case "purchase":
        setIsPurchaseApproved(action);
        await updateRecordStatus(purchaseInvoiceId)
        break;
      case "discharge":
        setIsDischargeApproved(action);
        await updateRecordStatus(dischargeSummaryId)
        break;
      case "prescriptions":
        setIsPrescriptionApproved(action);
        await updateRecordStatus(prescriptionId)
        // setShowDischargeApprove(false);
        break;
      default:
        // setShowPrescriptionApprove(action);
        // setShowPrescriptionApprove(false);
        break;
    }
    handleCloseModal();
  };

  const handleReuploadConfirmation = async (action: boolean, text?: string) => {
    if (action) {
      switch (selectedPdfType) {
        case "purchase":
          setIsPurchaseReupload(action);
          await updateReuploadStatus(purchaseInvoiceId, text)
          break;
        case "discharge":
          setIsDischargeReupload(action);
          await updateReuploadStatus(dischargeSummaryId, text)
          break;
        case "prescriptions":
          setIsPrescriptionReupload(action);
          await updateReuploadStatus(prescriptionId, text)
          break;
        default:
          // setShowPrescriptionApprove(action);
          // setShowPrescriptionApprove(false);
          break;
      }
    }
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setShowDischargeApprove(false);
    setShowPurchaseApprove(false);
    setShowPrescriptionApprove(false);
    setShowDischargeReupload(false);
    setShowPurchaseReupload(false);
    setshowPrescriptionReupload(false);
    setShowVivitraConfirmation(false)
    setSelectedPdfType("");
  };

  const handleVivitraConfirmation = async (action: boolean) => {
    setIsVivitraEnabled(action);
    if (action) {
      saveVivitraDetails();
    }
    handleCloseModal();
  };

  const handleCheckboxChange = () => {
    // If checkbox is already confirmed, that can not be undo it.
    if (isVivitraEnabled === true) {
      setShowVivitraConfirmation(false);
    } else {
      setShowVivitraConfirmation(true);
    }
  };

  const saveVivitraDetails = async () => {
    try {
      const body = JSON.stringify({
        patient_id: patientData?.patient_id,
        patient_name: patientDetails.patient_name,
        patient_phone: patientDetails.patient_contact_no,
        patient_meta_info: {
          "vivitra_eligible": 1
        },
      });
      const token = location.state?.token ?? sessionStorage.getItem("token");
      const secretKey = 'SfUyfAztruqg92sbm30rEIyHLNV7f5';
      const response = await fetch(SavePatientDetailsAPI, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
          'token': `${token}`,
          'health_secret': secretKey,
        },
        body: body,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      console.log(data, "vivitra-updated-successfully")
    } catch (error) {
      console.error("Error updating patient details:", error);
    }
  };

  const handleAddCycleButtonClick = () => {
    setShowCreateCycleModal(!showCreateCycleModal)
    // if (modalRef.current) {
    //   modalRef.current.scrollTop = 0;
    // }
    window.parent.postMessage("scrollToTop", "*")
  }

  return (
    <>
      {showDischargeApprove && (
        <div
          className={`confirmation-modal ${showDischargeApprove ? "overlay visible" : ""
            }`}
        >
          <ConfirmationModal
            show={showDischargeApprove}
            onConfirmation={handleConfirmation}
            onClose={handleCloseModal}
          />
        </div>
      )}
      {showPurchaseApprove && (
        <div
          className={`confirmation-modal ${showPurchaseApprove ? "overlay visible" : ""
            }`}
        >
          <ConfirmationModal
            show={showPurchaseApprove}
            onConfirmation={handleConfirmation}
            onClose={handleCloseModal}
          />
        </div>
      )}
      {showPrescriptionApprove && (
        <div
          className={`confirmation-modal ${showPrescriptionApprove ? "overlay visible" : ""
            }`}
        >
          <ConfirmationModal
            show={showPrescriptionApprove}
            onConfirmation={handleConfirmation}
            onClose={handleCloseModal}
          />
        </div>
      )}
      {showDischargeReupload && (
        <div
          className={`confirmation-modal ${showDischargeReupload ? "overlay visible" : ""
            }`}
        >
          <RequestToUpload
            show={showDischargeReupload}
            onConfirmation={handleReuploadConfirmation}
            onClose={handleCloseModal}
          />
        </div>
      )}
      {showPurchaseReupload && (
        <div
          className={`confirmation-modal ${showPurchaseReupload ? "overlay visible" : ""
            }`}
        >
          <RequestToUpload
            show={showPurchaseReupload}
            onConfirmation={handleReuploadConfirmation}
            onClose={handleCloseModal}
          />
        </div>
      )}
      {showPrescriptionReupload && (
        <div
          className={`confirmation-modal ${showPrescriptionReupload ? "overlay visible" : ""
            }`}
        >
          <RequestToUpload
            show={showPrescriptionReupload}
            onConfirmation={handleReuploadConfirmation}
            onClose={handleCloseModal}
          />
        </div>
      )}
      {showVivitraConfirmation && !isVivitraEnabled && (
        <div
          className={`confirmation-modal ${showVivitraConfirmation ? "overlay visible" : ""
            }`}
        >
          <ConfirmationModal
            show={showVivitraConfirmation}
            onConfirmation={handleVivitraConfirmation}
            onClose={handleCloseModal}
          />
        </div>
      )}
      {showCreateCycleModal && (
        <div ref={modalRef} className={`create-journey ${showCreateCycleModal ? "overlay visible" : ""}`}>
          <CreateJourney 
            onCloseModal={handleAddCycleButtonClick} 
            patientId={location.state?.patientId} 
            healCoachId={userDetails} 
            patient_name={patientDetails.patient_name}
            patient_phone={patientDetails.patient_contact_no}
            coins={coins}
          />
        </div>
      )}
      {/* <Modal
        centered
        open={showCreateCycleModal}
        footer={null}
        onCancel={() => setShowCreateCycleModal(false)}
        width={'50%'}
        style={{ height: '500px', marginTop: '70%' }}
        zIndex={10000}
      >
        <div ref={modalRef} className={`create-journey ${showCreateCycleModal ? "overlay visible" : ""}`}>
          <CreateJourney onCloseModal={handleAddCycleButtonClick} patientId={location.state?.patientId} healCoachId={userDetails} />
        </div>
      </Modal> */}
      <div className="desktop-3">
        <img className="desktop-3-child" alt="" src={rectangleIcon} />
        <header className="container-f-r-a-m-e">
          <Link
            to={`/${"all-patients/:token"}`}
            style={{ textDecoration: "none" }}
            state={{ token: `${token}` }}
          >
            <div className="rectangle-v-e-c-t-o-r">
              <div className="frame-f-r-a-m-e">
                <button
                /*   onClick={ () => handleBackButtonClick("all-patients")}>
                onClick={handleBackButtonClick}> */
                >
                  <img
                    className="arrow-left"
                    loading="lazy"
                    alt=""
                    src={arrowLeftIcon}
                  />
                </button>
              </div>
              <h3 className="products-list1">Patient Summary</h3>
            </div>
          </Link>
          <div className="checkbox-frame">
            <div
              className={`checkbox ${isVivitraEnabled !=="0" ? "checked" : ""}`}
              onClick={handleCheckboxChange}
            >
              <input
                type="checkbox"
                checked={isVivitraEnabled}
                onChange={() => { }}
              />
              <span className="checkmark"></span>
            </div>
            <div className="label-checkbox">
              Enable this patient for VIVITRA Program
            </div>
          </div>
        </header>
        <main className="group-f-r-a-m-e">
          <div className="line-l-i-n-e">
            <CaregiverInfo careGiverDetails={careGiverDetails} onSave={handleSave} />
            <PatientInfo patientDetails={patientDetails} onSave={handleSave} />
            <DoctorInfo doctorDetails={doctorDetails} onSave={handleSave} />
          </div>
          <div className="input-field-with-label-and-pla">
            {isVivitraEnabled && prescriptionPdfUrl && (
              <GroupComponent
                fRAMEFRAMEPlaceholder="Doctor Prescription"
                pdfUrl={prescriptionPdfUrl || ""}
                onApprove={() => handleApprove("prescriptions")}
                isApproved={isPrescriptionApproved}
                onReupload={() => handleReupload("prescriptions")}
                isReupload={isPrescriptionrReupload}
              />
            )}
            <GroupComponent
              fRAMEFRAMEPlaceholder="Discharge Summary"
              pdfUrl={dischargeSummaryPdfUrl || ""}
              onApprove={() => handleApprove("discharge")}
              isApproved={isDischargeApproved}
              onReupload={() => handleReupload("discharge")}
              isReupload={isDischargeReupload}
            />
            <GroupComponent
              fRAMEFRAMEPlaceholder="Purchase Invoice"
              pdfUrl={purchaseInvoicePdfUrl || ""}
              onApprove={() => handleApprove("purchase")}
              isApproved={isPurchaseApproved}
              onReupload={() => handleReupload("purchase")}
              isReupload={isPurchaseReupload}
            />
            <div className="frame-f-r-a-m-e1">
              <button
                className={(!isPurchaseApproved || !isDischargeApproved) ? "button-disabled" : "button"}
                onClick={handleAddCycleButtonClick}
                disabled={!isPurchaseApproved || !isDischargeApproved}
              >
                <div className="button-frame">
                  <div className="button1">Create Current Cycle</div>
                  <img
                    className="huge-iconarrowsoutlinedirec"
                    alt=""
                    src={directionRightIcon}
                  />
                </div>
              </button>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default VerifyDocuments;
