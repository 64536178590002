import { FunctionComponent, useState, useEffect } from "react";
import "./PatientInfo.css";
import editIcon from '../../assets/icons/edit.svg';
import 'react-toastify/dist/ReactToastify.css';

interface InfoProps {
  patientDetails: any;
  onSave?: (updatedDetails: any, userType:any) => void;
}

const PatientInfo: FunctionComponent<InfoProps> = ({ patientDetails, onSave }) => {

  const [editedDetails, setEditedDetails] = useState<any>({});
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (patientDetails) {
      setEditedDetails(patientDetails)
    }
  }, [patientDetails])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedDetails({ ...editedDetails, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    try {
      onSave?.(editedDetails, "Patient");
    } catch (error) {
    }
  }

  return (
    <div className="button-frame-group">
      <div className="button-frame2">
        <div className="products-list-group">
          <div className="products-list9">Patient Info</div>
          <div className="input-field-frame-label-frame1" onClick={handleSave}>
            <div className="frame-products-list-text-insta1">
              <img
                className="edit-icon4"
                loading="lazy"
                alt=""
                src={editIcon}
              />
            </div>
            <div className="text5">
              <div className="text-instance1">{isSaving ? 'Saving...' : 'Save'}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="line-div" />
      <div className="frame-products-list-input-fiel">
        <div className="products-list-input-field-grou1">
          <div className="input-field10">
            <div className="input-field-label">
              <div className="label-custom">Name*</div>
            </div>
            <input
              className="placeholder5"
              name="patient_name"
              value={editedDetails.patient_name || ""}
              placeholder="Enter Name"
              type="text"
              onChange={handleChange}
            />
          </div>
          <div className="input-field11">
            <div className="label-parent2">
              <div className="label-custom">Phone Number*</div>
            </div>
            <input
              className="placeholder5"
              name="patient_contact_no"
              value={editedDetails.patient_contact_no || ""}
              placeholder="Enter Number"
              type="text"
              onChange={handleChange}
            />
          </div>
          <div className="input-field10">
            <div className="input-field-label">
              <div className="label-custom">Gender*</div>
            </div>
            <input
              className="placeholder5"
              name="patient_gender"
              value={(editedDetails.patient_gender === "F" ? "Female" : (editedDetails.patient_gender === "M" ? "Male" : ""))}
              placeholder="Enter Gender"
              type="text"
              onChange={handleChange}
            />
          </div>
          <div className="input-field10">
            <div className="input-field-label">
              <div className="label-custom">Date of Birth*</div>
            </div>
            <input
              className="placeholder5"
              name="patient_dob"
              value={editedDetails.patient_dob || ""}
              placeholder="Enter Date of birth"
              type="text"
              onChange={handleChange}
            />
          </div>
          <div className="input-field10">
            <div className="input-field-label">
              <div className="label-custom">Height*</div>
            </div>
            <input
              className="placeholder5"
              name="patient_height"
              value={editedDetails.patient_height || ""}
              placeholder="Enter Height"
              type="text"
              onChange={handleChange}
            />
          </div>
          <div className="input-field10">
            <div className="input-field-label">
              <div className="label-custom">Weight*</div>
            </div>
            <input
              className="placeholder5"
              name="patient_weight"
              value={editedDetails.patient_weight || ""}
              placeholder="Enter Weight"
              type="text"
              onChange={handleChange}
            />
          </div>
          <div className="input-field10">
            <div className="input-field-label">
              <div className="label-custom">Email Address*</div>
            </div>
            <input
              className="placeholder5"
              name="patient_email"
              value={editedDetails.patient_email || ""}
              placeholder="Enter Email"
              type="text"
              onChange={handleChange}
            />
          </div>
          <div className="input-field10">
            <div className="input-field-label">
              <div className="label-custom">Address*</div>
            </div>
            <input
              className="placeholder5"
              name="patient_address"
              value={editedDetails.patient_address || ""}
              placeholder="Enter Address"
              type="text"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientInfo;
