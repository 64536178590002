import React, { useEffect, useRef, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LineChart, Line } from 'recharts';
import './NewDashboard.scss'
import { useNavigate, useParams } from "react-router-dom";
import { Progress } from "antd";
import Select from "react-select";
import moment from "moment";
import dayjs from "dayjs";
import ReactSelect from "react-select";
import Loader from "../../atoms/Loader";
import HealthCoachListing from "../../molecules/HealthCoachListing/HealthCoachListing";
import API from "../../services/rest/api";

import TotalPatientIcon from '../../assets/icons/TotalPatient.svg'
import ActivePatientIcon from '../../assets/icons/ActivePatient.svg'
import InActivePatientIcon from '../../assets/icons/InActivePatient.svg'
import PatientWithDoctor from '../../assets/icons/PatientWithDoctor.svg'
import { Chart } from "react-google-charts";
import { ExperienceIcon, GreenDotIcon, LanguageIcon, MenDotIcon, PhoneIcon, RedDotIcon, RightPurpleIcon, WomenDotIcon } from "../../assets/icons";
import HealthCoachIcon from "../../assets/icons/healthCoach.svg"
import AssessmentIcon from "../../assets/icons/AssessmentIcon.svg"
import AppointmentIcon from "../../assets/icons/AppointmentsIcon.svg"
import GenderIcon from "../../assets/icons/Gender.svg"



const NewDashboard = () => {
    const params = useParams()
    const navigate = useNavigate()
    const chartContainerRef = useRef<any>(null);
    const charAssessmentRef = useRef<any>(null);

    const [chartWidth, setChartWidth] = useState(0);
    const [chartAssessmentWidth, setChartAssessmentWidth] = useState(0)
    const [selectedHc, setSelectedHc] = useState<any>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [userDetails, setUserDetails] = useState<any>(null)
    const [selectedFilterPlan, setSelectedFilterPlan] = useState<any>('plans')
    const [selectedAppointmentTab, setSelectedAppointmentTab] = useState<number>(1)
    const [hcData, setHcData] = useState<any>([])
    const [dashboardCount, setDashboardCount] = useState<any>(null);
    const [overAllPatientCount, setOverAllPatientCount] = useState<any>(null)

    const [assessmentGraphData, setAssessmentGraphData] = useState<any>([])
    const [appointmentsGraphData, setAppointmentsGraphData] = useState<any>(null)
    const [indicationWisePatients, setIndicationWisePatients] = useState<any>([])
    const [planExpireUpcomingDueData, setPlanExpireUpcomingDueData] = useState<any>([]);

    const [assessmentFilter, setAssessmentFilter] = useState<any>({ hcFilter: 'All', daysFilter: '7D' })
    const [appointmentsFilter, setAppointmentsFilter] = useState<any>({ hcFilter: 'All', daysFilter: '7D' })
    const [planReportFilter, setPlanReportFilter] = useState<any>('7D')
    const [patientConditionFilter, setPatientConditionFilter] = useState<any>('All')
    const [selectedExpireFilter, setSelectedExpireFilter] = useState<any>('upcoming')
    const [selectedExpireTime, setSelectedExpireTime] = useState<any>('7D')
    const [hcOptions, setHcOptions] = useState<any>([])

    console.log(assessmentGraphData)

    const timeOptions: any = [
        { value: '7D', label: 'Last 7 Days' },
        { value: '15D', label: '15 Days' },
        { value: '30D', label: '30 Days' },
        { value: 'TM', label: 'This Month' },
        { value: '1Y', label: '1 Year' },
    ];

    const assessmentTimeOptions: any = [
        { value: '7D', label: 'Last 7 Days' },
        { value: '15D', label: '15 Days' },
        { value: '30D', label: '30 Days' },
        { value: '90D', label: '90 Days' },
        { value: '1Y', label: '1 Year' },
    ];

    const appointmentTimeOptions: any = [
        { value: '7D', label: 'Last 7 Days' },
        { value: '15D', label: '15 Days' },
        { value: '30D', label: '30 Days' },
        { value: '90D', label: '90 Days' },
        { value: '1Y', label: '1 Year' },
    ];

    const UpcomingTabOptions: any = [
        { value: '7D', label: 'Next 7 Days' },
        { value: '15D', label: '15 Days' },
        { value: '30D', label: '30 Days' },
        { value: 'TM', label: 'This Month' },
        { value: '1Y', label: '1 Year' },
    ]

    const DueTabOptions: any = [
        { value: '7D', label: 'Last 7 Days' },
        { value: '15D', label: '15 Days' },
        { value: '30D', label: '30 Days' },
        { value: 'TM', label: 'This Month' },
        { value: '1Y', label: '1 Year' },
    ]

    useEffect(() => {
        const token: any = params.token
        localStorage.setItem('token', token)
    }, [params])

    useEffect(() => {
        getProfileDetails()
        getOverallPatientCount()
        getIndicationWisePatients()
    }, [])

    useEffect(() => {
        if (userDetails?.role) {
            getHCList()
        }
    }, [userDetails])

    useEffect(() => {
        const updateChartWidth = () => {
            if (chartContainerRef.current) {
                const containerWidth = chartContainerRef.current.clientWidth;
                const newChartWidth = containerWidth; // Adjust the percentage as needed
                setChartWidth(newChartWidth);
            }
        };
        updateChartWidth();
        window.addEventListener('resize', updateChartWidth);
        return () => {
            window.removeEventListener('resize', updateChartWidth);
        };
    }, []);

    useEffect(() => {
        const updateChartWidth = () => {
            if (charAssessmentRef.current) {
                const containerWidth = charAssessmentRef.current.clientWidth;
                const newChartWidth = containerWidth * 1; // Adjust the percentage as needed
                setChartAssessmentWidth(newChartWidth);
            }
        };
        updateChartWidth();
        window.addEventListener('resize', updateChartWidth);
        return () => {
            window.removeEventListener('resize', updateChartWidth);
        };
    }, []);

    useEffect(() => {
        getAssessmentGraph()
    }, [assessmentFilter])

    useEffect(() => {
        getAppointmentGraphData()
    }, [appointmentsFilter])

    useEffect(() => {
        getDashboardCount()
    }, [planReportFilter])

    useEffect(() => {
        getIndicationWisePatients()
    }, [patientConditionFilter])

    useEffect(() => {
        getPlanUpcomingDueDataList()
    }, [selectedExpireTime, selectedExpireFilter])

    //funtion to get Overall Dahboard Count
    const getOverallPatientCount = async () => {
        try {
            const count = await API.GetOverallPatientCount()
            if (count.code === '1') {
                setOverAllPatientCount(count?.data)
            }
            else {
                console.log(count?.message)
            }
        } catch (error) {
            console.log(error)
        }
    }

    //funtion to get dashboard count
    const getDashboardCount = async () => {
        try {
            const count = await API.GetDashboardCount({ time_period: planReportFilter })
            if (count.code === '1') {
                setDashboardCount(count?.data)
            }
            else {
                console.log(count?.message)
            }
        } catch (error) {
            console.log(error)
        }
    }

    //funtion to get profile details
    const getProfileDetails = async () => {
        try {
            setIsLoading(true)
            const userInfo = await API.GetProfileDetails()
            if (userInfo?.code === '1') {
                localStorage.setItem('userInfo', JSON.stringify(userInfo?.data))
                setUserDetails(userInfo?.data)
                setIsLoading(false)
            }
            else {
                setIsLoading(false)
                setUserDetails(null)
            }
        } catch (error) {
            setIsLoading(false)
        }
    }

    //funtion to get HC list
    const getHCList = async () => {
        try {
            setIsLoading(true)
            const hcList = await API.GetDashboardHcList({ role: userDetails?.role })
            if (hcList?.code === '1') {
                setHcData(hcList?.data)
                setSelectedHc(hcList?.data[0])
                setIsLoading(false)
                const allHcOptions = hcList?.data?.map((hc: any) => {
                    return { ...hc, label: (hc?.first_name + ' ' + hc?.last_name), value: hc?.health_coach_id }
                })
                allHcOptions.unshift(
                    {
                        label: "All Health Coaches",
                        value: "All"
                    },
                    {
                        label: `${(userDetails?.first_name + ' ' + userDetails?.last_name) + '(You)'}`,
                        value: userDetails?.health_coach_id
                    }
                );
                setHcOptions(allHcOptions)
            }
            else {
                setHcData([])
                setIsLoading(false)
                setHcOptions([])
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    //function to get assessment graph
    const getAssessmentGraph = async () => {
        try {
            // setIsLoading(true)
            const graphData = await API.GetDashboardAssessmentGraph({ taken_time: assessmentFilter?.daysFilter, selected_coach: assessmentFilter?.hcFilter })
            if (graphData?.code === '1') {
                setAssessmentGraphData(graphData?.data)
                // setIsLoading(false)
            }
            else {
                setAssessmentGraphData([])
                // setIsLoading(false)
            }
        } catch (error) {
            console.log(error)
            // setIsLoading(false)
        }
    }

    //funtion to get appointment graph data
    const getAppointmentGraphData = async () => {
        try {
            const graphAppointmentData = await API.GetAppointmentGraph({ duration: appointmentsFilter?.daysFilter, health_coach_id: appointmentsFilter?.hcFilter })
            if (graphAppointmentData?.code == '1') {
                setAppointmentsGraphData(graphAppointmentData?.data)
            }
            else {
                setAppointmentsGraphData(null)
            }
        } catch (error) {
            setAppointmentsGraphData(null)
            console.log(error)
        }
    }

    //function to get assessment graph
    const getIndicationWisePatients = async () => {
        try {
            // setIsLoading(true)
            const graphData = await API.GetIndicationWisePatientData({ selected_coach: patientConditionFilter })
            if (graphData?.code === '1') {
                setIndicationWisePatients(graphData?.data)
                // setIsLoading(false)
            }
            else {
                setIndicationWisePatients([])
                // setIsLoading(false)
            }
        } catch (error) {
            // setIsLoading(false)
        }
    }

    //funtion to get plan upcoming due data
    const getPlanUpcomingDueDataList = async () => {
        try {
            // setIsLoading(true)
            const graphData = await API.GetPlanUpcomingDueData({ time_period: selectedExpireTime, selected_tab: selectedExpireFilter })
            if (graphData?.code === '1') {
                setPlanExpireUpcomingDueData(graphData?.data)
                // setIsLoading(false)
            }
            else {
                setPlanExpireUpcomingDueData([])
                // setIsLoading(false)
            }
        } catch (error) {
            // setIsLoading(false)
        }
    }

    const percentageValue = (approved: any, total: any) => {
        const totalPlans = total || 0;
        const totalApprovedPlans = approved || 0;

        const percentage = ((totalApprovedPlans / totalPlans) * 100) || 0;
        const formattedPercentage = parseFloat(percentage.toFixed(2));
        return formattedPercentage;
    };

    const goToPatients = (hc: any, type?: string) => {
        // navigate(`/patients/${hc.health_coach_id}`, { state: { type: type, hc_role: hc?.role } })
        navigate(`/patients/${hc.health_coach_id}`, { state: { type: 'active', hc_role: hc?.role } })
    }

    const getPercentage = () => {
        if (selectedFilterPlan == 'plans') {
            const planProgress = (dashboardCount?.total_approved_plans / dashboardCount?.total_plans) * 100
            return (planProgress || 0)
        }
        else {
            const reportProgress = (dashboardCount?.total_approved_reports / dashboardCount?.total_reports) * 100
            return (reportProgress || 0)
        }
    }

    const customStyles2 = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            // borderColor: '#9e9e9e',
            minHeight: '30px',
            height: '30px',
            boxShadow: state.isFocused ? null : null,
            border: 'none',
            width: '150px',
        }),

        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            padding: '6px 0px',
            fontSize: '13px',
            marginTop: '-12px'
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '2px',
        }),
        indicatorSeparator: (state: any) => ({
            display: 'none',
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            marginTop: '-1px'
        }),
    };

    const customStyles3 = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            borderColor: '#E2E2EA',
            minHeight: '40px',
            height: '40px',
            boxShadow: state.isFocused ? null : null,
            width: '100%'
        }),

        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            padding: '6px 6px',
            fontSize: '13px',
            marginTop: '-8px'
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '2px',
        }),
        indicatorSeparator: (state: any) => ({
            display: 'none',
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '40px',
            marginTop: '-1px'
        }),
    };

    //Patient Gender graph
    const options = {
        pieHole: 0.6,
        is3D: false,
        pieSliceText: "none", // Hide percentage values
        legend: "none", // Hide legend
        colors: ["#01D1FC", "#427DFE"],
    };

    const data = [
        ["Task", "Count"],
        ["Men", overAllPatientCount?.total_male_patients || 0],
        ["Women", overAllPatientCount?.total_female_patients || 0],
    ];

    //hc active inactive patient graph
    const hcPatientOptions = {
        pieHole: 0.6,
        is3D: false,
        pieSliceText: "none", // Hide percentage values
        legend: "none", // Hide legend
        colors: ["#19BB7A", "#F04545"],
        backgroundColor: "#FAFAFB",
        tooltip: {
            trigger: 'hover',
            textStyle: {
                fontSize: 10
            },
        }
    };

    const hcPatientData = [
        ["Task", 'Count'],
        ["Active", selectedHc?.active_patient_count],
        ["InActive", selectedHc?.inactive_patient_count],
    ];

    return (
        <>
            {isLoading &&
                <div className="loaderAbsolute">
                    <Loader noBackground={true} />
                </div>
            }

            <div className="dashboard-page">
                <div className="dashboard-header">
                    <span className="dashboard-header-name">Welcome, {userDetails?.first_name} {userDetails?.last_name}</span>
                    <span className="dashboard-header-date"><span style={{ color: '#A724FC', fontWeight: '500' }}>{moment().format('dddd')}</span> , {moment().format('ll')}</span>
                </div>

                <div className="dashboard-page-first-row">
                    <div className="dashboard-page-first-row-left">
                        <div className="dashboard-page-first-row-card-wrapper">
                            <div className="dashboard-page-first-row-card">
                                <img src={TotalPatientIcon} alt='total_patient' />
                                <div className="dashboard-page-first-row-card-details">
                                    <span style={{ fontSize: '16px', fontWeight: '400' }}>Total Patients</span>
                                    <span style={{ fontSize: '24px', fontWeight: '600' }}>{overAllPatientCount?.total_patients || 0}</span>
                                </div>
                            </div>

                            <div className="dashboard-page-first-row-card">
                                <img src={ActivePatientIcon} alt='total_patient' />
                                <div className="dashboard-page-first-row-card-details">
                                    <span style={{ fontSize: '16px', fontWeight: '400' }}>Active Patients (Plans)</span>
                                    <span style={{ fontSize: '24px', fontWeight: '600' }}>{overAllPatientCount?.total_active_patients || 0}</span>
                                </div>
                            </div>
                        </div>

                        <div className="dashboard-page-first-row-card-wrapper">
                            <div className="dashboard-page-first-row-card">
                                <img src={PatientWithDoctor} alt='total_patient' />
                                <div className="dashboard-page-first-row-card-details">
                                    <span style={{ fontSize: '16px', fontWeight: '400' }}>Patients linked with Doctors</span>
                                    <span style={{ fontSize: '24px', fontWeight: '600' }}>{overAllPatientCount?.patients_linked_with_doctor || 0}</span>
                                </div>
                            </div>

                            <div className="dashboard-page-first-row-card">
                                <img src={InActivePatientIcon} alt='total_patient' />
                                <div className="dashboard-page-first-row-card-details">
                                    <span style={{ fontSize: '16px', fontWeight: '400' }}>Inactive Patients (Plans)</span>
                                    <span style={{ fontSize: '24px', fontWeight: '600' }}>{overAllPatientCount?.total_inactive_patients || 0}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="dashboard-page-first-row-right">
                        <span style={{ fontSize: '18px', fontWeight: '600' }}>Patients by Gender</span>

                        <div className="dashboard-gender-wrapper">
                            <div style={{ position: "relative", width: "150px", height: "150px" }}>
                                <Chart
                                    chartType="PieChart"
                                    data={data}
                                    options={options}
                                    width={"100%"}
                                    height={"100%"}
                                />
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "51%",
                                        left: "51%",
                                        transform: "translate(-50%, -50%)",
                                    }}
                                >
                                    <img src={GenderIcon} alt="icon" />
                                </div>
                            </div>

                            <div className="dashboard-gender-details">
                                <div style={{ display: 'flex', gap: '10px', alignItems: "center" }}>
                                    <MenDotIcon />
                                    <span style={{ fontSize: '16px', fontWeight: '400px' }}>Men: <span style={{ fontWeight: '600' }}>{overAllPatientCount?.total_male_patients || 0}</span></span>
                                </div>
                                <div style={{ display: 'flex', gap: '10px', alignItems: "center" }}>
                                    <WomenDotIcon />
                                    <span style={{ fontSize: '16px', fontWeight: '400px' }}>Women: <span style={{ fontWeight: '600' }}>{overAllPatientCount?.total_female_patients || 0}</span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="dashboard-page-second-row">
                    <div className="dashboard-page-second-row-left">
                        <div className="dashboard-health-coach-list-container">
                            <div className="dashboard-health-coach-list-header">
                                <img src={HealthCoachIcon} />
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '16px', fontWeight: '400' }}>Total Health Coaches</span>
                                    <span style={{ fontSize: '24px', fontWeight: '600' }}>{((hcData || [])?.length) || 0}</span>
                                </div>
                            </div>
                            <div className="dashboard-health-coach-list">
                                {(hcData || []).map((hc: any) => {
                                    return (
                                        <div className="dashboard-health-coach" onClick={() => setSelectedHc(hc)} style={selectedHc?.health_coach_id === hc?.health_coach_id ? { background: '#F6EAFF', border: '1px solid #A724FC' } : {}}>
                                            <img src={hc?.profile_pic} alt="hc_profile" style={{ width: '42px', height: '42px', borderRadius: '50%' }} />
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: '16px', fontWeight: '500' }}>{hc?.first_name} {hc?.last_name}</span>
                                                <span style={{ fontSize: '14px', fontWeight: '400' }}>{hc?.role}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        {selectedHc &&
                            <div className="dashboard-selected-health-coach">
                                <div className="dashboard-selected-health-coach-header">
                                    <img src={selectedHc?.profile_pic} alt="selectedHc_profile" style={{ width: '62px', height: '62px', borderRadius: '50%' }} />
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '20px', fontWeight: '500' }}>{selectedHc?.first_name} {selectedHc?.last_name}</span>
                                        <span style={{ fontSize: '16px', fontWeight: '400' }}>{selectedHc?.role}</span>
                                    </div>
                                </div>
                                <div className="dashboard-selected-health-coach-contact">
                                    <div style={{ display: 'flex', gap: '15px', alignItems: "center" }}>
                                        <PhoneIcon />
                                        <span>{selectedHc?.contact_no}</span>
                                    </div>
                                    <div style={{ display: 'flex', gap: '15px', alignItems: "center" }}>
                                        <ExperienceIcon />
                                        <span>{selectedHc?.years_of_experience} years of experience</span>
                                    </div>
                                    <div style={{ display: 'flex', gap: '15px', alignItems: "center" }}>
                                        <LanguageIcon />
                                        <span>{selectedHc?.language_spoken}</span>
                                    </div>
                                </div>

                                <div style={{ border: '1px solid #E2E2EA', width: '93%', margin: '0 auto' }}></div>

                                <div className="dashboard-selected-health-coach-graph">
                                    <Chart
                                        chartType="PieChart"
                                        data={hcPatientData}
                                        options={hcPatientOptions}
                                        width={"100px"}
                                        height={"100px"}
                                    />

                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                                        <span style={{ fontSize: '16px', fontWeight: '400' }}>Total Patient: <span style={{ fontWeight: '600' }}>{selectedHc?.all_patients_count}</span></span>
                                        <div style={{ display: 'flex', gap: '20px' }}>
                                            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                <GreenDotIcon />
                                                <span style={{ fontSize: '16px', fontWeight: '400' }}>Active: <span style={{ fontWeight: '600' }}>{selectedHc?.active_patient_count}</span></span>
                                            </div>
                                            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                <RedDotIcon />
                                                <span style={{ fontSize: '16px', fontWeight: '400' }}>Inactive <span style={{ fontWeight: '600' }}>{selectedHc?.inactive_patient_count}</span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dashboard-selected-health-coach-details">
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                        <div style={{ border: '1px solid #427DFE', height: '35px' }}></div>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ fontSize: '16px', fontWeight: '500' }}>{selectedHc?.pending_plans} Plans</span>
                                            <span style={{ fontSize: '14px', fontWeight: '400' }}>Pending Approvals</span>
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                        <div style={{ border: '1px solid #427DFE', height: '35px' }}></div>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ fontSize: '16px', fontWeight: '500' }}>{selectedHc?.pending_reports} Reports</span>
                                            <span style={{ fontSize: '14px', fontWeight: '400' }}>Pending Approvals</span>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    style={{ border: '1px solid #A461D8', color: '#A461D8', background: 'white', borderRadius: '10px', width: '90%', margin: 'auto', padding: '10px', alignItems: 'center', display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
                                    onClick={() => {
                                        goToPatients(selectedHc)
                                    }}
                                >
                                    <span style={{ flex: 1, textAlign: 'center' }}>View Details</span>
                                    <RightPurpleIcon />
                                </div>
                            </div>
                        }
                    </div>

                    <div className="dashboard-page-second-row-right">
                        <div className="dashboard-page-second-row-right-header">
                            <span style={{ fontSize: '18px', fontWeight: '600' }}>Plans & Reports</span>
                            <div style={{ width: '150px' }}>
                                <Select
                                    value={timeOptions.find((val: any) => val.value == planReportFilter)}
                                    onChange={(e: any) => setPlanReportFilter(e.value)}
                                    options={timeOptions}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 6,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#F0E7F7',
                                            primary: '#760fb2',
                                        },
                                    })}
                                    styles={customStyles3}
                                />
                            </div>
                        </div>

                        <div className="dashboard-page-second-row-right-tabs">
                            <div
                                style={selectedFilterPlan == 'plans' ? { fontSize: '14px', fontWeight: '500', width: '50%', alignItems: 'center', textAlign: 'center', display: 'flex', justifyContent: 'center', gap: '5px', cursor: 'pointer', color: '#A724FC', borderBottom: '2px solid #A724FC', paddingBottom: '10px' } : { fontSize: '14px', fontWeight: '500', width: '50%', alignItems: 'center', textAlign: 'center', display: 'flex', justifyContent: 'center', gap: '5px', cursor: 'pointer', paddingBottom: '10px' }}
                                onClick={() => setSelectedFilterPlan('plans')}
                            >
                                <span>Plans</span> {dashboardCount?.pending_plans > 0 && <span style={selectedFilterPlan == 'plans' ? { borderRadius: '10px', background: '#A724FC', color: 'white', padding: '2px 8px' } : { borderRadius: '10px', background: 'black', color: 'white', padding: '2px 8px' }}>{dashboardCount?.pending_plans}</span>}
                            </div>
                            <div
                                style={selectedFilterPlan == 'reports' ? { fontSize: '14px', fontWeight: '500', width: '50%', alignItems: 'center', textAlign: 'center', display: 'flex', justifyContent: 'center', gap: '5px', cursor: 'pointer', color: '#A724FC', borderBottom: '2px solid #A724FC', paddingBottom: '10px' } : { fontSize: '14px', fontWeight: '500', width: '50%', alignItems: 'center', textAlign: 'center', display: 'flex', justifyContent: 'center', gap: '5px', cursor: 'pointer', paddingBottom: '10px' }}
                                onClick={() => setSelectedFilterPlan('reports')}
                            >
                                <span>Reports</span> {dashboardCount?.pending_reports > 0 && <span style={selectedFilterPlan == 'reports' ? { borderRadius: '10px', background: '#A724FC', color: 'white', padding: '2px 8px' } : { borderRadius: '10px', background: 'black', color: 'white', padding: '2px 8px' }}>{dashboardCount?.pending_reports}</span>}
                            </div>
                        </div>

                        <div className="dashboard-page-second-row-right-details">
                            <Progress
                                strokeLinecap="round"
                                type="dashboard"
                                percent={getPercentage()}
                                strokeWidth={5}
                                width={250}
                                format={() => (
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                        <span style={{ fontSize: '28px', fontWeight: '600', color: 'black' }}>
                                            {getPercentage().toFixed(2)}%
                                        </span>
                                        <span style={{ fontSize: '16px', fontWeight: '500', color: 'black' }}>
                                            {selectedFilterPlan == 'plans' ? 'Plans Progress' : 'Reports Progress'}
                                        </span>
                                    </div>
                                )}
                                strokeColor={'#01D1FC'}
                            />

                            <div className="dashboard-page-second-row-right-details-count">
                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '50%' }}>
                                    <div style={{ border: '1px solid #4B4AD5', height: '35px' }}></div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '16px', fontWeight: '500' }}>{selectedFilterPlan == 'plans' ? dashboardCount?.total_plans : dashboardCount?.total_reports}</span>
                                        <span style={{ fontSize: '14px', fontWeight: '400' }}>Total {selectedFilterPlan == 'plans' ? 'Plans' : 'Reports'}</span>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '50%' }}>
                                    <div style={{ border: '1px solid #19BB7A', height: '35px' }}></div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '16px', fontWeight: '500' }}>{selectedFilterPlan == 'plans' ? dashboardCount?.total_approved_plans : dashboardCount?.total_approved_reports}</span>
                                        <span style={{ fontSize: '14px', fontWeight: '400' }}>Approved {selectedFilterPlan == 'plans' ? 'Plans' : 'Reports'}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="dashboard-page-second-row-right-details-count">
                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '50%' }}>
                                    <div style={{ border: '1px solid #ED8A00', height: '35px' }}></div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '16px', fontWeight: '500' }}>{selectedFilterPlan == 'plans' ? dashboardCount?.pending_plans : dashboardCount?.pending_reports}</span>
                                        <span style={{ fontSize: '14px', fontWeight: '400' }}>Pending {selectedFilterPlan == 'plans' ? 'Plans' : 'Reports'}</span>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '50%' }}>
                                    <div style={{ border: '1px solid #F04545', height: '35px' }}></div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '16px', fontWeight: '500' }}>{selectedFilterPlan == 'plans' ? dashboardCount?.total_rejected_plans : dashboardCount?.total_rejected_reports}</span>
                                        <span style={{ fontSize: '14px', fontWeight: '400' }}>Rejected {selectedFilterPlan == 'plans' ? 'Plans' : 'Reports'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="dashboard-page-third-row">
                    <div className="dashboard-page-third-row-left">
                        <div className="dashboard-page-third-row-left-header">
                            <div style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
                                <img src={AssessmentIcon} />
                                <div style={{ display: 'flex', flexDirection: "column" }}>
                                    <span style={{ fontSize: '18px', fontWeight: '600' }}>Assessments</span>
                                    <Select
                                        value={hcOptions.find((val: any) => val.value == assessmentFilter?.hcFilter)}
                                        onChange={(e) => setAssessmentFilter({ ...assessmentFilter, hcFilter: e.value })}
                                        options={hcOptions}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 6,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#F0E7F7',
                                                primary: '#760fb2',
                                            },
                                        })}
                                        styles={customStyles2}
                                    />
                                </div>
                            </div>
                            <div style={{ width: '150px' }}>
                                <Select
                                    value={assessmentTimeOptions.find((val: any) => val.value == assessmentFilter?.daysFilter)}
                                    onChange={(e) => setAssessmentFilter({ ...assessmentFilter, daysFilter: e.value })}
                                    options={assessmentTimeOptions}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 6,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#F0E7F7',
                                            primary: '#760fb2',
                                        },
                                    })}
                                    styles={customStyles3}
                                />
                            </div>
                        </div>
                        <div className="dashboard-page-third-row-left-content">
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '50%' }}>
                                <div style={{ border: '1px solid #4B4AD5', height: '35px' }}></div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '16px', fontWeight: '500' }}>{assessmentGraphData.reduce((acc: any, curr: any) => acc + curr.y_value, 0)}</span>
                                    <span style={{ fontSize: '14px', fontWeight: '400' }}>Total Assessments</span>
                                </div>
                            </div>
                        </div>
                        <div ref={charAssessmentRef} style={{ width: '92%', display: 'flex', justifyContent: 'center', padding: '20px' }}>
                            <BarChart width={chartAssessmentWidth} height={350} data={assessmentGraphData}>
                                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                                <XAxis dataKey="x_value" axisLine={false} />
                                <YAxis axisLine={false} tickLine={false} tickMargin={10} />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="y_value" name="Assessments" fill="#427DFE" barSize={10} radius={[10, 10, 0, 0]} />
                            </BarChart>
                        </div>
                    </div>
                    <div className="dashboard-page-third-row-right">
                        <span style={{ fontSize: '18px', fontWeight: '600' }}>Patients by Condition</span>

                        <div style={{ display: 'flex', width: '100%', marginTop: '10px' }}>
                            <div style={{ width: '100%' }}>
                                <Select
                                    value={hcOptions.find((val: any) => val.value === patientConditionFilter)}
                                    onChange={(e) => setPatientConditionFilter(e.value)}
                                    options={hcOptions}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 6,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#F0E7F7',
                                            primary: '#760fb2',
                                        },
                                    })}
                                    styles={customStyles3}
                                />
                            </div>
                        </div>

                        <div className="indication-wise-patient-list">
                            {
                                (indicationWisePatients || [])?.map((disease: any) => {
                                    return (
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <span style={{ fontSize: '16px', fontWeight: '400' }}>{disease?.x_value}</span>
                                            <span style={{ fontSize: '16px', fontWeight: '600' }}>{disease?.y_value}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className="dashboard-page-fourth-row">
                    <div className="dashboard-page-fourth-row-left">
                        <div className="dashboard-page-fourth-row-left-header">
                            <div style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
                                <img src={AppointmentIcon} />
                                <div style={{ display: 'flex', flexDirection: "column" }}>
                                    <span style={{ fontSize: '18px', fontWeight: '600' }}>Appointments</span>
                                    <Select
                                        value={hcOptions.find((val: any) => val.value == appointmentsFilter?.hcFilter)}
                                        onChange={(e) => setAppointmentsFilter({ ...appointmentsFilter, hcFilter: e.value })}
                                        options={hcOptions}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 6,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#F0E7F7',
                                                primary: '#760fb2',
                                            },
                                        })}
                                        styles={customStyles2}
                                    />
                                </div>
                            </div>
                            <div style={{ width: '150px' }}>
                                <Select
                                    value={appointmentTimeOptions.find((val: any) => val.value == appointmentsFilter?.daysFilter)}
                                    onChange={(e) => setAppointmentsFilter({ ...appointmentsFilter, daysFilter: e.value })}
                                    options={appointmentTimeOptions}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 6,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#F0E7F7',
                                            primary: '#760fb2',
                                        },
                                    })}
                                    styles={customStyles3}
                                />
                            </div>
                        </div>
                        <div className="dashboard-page-fourth-row-left-content">
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <div style={{ border: '1px solid #A461D8', height: '35px' }}></div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '16px', fontWeight: '500' }}>{appointmentsGraphData?.total_appointment}</span>
                                    <span style={{ fontSize: '14px', fontWeight: '400' }}>Total Appointments</span>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <div style={{ border: '1px solid #427DFE', height: '35px' }}></div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '16px', fontWeight: '500' }}>{appointmentsGraphData?.completed_appointments}</span>
                                    <span style={{ fontSize: '14px', fontWeight: '400' }}>Completed Appointments</span>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <div style={{ border: '1px solid #01D1FC', height: '35px' }}></div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '16px', fontWeight: '500' }}>{appointmentsGraphData?.pending_appointment}</span>
                                    <span style={{ fontSize: '14px', fontWeight: '400' }}>Pending Appointments</span>
                                </div>
                            </div>
                        </div>
                        <div ref={chartContainerRef} style={{ width: '92%', display: 'flex', justifyContent: 'center', padding: '20px' }}>
                            <BarChart width={chartWidth} height={350} data={appointmentsGraphData?.data}>
                                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                                <XAxis dataKey="x_value" axisLine={false} />
                                <YAxis axisLine={false} tickLine={false} tickMargin={10} />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="completed_appointments" name="Completed Appointments" fill="#427DFE" barSize={10} radius={[10, 10, 0, 0]} />
                                <Bar dataKey="pending_appointments" name="Pending Appointments" fill="#01D1FC" barSize={10} radius={[10, 10, 0, 0]} />
                            </BarChart>
                        </div>
                    </div>
                    <div className="dashboard-page-fourth-row-right">
                        <div style={{ display: 'flex', width: '100%', marginTop: '10px', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span style={{ fontSize: '18px', fontWeight: '600' }}>Plans expiring</span>
                            <div style={{ width: '150px' }}>
                                <Select
                                    value={(selectedExpireFilter == 'upcoming' ? UpcomingTabOptions : DueTabOptions).find((val: any) => val.value === selectedExpireTime)}
                                    onChange={(e) => setSelectedExpireTime(e.value)}
                                    options={(selectedExpireFilter == 'upcoming' ? UpcomingTabOptions : DueTabOptions)}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 6,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#F0E7F7',
                                            primary: '#760fb2',
                                        },
                                    })}
                                    styles={customStyles3}
                                />
                            </div>
                        </div>

                        <div className="dashboard-page-fourth-row-right-tabs">
                            <div
                                style={selectedExpireFilter == 'upcoming' ? { fontSize: '14px', fontWeight: '500', width: '50%', alignItems: 'center', textAlign: 'center', display: 'flex', justifyContent: 'center', gap: '5px', cursor: 'pointer', color: '#A724FC', borderBottom: '2px solid #A724FC', paddingBottom: '10px' } : { fontSize: '14px', fontWeight: '500', width: '50%', alignItems: 'center', textAlign: 'center', display: 'flex', justifyContent: 'center', gap: '5px', cursor: 'pointer', paddingBottom: '10px' }}
                                onClick={() => setSelectedExpireFilter('upcoming')}
                            >
                                <span>Upcoming</span>
                                {/* {planExpireUpcomingDueData?.length > 0 && <span style={selectedExpireFilter == 'upcoming' ? { borderRadius: '10px', background: '#A724FC', color: 'white', padding: '2px 8px' } : { borderRadius: '10px', background: 'black', color: 'white', padding: '2px 8px' }}>{planExpireUpcomingDueData?.length}</span>} */}
                            </div>
                            <div
                                style={selectedExpireFilter == 'due' ? { fontSize: '14px', fontWeight: '500', width: '50%', alignItems: 'center', textAlign: 'center', display: 'flex', justifyContent: 'center', gap: '5px', cursor: 'pointer', color: '#A724FC', borderBottom: '2px solid #A724FC', paddingBottom: '10px' } : { fontSize: '14px', fontWeight: '500', width: '50%', alignItems: 'center', textAlign: 'center', display: 'flex', justifyContent: 'center', gap: '5px', cursor: 'pointer', paddingBottom: '10px' }}
                                onClick={() => setSelectedExpireFilter('due')}
                            >
                                <span>Due</span>
                                {/* {planExpireUpcomingDueData?.length > 0 && <span style={selectedExpireFilter == 'due' ? { borderRadius: '10px', background: '#A724FC', color: 'white', padding: '2px 8px' } : { borderRadius: '10px', background: 'black', color: 'white', padding: '2px 8px' }}>{planExpireUpcomingDueData?.length}</span>} */}
                            </div>
                        </div>

                        <div className="dashboard-page-fourth-row-right-content-list">
                            {(planExpireUpcomingDueData || []).map((data: any) => {
                                return (
                                    <div className="dashboard-page-fourth-row-right-content">
                                        <div className="dashboard-page-fourth-row-right-content-left">
                                            <img src={data?.profile_pic} alt='profile' style={{ width: '42px', height: '42px', borderRadius: '50%' }} />
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: '16px', fontWeight: '500' }}>{data?.name}</span>
                                                <span style={{ fontSize: '14px', fontWeight: '400' }}>{data?.medical_condition_name}</span>
                                            </div>
                                        </div>
                                        <div style={{ width: '68px', borderRadius: '10px', padding: '7px 8px', textAlign: 'center', fontSize: '14px', fontWeight: '600', background: data?.bg_color, color: data?.font_color }}>
                                            {moment(data?.expiry_date).format('DD MMM')}
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default NewDashboard