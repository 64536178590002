import React from 'react';
import { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Button, message, Modal, Popover, Table } from 'antd';
import type { TablePaginationConfig } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import ViewDietPlanTemplate from './ViewDietPlanTemplate';
import AddDietPlanTemplate from './AddDietPlanTemplate';
import CopyDietTemplate from './CopyDietPlanTemplate';
import API from '../../services/rest/api';

import './index.scss';

const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_PAGINATION = { current: 1, pageSize: DEFAULT_PAGE_SIZE, total: 0 }

const DietPlanTemplate: React.FC = () => {

    const params = useParams();
    const location = useLocation();

    const [loading, setLoading] = useState<boolean>(false);
    const [refreshList, setRefreshList] = useState<boolean>(false);
    const [templateList, setTemplateList] = useState<Array<any>>([]);
    const [pagination, setPagination] = useState<TablePaginationConfig>(DEFAULT_PAGINATION);

    const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);

    const [viewModalOpen, setViewModalOpen] = useState<boolean>(false);
    const [copyTemplateModal, setCopyTemplateModal] = useState<boolean>(false);
    const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
    const [editModal, setEditModal] = useState<boolean>(false);
    const [dataAdded, setDataAdded] = useState<boolean>(false);

    useEffect(() => {
        const token = params.token;
        const getToken = () => (location.state?.token === ':token' || location.state?.token === undefined) ? token : location.state?.token;

        localStorage.setItem('token', getToken());
        sessionStorage.setItem('token', getToken());
    }, [params]);

    const columns = [
        {
            title: 'Template Name',
            dataIndex: 'template_name',
            key: 'template_name',
            render: (name: string) => <a style={{ color: '#760fb2' }}>{name}</a>,
        },
        {
            title: 'Total Calories Goal',
            dataIndex: 'total_calories',
            key: 'total_calories',
        },
        {
            title: 'Actual Calories',
            dataIndex: 'actual_calories',
            key: 'actual_calories',
        },
        {
            title: 'Created By',
            dataIndex: 'coach_name',
            key: 'coach_name',
        },
        {
            title: 'Action',
            key: 'action',
            render: (record: any) => renderActionData(record),
        },
    ];

    const renderActionData = (record: any) => {
        const PopoverComponent = ({ record, onPressViewPlan }: any) => {
            const [open, setOpen] = useState(false);
            const content = (
                <>
                    <p style={{ cursor: 'pointer' }} onClick={() => { setSelectedTemplateId(record._id); setViewModalOpen(true); }}>
                        View Template
                    </p>
                    <p style={{ cursor: 'pointer' }} onClick={() => { setSelectedTemplateId(record._id); setCopyTemplateModal(true); }}>
                        Copy Template
                    </p>
                    <p style={{ cursor: 'pointer' }} onClick={() => { setSelectedTemplateId(record._id); setEditModal(true); setAddModalOpen(true); }}>
                        Edit Template
                    </p>
                    <p style={{ cursor: 'pointer' }} onClick={() => { showDeleteConfirm(record.id) }}>
                        Delete Template
                    </p>
                </>
            );

            return (
                <Popover
                    content={content}
                    placement='bottomRight'
                    trigger='click'
                    onOpenChange={(o) => setOpen(o)}
                    open={open}
                >
                    <Button type='primary' style={{ backgroundColor: '#760fb2' }}>
                        Action
                    </Button>
                </Popover>
            );
        };

        return (
            <PopoverComponent
                key={record.id}
                record={record}
            // onPressViewPlan={onClickDietPlan}
            />
        );
    };

    const handleTableChange = (pagination: TablePaginationConfig) => {
        fetchTemplateData(pagination.current, pagination.pageSize);
    };

    const onCloseViewModal = () => {
        setViewModalOpen(false);
        setSelectedTemplateId(null);
    };

    const showDeleteConfirm = (templateId: string) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this template permanently?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action will delete the template permanently',
            async onOk() {
                try {
                    if (!templateId) return;
                    const deletePlan = await API.DeleteTemplateList(templateId);
                    if (deletePlan?.code !== 1) throw new Error(deletePlan?.msg || 'Internal server error!');
                    message.success({
                        content: deletePlan?.msg || 'Diet plan template deleted successfully.',
                    });
                    setRefreshList(true);
                } catch (error) {
                    console.log(error);
                    message.error({ content: 'Failed to delete diet template!' });
                }
            },
            onCancel() { },
            okButtonProps: {
                style: { backgroundColor: '#760fb2', borderColor: '#760fb2' },
            },
        });
    };

    const fetchTemplateData = async (page = 1, pageSize = DEFAULT_PAGE_SIZE) => {
        try {
            setLoading(true);
            const response = await API.GetDietTemplateList({ page, limit: pageSize });
            if (response?.code === 1) {
                const modifiedPlanList = modifyPlanResponseData(response?.data || []);
                setTemplateList(modifiedPlanList);
                setPagination({
                    current: response?.current_page || 1,
                    pageSize: response?.limit || DEFAULT_PAGE_SIZE,
                    total: response?.total_records || 0,
                });
            }
        } catch (error) {
            console.log('Error fetching template list:', error);
            message.error({ content: 'Failed to fetch template list!' });
        } finally {
            setLoading(false);
            setRefreshList(false);
        }
    };

    const modifyPlanResponseData = (response: any[]) => {
        return response.map((resp: any, index: number) => ({
            ...resp,
            key: resp._id || index,
            id: resp._id || '',
        }));
    };

    const showCloseConfirm = () => {
        if (!dataAdded) {
            setAddModalOpen(false);
            setSelectedTemplateId(null);
            setEditModal(false);
            setDataAdded(false);
            return;
        }
        Modal.confirm({
            title: 'Are you sure you want to close the modal?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action will remove all filled details.',
            async onOk() {
                setAddModalOpen(false);
                setSelectedTemplateId(null);
                setEditModal(false);
                setDataAdded(false);
                setDataAdded(false);
            },
            onCancel() { },
            okButtonProps: {
                style: { backgroundColor: '#760fb2', borderColor: '#760fb2' },
            }
        });
    };

    const onCloseCopyTemplate = () => {
        setCopyTemplateModal(false);
    };

    useEffect(() => {
        fetchTemplateData();
    }, []);

    useEffect(() => {
        if (refreshList) fetchTemplateData();
    }, [refreshList]);

    return (
        <div className='container-wrapper'>
            <div className='button-wrapper diet-template-wrapper'>
                <button className='buttons' onClick={() => { setAddModalOpen(true) }}>
                    Add Diet Template
                </button>
            </div>
            <div className='table-wrapper'>
                <Table
                    className='custom-table'
                    dataSource={templateList}
                    columns={columns}
                    loading={loading}
                    pagination={{
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: pagination.total,
                    }}
                    onChange={handleTableChange}
                />
            </div>

            {viewModalOpen &&
                <Modal
                    className='custom-modal'
                    title='Diet Plan Template'
                    centered
                    open={viewModalOpen}
                    onCancel={onCloseViewModal}
                    width={'85vw'}
                    footer={false}
                >
                    <ViewDietPlanTemplate templateId={selectedTemplateId} />
                </Modal>}

            {/* {addModalOpen && <Modal
                className='custom-modal'
                title='Add Diet Plan Template'
                centered
                open={addModalOpen}
                onCancel={showCloseConfirm}
                width={'85vw'}
                footer={false}
            >
                <AddDietPlanTemplate setAddModalOpen={setAddModalOpen} setRefreshList={setRefreshList} showCloseConfirm={showCloseConfirm} />
            </Modal>} */}

            {addModalOpen && <Modal
                className='custom-modal'
                title={`${editModal ? 'Edit' : 'Add'} Diet Plan Template`}
                centered
                open={addModalOpen}
                onCancel={showCloseConfirm}
                width={'85vw'}
                footer={false}
            >
                <AddDietPlanTemplate
                    setAddModalOpen={setAddModalOpen}
                    setRefreshList={setRefreshList}
                    showCloseConfirm={showCloseConfirm}
                    editModal={editModal}
                    templateId={selectedTemplateId}
                    dataAdded={dataAdded}
                    setDataAdded={setDataAdded}
                    setEditModal={setEditModal}
                    setSelectedTemplateId={setSelectedTemplateId}
                />
            </Modal>}

            {copyTemplateModal && <Modal
                className='custom-copy-modal'
                title={'Copy Template'}
                centered
                open={copyTemplateModal}
                onCancel={onCloseCopyTemplate}
                footer={false}
            >
                <CopyDietTemplate
                    templateId={selectedTemplateId}
                    onCloseModal={onCloseCopyTemplate}
                    setRefreshList={setRefreshList}
                />
            </Modal>}
        </div>
    );
};

export default DietPlanTemplate;