import { FunctionComponent, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import "./DoctorInfo.css";
import editIcon from '../../assets/icons/edit.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface InfoProps {
  doctorDetails: any;
  onSave?: (updatedDetails: any, userType: string) => void;
}

const DoctorInfo: FunctionComponent<InfoProps> = ({ doctorDetails, onSave }) => {

  const [editedDetails, setEditedDetails] = useState<any>([]);

  useEffect(() => {
    if (doctorDetails) {
      setEditedDetails(doctorDetails)
    }
  }, [doctorDetails])

  return (
    <form className="products-list-frame">
      <div className="button-frame-button-label">
        <div className="button-frame3">
          <div className="button-frame-button-label-fram">
            <div className="products-list10">Doctor Info</div>
          </div>
        </div>
        <div className="button-frame-button-label-child" />
        <div className="frame-products-list-input-fiel1">
          <div className="products-list-input-field-grou2">
            <div className="input-field13">
              <div className="label-parent4">
                <div className="label-custom">Name</div>
              </div>
              <input
                className="placeholder-disable"
                name="doc_name"
                value={editedDetails?.doc_name || ''}
                placeholder="---"
                type="text"
                readOnly
                // onChange={handleChange}
              />
            </div>
            <div className="input-field14">
              <div className="label-parent4">
                <div className="label-custom">Phone Number</div>
              </div>
              <input
                className="placeholder-disable"
                name="doc_contac_no"
                value={editedDetails?.doc_contac_no || ''}
                placeholder="---"
                type="text"
                readOnly
                // onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="frame-products-list-input-fiel1">
          <div className="products-list-input-field-grou2">
            <div className="input-field15">
              <div className="label-parent4">
                <div className="label-custom">City</div>
              </div>
              <input
                className="placeholder-disable"
                name="doc_city"
                value={editedDetails?.doc_city || ''}
                placeholder="---"
                type="text"
                readOnly
                // onChange={handleChange}
              />
            </div>
            <div className="input-field16">
              <div className="label-parent5">
                <div className="label-custom">Hospital</div>
              </div>
              <div className="input-field14">
                <input
                  className="placeholder-disable"
                  name="doc_clinic_name"
                  value={editedDetails?.doc_clinic_name|| ''}
                  placeholder="---"
                  type="text"
                  readOnly
                  // onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default DoctorInfo;
