import React from "react";
import "./DietList.scss";

import { MealOption } from "../../../types/diet";

interface Props {
  data: MealOption;
}

const DietList = ({ data }: Props) => {
  return (
    <div className="diet-list">
      <span className="option-name">Option {data?.option_no}</span>
      <ul>
        {data.option_data &&
          !!data.option_data.length &&
          data.option_data
            .filter((item: any) =>
              item?.is_food_item_added_by_patient === "Y"
                ? item?.meal_taken !== "N"
                : true
            )
            .map((item: any) => (
              <li>
                {item?.is_food_item_added_by_patient === "Y" ? (
                  <span>++</span>
                ) : item?.meal_taken !== "N" ? (
                  <span>✅</span>
                ) : (
                  <span>🛑</span>
                )}
                <span>{item?.food_name}</span>
              </li>
            ))}
      </ul>
    </div>
  );
};

export default DietList;
