
import React from 'react';
import "./ConfirmationModal.css"

interface ModalProps {
    show: boolean;
    onConfirmation: (action: boolean) => void;
    onClose: () => void;
}

const ConfirmationModal: React.FC<ModalProps> = ({ show, onConfirmation, onClose }) => {
    const handleConfirm = (action: boolean) => {
        onConfirmation(action);
    }
    return (
        <div>
            {show && (
                <div className="modal">
                    <div className="close-btn" onClick={onClose}><span>X</span></div>
                    <div className="modal-content">
                        <div>
                            <p>Are you sure you want to take this action?</p>
                            <p>This action can not be Undone</p>
                        </div>
                        <div className="button-container">
                            <button className="modal-btn" onClick={() => handleConfirm(true)}>Yes</button>
                            <button className="modal-btn" onClick={() => handleConfirm(false)}>No</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ConfirmationModal;
