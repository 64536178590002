import { FunctionComponent, useMemo, type CSSProperties } from "react"
import "./AppointmentCell.css";
import { Link } from "react-router-dom";

export type AppointmentCellType = {
  index?: number;
  name?: string;
  contact?: string;
  visitType?: string;
  doctor?: string;
  PSP_Name?: string;
  loginTime?: string;
  status?: string;
  primaryButton?: string;
  userData?: any;
  onPrimaryButtonClick?: () => void;
};

const AppointmentCell: FunctionComponent<AppointmentCellType> = ({
  index,
  name,
  contact,
  visitType,
  doctor,
  PSP_Name,
  loginTime,
  status,
  primaryButton,
  userData,
  onPrimaryButtonClick,
}) => {
  const token = localStorage.getItem("token")

  return (
    <div className="appointment-cell">
      <div className="contact-frame">{index}</div>
      <div className="appointment-cell-inner">
        <div className="contact-frame-parent">
          <div className="name">{name}</div>
        </div>
      </div>
      <div className="appointment-cell-inner">
        <div className="contact">{contact}</div>
      </div>
      <div className="appointment-cell-inner">
        <div className="contact1">{visitType}</div>
      </div>
      <div className="appointment-cell-inner">
        <div className="visit-type">{doctor}</div>
      </div>
      <div className="appointment-cell-inner">
        <div className="program-type">{PSP_Name}</div>
      </div>
      <div className="appointment-cell-inner">
        <div className="googleinc-parent">
          <div className="googleinc">{loginTime?.split(" ")[1]}</div>
          <div className="googleinc-wrapper">
            <div className="googleinc1">{loginTime?.split(" ")[0]}</div>
          </div>
        </div>
      </div>
      <div className="appointment-cell-inner">
        <Link to={{ pathname: `/verify-documents/:token` }} style={{ textDecoration: 'none' }} state={{ patientId: userData.patient_id, token:token}}>
          <button className="secondary-default" >
            <div className="primary-button">{primaryButton}</div>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default AppointmentCell;
