import React, { useEffect, useRef, useState } from "react";

const PageNotFound = () => {
    return (
        <div style={{margin:'20px', fontSize:'30px', color:'lightgray'}}>
            Coming soon...
        </div>
    )
}

export default PageNotFound