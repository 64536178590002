import React from "react";
import "./Diet&Adherence.scss";
import moment from "moment";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import DietList from "../../atoms/Diet/DietList/DietList";

type DietAndAdherenceProps = {
  selectedDate?: any;
  selectedFilter?: any;
  dietListShow?: any;
  selectedDietData?: any;
  selectedDietDate?: any;
  onChangeDate?: any;
};

const DietAndAdherence: React.FC<DietAndAdherenceProps> = ({
  selectedDietData,
  selectedDietDate,
  onChangeDate,
}) => {
  const disabledDate = (current: any) => {
    // Disable dates not having diet plan
    return (
      current.isBefore(moment(selectedDietData?.start_date)) ||
      current.isAfter(moment(selectedDietData?.end_date))
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", minWidth: `60%` }}>
      <DatePicker
        getPopupContainer={(triggerNode: any) => {
          return triggerNode.parentNode;
        }}
        value={
          selectedDietDate && selectedDietDate !== ""
            ? dayjs(selectedDietDate, "YYYY-MM-DD")
            : null
        }
        format={"YYYY-MM-DD"}
        onChange={(moment, date) => {
          onChangeDate(date);
        }}
        disabledDate={disabledDate}
      />
      <div className="diet-adherence-container">
        <div className="diet-plan-header">
          <div className="diet-plan-heading">
            <div className="diet-plan-title">{selectedDietData?.plan_name}</div>
          </div>
        </div>

        <div style={{ marginTop: "25px" }}>
          {selectedDietData.diet_plan_meal_rel &&
            !!selectedDietData.diet_plan_meal_rel.length &&
            selectedDietData.diet_plan_meal_rel.map(
              (meal: any) =>
                (meal?.hide_meal == "N" || !meal?.hide_meal) && (
                  <div>
                    <span className="meal-name">{meal?.meal_type}</span>
                    {meal?.meal_options?.map((mealOption: any) => (
                      <DietList data={mealOption} />
                    ))}
                  </div>
                )
            )}
        </div>
      </div>
    </div>
  );
};

export default DietAndAdherence;
